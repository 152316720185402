<template>
    <div  class="flex items-center rounded-lg shadow-md hover:bg-gray-200 bg-white px-2 py-3 mx-3 mt-2 relative">
        <van-checkbox v-model="LandInfor.isSelect" v-show="showSelect" @click="SelectThis"></van-checkbox>
        <img  :src="'https://datuland.com'+LandInfor.unitpicture" class="w-20 h-20 rounded-full shadow-lg mr-4 ml-2" />
        <div class="flex-grow">
            <div class="font-bold text-lg mb-2">{{ LandInfor.landname }}</div>
            <div class="text-gray-700 mb-2 text-sm">{{$t('ShopCartInfor.num')}}: {{ LandInfor.blocks }}</div>

            <div class="flex items-center">
                <span class="text-gray-500 text-xs mr-1">{{$t('ShopCartInfor.price')}}: </span>
                <span class="text-green-500 text-lg">{{ LandInfor.totalprice }} </span>
            </div>

            <div class="absolute top-0 right-0 mt-2 mr-2">
                <van-tag  round color="#7232dd" class="text-xs px-1" plain>{{ LandInfor.originalLand.originallandname }}</van-tag>
            </div>
            <div class="absolute bottom-0 right-0 mb-2 mr-2">
                <div class="flex items-center rounded-full bg-gray-100 px-1 py-1">
                    <img src="../images/locate.png" class="w-3 h-3" alt="" />
                    <span  class="ml-1 text-xs">{{  LandInfor.originalLand.geographicalcoordinates }}</span>
                </div>
            </div>
        </div>
        <div class="ml-auto hover:bg-gray-300" @click="deleteThis" v-show="showDelete">
            <img src="../images/delete.png" class="h-5 w-5" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 父子组件传值
        LandInfor: {
            // 包含图片链接image、landName、landPrice、landAddress
            type: Object,
            required: true,
            default:{
                originalLand: {
                    geographicalcoordinates: " ",
                },
            }
        },
        showSelect: {
            type: Boolean,
            required: false,
            default: false,
        },
        showDelete: {
            type: Boolean,
            required: false,
            default: false,
        },
        index: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {};
    },
    methods: {

        SelectThis() {
            // this.LandInfor.isSelect = !this.LandInfor.isSelect;
            this.$emit("selectThis", this.LandInfor.totalprice, this.LandInfor.isSelect);
        },
        deleteThis() {

        },
    },
};
</script>

<style scoped>
.market-background {
    height: 170px;
    width: 100%;
    background-color: #dedede;
    position: relative;
    margin-top: 10px;
    border-radius: 6px;
}
.market-select {
    /* 选中圆形栏 */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 30px;
}
.market-image {
    /* 图片区域 */
    height: 150px;
    width: 150px;
    max-height: 150px;
    max-width: 150px;
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translate(0, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
.market-landname {
    /* 土地名称 */
    position: absolute;
    top: 30px;
    left: 210px;
    font-size: 16px;
    font-weight: bold;
}
.market-landnum {
    /* 单位土地数量 */
    position: absolute;
    top: 60px;
    left: 210px;
    font-size: 14px;
}
.market-landprice {
    /* 土地价格 */
    position: absolute;
    bottom: 20px;
    left: 210px;
    font-size: 16px;
}
.market-landaddress {
    /* 小标签 */
    height: 20px;
    width: 80px;
    position: absolute;
    top: 90px;
    left: 210px;
    background-color: #582d7e;
    border-radius: 12px;
    color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 14px;
}
/* 删除该条主题信息 */
.mobile-email-box-delete {
    max-height: 30px;
    max-width: 30px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
}
</style>
