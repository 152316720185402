<template>
    <div class="first-main-container">
        <div class="go-back-button" @click="goBack" @mouseover="changeCursor">
            <img src="../icons/左箭头.png" class="go-back-button-img">
            <span class="go-back-button-text">返回</span>
        </div>
        <div class="add-parent">
            <el-steps :space="200" :active="active" finish-status="success">
                <el-step :title="stepTitle(0)" description="修改初始密码"></el-step>
                <el-step :title="stepTitle(1)" description="上传企业信息"></el-step>
                <el-step :title="stepTitle(2)" description="等待管理员审核"></el-step>
                <el-step :title="stepTitle(3)" description="审核结束"></el-step>
            </el-steps>
            <div class="add-box" v-if="active === 0" v-loading="loading">
                <div style="height: 500px;width: 500px;">
                <div class="add-input-parent">
                    <span>请输入原密码：</span>
                    <el-input :type="showPassword1 ? '' : 'password'" v-model="changePassword1" style="width:400px" placeholder="请输入修改后的密码" @input="checkPassword">
                        <el-tooltip class="item" effect="dark" content="点击显示/隐藏密码" placement="top-end"  slot="append">
                            <span :class="showPassword1 ? 'el-icon-view' : 'el-icon-lock'" @click="togglePassword(0)" @mouseover="changeCursor"></span>
                        </el-tooltip>
                    </el-input>
                </div>
                <div class="add-input-parent">
                    <span>请输入新密码：</span>
                    <el-input :type="showPassword2 ? '' : 'password'" v-model="changePassword2" style="width:400px" placeholder="请输入修改后的密码" @input="checkPassword">
                        <el-tooltip class="item" effect="dark" content="点击显示/隐藏密码" placement="top-end"  slot="append">
                            <span :class="showPassword2 ? 'el-icon-view' : 'el-icon-lock'" @click="togglePassword(1)" @mouseover="changeCursor"></span>
                        </el-tooltip>
                    </el-input>
                    <p v-show="showRules" style="font-size: 12px;">密码必须是8-16位字符,必须包含字母、数字、符号中至少两种。</p>
                    <p v-show="showRules" style="font-size: 12px;">不可输入空格</p>
                </div>
                <div>
                    <button class="add-button" @click="showModal" @mouseover="changeCursor">确定</button>
                    <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
                    <div class="modal" v-if="vmodal" @click="closeModal">
                        <div class="modal-box" v-if="!showError">
                            <span>确定修改密码？</span>
                            <div style="margin-top: 50px;">
                                <button class="modal-button" @click="add" style="margin-right: 30px;">确定</button>
                                <button class="modal-button" @click="closeModal">取消</button>
                            </div>
                        </div>
                        <div class="modal-box" v-if="showError">
                            <div>密码格式错误,请检查</div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="add-box" v-if="active === 1">
                <CompanyInfor :active = 'active'></CompanyInfor>
            </div>
            <div class="add-box" style="flex-direction: initial;" v-if="active === 2">
                <i class="el-icon-time"></i>
                <h2>审核中,预计等待1~3个工作日</h2>
            </div>
            <div class="add-box" style="flex-direction: initial;" v-if="active === 3">
                <i class="el-icon-close"></i>
                <h2>审核被驳回，请检查企业信息并重新上传认证</h2>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import CompanyInfor from './CompanyInfor.vue';
export default {
    components: {
        CompanyInfor, // 上传企业信息
    },
    mounted() {
        this.$http.get('/v1/index/getVerificationstatus', {
            withCredentials: true,
          })
          .then(res => {
            // 处理返回值
            const statu = res.data.data
            if(statu === '未重置密码') {
                this.active = 0
            } else if (statu === '未身份认证') {
                this.active = 1
            } else if (statu === '待审核') {
                this.active = 2
            } else if (statu === '已驳回') {
                this.active = 3
            } else if (statu === '已通过') {
                this.$router.push('/developer/home-page')
            }
          })
          .catch(error => {
            // 处理错误
            console.error(error)
          });
    },
    data() {
        return {
            businessid: '', // 企业用户的id，需要从网址获取
            active: 0,
            changePassword1: '',
            showPassword1: false,
            changePassword2: '',
            showPassword2: false,
            vmodal: false, // 显示弹窗
            showRules: false, // 输入新密码时显示的密码规则
            showError: false, // 输入完密码后点击确认按钮时验证密码格式是否正常
            differentPasswordError: false, // 两个密码不同时提示
            loading: false, // 修改密码时有一段请求数据库的时间，此时禁止操作
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        stepTitle(n) {
            if(this.active === n) {
                return '进行中'
            } else if(this.active > n) {
                return '已完成'
            } else if(this.active < n) {
                return '等待中'
            }
        },
        togglePassword(n) {
            if(n) {
                this.showPassword2 = !this.showPassword2
            } else {
                this.showPassword1 = !this.showPassword1
            }
        },
        goBack() {
            this.$router.go(-1)
        },
        showModal() {
            const re = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/;
            if (re.test(this.changePassword2)) {
                // 合法操作
                this.showError = false;
            } else {
                // 非法操作
                this.showError = true;
            }
            this.vmodal = true
        },
        closeModal() {
            this.vmodal = false
        },
        add() {
            this.$http.put('/v1/index/reset-userbusiness-password?Oldpassword=' + this.changePassword1 + '&Newpassword=' + this.changePassword2)
            .then(res => {
                if(res.data.data === '密码重置成功') {this.active++}
                else {alert(res.data.data)}
            })
            .catch(error => {
                // 处理错误
                alert('服务器异常，密码重置失败!')
            });
        },
        checkPassword() { // 点击密码输入框后检查密码格式是否错误
            const re = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/;
            this.showRules = !re.test(this.changePassword2);
        },
    },
}
</script>

<style scoped>
.first-main-container {
    height: 100vh;
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    overflow-y: auto; /* 添加滚动条，超出屏幕高度时可以滚动 */
}
/* -----------------------------------------返回文本按钮------------------------------------------ */
/* 返回文本按钮 */
.go-back-button {
    padding: 20px;
    white-space: nowrap;
}
/* 返回文本按钮左箭头图标 */
.go-back-button-img {
    vertical-align: top;
    display: inline-block;
    height: 26.67px;
    width: 40px;
}
/* 返回文本按钮文本 */
.go-back-button-text {
    vertical-align: top;
    display: inline-block;
    font-size: 20px;
}


.goback-parent {
    display: flex;
    align-items: center; /* 垂直居中 */
    font-size: 20px;
    margin-left: 20px;
    margin-top: 20px;
}
.goback-img {
    height: 20.67px;
    width: 40px;
}
.add-parent {
    padding: 50px;
    padding-top: 0;
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    justify-content: center; /* 水平居中 */
    flex: 1; /* 占据剩余空间 */
}
.el-steps {
    display: flex;
    justify-content: center; /* 水平居中 */
}
.add-box {
    flex: 1;
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    flex-direction: column;
    background-color: #a7a7a7;
    color: #fff;
}
.add-input-parent {
    padding: 20px;
    font-size: 30px;
}
.add-input {
    height: 34px;
    margin-left: 10px;
    vertical-align: top;
}
.add-button {
    height: 40px;
    width: 500px;
    margin-top: 30px;
    background-color: #ddd3fd;
    color: #fff;
}
/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    height: 150px;
    width: 300px;
    background-color: #a7a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>