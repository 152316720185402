<template>
    <div class="mobile-detail-home bg-gray-50">
        <van-nav-bar class="bg-gray-50" :title="$t('MobileLandDetail.title')" :left-text="$t('MobileLandDetail.back')" left-arrow @click-left="goBack" />
        <div class="flex flex-col animate__animated animate__fadeInUp" style="padding: 20px">
            <canvas ref="bigmap" :width="canvasSize" :height="canvasSize" class="mobile-detail-map mx-1 mb-3 shadow-lg rounded-lg  border-3 border-gray"></canvas>
            <!-- 挂牌信息 -->
            <div class="flex items-center rounded-lg shadow-md bg-white px-5 py-4 mt-0 mb-3">
                <div class="flex-1 flex-col items-end">
                    <p class="text-lg font-semibold">{{ LandInfor.landname }}</p>
                    <p class="text-gray-500 text-blue-500">{{ LandInfor.pricenum }}</p>
                    <p class="text-gray-500 text-xs">({{$t('MobileLandDetail.price')}})</p>
                </div>
                <div class="flex-1 text-right">
                    <p class="text-xs text-gray-600">{{$t('MobileLandDetail.landNum')}}: {{ LandInfor.blocks }}</p>
                    <p class="text-xs text-gray-500 mt-8">{{$t('MobileLandDetail.listTime')}}: {{ LandInfor.createtime }}</p>
                </div>
            </div>
            <!-- 详细信息及原始土地信息 -->
            <div class="rounded-lg shadow-md bg-white px-5 mt-0 mb-3">
                <van-tabs v-model="tabControl">
                    <van-tab :title="$t('MobileLandDetail.detail')">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.area')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitLand.communitynumber }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.number')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitlandid }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.altitude')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.height }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.length')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landlength }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.width')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landwidth }}</span>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab title="原始土地信息">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.landId')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandid }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.landName')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandname }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.developer')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.ownerid }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.location')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.detailedaddress }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobileLandDetail.coordinate')}}:</span>
                                <span class="text-gray-700 text-sm">({{ LandInfor.originalLand.geographicalcoordinates }})</span>
                            </div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
            <!-- 地权解释 -->
            <div class="rounded-lg shadow-md bg-white px-5 mt-1 mb-3 pt-1">
                <span class="text-gray-600 text-md">{{$t('MobileLandDetail.landRights')}}</span>
                <div class="flex flex-col mb-3">
                    <div class="mt-1">
                        <span class="text-gray-500 text-xs mr-1">{{$t('MobileLandDetail.surface')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobileLandDetail.surfaceIntro')}}</span>
                    </div>
                    <div class="mt-1">
                        <span class="text-gray-500 text-xs mr-1">{{$t('MobileLandDetail.digital')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobileLandDetail.digitalIntro')}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-gray-50 mobile-detail-bottom border-t border-gray-300 py-3 flex items-center justify-between">
            <div class="px-2 py-1 mr-1 ml-3   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-xs rounded w-auto" @click="OpenSeller">{{$t('MobileLandDetail.seller')}}<br/>{{$t('MobileLandDetail.home')}}</div>
            <div class="px-2 py-1 mr-1 shadow-md text-white hover:bg-purple-600 bg-purple-500  cursor-pointer text-xs rounded  w-auto" @click="TalkSeller">{{$t('MobileLandDetail.private')}}<br/>{{$t('MobileLandDetail.chat')}}</div>
            <div>
                <button class="bg-blue-500 text-white hover:bg-blue-600 rounded px-4 py-2 w-26" style="font-size: 0.75rem" @click="addLandCart" v-if="haveAddIn">{{$t('MobileLandDetail.add')}}</button>
                <button class="bg-gray-300 text-gray-600 rounded px-4 py-2 flex items-center w-28" v-if="!haveAddIn">
                    <span>{{$t('MobileLandDetail.havaAdd')}}</span>
                </button>
            </div>
            <button class="bg-red-500 text-white hover:bg-red-600 rounded ml-1 mr-5 px-4 py-2 w-28" @click="onBuy">{{$t('MobileLandDetail.buy')}}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "MobileLandDetail",
    created() {
        // 不要忘了获取传输过来的土地信息
        this.selectPage = this.$route.query.index;
        this.originallandid = this.$route.query.land;
        this.unitlandid = this.$route.query.merch;
    },
    mounted() {
        this.canvasSize=this.$refs.bigmap.clientWidth;

        // 获取土地详情信息
        if (this.flag) {
            this.flag = false;
            this.$http
                .get("/v1/sale/get-saleselect-one?unitlandid=" + this.unitlandid)
                .then((res) => {
                    this.LandInfor = res.data.data;
                    this.LandInfor.createtime = this.LandInfor.createtime.split("")[0];
                    let time1 = setTimeout(() => {
                        // 等待 100 毫秒后再绘制
                        const canvas = this.$refs.bigmap; // 获取 canvas 元素
                        canvas.style.backgroundImage = "url(https://datuland.com" + this.LandInfor.originalLand.landpicture + ")";
                        this.drawGrid();
                        this.drawLand();
                        clearInterval(time1);
                    }, 100);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        this.isInMyCart();
    },
    data() {
        return {
            selectPage: null, // 下方选项头的属性
            originallandid: null, // 与unitlandid共同获取该商品详细信息
            unitlandid: null, // 与originallandid共同获取该商品详细信息
            flag: true, // 防抖
            haveAddIn: true, // 表示是否已加入购物车，已经加入的为false
            LandInfor: {
                id: 138,
                originallandid: " ",
                unitlandid: " ",
                landname: " ",
                sellerid: " ",
                seller: " ",
                salesmethods: " ",
                expirydate: " ",
                unitlandprice: null,
                pricenum: ' ',
                unitlandminprice: null,
                minimumprice: null,
                totalprice: null,
                saleslandpicture: null,
                createtime: " ",
                status: " ",
                remark: null,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                unitLand: {
                    id: 0,
                    communitynumber: " ",
                    originallandid: " ",
                    mergelandname: " ",
                    landnumber: " ",
                    landblocks: 0,
                    landcoordinates: " ",
                    virtualownership: " ",
                    remark: " ",
                    status: " ",
                    createdate: " ",
                },
            },
            canvasSize: 0,
            gridSize: 0, // 保存网格边长，用来绘制土地块时使用
            tabControl: 0, // 第三格土地详情栏tab控制参数，true是土地详情，false是原始土地详情
        };
    },
    methods: {
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            canvas.width=this.canvasSize;
            canvas.height=this.canvasSize;
            this.gridSize = this.canvasSize / 22;// 清除原有的所有大地图画布元素
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#5eee45"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.unitLand.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);
            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col;
            const height = end.row - start.row;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            // ctx.fillStyle = "red"; // 设置网格颜色
            ctx.fillStyle = "rgba(0,171,151,0.61)";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },
        OpenSeller() {
            // 查看卖家
            this.$router.push({
                path: "/mobile/seller-home?index=" + this.selectPage + "&land=" + this.originallandid + "&merch=" + this.unitlandid + "&seller=" + this.LandInfor.sellerid,
            });
        },
        TalkSeller() {
            // 私聊卖家
            this.$router.push({
                path: "/mobile/private-chat?index=" + this.selectPage + "&land=" + this.originallandid + "&merch=" + this.unitlandid + "&receive=" + this.LandInfor.sellerid,
            });
        },
        goBack() {
            this.$router.go(-1);
        },
        addLandCart() {
            // 加入购物车
            const data = {
                unitlandid: this.LandInfor.unitlandid,
                // landnumber: this.LandInfor.originallandid,
                // landname: this.LandInfor.landname,
                // unitprice: "1000",
                // blocks: this.LandInfor.unitLand.landblocks,
                // totalprice: this.LandInfor.pricenum,
            };
            this.$http
                .post("/v1/shoppingcart/add-landcart", data, {
                    withCredentials: true,
                })
                .then((res) => {
                    this.haveAddIn = false;
                    this.$message({
                        message: this.$t('MobileLandDetail.alertaddIn'),
                        type: "success",
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        isInMyCart() {
            // 判断该商品是否已经加入购物车
            this.$http
                .get("/v1/shoppingcart/get-landcart")
                .then((res) => {
                    let LandInfor = res.data.data;
                    for (let i = 0; i < LandInfor.length; i++) {
                        if (LandInfor[i].unitlandid === this.unitlandid) {
                            this.haveAddIn = false;
                            break;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onBuy() {
            this.$router.push({
                path: "/mobile/confirm-order",
                query: {
                    LandInfor: JSON.stringify([this.LandInfor]),
                    totalPrice: JSON.stringify(this.LandInfor.totalprice),
                },
            });
        },
    },
};
</script>

<style scoped>
.mobile-detail-home {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;
}
/* ---------------------------------顶部：返回功能与图标------------------------------- */
.mobile-email-top {
    height: 70px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #747474;
    position: relative;
    top: 0;
}
.mobile-email-top-topic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
}
.mobile-email-top-goback {
    max-height: 50px;
    max-width: 50px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
}
/* ---------------------------------底部：超链接------------------------------- */
.mobile-detail-bottom {
    height: 50px;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgb(220, 220, 220);
    position: sticky;
    bottom: 0;
}
.mobile-detail-bottom-seller {
    height: 40px;
    width: 40px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    background-color: #31e4ff;
    color: #ff1323;
}
.mobile-detail-bottom-email {
    height: 40px;
    width: 40px;
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    background-color: #31e4ff;
    color: #ff1323;
}
.mobile-detail-bottom-buttons {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
}
.mobile-detail-bottom-button {
    height: 40px;
    width: 100px;
    margin-left: 20px;
    background-color: #fda1ff;
    border-radius: 20px;
    color: #fff;
    font-size: 10px;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    flex-direction: row;
}
/* ---------------------------------第一格：土地地图------------------------------- */
.mobile-detail-map {

    background-size: cover;
}
/* ---------------------------------第二格：土地挂牌信息------------------------------- */
.mobile-detail-landinfor {
    position: relative;
    height: 100px;
    border-radius: 6px;
    background-color: #eeeeee;
}
.mobile-detail-laninfor-data {
    position: absolute;
}
/* ---------------------------------第三格：土地详情信息------------------------------- */
.mobile-detail-landdetail {
    height: auto;
    border-radius: 6px;
    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}
.mobile-detail-landdetail-tab {
    font-size: 14px;
    padding: 10px;
}
.mobile-detail-landdetail-tab-control {
    border-bottom: 2px solid #000000; /* 给文本添加下划线 */
    font-weight: bold;
}
.mobile-detail-landdetail-label {
    color: #b9b9b9;
    padding: 10px;
    font-size: 12px;
}
.mobile-detail-landdetail-text {
    color: black;
    margin-left: 5px;
}
/* ---------------------------------第四格：卖家信息------------------------------- */
.mobile-detail-seller {
    height: auto;
    border-radius: 6px;
    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

</style>
