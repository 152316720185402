<template>
    <div class="main-container2">
        <div class="tabs">
            <div class="tabs-head">
                <div class="tabs-head-item" :class="isTab('全部')" @click="tabsControl('全部')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">全部</span>
                </div>
                <div class="tabs-head-item" :class="isTab('已摘牌')" @click="tabsControl('已摘牌')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">已下架</span>
                </div>
            </div>
            <div class="search-container">
                <div class="search-box">
                    <div class="search-input">
                        <span class="search-input-label">挂牌编号</span>
                        <el-input v-model="search.id" placeholder="请输入用户id"></el-input>
                    </div>
                    <div class="search-input" style="margin-top: 20px;">
                        <span class="search-input-label">卖家编号</span>
                        <el-input v-model="search.sellerid" placeholder="请输入卖家id"></el-input>
                    </div>
                </div>
                <div class="search-box">
                    <div class="search-input">
                        <span class="search-input-label">原始土地id</span>
                        <el-input v-model="search.originallandid" placeholder="请输入所属原始土地编号"></el-input>
                    </div>
                    <div class="search-input" style="margin-top: 20px;">
                        <span class="search-input-label">挂牌时间</span>
                        <el-date-picker
                            v-model="search.date"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                </div>
                <div class="search-box">
                    <div class="search-input">
                        <el-button @click="searchUser">查询</el-button>
                        <el-button style="margin-left: 20px;" @click="resetUser">重置</el-button>
                    </div>
                </div>
                <div class="search-box">
                    <div class="search-input">
                        <el-checkbox v-model="selectThisPage" :indeterminate="isIndeterminate" @change="checkThisPageChange">全选当前页</el-checkbox>
                    </div>
                </div>
            </div>
            <div class="tabs-content">
                <div class="flex flex-row">
                    <div class="sellmanage-infor-checkbox"></div>
                    <el-row :gutter="15" class="tabs-content-row">
                        <el-col :span="4"><i>挂牌时间</i></el-col>
                        <el-col :span="4"><i>挂牌编号</i></el-col>
                        <el-col :span="4"><i>土地名称</i></el-col>
                        <el-col :span="4"><i>卖家id</i></el-col>
                        <el-col :span="4"><i>售价</i></el-col>
                        <el-col :span="4"><i>操作</i></el-col>
                    </el-row>
                </div>
                    <div class="sellmanage-infor-row">
                        <el-checkbox-group v-model="checkList" @change="handleCheckedListsChange" v-for="(apply, index) in LandInfor" :key="index" class="sellmanage-infor-checkbox">
                            <el-checkbox :key="apply.unitlandid"></el-checkbox>
                        </el-checkbox-group>
                        <div class="tabs-content-col" v-for="(apply, index) in LandInfor" :key="index">
                            <el-col :span="4" class="tabs-content-col-text"><i>{{ apply.createtime }}</i></el-col>
                            <el-col :span="4" class="tabs-content-col-text"><i>{{ apply.unitlandid }}</i></el-col>
                            <el-col :span="4" class="tabs-content-col-text"><i>{{ apply.landname }}</i></el-col>
                            <el-col :span="4" class="tabs-content-col-text"><i>{{ apply.sellerid }}</i></el-col>
                            <el-col :span="4" class="tabs-content-col-text"><i>{{ apply.totalprice }}</i></el-col>
                            <el-col :span="4" class="tabs-content-col-text">
                                <i @mouseover="changeCursor" style="text-decoration: underline;" @click="showDetail(apply.unitlandid)">查看详情</i>
                            </el-col>
                        </div>
                    </div>
                <el-pagination
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    background
                    :current-page.sync="currentPage"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="showLength">
                </el-pagination>
            </div>
        </div>
        <!-- 这个是用来封禁用户的弹窗 -->
        <!-- <div class="modal" v-show="modal.bmodal" @click="closeModal">
            <div class="modal-box">
                <span>确定封禁用户？</span>
                <div style="margin-top: 50px;">
                    <button class="modal-button" @click="banUser" style="margin-right: 30px;">确定</button>
                    <button class="modal-button" @click="closeModal">取消</button>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    mounted() {
        /* this.updatePage() */
        this.showLength = 1
    },
    data() {
      return {
        LandInfor: [{
            id: 138,
                originallandid: " ",
                unitlandid: "6000",
                landname: "建筑群",
                sellerid: "b4bcab5f1afa44e8bbba5449afa13d6d",
                seller: " ",
                salesmethods: " ",
                expirydate: " ",
                unitlandprice: null,
                pricenum: ' ',
                unitlandminprice: null,
                minimumprice: null,
                totalprice: 1145,
                saleslandpicture: null,
                createtime: "2023-8-03",
                status: " ",
                remark: null,
        }], // 测试数据，正常用户数据

        showLength: 0, // 所有数据的长度
        whichtab: '全部',
        pageSize: 10, // 每页条数
        currentPage: 1,// 页码

        selectAll: false, // 全选所有挂牌
        selectThisPage: false, // 全选当前页挂牌
        isIndeterminate: false, // 全选当前页是否未全选但选中部分
        checkList: [], // 全选当前页的数组
        search: {
            id: '',
            originallandid: '',
            sellerid: '',
            date: '',
        },
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        
        modal: {
            bmodal: false, // 封禁用户的弹窗
            vmodal: false, // 弹窗开关
            text: '', // 带按钮的弹窗内容
            showError: false, // true是带按钮的，false是仅提示
            errorText: '', // 仅提示时的文本
        },
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n
            this.currentPage = 1
            this.search.id = ''
            this.search.sellerid = ''
            this.search.originallandid = ''
            this.search.date = ''
            this.updatePage()
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'tabs-head-item-select'
            } else {
                return
            }
        },
        updatePage() { // 更新显示的数据
            this.$http
                .get("/v1/sale/get-saleselect-list?pageNum=" + this.currentPage + "&pageSize=" + this.pageSize)
                .then((response) => {
                    let newData = response.data.data.rows;
                    this.LandInfor = newData;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        searchUser() {
            if (this.search.id !== '') {
                if(this.whichtab === '正常') {
                    let array = this.userNormal.filter(function(item){
                        return item.userid === this.search.id
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '封禁中') {
                    let array = this.userBanned.filter(function(item){
                        return item.userid === this.search.id
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '已注销') {
                    let array = this.userLogOut.filter(function(item){
                        return item.userid === this.search.id
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                }
            } else if (this.search.name !== '') {
                if(this.whichtab === '正常') {
                    let array = this.userNormal.filter(function(item){
                        return item.username === this.search.name
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '封禁中') {
                    let array = this.userBanned.filter(function(item){
                        return item.username === this.search.name
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '已注销') {
                    let array = this.userLogOut.filter(function(item){
                        return item.username === this.search.name
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                }
            } else if (this.search.date !== '') {
                if(this.whichtab === '正常') {
                    let array = this.userNormal.filter(function(item) {
                        return item.createdate >= this.search.date[0] && item.createdate <= this.search.date[1];
                    });
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '封禁中') {
                    let array = this.userBanned.filter(function(item){
                        return item.createdate >= this.search.date[0] && item.createdate <= this.search.date[1];
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '已注销') {
                    let array = this.userLogOut.filter(function(item){
                        return item.createdate >= this.search.date[0] && item.createdate <= this.search.date[1];
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                }
            } else {
                alert('请填写任一查询方式')
            }
        },
        resetUser() {
            this.search.id = ''
            this.search.sellerid = ''
            this.search.originallandid = ''
            this.search.date = ''
            this.currentPage = 1
            this.updatePage()
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val
            // 更新显示的数据
            this.updatePage()
        },
        pageCurrentChange(val) {
            // 更新页码
            this.currentPage = val
            // 更新显示的数据
            this.updatePage()
        },
        /* 全选当前页的多选框点击事件设置 */
        checkThisPageChange(val) {
            this.checkList = val ? this.LandInfor.map(item => item.unitlandid) : [];
            this.isIndeterminate = false;
        },
        handleCheckedListsChange(value) {
            let checkedCount = value.length;
            this.selectThisPage = checkedCount === this.LandInfor.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.LandInfor.length;
        },
        closeModal() {
            this.modal.vmodal = false
            this.modal.bmodal = false
        },
        showModal(val) {
            this.modal.showError = true
            this.modal.errorText = '此账号注销于2023-8-01'
            this.modal.vmodal = true
        },
        showDetail(id) {
            this.$router.push({
                path: '/admin/sell-manage/detail?merch=' + id})
        },
    },
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container2 {
    height: 100%;
    background-color: black;
    padding: 50px;
}
.tabs {
    min-height: 100%;
    width: 100%;
}



/* --------------------------------------------选项卡样式-------------------------------------------- */
.tabs-head {
    width: 100%;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
    /* 在父块加上position: relative;并在子块加上position: absolute;来控制子块在父块内的位置 */
    position: relative;
}
.tabs-head-item {
    display: inline-block;
    height: 50px;
    width: 100px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.tabs-head-item-select {
    padding: 0;
    margin: 0;
    border-bottom: none;
    background-color: #383838;
    color: aqua;
    font-size: 18px;
    font-weight: bold;
}
.tabs-head-item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
/* 新增企业按钮 */
.tabs-head-add {
    display: inline-block;
    position: absolute;
    right: 0;
}
.tabs-head-add-button {
    height: 50px;
    width: 100px;
    border: 1px solid #383838;
    border-radius: 6px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}



/* --------------------------------------------搜索栏样式-------------------------------------------- */
.search-container {
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    color: #fff;
}
.search-box {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}
.search-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.search-input-label {
    width: 100px;
}



/* --------------------------------------------管理页面样式-------------------------------------------- */
/* -------------------------------土地上传管理页面------------------------------- */
.tabs-content {
    padding: 20px;
}
/* 信息栏样式 */
.tabs-content-row {
    flex: 1;
    padding: 10px;
    text-align: center;
    color: #fff;
}
.tabs-content-col {
    min-height: 50px;
    flex: 1;
    /* 采用`flex`布局，则可以通过设置`justify-content`和`align-items`属性来实现水平和垂直居中 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
}
.tabs-content-col-text {
    word-wrap: break-word;
    text-align: center;
}
.sellmanage-infor-row {
    display: flex;
    flex-direction: row;
    background-color: #a7a5a5;
    border: 2px solid #a7a5a5;
    border-radius: 6px;
}
.sellmanage-infor-checkbox {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    flex-direction: column;
    width: 8.33333%;
}


/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    padding: 50px;
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-text {
    width: 300px; /* 设置文本容器的宽度 */
    word-wrap: break-word; /* 当单词太长时，允许在单词内部进行换行 */
    overflow-wrap: break-word; /* 标准的word-wrap属性的替代写法 */
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}
.modal-select {
    width: 200px;
    margin-top: 30px;
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>