<template>
    <div class="mobile-email-home">
        <van-nav-bar class="bg-gray-50" :title="$t('MobileServiceA.title')" :left-text="$t('MobileServiceA.back')" left-arrow @click-left="goBack" />
        <div style="min-height: 200px" class="animate__animated animate__fadeInUp mt-2 mx-3 bg-white rounded-lg shadow-md">
            <MobileMessageInfor v-for="(message, index) in Message" :key="index" :Message="message" />
        </div>
        <div class="fixed bottom-2 left-0 right-0 flex justify-center">
            <button class="py-3 px-10 bg-blue-500 text-white rounded-full" @click="reply">{{$t('MobileServiceA.reply')}}</button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import MobileMessageInfor from "./MobileMessageInfor.vue";
export default {
    components: {
        // 土地市场组件
        MobileMessageInfor, // 对话信息
    },
    mounted() { // TODO 未解封
        /* this.$http.get('/v1/mail/select-threadid', {
            withCredentials: true,
        }).then(res => {
            this.Message = res.data.data
        }).catch(error => {
            console.log(error)
        }) */
    },
    data() {
        return {
            flag: true,
            Message: [
                {
                    id: 6134,
                    emailsid: "3732cf6ce03047dc996c003940212eff",
                    threadid: "3732c6ce03047dc996c003940212eff",
                    unitlandid: "6061",
                    fromid: "43a89960781b41de9e16c2575772dc54",
                    fromname: "亚瑟",
                    recipientid: "b4bcab5flafa44e8bbba5449afa13d6d",
                    recipientname: "隔壁老王",
                    emailscontent: "你好，你家土地有问题，地里都是虫子!!!",
                    createdate: "2023-07-10T16:49:02",
                    receivedate: null,
                    fromdelete: false,
                    recipientdelete: false,
                    isme: true, // 当前用户是否为发送方
                },
                {
                    id: 6134,
                    emailsid: "3732cf6ce03047dc996c003940212eff",
                    threadid: "3732c6ce03047dc996c003940212eff",
                    unitlandid: "6061",
                    fromid: "43a89960781b41de9e16c2575772dc54",
                    fromname: "亚瑟",
                    recipientid: "b4bcab5flafa44e8bbba5449afa13d6d",
                    recipientname: "隔壁老王",
                    emailscontent: "你好,工号2023为您服务~",
                    createdate: "2023-07-10T16:49:02",
                    receivedate: null,
                    fromdelete: false,
                    recipientdelete: false,
                    isme: false, // 当前用户是否为发送方
                },
            ],
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        reply() {}, // TODO 客服回复还没功能
    },
};
</script>

<style scoped>
.mobile-email-home {
    height: 100%;
    width: 100%;
    background-color: #f9fafb;
    position: relative;
    overflow: hidden;
}
/* ---------------------------------顶部：返回图标与标题------------------------------- */
.mobile-email-top {
    height: 70px;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgb(220, 220, 220);
    position: sticky;
    top: 0;
}
.mobile-email-top-topic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
}
.mobile-email-top-goback {
    max-height: 50px;
    max-width: 50px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
}
/* ---------------------------------所有意见------------------------------- */
.mobile-email-input {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #fff;
}
.mobile-email-input-inn {
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #dcdcdc;
}
</style>
