<template>
    <div class="first-main-container">
        <div class="go-back-button" @click="goBack" @mouseover="changeCursor">
            <img src="../icons/左箭头.png" class="go-back-button-img">
            <span class="go-back-button-text">返回</span>
        </div>
        <div class="add-parent">
            <el-steps :space="200" :active="active" finish-status="success">
                <el-step :title="stepTitle(0)" description="上传土地信息"></el-step>
                <el-step :title="stepTitle(1)" description="待审核"></el-step>
                <el-step :title="stepTitle(2)" description="已驳回"></el-step>
                <el-step :title="stepTitle(3)" description="已通过"></el-step>
            </el-steps>
            <div class="add-box" v-if="active === 0">
                <LandUpload :active = 'active'></LandUpload>
            </div>
            <div class="add-box" style="flex-direction: initial;" v-if="active === 1">
                <i class="el-icon-time"></i>
                <h2>审核中，预计等待1~3个工作日</h2>
            </div>
            <div class="add-box" style="flex-direction: initial;" v-if="active === 2">
                <i class="el-icon-close"></i>
                <h2>审核被驳回，请检查土地信息并重新上传认证</h2>
                <span @mouseover="changeCursor" @click="gotolandupload">修改信息</span>
            </div>
            <div class="add-box" style="flex-direction: initial;" v-if="active === 3">
                <i class="el-icon-close"></i>
                <h2>审核通过</h2>
                <span @mouseover="changeCursor" @click="gotoland">查看土地</span>
            </div>
        </div>
    </div>
</template>

<script>
import LandUpload from './LandUpload.vue';
export default {
    components: {
        LandUpload, // 上传土地信息
    },
    created() {
        this.landid = this.$route.query.land;
    },
    mounted() {
        if(this.landid) {
            this.$http.get('/v1/index/getVerificationstatus', {
                withCredentials: true,
            }).then(res => {
                // 处理返回值
                const statu = res.data.data
                if(statu === '待审核') {
                    this.active = 1
                } else if (statu === '已驳回') {
                    this.active = 2
                } else if (statu === '已上线') {
                    this.active = 3
                }
            }) .catch(error => {
                // 处理错误
                alert('获取状态失败，请重新登录')
            });
        }
    },
    data() {
        return {
            active: 0,
            changePassword2: '',
            vmodal: false, // 显示弹窗
            landid: null, // 土地编号，只有当查看土地时有编号
            landName: null, // 同上
        }
    },
    methods: {
        goBack() { // 返回文本按钮事件
            this.$router.go(-1)
        },
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        stepTitle(n) {
            if(this.active === n) {
                return '进行中'
            } else if(this.active > n) {
                return '已完成'
            } else if(this.active < n) {
                return '等待中'
            }
        },
        showModal() {
            const re = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/;
            if (re.test(this.changePassword2)) {
                // 合法操作
                this.showError = false;
            } else {
                // 非法操作
                this.showError = true;
            }
            this.vmodal = true
        },
        closeModal() {
            this.vmodal = false
        },
        gotolandupload() {
            this.active = 0
        },
        gotoland() {
            this.$router.push({
                path: '/land-operate',
                name: 'Originland',
                params: {
                    landid: this.landid,
                    landName: this.landName,
                }
            })
        },
    },
}
</script>

<style scoped>
.first-main-container {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.goback-parent {
    display: flex;
    align-items: center; /* 垂直居中 */
    font-size: 20px;
    margin-left: 20px;
    margin-top: 20px;
}
.goback-img {
    height: 20.67px;
    width: 40px;
}
.add-parent {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    justify-content: center; /* 水平居中 */
    flex: 1; /* 占据剩余空间 */
}
.el-steps {
    display: flex;
    justify-content: center; /* 水平居中 */
}
.add-box {
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    flex-direction: column;
    background-color: #a7a7a7;
    color: #fff;
}
.add-input-parent {
    padding: 20px;
    font-size: 30px;
}
.add-input {
    height: 34px;
    margin-left: 10px;
    vertical-align: top;
}
.add-button {
    height: 40px;
    width: 500px;
    margin-top: 30px;
    background-color: #ddd3fd;
    color: #fff;
}
/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    height: 150px;
    width: 300px;
    background-color: #a7a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}


/* -----------------------------------------返回文本按钮------------------------------------------ */
/* 返回文本按钮 */
.go-back-button {
    padding: 20px;
    white-space: nowrap;
}
/* 返回文本按钮左箭头图标 */
.go-back-button-img {
    vertical-align: top;
    display: inline-block;
    height: 26.67px;
    width: 40px;
}
/* 返回文本按钮文本 */
.go-back-button-text {
    vertical-align: top;
    display: inline-block;
    font-size: 20px;
}
</style>