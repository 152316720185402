<template>
    <div class="mobile-orderList-home">
        <van-nav-bar class="bg-gray-50" :title="$t('MobileOrderList.title')" :left-text="$t('MobileOrderList.back')" left-arrow @click-left="onBack" />
        <van-skeleton  v-for="i in 10" class="mt-3" title avatar :row="3" :loading="loading" />
        <van-empty v-if="LandInfor.length === 0" :description="$t('MobileOrderList.noorder')" />

        <div class="orderList-view-infor">
            <div class="orderList-view-filter">
                <van-dropdown-menu active-color="#1989fa">
                    <van-dropdown-item v-model="filterMode" :options="filterOption" @change="productFilter" />
                </van-dropdown-menu>
            </div>
            <div v-for="(order, index) in LandInfor" :key="index" class="order-bigorder-box">
                <div class="order-background animate__animated animate__fadeInUp" v-for="(land, index2) in order.separateOrders" :key="index2" @click="turnToDetail(index,index2,land.separateOrderId,order.combinedOrderId)">
                    <van-checkbox icon-size="20px" class="order-select" v-model="select[index][index2].isSelect" v-show="!mode"></van-checkbox>
                    <img :src="land.landpicture?'https://datuland.com'+land.landpicture:require('../assets/no-image.png')" class="order-image" :style="mode?'left:20px':''" />
                    <div class="order-landname" :style="mode?'left:130px':''">{{ land.landname }}</div>
                    <div class="order-landprice" :style="mode?'left:130px':''">{{ land.totalprice }}</div>
                    <div class="order-status">{{ land.orderstatus }}</div>
                </div>
                <div class="order-continue" v-if="order.separateOrders[0].orderstatus === '待付款'">
                    <button class="footer-button" :style="$i18n.locale === 'en'?'font-size:12px':''" @click="showContinue = true;totalPrice = order.totalAmount;orderInfor = order">{{$t('MobileOrderList.continue')}}</button>
                </div>
            </div>
        </div>
        <div class="footer-container">
            <button class="footer-button bg-green-400" v-if="mode" @click="mode = false">{{$t('MobileOrderList.bulkRefund')}}</button>
            <button class="footer-button bg-yellow-300" v-else-if="!mode" @click="mode = true">{{$t('MobileOrderList.exit')}}</button>
        </div>

        <!-- 退款弹窗 -->
        <van-popup v-model="showRefund" position="bottom" round class="py-5" >
            <div class="items-center justify-center">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md">{{$t('MobilePurchaseDetails.refundNum')}}</span>
                        <span class="text-gray-600 text-lg my-2 ">￥{{refundPrice}}</span>
                    </div>
                    <div class="flex flex-col items-center justify-center p-2">
                        <span class="text-gray-600 text-md ">{{$t('MobilePurchaseDetails.reason')}}</span>
                        <van-field
                            v-model="refundReason"
                            :placeholder="$t('MobilePurchaseDetails.reasonInput')"
                            type="textarea"
                            rows="5"
                            autosize
                            show-word-limit
                            maxlength="100"
                            class="refund-modal-input-box"
                        />
                    </div>
                </div>
                <div class="flex  items-center justify-center">
                    <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="showRefund = false;refundReason = '';">{{$t('MobilePurchaseDetails.cancel')}}</button>
                    <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="RefundConfirm">{{$t('MobilePurchaseDetails.confirm')}}</button>
                </div>
            </div>
        </van-popup>

        <!-- 继续支付弹窗 -->
        <van-popup v-model="showContinue" position="bottom" round class="py-5" >
            <div class="items-center justify-center">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md">{{$t('MobileOrderList.continueTitle')}}</span>
                        <span class="text-gray-600 text-lg my-2 ">￥{{totalPrice}}</span>
                    </div>
                    <div class="grid grid-cols-1 gap-2 text-lg">
                        <!-- TODO 注释掉了支付宝和微信支付 -->
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                            <div class="flex items-center">
                                <img src="../images/alipay.png" alt="" class="w-8 h-8 mr-2" />
                                {{$t('MobileConfirmOrder.aliPay')}}
                            </div>
                            <van-radio class="flex justify-center items-center" v-model="payWay" name="aliPay" disabled></van-radio>
                        </div>
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                            <div class="flex items-center"><img src="../images/wechat.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.wechat')}}</div>
                            <van-radio class="flex justify-center items-center" v-model="payWay" name="wechat" disabled></van-radio>
                        </div>
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                            <div class="flex items-center"><img src="../images/paypal.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.paypal')}}</div>
                            <van-radio class="flex justify-center items-center" v-model="payWay" name="PayPal"></van-radio>
                        </div>
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10">
                            <div class="flex items-center"><img src="../images/balance.png" alt="" class="w-8 h-8 mr-2" />
                                {{$t('MobileConfirmOrder.balance')}}
                            </div>
                            <van-radio class="flex justify-center items-center" v-model="payWay" name="balance"></van-radio>
                        </div>
                    </div>
                </div>
                <div class="flex  items-center justify-center">
                    <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="showContinue = false;">{{$t('MobilePurchaseDetails.cancel')}}</button>
                    <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="onSubmit">{{$t('MobilePurchaseDetails.confirm')}}</button>
                </div>
            </div>
        </van-popup>
        <van-popup id="popup" v-model="showPopup" closeable close-icon-position="top-left" position="bottom" round class="pb-14" @close="onClosePop">
            <div class="flex justify-center items-center text-center text-3xl mt-12" >
                <span>￥{{(totalPrice).toFixed(2)}}</span>
            </div>
            <div v-if="!showPasswordInput" class="flex justify-center items-center text-center text-sm text-gray-400 mt-1" >
                <span>{{$t('MobileConfirmOrder.balance')}} ￥{{(balance).toFixed(2) }}</span>
                <span class="ml-3 text-red-300">{{balance < totalPrice?$t('MobileConfirmOrder.insufficientBalance'):''}}</span>
                <router-link to="/usermobile/recharge" class="text-blue-500"> &nbsp;&nbsp;{{$t('MobileConfirmOrder.topup')}}</router-link>

            </div>
            <div class="mx-3" v-if="!showPasswordInput">
                <div class="mt-2">
                    <span class="text-blue-500 ">{{$t('MobileConfirmOrder.mixPay')}}</span>
                    <span >￥{{((totalPrice)-balance).toFixed(2)}}：</span>
                </div>
                <div class="grid grid-cols-1 gap-2 text-lg">
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mt-2 mb-2">
                        <div class="flex items-center">
                            <img src="../images/alipay.png" alt="" class="w-8 h-8 mr-2" />
                            {{$t('MobileConfirmOrder.aliPay')}}
                        </div>
                        <!-- TODO 注释了支付宝和微信 -->
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixAliPay" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                        <div class="flex items-center"><img src="../images/wechat.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.wechat')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixWechat" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                        <div class="flex items-center"><img src="../images/paypal.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.paypal')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixPaypal"></van-radio>
                    </div>
                </div>
            </div>
            <!-- 密码输入框 -->
            <van-password-input
                v-if="showPasswordInput"
                :value="payPassword"
                :focused="showKeyboard"
                :info="$t('MobileConfirmOrder.enterNewPassword')"
                :error-info="errorInfo"
                @focus="onFocusPassword"
                class="mb-60"
            />
            <div v-if="!showPasswordInput" @click="onPay" class="rounded-md hover:bg-blue-600 bg-blue-500 px-8 pt-2 pb-2 mx-32 mt-8 flex justify-center">
                <span class="text-lg self-center text-white">{{$t('MobileConfirmOrder.pay')}}</span>
            </div>

            <!-- 数字键盘 -->
            <van-number-keyboard
                v-model="payPassword"
                :show="showKeyboard"
                @blur="onBlurPassword"
            />
        </van-popup>

    </div>
</template>

<script>
export default {
    mounted() {
        // 初始化我的订单列表
        this.$http.get('/v1/order/select-separateorder-list').then(response => {
            this.LandInfor = response.data.data
            this.initializeSelect()
            this.loading = false
        }).catch(error => {
            console.log(error)
        })
        //查询余额
        this.$http.get("/v1/recharge/check-balance")
            .then((res) => {
                this.balance = res.data.data.balance;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    data() {
        return {
            loading: true, // 骨架屏加载条
            mode: true, // 模式，true为查看模式，false为批量退款模式
            showRefund: false, // 退款弹窗
            LandInfor: null, // 订单数据
            example: [
                {
                    combinedOrderId: "100-1034160-057194",
                    totalAmount: "1925.0",
                    paymentStatus: "已付款",
                    separateOrders: [{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "黑土地",
                        totalAmount: "1000.0",
                        paymentStatus: "交易成功",
                        landpicture: null,
                    },{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "黄土地",
                        totalAmount: "900.0",
                        paymentStatus: "已退款",
                        landpicture: "/image/20230731145640.png",
                    }],
                },
                {
                    combinedOrderId: "100-1034160-057194",
                    totalAmount: "1100.0",
                    paymentStatus: "售后中",
                    separateOrders: [{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "红土地",
                        totalAmount: "1100.0",
                        paymentStatus: "退款中",
                        landpicture: null,
                    },{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "泥土地",
                        totalAmount: "1200.0",
                        paymentStatus: "退款申请被驳回",
                        landpicture: null,
                    }],
                },
                {
                    combinedOrderId: "100-1034160-057194",
                    totalAmount: "1300.0",
                    paymentStatus: "待付款",
                    separateOrders: [{
                        separateOrderId: "1001-1694160-8528650",
                        landname: "湿土地",
                        totalAmount: "1300.0",
                        paymentStatus: "待付款",
                        landpicture: null,
                    }],
                },
            ], // 后端获取的订单信息
            select: [[{isSelect: false,reason: null},{isSelect: false,reason: null}],[{isSelect: false,reason: null},{isSelect: false,reason: null}],[{isSelect: false,reason: null}]],
            selectIndex1: null, // 选中的商品下标1
            selectIndex2: null, // 选中的商品下标2
            refundPrice: 0, // 退款金额
            refundReason: '', // 退款弹窗输入框临时存储区
            flag: true, // 防抖
            showSteps: true, // 是否隐藏进度条
            filterMode: '', // 筛选模式
            filterOption: [
                { text: '全部商品', value: '' },
                { text: '未付款', value: '待付款' },
                { text: '交易成功', value: '已付款' },
                { text: '已退款', value: '已退款' },
                { text: '退款中', value: '售后中' },
            ],
            showContinue: false, // 继续支付弹窗
            payWay: 'PayPal', // 继续支付支付方式，默认PayPal支付
            orderInfor: null, // 继续支付交易信息
            balance: 0, // 余额，在mounted里获取
            totalPrice: 0, // 继续支付所需金额
            showPopup: false, // 继续支付余额支付余额不足选择支付方式弹窗
            showPasswordInput: false, // 余额支付密码弹窗
            payPassword: "", // 余额支付密码
            showKeyboard: false, // 键盘
            errorInfo: "", // 错误信息
        };
    },
    methods: {
        onBack() {
            this.$router.go(-1);
        },
        turnToDetail(index,index2,unitlandid,orderid) { // 跳转到土地详情页
            const status = this.LandInfor[index].separateOrders[index2].orderstatus
            if (this.mode) { // 查看模式，跳转到详情页面
                if (status === '交易成功' || status === '待付款') {
                    const newpath = "/mobile/my-order/detail?order=" + orderid
                    this.$router.push({
                        path: newpath,
                    });
                } else if(status === '已退款' || status === '退款中' || status === '退款申请被驳回' || status === '待付款') {
                    const newpath = "/mobile/my-order/refund?order=" + unitlandid
                    this.$router.push({
                        path: newpath,
                    });
                }
            } else {
                if (!this.select[index][index2].isSelect) { // 选中该商品
                    if (status === '交易成功' || status === '退款申请被驳回') {
                        this.selectIndex1 = index;
                        this.selectIndex2 = index2;
                        this.refundPrice = this.LandInfor[index].separateOrders[index2].totalprice
                        this.showRefund = true;
                    } else {
                        this.$toast(this.$t('MobileOrderList.alertCanotRefund'))
                    }
                }
            }
        },
        RefundConfirm(){ // 发送退款申请
            if (!this.flag) {
                return;
            }
            this.flag = false;
            this.select[index][index2].isSelect = true;
            this.select[index][index2].reason = this.refundReason;
        },
        initializeSelect() { // 初始化select数组
            this.select = []
            for (let i = 0;i < this.LandInfor.length;i++) {
                let temp = []
                for (let j = 0;j < this.LandInfor[i].separateOrders.length;j++) {
                    temp.push({isSelect: false,reason: null})
                }
                this.select.push(temp)
            }
        },
        productFilter() { // 筛选商品
            this.loading = true
            this.$http.get('/v1/order/select-separateorder-list?paymentstatus=' + this.filterMode).then(response => {
                this.LandInfor = response.data.data
                this.initializeSelect()
                this.loading = false
            }).catch(error => {
                console.log(error)
            })
        },
        onClosePop(){
            this.payWay='balance';
            // 等待500ms
            setTimeout(() => {
                this.showPasswordInput = false;
            }, 500);
        },
        onFocusPassword() {
            this.showKeyboard = true;
            // document.querySelector('#popup').style.height = "80%";
        },
        onBlurPassword() {
            this.showKeyboard = false;
            //删除height的设置
            document.querySelector('#popup').style.height = "";
        },
        /** 提交订单&&正常支付*/
        onSubmit() {
            if (this.payWay==='balance'){
                this.showContinue = false;
                this.showPopup = true;
                this.totalPrice = this.orderInfor.totalAmount
                if(this.balance>=this.totalPrice){
                    this.showPasswordInput = true;
                }
            } else {
                const data = {
                    combinedOrderId: this.orderInfor.combinedOrderId,
                    paymeans: this.payWay
                }
                const toastLoading= this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: this.$t('MobileConfirmOrder.alertLoading'),
                    forbidClick: true,
                });
                this.$http.post("/v1/order/continue-payment", data)
                    .then((res) => {
                        toastLoading.clear();
                        this.paymentForm = res.data.html;
                        //todo 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.orderid;
                        // 等待1秒
                        setTimeout(() => {
                            this.submitLoading = false;
                            document.querySelector('form[name="punchout_form"]').submit();
                        }, 500);
                    });
            }
        },
        /** 点击余额支付*/
        onPay(){
            const unitlandids = this.orderInfor.separateOrders.map((item) => item.unitlandid);
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileConfirmOrder.alertLoading'),
                forbidClick: true,
            });
            const data = {
                needpaper: this.orderInfor.separateOrders.needpaper,
                unitlandid: unitlandids,
                addressid: this.orderInfor.separateOrders[0].addressid,
            };
            /** 检查余额是否足够*/
            if (this.payWay==='balance'){
                if(this.balance<this.totalPrice){
                    this.$toast(this.$t('MobileConfirmOrder.alertNoMoney'));
                    return ;
                }
                /** 余额足够*/
                else {
                    this.showPasswordInput = true;
                    return;
                }
            }
            else if(this.payWay==="mixAliPay"){
                this.$http.post("/v1/balance-payment/alipay-combination-payment", data)
                    .then((res) => {
                        toastLoading.clear();
                        if(res.data==='密码错误'){
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return ;
                        }
                        this.paymentForm = res.data.html;
                        //todo 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.orderid;
                        // 等待1秒
                        setTimeout(() => {
                            this.submitLoading = false;
                            document.querySelector('form[name="punchout_form"]').submit();
                        }, 500);
                        // document.querySelector('form[name="punchout_form"]').submit();
                    });
            }
            else if(this.payWay==="mixWechat"){
                toastLoading.clear();
                console.log("微信支付");
            }
            else if(this.payWay==="mixPaypal"){
                this.$http.post("/v1/balance-payment/paypal-combination-payment", data)
                    .then((res) => {
                        toastLoading.clear();
                        if(res.data==='密码错误'){
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return ;
                        }
                        //访问paylink
                        window.location.href = res.data.html;
                        //todo 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.orderid;
                    });
            }
            this.goPay();
        },
        /** 去支付*/
        goPay(){
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileConfirmOrder.alertLoading'),
                forbidClick: true,
            });
            const data = {
                separateOrderId: this.orderInfor.combinedOrderId,
                paymeans: this.payWay,
                paymentpassword: this.payPassword
            };
            if(this.payWay==='balance'){
                this.$http.post("/v1/order/continue-payment", data)
                    .then((res) => {
                    toastLoading.clear();
                        if(res.data.data==='密码错误'){
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return ;
                        }
                    // 保存订单号后提示用户支付成功并做后续操作
                    this.showPasswordInput = false;
                    this.showPopup = false;
                    this.$toast(this.$t('MobileConfirmOrder.alertPaySuccessfully'));
                    this.$router.go(-1);
                });
            }
        },
    },
}
</script>

<style scoped>
.mobile-orderList-home {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
/* 退款信息 */
.orderList-view-infor {
    flex-grow: 1;
    padding: 10px;
    padding-top: 0;
    background-color: #eeeeee;
    position: relative;
    overflow-y: auto;
    margin-bottom: 80px;
}
/* 筛选 */
.orderList-view-filter {
    width: 100%;
}
.van-dropdown-item >>> .van-dropdown-item__content {
    margin-left: 10px;
    width: calc(100% - 20px);
}
.order-bigorder-box {
    background-color: #ffffff;
    margin-top: 10px;
    border-radius: 6px;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194);
}
.orderList-reason-box {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.orderList-reason-topic {
    font-size: 18px;
}
.orderList-reason-text {
    min-width: 100%;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194); /* 边框 */
    padding: 20px;
    word-wrap: break-word; /* 当单词太长时，允许在单词内部进行换行 */
    font-size: 18px;
}
/* 退款输入框 */
.orderList-reason-input {
    min-height: 100px;
    width: 100%;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194); /* 边框 */
    padding: 20px;
    word-wrap: break-word; /* 当单词太长时，允许在单词内部进行换行 */
    font-size: 18px;
}
.orderList-price {
    padding: 20px;
    font-size: 18px;
}
/* 退款结果通知 */
.orderList-result {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
}
.orderList-result-button {
    margin-top: 20px;
    height: 40px;
    width: 120px;
    border-radius: 20px;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194);
}


/* ----------------------------------------商品图鉴----------------------------------------- */
.order-background {
    height: 120px;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    position: relative;
}
.order-select {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
}
.order-image {
    /* 图片区域 */
    height: 100px;
    width: 100px;
    max-height: 150px;
    max-width: 150px;
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translate(0, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
.order-landname {
    /* 土地名称 */
    position: absolute;
    top: 10px;
    left: 150px;
    font-size: 20px;
}
.order-landprice {
    /* 土地价格 */
    position: absolute;
    bottom: 40px;
    left: 150px;
    font-size: 20px;
}
.order-status {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 16px;
    color: #bebebe;
}
.refund-modal-input-box {
    border-radius: 6px;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194);
}
/* 继续付款 */
.order-continue {
    width: 100%;
    text-align: right;
    padding: 10px;
}

/* ----------------------------------------底部按钮----------------------------------------- */
.footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0px 2px 0 rgb(0, 0, 0);
    padding: 20px;
    text-align: right;
}
.footer-button {
    height: 40px;
    width: 100px;
    border-radius: 20px;
    box-shadow: 0 0px 2px 0 rgb(199, 194, 194);
}
</style>