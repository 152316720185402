<template>
    <div class="main-container2">
        <el-skeleton :rows="6" :loading="loading" animated>
            <div class="tabs">
            <div class="tabs-head">
                <div class="tabs-head-item" :class="isTab('全部')" @click="tabsControl('全部')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">全部</span>
                </div>
                <div class="tabs-head-item" :class="isTab('挂牌中')" @click="tabsControl('挂牌中')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">挂牌中</span>
                </div>
                <div class="tabs-head-item" :class="isTab('已售出')" @click="tabsControl('已售出')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">已售出</span>
                </div>
                <div class="tabs-head-item" :class="isTab('已分割')" @click="tabsControl('已分割')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">已分割</span>
                </div>
            </div>
            <div class="search-container">
                <div class="search-box">
                    <div class="search-input">
                        <span class="search-input-label">所属原始土地编号</span>
                        <el-input v-model="search.id" placeholder="请输入原始土地编号"></el-input>
                    </div>
                    <div class="search-input" style="margin-top: 20px;">
                        <span class="search-input-label">所属小区</span>
                        <el-input v-model="search.name" placeholder="请输入小区编号"></el-input>
                    </div>
                </div>
                <div class="search-box">
                    <div class="search-input">
                        <span class="search-input-label">分割时间</span>
                        <el-date-picker
                            v-model="search.divisionDate"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                    <div class="search-input" style="margin-top: 20px;">
                        <span class="search-input-label">挂牌时间</span>
                        <el-date-picker
                            v-model="search.sellDate"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                </div>
                <div class="search-box">
                    <div class="search-input">
                        <el-button @click="searchUser">查询</el-button>
                        <el-button style="margin-left: 20px;" @click="resetUser">重置</el-button>
                    </div>
                    <div class="search-input">
                        <span style="color: red;font-size: 12px;">*请尽可能输入关键词,即使少字也不要错字漏字</span>
                    </div>
                </div>
            </div>
            <div class="tabs-content">
                    <el-row :gutter="15" class="tabs-content-row">
                        <el-col :span="4"><i>原始土地编号</i></el-col>
                        <el-col :span="2"><i>小区编号</i></el-col>
                        <el-col :span="3"><i>土地块名称</i></el-col>
                        <el-col :span="3"><i>地理位置</i></el-col>
                        <el-col :span="2"><i>状态</i></el-col>
                        <el-col :span="3"><i>售价</i></el-col>
                        <el-col :span="3"><i>当前属权所有人</i></el-col>
                        <el-col :span="2"><i>单位土地块数</i></el-col>
                        <el-col :span="2"><i>操作</i></el-col>
                    </el-row>
                    <el-row :gutter="15" v-for="(apply, index) in view" :key="index" class="tabs-content-row">
                        <div class="tabs-content-col">
                            <el-col :span="4"><i style="word-wrap: break-word;">{{ apply.originallandid }}</i></el-col>
                            <el-col :span="2"><i style="word-wrap: break-word;">{{ apply.unitlandid }}</i></el-col>
                            <el-col :span="3"><i style="word-wrap: break-word;">{{ apply.landname }}</i></el-col>
                            <el-col :span="3"><i style="word-wrap: break-word;">{{ apply.originalLand.detailedaddress }}</i></el-col>
                            <el-col :span="2"><i style="word-wrap: break-word;">{{ apply.status }}</i></el-col>
                            <el-col :span="3"><i style="word-wrap: break-word;">{{ apply.totalprice }}</i></el-col>
                            <el-col :span="3"><i style="word-wrap: break-word;">{{ apply.originalLand.ownerid }}</i></el-col>
                            <el-col :span="2"><i style="word-wrap: break-word;">{{ apply.unitLand.landblocks }}</i></el-col>
                            <el-col :span="2" style="word-wrap: break-word;">
                                <i @mouseover="changeCursor" style="text-decoration: underline;" @click="showModal(apply.userid)">查看详情</i>
                            </el-col>
                        </div>
                    </el-row>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
            </div>
        </div>
        </el-skeleton>
    </div>
</template>

<script>
export default {
    mounted() { // TODO 未解封
        /* this.$http.get('/v1/Administrator/BusinessStatus?status=' + this.companyChild.whichtab ,{
                withCredentials: true // 在请求中携带 Cookie 信息
            })
            .then(res => {
                this.apply = res.data.data
            }).catch(error => {
                // 处理错误
            }); */
        this.view = this.apply.slice(0,9)
        this.showLength = this.apply.length
    },
    data() {
      return {
        loading: false, // 加载中
        apply: [{
                id: 138,
                originallandid: " ",
                unitlandid: " ",
                landname: " ",
                sellerid: " ",
                seller: " ",
                salesmethods: " ",
                expirydate: " ",
                unitlandprice: null,
                pricenum: ' ',
                unitlandminprice: null,
                minimumprice: null,
                totalprice: null,
                saleslandpicture: null,
                createtime: " ",
                status: " ",
                remark: null,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                unitLand: {
                    id: 0,
                    communitynumber: " ",
                    originallandid: " ",
                    mergelandname: " ",
                    landnumber: " ",
                    landblocks: 0,
                    landcoordinates: " ",
                    virtualownership: " ",
                    remark: " ",
                    status: " ",
                    createdate: " ",
                },
            },{
            id: 68,
            userid: "43a89960781b41de9e16c2575772dc54",
            username: "亚瑟",
            userpassword: "b385ff585a4fb9f2de8fd29d6048fc42",
            useremail: "admin@qq.com",
            userphone: "13754687111",
            userpart: "普通用户",
            userrealname: "盖伦",
            usercard: "283648202008240036",
            usersex: "男",
            birthdate: "2020-08-24",
            createdate: "2023-03-13",
            status: true,
            remark: null,
            verificationstatus: "已验证",
            userphoto: null,
            isbanned: true,
            bantime: "2023-06-15T08:39:55.000+00:00",
            bandays: 3
        },{
            id: 68,
            userid: "43a89960781b41de9e16c2575772dc54",
            username: "亚瑟",
            userpassword: "b385ff585a4fb9f2de8fd29d6048fc42",
            useremail: "admin@qq.com",
            userphone: "13754687111",
            userpart: "普通用户",
            userrealname: "盖伦",
            usercard: "283648202008240036",
            usersex: "男",
            birthdate: "2020-08-24",
            createdate: "2023-03-13",
            status: true,
            remark: null,
            verificationstatus: "已验证",
            userphoto: null,
            isbanned: true,
            bantime: "2023-06-15T08:39:55.000+00:00",
            bandays: 3
        }], // 测试数据,后端获取的数据
        selling: null, // 挂牌中
        haveSelled: null, // 已售出
        notSelled: null, // 已分割

        view: null, // 测试数据,显示的数据



        whichtab: '全部', // 标签
        pageSize: 10, // 每页条数
        currentPage: 1,// 页码

        search: {
            landid: '',
            smalllandid: '',
            divisionDate: '',
            sellDate: '',
        },
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n
            this.currentPage = 1
            this.search.landid = ''
            this.search.smalllandid = ''
            this.search.divisionDate = ''
            this.search.sellDate = ''
            this.updatePage(0,this.pageSize - 1)
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'tabs-head-item-select'
            } else {
                return
            }
        },
        updatePage(start,end) { // 更新显示的数据
            if(this.whichtab === '全部') {
                this.view = this.apply.slice(start,end)
            } else if (this.whichtab === '挂牌中') {
                if (this.selling) {
                } else {
                    this.selling = this.apply.filter(function(item){
                        return item.sellerid === 该用户id
                    })
                }
                this.view = this.selling.slice(start,end)
            } else if (this.whichtab === '已售出') {
                this.userArray = this.userLogOut.slice(start,end)
                this.showLength = this.userLogOut.length
            }
        },
        searchUser() {
            if (this.search.id !== '') {
                if(this.whichtab === '正常') {
                    let array = this.userNormal.filter(function(item){
                        return item.userid === this.search.id
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '封禁中') {
                    let array = this.userBanned.filter(function(item){
                        return item.userid === this.search.id
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '已注销') {
                    let array = this.userLogOut.filter(function(item){
                        return item.userid === this.search.id
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                }
            } else if (this.search.name !== '') {
                if(this.whichtab === '正常') {
                    let array = this.userNormal.filter(function(item){
                        return item.username === this.search.name
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '封禁中') {
                    let array = this.userBanned.filter(function(item){
                        return item.username === this.search.name
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '已注销') {
                    let array = this.userLogOut.filter(function(item){
                        return item.username === this.search.name
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                }
            } else if (this.search.date !== '') {
                if(this.whichtab === '正常') {
                    let array = this.userNormal.filter(function(item) {
                        return item.createdate >= this.search.date[0] && item.createdate <= this.search.date[1];
                    });
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '封禁中') {
                    let array = this.userBanned.filter(function(item){
                        return item.createdate >= this.search.date[0] && item.createdate <= this.search.date[1];
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '已注销') {
                    let array = this.userLogOut.filter(function(item){
                        return item.createdate >= this.search.date[0] && item.createdate <= this.search.date[1];
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                }
            } else {
                alert('请填写任一查询方式')
            }
        },
        resetUser() {
            this.search.id = ''
            this.search.name = ''
            this.search.date = ''
            this.updatePage(0,this.pageSize - 1)
        },
        pageSizeChange(val) {
            // 计算索引值
            const start = (this.currentPage - 1) * val
            const end = this.currentPage * val - 1
            // 更新显示的数据
            this.updatePage(start,end)
            // 更新页距
            this.pageSize = val
        },
        pageCurrentChange(val) {
            // 计算索引值
            const start = (val - 1) * this.pageSize
            const end = this.pageSize * val - 1
            // 更新显示的数据
            this.updatePage(start,end)
            // 更新页距
            this.currentPage = val
        },
    },
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container2 {
    height: 100%;
    background-color: black;
    padding: 50px;
}
.tabs {
    min-height: 100%;
    width: 100%;
}



/* --------------------------------------------选项卡样式-------------------------------------------- */
.tabs-head {
    width: 100%;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
    /* 在父块加上position: relative;并在子块加上position: absolute;来控制子块在父块内的位置 */
    position: relative;
}
.tabs-head-item {
    display: inline-block;
    height: 50px;
    width: 100px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.tabs-head-item-select {
    padding: 0;
    margin: 0;
    border-bottom: none;
    background-color: #383838;
    color: aqua;
    font-size: 18px;
    font-weight: bold;
}
.tabs-head-item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
/* 新增企业按钮 */
.tabs-head-add {
    display: inline-block;
    position: absolute;
    right: 0;
}
.tabs-head-add-button {
    height: 50px;
    width: 100px;
    border: 1px solid #383838;
    border-radius: 6px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}



/* --------------------------------------------搜索栏样式-------------------------------------------- */
.search-container {
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    color: #fff;
}
.search-box {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}
.search-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.search-input-label {
    min-width: 80px;
}



/* --------------------------------------------管理页面样式-------------------------------------------- */
/* -------------------------------土地上传管理页面------------------------------- */
.tabs-content {
    padding: 20px;
}
/* 信息栏样式 */
.tabs-content-row {
    padding: 10px;
    text-align: center;
    color: #fff;
}
.tabs-content-col {
    min-height: 50px;
    /* 采用`flex`布局，则可以通过设置`justify-content`和`align-items`属性来实现水平和垂直居中 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    background-color: #a7a5a5;
    border: 2px solid #a7a5a5;
    border-radius: 6px;
}
</style>