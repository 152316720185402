<template>
    <div>
        <van-nav-bar class="bg-gray-50" :title="$t('MobileMyWallet.title')" :left-text="$t('MobileMyWallet.back')" left-arrow @click-left="onBack" />
        <div class="animate__animated animate__fast animate__fadeInUp rounded-lg items-center shadow-md bg-white h-32 px-8 pt-4 pb-3 mx-6 mt-5 flex">
            <img src="../images/coin.png" class="w-14 h-14 place-self-center" />
            <span class="flex-1 mx-5 text-left text-lg font-bold text-gray-500 self-center" v-text="balance!==''?balance + $t('MobileMyWallet.sum'):''"/>
            <div class="bg-blue-500 hover:bg-blue-600 flex justify-center items-center text-center text-white text-lg rounded-lg h-10" @click="goRecharge">{{$t('MobileMyWallet.Recharge')}}</div>
        </div>
        <div class="grid-cols-8 gap-2 px-8 text-lg"></div>
    </div>
</template>

<script>
export default {
    name: "MobileMyWallet",
    data() {
        return {
            balance: "",
        };
    },
    created() {
        // /Recharge/checkBalance
        this.$http
            .get("/v1/recharge/check-balance")
            .then((res) => {
                console.log(res);
                this.balance = res.data.data.balance;
            })
            .catch((err) => {
                console.log(err);
            });
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL);
            window.onpopstate=() => {
                this.onBack();
            }
        }

    },
    methods: {
        onBack() {
            window.onpopstate=null;
            this.$router.push("/mobile/my-infor?index=4");
        },
        goRecharge() {
            this.$router.push("/mobile/recharge");
        },
    },
};
</script>

<style scoped></style>
