<template>
    <div class="home-container">
        <header class="header-container">
            <div class="header-image-div">
                <img src="../icons/logo.png" class="header-image-img">
            </div>
            <div class="header-language-div">
                <div class="header-language-text-box" @click="changeLanguage">
                    <span :style="$i18n.locale === 'en'? 'color: blue' : ''">EN</span>
                    <span>&nbsp;|&nbsp;</span>
                    <span :style="$i18n.locale === 'zh'? 'color: blue' : ''">中文</span>
                </div>
                <button class="header-login-button" @click="goLogin">{{$t('MobileHomePage.button')}}</button>
            </div>
        </header>
        <main class="main-container">
            <section class="slider">
                <div class="Image-impression-box">
                    <span class="Image-impression-text font-family-normal">{{$t('MobileHomePage.introduce1')}}</span>
                    <span class="Image-impression-text font-family-normal">{{$t('MobileHomePage.introduce2')}}</span>
                </div>
            </section>
            <div class="Image-propagate-box" v-for="(item,index) in images" :key="index" :class="index%2===0?'Image-propagate-box-blue':''">
                <div class="Image-propagate-text-box"><span class="Image-propagate-text">{{$t('MobileHomePage.view' + index)}}</span></div>
                <img :src="item" class="Image-propagate-img">
            </div>
            <div class="footer-container" v-show="footer">
                <a href="mailto:info@datuland.com?subject=Hello" class="footer-link font-family-normal">{{$t('MobileHomePage.contact')}}</a>
                <div class="font-family-normal footer-copyright">{{$t('MobileHomePage.copyright')}}</div>
            </div>
        </main>
        <!-- <footer class="footer-container">
            <a href="mailto:info@datuland.com?subject=Hello" class="p-2 underline text-blue-500 font-family-normal">{{$t('MobileHomePage.contact')}}</a>
            <div class="font-family-normal">{{$t('MobileHomePage.copyright')}}</div>
        </footer> -->
    </div>
</template>

<script>
export default {
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    data() {
        return {
            images: [ // 首页轮播图
                require('@/images/propagandizeImage1.jpg'),
                require('@/images/propagandizeImage2.jpg'),
                require('@/images/propagandizeImage3.jpg'),
                require('@/images/propagandizeImage4.jpg'),
            ],
            footer: false, // 控制底部显示
        }
    },
    methods: {
        changeLanguage() {
            if (this.$i18n.locale === 'en') {
                this.$i18n.locale = 'zh'
                localStorage.setItem('language', 'zh'); // 存储语言选择到localStorage
            } else {
                this.$i18n.locale = 'en'
                localStorage.setItem('language', 'en'); // 存储语言选择到localStorage
            }
            document.title = this.$t('pageTitle')
        },
        goLogin() {
            this.$router.push('/mobile/login')
        },
        handleScroll() {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.offsetHeight;
      
            if (window.pageYOffset + windowHeight >= documentHeight) {
                // 页面滚动到底部时显示底部div层
                /* document.querySelector('.footer-container').style.display = 'block'; */
                this.footer = true;
            } else {
                // 页面未滚动到底部时隐藏底部div层
                /* document.querySelector('.footer-container').style.display = 'none'; */
                this.footer = false;
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style scoped>
.home-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
/* --------------------------------------------头部------------------------------------------------- */
.header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    z-index: 1;
}
.header-image-div {
    padding: 0px;
}
.header-image-img {
    object-fit: cover;
    height: 40px;
    margin-top: 10px;
    height: 40px;
    margin-top: 10px;
    margin-left: 5px;
}
.header-language-div {
    margin-left: 10px;
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-language-text-box {
    font-size: 14px;
    font-size: 14px;
}
.header-login-button {
    height: 35px;
    width: 100px;
    position: absolute;
    top: 12.5px;
    right: 10px;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    text-overflow: ellipsis; /* 对文本进行截断和省略 */
    white-space: nowrap; /* 防止文本换行 */
    overflow: hidden;
    font-size: 16px;
    text-overflow: ellipsis; /* 对文本进行截断和省略 */
    white-space: nowrap; /* 防止文本换行 */
    overflow: hidden;
    background-color: #248aff;
    color: #fff;
}
/* --------------------------------------------主页------------------------------------------------- */
.main-container {
    margin-top: 60px;
    flex: 1;
    display: flex;
    background-image: linear-gradient(to bottom, #0083e0 5%, #ffffff);
    background-repeat: no-repeat;
    background-color: #fff;
    overflow-y: auto;
    flex-direction: column;
}
/* 印象图 */
.Image-impression-box {
    position: inherit;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
.Image-impression-text {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    text-align: center;
}
.slider {
  position: relative;
}
.slider:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #1d192c;
  opacity: 0.8;
}
.slider {
  background: url("../images/slider-bg.jpg") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 10% 0%;
  padding: 200px 0;
  position: relative;
}
@media (max-width: 992px) {
  .slider {
    padding: 150px 0;
  }
}
.slider .block {
  color: #E3E3E4;
  text-align: center;
}
.slider .block h1 {
  font-weight: 100;
  font-size: 45px;
  line-height: 60px;
  letter-spacing: 10px;
  padding-bottom: 15px;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .slider .block h1 {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 768px) {
  .slider .block h1 {
    font-size: 32px;
    line-height: 42px;
  }
}
.slider .block p {
  margin-bottom: 30px;
  color: #b9b9b9;
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
}
@media (max-width: 992px) {
  .slider .block p {
    font-size: 16px;
  }
}


/* 宣传图 */
.Image-propagate-box {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}
/* 白色灰色交替展示 */
.Image-propagate-box-blue {
    background-color: #f5f8fc;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}
/* 白色灰色交替展示 */
.Image-propagate-box-blue {
    background-color: #f5f8fc;
}
.Image-propagate-img {
    max-width: 100%; /* 控制图片大小不能超出屏幕宽度 */
    padding: 20px;
}
.Image-propagate-text-box {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Image-propagate-text {
    font-size: 20px;
    font-weight: bold;
    word-wrap: break-word;
}
.Image-propagate-text-box {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Image-propagate-text {
    font-size: 20px;
    font-weight: bold;
    word-wrap: break-word;
}


/* 这是之前的轮播图样式，先不删了 */
.BootstraDiagram {
    width: 100%;
    display: inline-block;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
}
.el-carousel >>> .el-carousel__container {
    height: 200px;
}
.BootstraDiagram-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 224.17px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    z-index: 2;
}
.BootstraDiagram-text {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}
/* --------------------------------------------底部------------------------------------------------- */
.footer-container {
    height: 100px;
    width: 100%;
    background-color: #515666;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/* 联系我们-样式 */
.footer-link {
    margin-bottom: 10px;
    color: #313131;
    font-size: 14px;
}
/* 版权所有-样式 */
.footer-copyright {
    font-size: 14px;
    color: #919191;
}
.font-family-normal {
    font-family: "Roboto", "Droid Serif", Georgia, "Times New Roman", "PingFang SC", "Hiragino Sans GB", "Source Han Sans CN", "WenQuanYi Micro Hei","Microsoft Yahei", serif
}
</style>