<template>
    <div class="first-main-container">
        <!-- 左上角返回 -->
        <van-nav-bar class="bg-gray-50" :title="topicTitle" :left-text="$t('MobileAssociatedReplace.back')" left-arrow @click-left="goBack" />
        <!-- 用户刷新 -->
        <div class="mobile-box-container" v-if="webError">
            <div class="mobile-form-topic">{{$t('MobileAssociatedReplace.title1')}}</div>
        </div>
        <!-- 身份认证 -->
        <div class="mobile-box-container" v-if="!webError && isverifing">
            <div class="mobile-form-topic">{{ verifyTopic }}</div>
            <div class="mobile-box-input-parent">
                <div class="mobile-box-input-labal"><span>{{$t('MobileAssociatedReplace.verify')}}:</span></div>
                <input type="text" v-model="code" :placeholder="$t('MobileAssociatedReplace.codeInput')" class="mobile-box-input" />
                <span @click="getVerifyCode" v-if="isCode" class="mobile-box-code-button">{{$t('MobileAssociatedReplace.getCode')}}</span>
                <span v-if="!isCode" disabled class="mobile-box-code-button">{{$t('MobileAssociatedReplace.resendCode')}}{{ time }}s</span>
            </div>
            <div style="width: 80%; margin-bottom: 10px">
                <button class="mobile-box-button" @click="nextStep">{{$t('MobileAssociatedReplace.nextStep')}}</button>
                <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
                <div class="modal" v-if="showError" @click="closeModal">
                    <div class="modal-box">
                        <div>{{ errorText }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 设置邮箱账号 -->
        <div class="mobile-box-container" v-if="!webError && setType === 'email' && !isverifing">
            <div class="mobile-box-input-parent">
                <div class="mobile-box-input-labal"><span>{{$t('MobileAssociatedReplace.email')}}:</span></div>
                <input type="email" v-model="email" class="mobile-box-input" :placeholder="$t('MobileAssociatedReplace.emailInput')" />
            </div>
            <div class="mobile-box-input-parent">
                <div class="mobile-box-input-labal"><span>{{$t('MobileAssociatedReplace.verify')}}:</span></div>
                <input type="text" v-model="code" :placeholder="$t('MobileAssociatedReplace.codeInput')" class="mobile-box-input" />
                <span @click="getEmailCode" v-if="isCode" class="mobile-box-code-button">{{$t('MobileAssociatedReplace.getCode')}}</span>
                <span v-if="!isCode" disabled class="mobile-box-code-button">{{$t('MobileAssociatedReplace.resendCode')}}{{ time }}s</span>
            </div>
            <div style="width: 80%; margin-bottom: 10px">
                <button class="mobile-box-button" @click="AddEmailAssociated">{{$t('MobileAssociatedReplace.confirm')}}</button>
                <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
                <div class="modal" v-if="showError" @click="closeModal">
                    <div class="modal-box">
                        <div>{{ errorText }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 设置手机号码 -->
        <div class="mobile-box-container" v-if="!webError && setType === 'phone' && !isverifing">
            <div class="mobile-box-input-parent">
                <div class="mobile-box-input-labal"><span>{{$t('MobileAssociatedReplace.phone')}}:</span></div>
                <input type="tel" v-model="phone" class="mobile-box-input" :placeholder="$t('MobileAssociatedReplace.phoneInput')" />
            </div>
            <div class="mobile-box-input-parent">
                <div class="mobile-box-input-labal"><span>{{$t('MobileAssociatedReplace.verify')}}:</span></div>
                <input type="text" v-model="code" :placeholder="$t('MobileAssociatedReplace.codeInput')" class="mobile-box-input" />
                <span @click="getPhoneCode" v-if="isCode" class="mobile-box-code-button">{{$t('MobileAssociatedReplace.getCode')}}</span>
                <span v-if="!isCode" disabled class="mobile-box-code-button">{{$t('MobileAssociatedReplace.resendCode')}}{{ time }}s</span>
            </div>
            <div style="width: 80%; margin-bottom: 10px">
                <button class="mobile-box-button" @click="AddPhoneAssociated">{{$t('MobileAssociatedReplace.confirm')}}</button>
                <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
                <div class="modal" v-if="showError" @click="closeModal">
                    <div class="modal-box">
                        <div>{{ errorText }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.setType = this.$route.params.type;
        this.useremail = this.$route.params.useremail;
        this.userphone = this.$route.params.userphone;
        this.topicTitle = this.$t('MobileAssociatedReplace.title1')
        if (this.setType === "email") {
            this.verifyTopic = this.$t('MobileAssociatedReplace.sendToEmail') + this.useremail;
        } else if (this.setType === "phone") {
            this.verifyTopic = this.$t('MobileAssociatedReplace.sendToPhone') + this.userphone;
        } else {
            this.webError = true;
        }
    },
    data() {
        return {
            webError: false, // 由于用了param传值，如果用户刷新，则值消失
            useremail: "",
            userphone: "",
            isverifing: true, // 是否正在身份认证，初始进入页面时先身份认证，再绑定新账号
            topicTitle: "身份认证", // 标题名称,根据是邮箱还是手机号修改名称
            verifyTopic: "发送至", // 发送到的账户
            setType: "", // 本次添加关联的类型，分为邮箱email和手机号phone
            email: "", // 邮箱
            phone: "", // 手机号
            code: "", // 验证码
            time: 0, // 发送验证码后重新发送需要等待60s
            isCode: true,
            timer: null, // 计时器

            showError: false, // 显示弹窗
            errorText: "", // 弹窗文本
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        closeModal() {
            this.showError = false;
        },
        checkPassword() {
            // 点击密码输入框后检查密码格式是否错误
            const re = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/;
            this.showRules = !re.test(this.changePassword);
        },
        setMyInterval() {
            // 设置计时器
            this.time = 60;
            // 计时器
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    this.isCode = true;
                    clearInterval(this.timer);
                }
            }, 1000);
            this.isCode = false;
        },

        getVerifyCode() {
            // 身份认证获取验证码
            if (this.setType === "email") {
                this.setMyInterval();
                this.errorText = this.$t('MobileAssociatedReplace.alertLoading');
                this.showError = true;
                this.$http
                    .post("/v1/index/get-verificationcode-topassword?useremail=" + this.useremail)
                    .then((res) => {
                        this.errorText = res.data.data;
                        this.showError = true;
                    })
                    .catch((err) => {
                        this.errorText = this.$t('MobileAssociatedReplace.alertAxiosError');
                        this.showError = true;
                    });
            } else if (this.setType === "phone") {
                this.setMyInterval();
                this.errorText = this.$t('MobileAssociatedReplace.alertLoading');
                this.showError = true;
                this.$http
                    .post("/v1/register/phone-verificationcode?userphone=" + this.userphone)
                    .then((res) => {
                        this.errorText = res.data.data;
                        this.showError = true;
                    })
                    .catch((err) => {
                        this.errorText = this.$t('MobileAssociatedReplace.alertAxiosError');
                        this.showError = true;
                    });
            }
        },
        nextStep() {
            // 身份认证下一步
            if (this.code === "") {
                this.errorText = this.$t('MobileAssociatedReplace.alertNoCodeInput');
                this.showError = true;
            }
            let data = {};
            if (this.setType === "email") {
                data = {
                    loginname: this.useremail,
                    code: this.code,
                };
            } else if (this.setType === "phone") {
                data = {
                    loginname: this.userphone,
                    code: this.code,
                };
            }
            this.errorText = this.$t('MobileAssociatedReplace.alertLoading');
            this.showError = true;
            this.$http
                .post("/v1/register/user-update-password-authentication", data)
                .then((res) => {
                    this.topicTitle = this.$t('MobileAssociatedReplace.alertPhoneError')
                    this.isverifing = false;
                    this.showError = false;
                })
                .catch((err) => {
                    this.errorText = this.$t('MobileAssociatedReplace.alertAxiosError');
                    this.showError = true;
                });
        },

        getEmailCode() {
            // 获取邮箱验证码
            if (!this.email.includes("@")) {
                this.errorText = this.$t('MobileAssociatedReplace.alertEmailError');
                this.showError = true;
                return;
            }
            this.setMyInterval();
            this.errorText = this.$t('MobileAssociatedReplace.alertLoading');
            this.showError = true;
            this.$http
                .post("/v1/index/get-verificationcode-topassword?useremail=" + this.email)
                .then((res) => {
                    this.errorText = res.data.data;
                    this.showError = true;
                })
                .catch((err) => {
                    this.errorText = this.$t('MobileAssociatedReplace.alertAxiosError');
                    this.showError = true;
                });
        },
        getPhoneCode() {
            // 获取手机验证码
            if (!this.phone.test(/^[0-9]*$/)) {
                this.errorText = this.$t('MobileAssociatedReplace.alertPhoneError');
                this.showError = true;
            }
            this.setMyInterval();
            this.errorText = this.$t('MobileAssociatedReplace.alertLoading');
            this.showError = true;
            this.$http
                .post("/v1/register/phone-verificationcode?userphone=" + this.phone)
                .then((res) => {
                    this.errorText = res.data.data;
                    this.showError = true;
                })
                .catch((err) => {
                    this.errorText = this.$t('MobileAssociatedReplace.alertAxiosError');
                    this.showError = true;
                });
        },

        AddEmailAssociated() {
            if (!this.email.includes("@")) {
                this.errorText = this.$t('MobileAssociatedReplace.alertEmailError');
                this.showError = true;
                return;
            }
            if (this.code === "") {
                this.errorText = this.$t('MobileAssociatedReplace.alertNoCodeInput');
                this.showError = true;
                return;
            }
            this.errorText = this.$t('MobileAssociatedReplace.alertLoading');
            this.showError = true;
            this.$http
                .post("/v1/register/association?contact=" + this.email + "&code=" + this.code)
                .then((res) => {
                    this.errorText = res.data.data;
                    this.showError = true;
                })
                .catch((err) => {
                    this.errorText = this.$t('MobileAssociatedReplace.alertAxiosError');
                    this.showError = true;
                });
        },
        AddPhoneAssociated() {
            if (!this.phone.test(/^[0-9]*$/)) {
                this.errorText = this.$t('MobileAssociatedReplace.alertPhoneError');
                this.showError = true;
            }
            if (this.code === "") {
                this.errorText = this.$t('MobileAssociatedReplace.alertNoCodeInput');
                this.showError = true;
                return;
            }
            this.errorText = this.$t('MobileAssociatedReplace.alertLoading');
            this.showError = true;
            this.$http
                .post("/v1/register/association?contact=" + this.phone + "&code=" + this.code)
                .then((res) => {
                    this.errorText = res.data.data;
                    this.showError = true;
                })
                .catch((err) => {
                    this.errorText = this.$t('MobileAssociatedReplace.alertAxiosError');
                    this.showError = true;
                });
        },
    },
};
</script>

<style scoped>
.first-main-container {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    height: 100%; /* 容器高度为屏幕高度 */
}
.mobile-form-topic {
    /* 小标题 */
    font-size: 20px;
    padding: 10px;
}
.mobile-box-input-parent {
    /* 输入框样式 */
    width: 80%;
    display: flex;
    flex-direction: row;
    position: relative;
}
.mobile-box-input-labal {
    /* 输入框前文字信息样式 */
    width: 70px;
    display: flex;
    align-items: center; /* 水平居中 */
}
.mobile-box-input {
    /* 输入框样式 */
    height: 50px;
    width: 70%;
    font-size: 14px;
    color: #dcdcdc;
    border: none;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 10px;
}
.mobile-box-code-button {
    /* 获取验证码按钮 */
    height: 40px;
    width: 100px;
    border: none;
    border-left: 1px solid #dcdcdc;
    display: flex;
    align-items: center; /* 水平居中 */
    justify-content: center;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 5px;
}
.mobile-box-container {
    /* 手机端登陆页面 */
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    flex-direction: column;
}
.mobile-box-button {
    /* 按钮样式 */
    height: 50px;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(to bottom right, #5a4de2, #274dba);
    border-radius: 30px;
    box-shadow: 0 2px 12px 0 rgb(255, 255, 255);
    margin-top: 10px;
    margin-bottom: 20px;
}
/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    height: 150px;
    width: 300px;
    padding: 20px;
    background-color: #a7a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
</style>
