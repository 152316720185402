<template>
    <div class="main-container2">
        <div class="tabs">
            <div class="tabs-head">
                <div class="tabs-head-item" :class="isTab('正常')" @click="tabsControl('正常')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">正常</span>
                </div>
                <div class="tabs-head-item" :class="isTab('封禁中')" @click="tabsControl('封禁中')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">封禁中</span>
                </div>
                <div class="tabs-head-item" :class="isTab('已注销')" @click="tabsControl('已注销')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">已注销</span>
                </div>
            </div>
            <div class="search-container">
                <div class="search-box">
                    <div class="search-input">
                        <span class="search-input-label">用户id</span>
                        <el-input v-model="search.id" placeholder="请输入用户id"></el-input>
                    </div>
                    <div class="search-input" style="margin-top: 20px;">
                        <span class="search-input-label">用户名</span>
                        <el-input v-model="search.name" placeholder="请输入用户名"></el-input>
                    </div>
                </div>
                <div class="search-box">
                    <div class="search-input">
                        <span class="search-input-label">注册时间</span>
                        <el-date-picker
                            v-model="search.date"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                </div>
                <div class="search-box">
                    <div class="search-input">
                        <el-button @click="searchUser">查询</el-button>
                        <el-button style="margin-left: 20px;" @click="resetUser">重置</el-button>
                    </div>
                </div>
            </div>
            <div class="tabs-content">
                    <el-row :gutter="15" class="tabs-content-row">
                        <el-col :span="4"><i>用户id</i></el-col>
                        <el-col :span="3"><i>邮箱</i></el-col>
                        <el-col :span="2"><i>手机号</i></el-col>
                        <el-col :span="2"><i>用户名</i></el-col>
                        <el-col :span="1"><i>性别</i></el-col>
                        <el-col :span="2"><i>出生日期</i></el-col>
                        <el-col :span="4"><i>默认收货地址</i></el-col>
                        <el-col :span="1"><i>等级</i></el-col>
                        <el-col :span="1"><i>积分</i></el-col>
                        <el-col :span="2"><i>虚拟币数量</i></el-col>
                        <el-col :span="2"><i>操作</i></el-col>
                    </el-row>
                    <el-row :gutter="15" v-for="(apply, index) in userArray" :key="index" class="tabs-content-row">
                        <div class="tabs-content-col">
                            <el-col :span="4"><i style="word-wrap: break-word;">{{ apply.userid }}</i></el-col>
                            <el-col :span="3"><i style="word-wrap: break-word;">{{ apply.useremail }}</i></el-col>
                            <el-col :span="2"><i style="word-wrap: break-word;">{{ apply.userphone }}</i></el-col>
                            <el-col :span="2"><i style="word-wrap: break-word;">{{ apply.username }}</i></el-col>
                            <el-col :span="1"><i style="word-wrap: break-word;">{{ apply.usersex }}</i></el-col>
                            <el-col :span="2"><i style="word-wrap: break-word;">{{ apply.birthdate }}</i></el-col>
                            <el-col :span="4"><i style="word-wrap: break-word;">默认收货地址</i></el-col>
                            <el-col :span="1"><i style="word-wrap: break-word;">等级</i></el-col>
                            <el-col :span="1"><i style="word-wrap: break-word;">积分</i></el-col>
                            <el-col :span="2"><i style="word-wrap: break-word;">虚拟币数量</i></el-col>
                            <el-col :span="2" style="word-wrap: break-word;">
                                <i @mouseover="changeCursor" style="text-decoration: underline;" @click="banUserModal(apply.userid)" v-if="whichtab === '正常'">封禁</i>
                                <i @mouseover="changeCursor" style="text-decoration: underline;" @click="unlockUserModal(apply.userid)" v-else-if="whichtab === '封禁中'">解封</i>
                                <i @mouseover="changeCursor" style="text-decoration: underline;" @click="showModal(apply.userid)" v-else-if="whichtab === '已注销'">查看注销时间</i>
                            </el-col>
                        </div>
                    </el-row>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
            </div>
        </div>
        <!-- 这个是用来封禁用户的弹窗 -->
        <div class="modal" v-show="modal.bmodal" @click="closeModal">
            <div class="modal-box">
                <span>确定封禁用户？</span>
                <el-select v-model="selectValue" placeholder="请选择" class="modal-select">
                    <el-option
                        v-for="item in selectArray"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <input type="text" class="modal-input" placeholder="请输入封禁原因">
                <div style="margin-top: 50px;">
                    <button class="modal-button" @click="banUser" style="margin-right: 30px;">确定</button>
                    <button class="modal-button" @click="closeModal">取消</button>
                </div>
            </div>
        </div>
        <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="modal" v-show="modal.vmodal" @click="closeModal">
            <div class="modal-box" v-if="!modal.showError">
                <span>此账号将于</span>
                <span>{{ modal.text }}</span>
                <span>解封</span>
                <span style="margin-top: 10px;">是否立即解封？</span>
                <div style="margin-top: 50px;">
                    <button class="modal-button" @click="unlockUser" style="margin-right: 30px;">确定</button>
                    <button class="modal-button" @click="closeModal">取消</button>
                </div>
            </div>
            <div class="modal-box" v-if="modal.showError">
                <div>{{ modal.errorText }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() { // TODO 未解封
        /* this.$http.get('/v1/Administrator/BusinessStatus?status=' + this.companyChild.whichtab ,{
                withCredentials: true // 在请求中携带 Cookie 信息
            })
            .then(res => {
                this.apply = res.data.data
            }).catch(error => {
                // 处理错误
            }); */
        this.userArray = this.userNormal.slice(0,9)
        this.showLength = this.userNormal.length
    },
    data() {
      return {
        userNormal: [{
            id: 68,
            userid: "43a89960781b41de9e16c2575772dc54",
            username: "亚瑟",
            userpassword: "b385ff585a4fb9f2de8fd29d6048fc42",
            useremail: "admin@qq.com",
            userphone: "13754687111",
            userpart: "普通用户",
            userrealname: "盖伦",
            usercard: "283648202008240036",
            usersex: "男",
            birthdate: "2020-08-24",
            createdate: "2023-03-13",
            status: true,
            remark: null,
            verificationstatus: "已验证",
            userphoto: null,
            isbanned: true,
            bantime: "2023-06-15T08:39:55.000+00:00",
            bandays: 3
        }], // 测试数据，正常用户数据

        userBanned: [{
            id: 68,
            userid: "43a89960781b41de9e16c2575772dc54",
            username: "亚瑟",
            userpassword: "b385ff585a4fb9f2de8fd29d6048fc42",
            useremail: "admin@qq.com",
            userphone: "13754687111",
            userpart: "普通用户",
            userrealname: "盖伦",
            usercard: "283648202008240036",
            usersex: "男",
            birthdate: "2020-08-24",
            createdate: "2023-03-13",
            status: true,
            remark: null,
            verificationstatus: "已验证",
            userphoto: null,
            isbanned: true,
            bantime: "2023-06-15T08:39:55.000+00:00",
            bandays: 3
        },
        {
            id: 68,
            userid: "43a89960781b41de9e16c2575772dc54",
            username: "亚瑟",
            userpassword: "b385ff585a4fb9f2de8fd29d6048fc42",
            useremail: "admin@qq.com",
            userphone: "13754687111",
            userpart: "普通用户",
            userrealname: "盖伦",
            usercard: "283648202008240036",
            usersex: "男",
            birthdate: "2020-08-24",
            createdate: "2023-03-13",
            status: true,
            remark: null,
            verificationstatus: "已验证",
            userphoto: null,
            isbanned: true,
            bantime: "2023-06-15T08:39:55.000+00:00",
            bandays: 3
        }], // 测试数据，正被封禁的用户数据

        userLogOut: [{
            id: 68,
            userid: "43a89960781b41de9e16c2575772dc54",
            username: "亚瑟",
            userpassword: "b385ff585a4fb9f2de8fd29d6048fc42",
            useremail: "admin@qq.com",
            userphone: "13754687111",
            userpart: "普通用户",
            userrealname: "盖伦",
            usercard: "283648202008240036",
            usersex: "男",
            birthdate: "2020-08-24",
            createdate: "2023-03-13",
            status: true,
            remark: null,
            verificationstatus: "已验证",
            userphoto: null,
            isbanned: true,
            bantime: "2023-06-15T08:39:55.000+00:00",
            bandays: 3
        },
        {
            id: 68,
            userid: "43a89960781b41de9e16c2575772dc54",
            username: "亚瑟",
            userpassword: "b385ff585a4fb9f2de8fd29d6048fc42",
            useremail: "admin@qq.com",
            userphone: "13754687111",
            userpart: "普通用户",
            userrealname: "盖伦",
            usercard: "283648202008240036",
            usersex: "男",
            birthdate: "2020-08-24",
            createdate: "2023-03-13",
            status: true,
            remark: null,
            verificationstatus: "已验证",
            userphoto: null,
            isbanned: true,
            bantime: "2023-06-15T08:39:55.000+00:00",
            bandays: 3
        },
        {
            id: 68,
            userid: "43a89960781b41de9e16c2575772dc54",
            username: "亚瑟",
            userpassword: "b385ff585a4fb9f2de8fd29d6048fc42",
            useremail: "admin@qq.com",
            userphone: "13754687111",
            userpart: "普通用户",
            userrealname: "盖伦",
            usercard: "283648202008240036",
            usersex: "男",
            birthdate: "2020-08-24",
            createdate: "2023-03-13",
            status: true,
            remark: null,
            verificationstatus: "已验证",
            userphoto: null,
            isbanned: true,
            bantime: "2023-06-15T08:39:55.000+00:00",
            bandays: 3
        }], // 测试数据，注销的用户数据

        userArray: [], // 用于显示的数组，根据需要显示
        showLength: 0, // 所有数据的长度
        
        whichtab: '正常',
        pageSize: 10, // 每页条数
        currentPage: 1,// 页码

        search: {
            id: '',
            name: '',
            date: '',
        },
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        
        modal: {
            bmodal: false, // 封禁用户的弹窗
            vmodal: false, // 弹窗开关
            text: '', // 带按钮的弹窗内容
            showError: false, // true是带按钮的，false是仅提示
            errorText: '', // 仅提示时的文本
        },

        selectArray: [{
          value: '3',
          label: '3天'
        }, {
          value: '7',
          label: '7天'
        }, {
          value: '30',
          label: '1个月'
        }, {
          value: '10y',
          label: '10年'
        }],
        selectValue: '3天', // 封禁时间对应的参数
        selectUserId: '', // 封禁的用户id
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n
            this.currentPage = 1
            this.search.id = ''
            this.search.name = ''
            this.search.date = ''
            this.updatePage(0,this.pageSize - 1)
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'tabs-head-item-select'
            } else {
                return
            }
        },
        updatePage(start,end) { // 更新显示的数据
            if(this.whichtab === '正常') {
                this.userArray = this.userNormal.slice(start,end)
                this.showLength = this.userNormal.length
            } else if (this.whichtab === '封禁中') {
                this.userArray = this.userBanned.slice(start,end)
                this.showLength = this.userBanned.length
            } else if (this.whichtab === '已注销') {
                this.userArray = this.userLogOut.slice(start,end)
                this.showLength = this.userLogOut.length
            }
        },
        searchUser() {
            if (this.search.id !== '') {
                if(this.whichtab === '正常') {
                    let array = this.userNormal.filter(function(item){
                        return item.userid === this.search.id
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '封禁中') {
                    let array = this.userBanned.filter(function(item){
                        return item.userid === this.search.id
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '已注销') {
                    let array = this.userLogOut.filter(function(item){
                        return item.userid === this.search.id
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                }
            } else if (this.search.name !== '') {
                if(this.whichtab === '正常') {
                    let array = this.userNormal.filter(function(item){
                        return item.username === this.search.name
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '封禁中') {
                    let array = this.userBanned.filter(function(item){
                        return item.username === this.search.name
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '已注销') {
                    let array = this.userLogOut.filter(function(item){
                        return item.username === this.search.name
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                }
            } else if (this.search.date !== '') {
                if(this.whichtab === '正常') {
                    let array = this.userNormal.filter(function(item) {
                        return item.createdate >= this.search.date[0] && item.createdate <= this.search.date[1];
                    });
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '封禁中') {
                    let array = this.userBanned.filter(function(item){
                        return item.createdate >= this.search.date[0] && item.createdate <= this.search.date[1];
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                } else if (this.whichtab === '已注销') {
                    let array = this.userLogOut.filter(function(item){
                        return item.createdate >= this.search.date[0] && item.createdate <= this.search.date[1];
                    })
                    this.userArray = array.slice(0,this.pageSize - 1)
                }
            } else {
                alert('请填写任一查询方式')
            }
        },
        resetUser() {
            this.search.id = ''
            this.search.name = ''
            this.search.date = ''
            this.updatePage(0,this.pageSize - 1)
        },
        pageSizeChange(val) {
            // 计算索引值
            const start = (this.currentPage - 1) * val
            const end = this.currentPage * val - 1
            // 更新显示的数据
            this.updatePage(start,end)
            // 更新页距
            this.pageSize = val
        },
        pageCurrentChange(val) {
            // 计算索引值
            const start = (val - 1) * this.pageSize
            const end = this.pageSize * val - 1
            // 更新显示的数据
            this.updatePage(start,end)
            // 更新页距
            this.currentPage = val
        },
        closeModal() {
            this.modal.vmodal = false
            this.modal.bmodal = false
        },
        showModal(val) {
            this.modal.showError = true
            this.modal.errorText = '此账号注销于2023-8-01'
            this.modal.vmodal = true
        },
        banUserModal(id) {
            this.selectUserId = id
            this.modal.bmodal = true
        },
        banUser() {},
        unlockUserModal(id) {
            this.selectUserId = id
            this.modal.text = '2023-8-31'
            this.modal.vmodal = true
        },
        unlockUser() {},
    },
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container2 {
    height: 100%;
    background-color: black;
    padding: 50px;
}
.tabs {
    min-height: 100%;
    width: 100%;
}



/* --------------------------------------------选项卡样式-------------------------------------------- */
.tabs-head {
    width: 100%;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
    /* 在父块加上position: relative;并在子块加上position: absolute;来控制子块在父块内的位置 */
    position: relative;
}
.tabs-head-item {
    display: inline-block;
    height: 50px;
    width: 100px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.tabs-head-item-select {
    padding: 0;
    margin: 0;
    border-bottom: none;
    background-color: #383838;
    color: aqua;
    font-size: 18px;
    font-weight: bold;
}
.tabs-head-item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
/* 新增企业按钮 */
.tabs-head-add {
    display: inline-block;
    position: absolute;
    right: 0;
}
.tabs-head-add-button {
    height: 50px;
    width: 100px;
    border: 1px solid #383838;
    border-radius: 6px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}



/* --------------------------------------------搜索栏样式-------------------------------------------- */
.search-container {
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    color: #fff;
}
.search-box {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}
.search-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.search-input-label {
    width: 80px;
}



/* --------------------------------------------管理页面样式-------------------------------------------- */
/* -------------------------------土地上传管理页面------------------------------- */
.tabs-content {
    padding: 20px;
}
/* 信息栏样式 */
.tabs-content-row {
    padding: 10px;
    text-align: center;
    color: #fff;
}
.tabs-content-col {
    min-height: 50px;
    /* 采用`flex`布局，则可以通过设置`justify-content`和`align-items`属性来实现水平和垂直居中 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    background-color: #a7a5a5;
    border: 2px solid #a7a5a5;
    border-radius: 6px;
}


/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    padding: 50px;
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-text {
    width: 300px; /* 设置文本容器的宽度 */
    word-wrap: break-word; /* 当单词太长时，允许在单词内部进行换行 */
    overflow-wrap: break-word; /* 标准的word-wrap属性的替代写法 */
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}
.modal-input {
    padding: 25px;
    height: 250px;
    word-wrap: break-word; /* 当单词太长时，允许在单词内部进行换行 */
}
.modal-select {
    width: 200px;
    margin-top: 30px;
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>