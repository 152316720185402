<template>
    <div class="mobile-detail-home bg-gray-50">
        <van-nav-bar class="bg-gray-50" :title="$t('MobilePurchaseDetails.title')" :left-text="$t('MobilePurchaseDetails.back')" left-arrow @click-left="goBack" />
        <div class="flex flex-col animate__animated animate__fadeInUp" style="padding: 20px">
            <canvas ref="bigmap" :width="canvasSize" :height="canvasSize" class="mobile-detail-map mb-3 mx-1 shadow-lg rounded-lg  border-3 border-gray"></canvas>

            <!-- 挂牌信息 -->
            <div class="relative items-center rounded-lg shadow-md bg-white px-5 py-4 mt-0 mb-3">
                <div class="absolute top-2 left-3 text-sm text-gray-400">
                    {{$t('MobilePurchaseDetails.infor')}}
                </div>
                <div class="mt-3">
                    <div class="flex justify-between mt-5">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.orderno')}}</p>
                        <p class="text-sm text-right">{{ this.LandInfor.separateOrderId }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.ordertime')}}</p>
                        <p class="text-sm text-right">{{ this.LandInfor.ordertime }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.totalprice')}}</p>
                        <p class="text-sm text-right">{{ this.LandInfor.totalprice+'￥' }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.unitlandid')}}</p>
                        <p class="text-sm text-right">{{ this.LandInfor.unitlandid }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.landname')}}</p>
                        <p class="text-sm text-right">{{ this.LandInfor.landname }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.sellerid')}}</p>
                        <p class="text-sm text-right">{{ this.LandInfor.sellerid }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.certificate')}}</p>
                        <p class="text-sm text-right">{{ this.LandInfor.needpaper?$t('MobilePurchaseDetails.yes'):$t('MobilePurchaseDetails.no') }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-gray-500 text-sm">{{$t('MobilePurchaseDetails.address')}}</p>
                        <p class="text-sm text-right">{{ this.LandInfor.originalLand.detailedaddress }}</p>
                    </div>
                </div>
            </div>
            <!-- 详细信息及原始土地信息 -->
            <div class="rounded-lg shadow-md bg-white px-5 mt-0 mb-3">
                <van-tabs v-model="tabControl">
                    <van-tab title="详情信息">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.area')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.unitLand.communitynumber }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.altitude')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.height }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.length')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landlength }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.width')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.landwidth }}</span>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab title="原始土地信息">
                        <div class="flex flex-col mb-3">
                            <div class="mt-2">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.landId')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandid }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.landName')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.originallandname }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.developer')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.ownerid }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.location')}}:</span>
                                <span class="text-gray-700 text-sm">{{ LandInfor.originalLand.detailedaddress }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-gray-500 text-sm mr-3">{{$t('MobilePurchaseDetails.coordinate')}}:</span>
                                <span class="text-gray-700 text-sm">({{ LandInfor.originalLand.geographicalcoordinates }})</span>
                            </div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
            <!-- 地权解释 -->
            <div class="rounded-lg shadow-md bg-white px-5 mt-1 mb-1 pt-1">
                <span class="text-gray-600 text-md">{{$t('MobilePurchaseDetails.landRights')}}</span>
                <div class="flex flex-col mb-3">
                    <div class="mt-1">
                        <span class="text-gray-500 text-xs mr-1">{{$t('MobilePurchaseDetails.surface')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobilePurchaseDetails.surfaceIntro')}}</span>
                    </div>
                    <div class="mt-1">
                        <span class="text-gray-500 text-xs mr-1">{{$t('MobilePurchaseDetails.digital')}}:</span>
                        <span class="text-gray-600 text-xs">{{$t('MobilePurchaseDetails.digitalIntro')}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="sticky bottom-0 w-full py-3 flex items-center justify-end">
            <button :class="'px-4 mr-5 py-2  shadow-md  cursor-pointer text-sm rounded-3xl w-auto '
            +(orderStatus==='交易成功' || orderStatus==='退款申请被驳回' || orderStatus==='待付款'?'text-grey-400 hover:bg-yellow-500 bg-yellow-300 ':'bg-gray-200 text-black')"
                    @click="onRefund">{{orderStatus===''?$t('MobilePurchaseDetails.refund'):orderStatus}}</button>
            <button class="px-4 mr-5 py-2 shadow-md text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto"
                    @click="TalkSeller">{{$t('MobilePurchaseDetails.private')}}</button>
        </div>

        <!-- 退款弹窗 -->
        <van-popup v-model="showRefund" position="bottom" round class="py-5" >
            <div class="items-center justify-center">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md">{{$t('MobilePurchaseDetails.refundNum')}}</span>
                        <span class="text-gray-600 text-lg my-2 ">￥{{LandInfor.totalprice}}</span>
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md ">{{$t('MobilePurchaseDetails.reason')}}</span>
                        <van-field
                            v-model="refundReason"
                            :placeholder="$t('MobilePurchaseDetails.reasonInput')"
                            type="textarea"
                            rows="5"
                            autosize
                            show-word-limit
                            maxlength="100"
                        />
                    </div>
                </div>
                <div class="flex  items-center justify-center">
                    <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="showRefund = false">{{$t('MobilePurchaseDetails.cancel')}}</button>
                    <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="RefundConfirm">{{$t('MobilePurchaseDetails.confirm')}}</button>
                </div>
            </div>
        </van-popup>

        <!-- 继续支付弹窗 -->
        <van-popup v-model="showContinue" position="bottom" round class="py-5" >
            <div class="items-center justify-center">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex flex-col items-center justify-center">
                        <span class="text-gray-600 text-md">{{$t('MobileOrderList.continueTitle')}}</span>
                        <span class="text-gray-600 text-lg my-2 ">￥{{LandInfor.totalprice}}</span>
                    </div>
                    <div class="grid grid-cols-1 gap-2 text-lg">
                        <!-- TODO 注释掉了支付宝和微信支付 -->
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                            <div class="flex items-center">
                                <img src="../images/alipay.png" alt="" class="w-8 h-8 mr-2" />
                                {{$t('MobileConfirmOrder.aliPay')}}
                            </div>
                            <van-radio class="flex justify-center items-center" v-model="payWay" name="aliPay" disabled></van-radio>
                        </div>
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                            <div class="flex items-center"><img src="../images/wechat.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.wechat')}}</div>
                            <van-radio class="flex justify-center items-center" v-model="payWay" name="wechat" disabled></van-radio>
                        </div>
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                            <div class="flex items-center"><img src="../images/paypal.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.paypal')}}</div>
                            <van-radio class="flex justify-center items-center" v-model="payWay" name="PayPal"></van-radio>
                        </div>
                        <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10">
                            <div class="flex items-center"><img src="../images/balance.png" alt="" class="w-8 h-8 mr-2" />
                                {{$t('MobileConfirmOrder.balance')}}
                            </div>
                            <van-radio class="flex justify-center items-center" v-model="payWay" name="balance"></van-radio>
                        </div>
                    </div>
                </div>
                <div class="flex  items-center justify-center">
                    <button class="px-4 mr-5 py-2  shadow-md  text-grey-400 hover:bg-yellow-500 bg-yellow-300  cursor-pointer text-sm rounded-3xl w-auto" @click="showContinue = false;">{{$t('MobilePurchaseDetails.cancel')}}</button>
                    <button class="px-4 mr-5 py-2  shadow-md   text-white hover:bg-green-600 bg-green-500  cursor-pointer text-sm rounded-3xl w-auto" @click="onSubmit">{{$t('MobilePurchaseDetails.confirm')}}</button>
                </div>
            </div>
        </van-popup>
        <van-popup id="popup" v-model="showPopup" closeable close-icon-position="top-left" position="bottom" round class="pb-14" @close="onClosePop">
            <div class="flex justify-center items-center text-center text-3xl mt-12" >
                <span>￥{{(LandInfor.totalprice).toFixed(2)}}</span>
            </div>
            <div v-if="!showPasswordInput" class="flex justify-center items-center text-center text-sm text-gray-400 mt-1" >
                <span>{{$t('MobileConfirmOrder.balance')}} ￥{{(balance).toFixed(2) }}</span>
                <span class="ml-3 text-red-300">{{balance < LandInfor.totalprice?$t('MobileConfirmOrder.insufficientBalance'):''}}</span>
                <router-link to="/usermobile/recharge" class="text-blue-500"> &nbsp;&nbsp;{{$t('MobileConfirmOrder.topup')}}</router-link>

            </div>
            <div class="mx-3" v-if="!showPasswordInput">
                <div class="mt-2">
                    <span class="text-blue-500 ">{{$t('MobileConfirmOrder.mixPay')}}</span>
                    <span >￥{{((LandInfor.totalprice)-balance).toFixed(2)}}：</span>
                </div>
                <div class="grid grid-cols-1 gap-2 text-lg">
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mt-2 mb-2">
                        <div class="flex items-center">
                            <img src="../images/alipay.png" alt="" class="w-8 h-8 mr-2" />
                            {{$t('MobileConfirmOrder.aliPay')}}
                        </div>
                        <!-- TODO 注释了支付宝和微信 -->
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixAliPay" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                        <div class="flex items-center"><img src="../images/wechat.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.wechat')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixWechat" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                        <div class="flex items-center"><img src="../images/paypal.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.paypal')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixPaypal"></van-radio>
                    </div>
                </div>
            </div>
            <!-- 密码输入框 -->
            <van-password-input
                v-if="showPasswordInput"
                :value="payPassword"
                :focused="showKeyboard"
                :info="$t('MobileConfirmOrder.enterNewPassword')"
                :error-info="errorInfo"
                @focus="onFocusPassword"
                class="mb-60"
            />
            <div v-if="!showPasswordInput" @click="onPay" class="rounded-md hover:bg-blue-600 bg-blue-500 px-8 pt-2 pb-2 mx-32 mt-8 flex justify-center">
                <span class="text-lg self-center text-white">{{$t('MobileConfirmOrder.pay')}}</span>
            </div>

            <!-- 数字键盘 -->
            <van-number-keyboard
                v-model="payPassword"
                :show="showKeyboard"
                @blur="onBlurPassword"
            />
        </van-popup>
    </div>
</template>
<script>
export default {
    name: "MobilePurchaseDetails",
    created() {
        // 不要忘了获取传输过来的土地信息
        this.orderid = this.$route.query.order;
    },
    mounted() {
        this.canvasSize = this.$el.clientWidth;
        // 获取土地详情信息
        if (this.flag) {
            this.flag = false;
            this.$http
                .get("/v1/order/get-myorder-details?separateOrderId=1001-1694487-6405617"/*  + this.orderid */)
                .then((res) => {
                    this.LandInfor = res.data.data;
                    const date = new Date(this.LandInfor.ordertime);// 格式化日期
                    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;// 格式化时间
                    const formattedTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                    this.LandInfor.ordertime = `${formattedDate} ${formattedTime}`;
                    this.orderStatus = this.LandInfor.orderstatus
                    let time1 = setTimeout(() => {
                        // 等待 100 毫秒后再绘制
                        const canvas = this.$refs.bigmap; // 获取 canvas 元素
                        canvas.style.backgroundImage = "url(https://datuland.com" + this.LandInfor.originalLand.landpicture + ")";
                        this.drawGrid();
                        this.drawLand();
                        clearInterval(time1);
                    }, 1000);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        //查询余额
        this.$http.get("/v1/recharge/check-balance")
            .then((res) => {
                this.balance = res.data.data.balance;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    data() {
        return {
            selectPage: 2, // 下方选项头的属性
            originallandid: null, // 与unitlandid共同获取该商品详细信息
            unitlandid: null, // 与originallandid共同获取该商品详细信息
            orderid: null, // 订单id
            flag: true, // 防抖
            haveAddIn: true, // 表示是否已加入购物车，已经加入的为false
            LandInfor: {
                ids: 0,
                separateOrderId: "",
                combinedOrderId: "",
                ordertime: "",
                unitlandid: "",
                landid: "",
                landname: "",
                landpicture: "",
                salemeans: "",
                paymeans: "",
                totalprice: 0,
                combinationpay: null,
                buyerid: "",
                sellerid: "",
                addressid: "",
                needpaper: true,
                orderstatus: "",
                processstatus: "",
                refundreason: null,
                refundtime: null,
                rejectionreason: null,
                isdelete: null,
                originalLand: {
                    id: 0,
                    originallandid: "",
                    ownershipnumber: "",
                    originallandname: "",
                    detailedaddress: "",
                    landcertificate: "",
                    warrantydeed: null,
                    ownerid: "",
                    entrance: "",
                    geographicalcoordinates: "",
                    landlength: "",
                    landwidth: "",
                    height: "",
                    digitalright: "",
                    surfaceright: "",
                    landpicture: "",
                    createdate: "",
                    landstatus: ""
                },
                unitLand: {
                    id: 0,
                    originallandid: "",
                    mergelandname: "",
                    landnumber: "",
                    communitynumber: "",
                    landblocks: 0,
                    landcoordinates: "",
                    virtualownership: "",
                    remark: null,
                    status: null,
                    createdate: ""
                },
                address: {
                    id: 0,
                    userid: "",
                    consigneename: "",
                    contactinformation: "",
                    provincecode: "",
                    province: "",
                    citycode: "",
                    city: "",
                    areacode: "",
                    area: "",
                    detailedaddress: "",
                    createtime: "",
                    isdefault: false
                }
            },
            showRefund: false, // 退款弹窗
            refundReason: "", // 退款原因
            orderStatus: '', // 订单状态
            canvasSize: 0,
            gridSize: 0, // 保存网格边长，用来绘制土地块时使用
            tabControl: 0, // 第三格土地详情栏tab控制参数，true是土地详情，false是原始土地详情
            balance: 0, // 余额
            showContinue: false, // 继续支付弹窗
            payWay: 'PayPal', // 继续支付支付方式，默认PayPal支付
            orderInfor: null, // 继续支付交易信息
            balance: 0, // 余额，在mounted里获取
            totalPrice: 0, // 继续支付所需金额
            showPopup: false, // 继续支付余额支付余额不足选择支付方式弹窗
            showPasswordInput: false, // 余额支付密码弹窗
            payPassword: "", // 余额支付密码
            showKeyboard: false, // 键盘
            errorInfo: "", // 错误信息
        };
    },
    methods: {
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            this.gridSize =this.$el.clientWidth / 22;
            // 清除原有的所有大地图画布元素
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#5eee45"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.unitLand.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);

            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col;
            const height = end.row - start.row;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            // ctx.fillStyle = "red"; // 设置网格颜色
            ctx.fillStyle = "rgba(0,171,151,0.61)";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },
        onRefund(){
            if (this.orderStatus === '交易成功' || this.orderStatus === '退款申请被驳回') {
                this.showRefund = true;
            } else if (this.orderStatus === '待付款') {
                this.showContinue = true;
            } else {
                return;
            }
        },
        RefundConfirm(){
            // 退款确认
            this.$http
                .post("/v1/order/request-refund", {
                    separateOrderId: this.orderid,
                    refundreason: this.refundReason,
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$toast.success(this.$t('MobilePurchaseDetails.alertSeccess'));
                        this.showRefund = false;
                        this.$router.go(-1);
                    } else {
                        this.$toast.fail(res.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        TalkSeller() {
            // 私聊卖家
            this.$router.push({
                path: "/mobile/private-chat?index=" + this.selectPage + "&land=" + this.LandInfor.originalLand.originallandid + "&merch=" + this.LandInfor.unitlandid + "&receive=" + this.LandInfor.sellerid,
            });
        },
        goBack() {
            this.$router.go(-1);
        },
        onClosePop(){
            this.payWay='balance';
            // 等待500ms
            setTimeout(() => {
                this.showPasswordInput = false;
            }, 500);
        },
        onFocusPassword() {
            this.showKeyboard = true;
            // document.querySelector('#popup').style.height = "80%";
        },
        onBlurPassword() {
            this.showKeyboard = false;
            //删除height的设置
            document.querySelector('#popup').style.height = "";
        },
        /** 提交订单&&正常支付*/
        onSubmit() {
            if (this.payWay==='balance'){
                this.showContinue = false;
                this.showPopup = true;
                if(this.balance>=this.LandInfor.totalprice){
                    this.showPasswordInput = true;
                }
            } else {
                const data = {
                    combinedOrderId: this.LandInfor.combinedOrderId,
                    paymeans: this.payWay
                }
                const toastLoading= this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: this.$t('MobileConfirmOrder.alertLoading'),
                    forbidClick: true,
                });
                this.$http.post("/v1/order/continue-payment", data)
                    .then((res) => {
                        toastLoading.clear();
                        this.paymentForm = res.data.html;
                        //todo 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.orderid;
                        // 等待1秒
                        setTimeout(() => {
                            this.submitLoading = false;
                            document.querySelector('form[name="punchout_form"]').submit();
                        }, 500);
                    });
            }
        },
        /** 点击余额支付*/
        onPay(){
            const unitlandids = [this.LandInfor.unitlandid];
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileConfirmOrder.alertLoading'),
                forbidClick: true,
            });
            const data = {
                needpaper: this.LandInfor.needpaper,
                unitlandid: unitlandids,
                addressid: this.LandInfor.address.id,
            };
            /** 检查余额是否足够*/
            if (this.payWay==='balance'){
                if(this.balance<this.LandInfor.totalprice){
                    this.$toast(this.$t('MobileConfirmOrder.alertNoMoney'));
                    return ;
                }
                /** 余额足够*/
                else {
                    this.showPasswordInput = true;
                    return;
                }
            }
            else if(this.payWay==="mixAliPay"){
                this.$http.post("/v1/balance-payment/alipay-combination-payment", data)
                    .then((res) => {
                        toastLoading.clear();
                        if(res.data==='密码错误'){
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return ;
                        }
                        this.paymentForm = res.data.html;
                        //todo 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.orderid;
                        // 等待1秒
                        setTimeout(() => {
                            this.submitLoading = false;
                            document.querySelector('form[name="punchout_form"]').submit();
                        }, 500);
                        // document.querySelector('form[name="punchout_form"]').submit();
                    });
            }
            else if(this.payWay==="mixWechat"){
                toastLoading.clear();
                console.log("微信支付");
            }
            else if(this.payWay==="mixPaypal"){
                this.$http.post("/v1/balance-payment/paypal-combination-payment", data)
                    .then((res) => {
                        toastLoading.clear();
                        if(res.data==='密码错误'){
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return ;
                        }
                        //访问paylink
                        window.location.href = res.data.html;
                        //todo 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.orderid;
                    });
            }
            this.goPay();
        },
        /** 去支付*/
        goPay(){
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileConfirmOrder.alertLoading'),
                forbidClick: true,
            });
            const data = {
                separateOrderId: this.orderInfor.combinedOrderId,
                paymeans: this.payWay,
                paymentpassword: this.payPassword
            };
            if(this.payWay==='balance'){
                this.$http.post("/v1/order/continue-payment", data)
                    .then((res) => {
                    toastLoading.clear();
                        if(res.data.data==='密码错误'){
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return ;
                        }
                    // 保存订单号后提示用户支付成功并做后续操作
                    this.showPasswordInput = false;
                    this.showPopup = false;
                    this.$toast(this.$t('MobileConfirmOrder.alertPaySuccessfully'));
                    this.$router.go(-1);
                });
            }
        },
    },
};
</script>
<style scoped>
.mobile-detail-home {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;
}
.mobile-detail-map {
    background-size: cover;
}
.mobile-detail-bottom {
    height: 50px;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgb(220, 220, 220);
    position: sticky;
    bottom: 0;
}
</style>