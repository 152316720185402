<template>
    <div class="mobile-help-home">
        <van-nav-bar class="bg-gray-50" :title="$t('MobileHelp.title')" :left-text="$t('MobileHelp.back')" left-arrow @click-left="goBack" />

        <div class="flex justify-center items-center bg-gray-100 mt-3">
            <div class="mx-auto px-4 py-4 bg-white">
                <div class="flex items-center">
                    <div class="flex-none mr-4">
                        <img src="../images/service.png" class="w-16 h-16" alt="Customer Service Icon" />
                    </div>
                    <div>
                        <h2 class="text-lg font-bold mb-2">{{$t('MobileHelp.title')}}</h2>
                        <p class="text-gray-600 text-sm">{{$t('MobileService.introduce')}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-for="(i,index) in item" :key="index">
            <h1 class="mobile-help-collapse-topic">{{$t('MobileHelp.t'+(index+1))}}</h1>
            <van-collapse v-model="activeNames">
                <van-collapse-item :title="$t('MobileHelp.q'+(index+1)+'-'+j)" :name="index*10+j" v-for="j in i" :key="j">
                    <p v-html="$t('MobileHelp.a'+(index+1)+'-'+j)"></p>
                </van-collapse-item>
            </van-collapse>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeNames: [],
            item: [6,2,2,4,6,1,3,4,1,2,3,5], // 循环次数，12个大标题，每个标题n个问题
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
.mobile-help-home {
    height: 100%;
    width: 100%;
    background-color: #f9fafb;
    position: relative;
    overflow: auto;
}
.mobile-help-collapse-topic {
    padding: 10px;
    font-size: 14px;
    color: #bebebe;
}
</style>
