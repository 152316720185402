<template>
    <div refs="container" class="canvas-container">
        <!-- 大地图 -->
        <canvas
        ref="bigmapbackground"
        :width="canvasWidth"
        :height="canvasHeight"
        class="big-map-background"
        style="left: 0;top: 0;"></canvas>
        <canvas
        ref="bigmap"
        :width="canvasWidth"
        :height="canvasHeight"
        class="big-map"
        style="left: 0;top: 0;"
        @mousedown="onMouseDown"
        @mouseup="onMouseUp"
        @mousemove="onMouseMove"
        @mouseover="changeCursor"
        @wheel.prevent="zoom"
        ></canvas>
        <!-- 小地图 -->
        <canvas v-show="showSmallMap"
        ref="smallmapbackground"
        :width="smallMapCanvasWidth"
        :height="smallMapCanvasHeight"
        class="small-map-background"></canvas>
        <canvas v-show="showSmallMap"
        ref="smallmap"
        :width="smallMapCanvasWidth"
        :height="smallMapCanvasHeight"
        class="small-map"
        @mousedown="smallMapMouseDown"
        @mouseup="smallMapMouseUp"
        @mousemove="smallMapMouseMove"
        @mouseover="changeCursor"></canvas>
        <!-- 小地图缩放图标 -->
        <div class="land-smallmap-show" v-if="showSmallMap" @click="showSmallMap = false" @mouseover="changeCursor">隐藏小地图</div>
        <div class="land-smallmap-show" v-if="!showSmallMap" @click="showSmallMap = true" @mouseover="changeCursor">显示小地图</div>
        <!-- 左侧土地信息栏 -->
        <div class="land-information-menu" v-if="isLandInforMenu">
            <span style="padding: 10px;">土地名称:{{ originLand.originallandname }}</span>
            <span style="padding: 10px;">所在地址:{{ originLand.detailedaddress }}</span>
            <span style="padding: 10px;">开发商:xxx</span>
            <el-divider></el-divider>
            <span style="padding: 10px;">当前土地坐标:{{ '(' + landcol + ',' + landrow + ')'}}</span>
            <span style="padding: 10px;">当前区:{{ SecondLandCoordinates }}</span>
            <span style="padding: 10px;">坐标范围:0~{{ gridNum - 1 }}</span>
            <button class="button-movein" @click="moveIn" @mouseover="changeCursor" v-show="canMoveIn">进入该小区</button>
        </div>
        <!-- 缩放图标 -->
        <div class="land-information-menu-show el-icon-arrow-left" v-if="isLandInforMenu" @click="isLandInforMenu = false" @mouseover="changeCursor"></div>
        <div class="land-information-menu-hidden el-icon-arrow-right" v-if="!isLandInforMenu" @click="isLandInforMenu = true" @mouseover="changeCursor"></div>
    </div>
</template>

<script>
export default {
    created() {
        this.originallandid = this.$route.query.land;
    },
    mounted() { // TODO 未解封
        /* this.$http
            .get("/v1/order/getMyorderDetails?separateOrderId=" + this.merch + "&landId=" + this.land)
            .then((res) => {
                this.originLand = res.data.data;
            })
            .catch((error) => {
                console.log(error);
            }); */
        /* ctx.fillStyle = "rgb(200,0,0)"; // 设置填充颜色
        ctx.fillRect(25, 25, 100, 100); // fillRect(x, y, width, height)绘制一个填充的矩形
        ctx.clearRect(45, 45, 60, 60); // strokeRect(x, y, width, height)绘制一个矩形的边框
        ctx.strokeStyle = "rgb(200,0,0)"; // 设置边框颜色
        ctx.strokeRect(50, 50, 50, 50); // clearRect(x, y, width, height)清除指定矩形区域，让清除部分完全透明。 */
        // 获取容器的宽高
        this.containerWidth = this.$el.clientWidth;
        this.containerHeight = this.$el.clientHeight;
        // 根据后端获取的土地长宽赋予画布长宽
        this.originalCanvasHeight = this.originLand.width * 2
        this.originalCanvasWidth = this.originLand.height * 2
        this.canvasWidth = this.originalCanvasHeight
        this.canvasHeight = this.originalCanvasWidth
        // 获取小地图画布长宽和容器长宽
        this.smallMapContainerHeight = this.containerHeight / 10
        this.smallMapContainerWidth = this.containerWidth / 10
        this.smallMapCanvasWidth = this.canvasWidth / 10
        this.smallMapCanvasHeight = this.canvasHeight / 10
        // 获取横纵坐标各有多少个小方块
        this.colLength = this.canvasWidth / this.gridSize
        this.rowLength = this.canvasHeight / this.gridSize
        // 计算小区土地坐标范围
        this.gridNum = this.colLength * this.rowLength

    
        let time1 = setTimeout(() => {  // 等待 100 毫秒后再绘制
            const canvas = document.querySelector('.big-map-background');  // 获取 canvas 元素
            if (!canvas) { // 判断 canvas 元素是否存在
                console.error('Canvas element is not found');
            } else {
                this.drawBigMapGrid() // 初始化大地图网格
                clearInterval(time1)
            }
        }, 100);
        let time2 = setTimeout(() => {  // 等待 100 毫秒后再绘制
            // 绘制小地图初始化
            const canvas2 = this.$refs.smallmap
            if (!canvas2) { // 判断 canvas 元素是否存在
                console.error('Canvas2 element is not found');
            } else {
                this.drawSmallMapGrid() // 初始化小地图网格
                this.drawSmallMapBoxShadow() // 初始化小地图容器边框
                clearInterval(time2)
            }
        }, 100);
    },
    data() {
        return {
            originalCanvasHeight: 0,
            originalCanvasWidth: 0,
            containerHeight: 0,
            containerWidth: 0,
            canvasHeight: 0,
            canvasWidth: 0,
            smallMapContainerHeight: 0,
            smallMapContainerWidth: 0,
            smallMapCanvasHeight: 0,
            smallMapCanvasWidth: 0,
            gridSize: 44,  // 原始土地分割成22x22的区块
            colLength: 0, // 计算获得横坐标方向有多少小方块
            rowLength: 0,
            data: [],
            isMouseDown: false,
            originX: 0, // 大地图鼠标按下时的坐标
            originY: 0,
            col: 0, // 地图容器左上角坐标
            row: 0,
            landcol: null, // 土地网格坐标
            landrow: null,
            gridNum: 0,

            zoomFactor: 1, // 缩放指数

            originallandid: '', // 原始土地id，查询信息需要用到
            originLand: {
                id: 1,
                originallandid: "S538",
                ownershipnumber: "889123654",
                originallandname: "黑土地",
                detailedaddress: "青岛市",
                landcertificate: "/usr/local/land/landpicture/USER_10022/8c1d34694ed242f397dabc8a8ad34867.pdf",
                warrantydeed: null,
                ownerid: "b4bcab5f1afa44e8bbba5449afa13d6d",
                entrance: "东",
                geographicalcoordinates: "12,23",
                landlength: "120",
                landwidth: "150",
                height: "2",
                digitalright: "出售数字权",
                surfaceright: "出售地表权",
                landpicture: "/usr/local/land/landpicture/USER_10023/d0a14175baaa46a9abb343df5af93764.png",
                createdate: "2023-05-31 09:52:24",
                landstatus: "未审核",
                width: 2640,
                height: 2640,
            },

            canMoveIn: false, // 进入该小区，初始化时不显示按钮，第一次选中后显示按钮

            isSmallMapMouseDown: false, // 小地图被按下

            isLandInforMenu: true, // 左侧信息栏显示控制

            SecondLandCoordinates: 0, // 二号土地坐标

            showSmallMap: true, // 是否显示小地图
        }
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        drawBigMapGrid() { // 绘制大地图网格
            const canvas = document.querySelector('.big-map-background');  // 获取 canvas 元素
            const ctx = canvas.getContext("2d");  // 获取绘画环境
            // 清除原有的所有大地图画布元素
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (let i = 0;i < this.colLength;i++) {  // 绘制大地图网格
                for (let j = 0;j < this.rowLength;j++) {
                    ctx.strokeRect(i*this.gridSize,j*this.gridSize,this.gridSize,this.gridSize)
                }
            }
        },
        drawSmallMapGrid() { // 绘制小地图网格
            const canvas = this.$refs.smallmapbackground
            const ctx = canvas.getContext("2d")
            // 清除原有的所有小地图画布元素
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#eeeeee" // 设置网格颜色
            for (let i = 0;i < this.colLength;i++) {  // 绘制大地图网格
                for (let j = 0;j < this.rowLength;j++) {
                    ctx.strokeRect(i * this.gridSize / 10,j * this.gridSize / 10,this.gridSize / 10,this.gridSize / 10)
                }
            }
        },
        drawSmallMapBoxShadow() { // 绘制小地图容器边框
            const canvas = this.$refs.smallmap
            const ctx = canvas.getContext("2d")
            // 清除原有的所有小地图画布元素
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#5eee45" // 设置小地图边框颜色
            ctx.strokeRect(this.col,this.row,this.smallMapContainerWidth,this.smallMapContainerHeight)
        },
        // 鼠标按下事件
        onMouseDown(event) {
            this.isMouseDown = true;
            this.originX = event.clientX; // 在鼠标按下时，获取鼠标的当前坐标值
            this.originY = event.clientY;
            this.bigMapSelect() // 将选中的土地方块高亮显示
            this.showLandInfor() // 显示当前网格土地信息
            event.preventDefault();
        },
        // 鼠标松开事件
        onMouseUp() {
            this.isMouseDown = false;
        },
        // 鼠标移动事件
        onMouseMove(event) {
            if (!this.isMouseDown) {
                this.floatingframe(event.clientX,event.clientY)
                return;
            }
            let moveX = event.clientX - this.originX; // 当鼠标滑动时，每当坐标值更改，获取一次鼠标坐标移动的偏差值
            let moveY = event.clientY - this.originY;
      
            // 获取当前容器显示的网格缓冲区位置，根据移动边界进行更新
            const canvas = document.querySelector('.big-map-background');
            const left = parseInt(canvas.style.left) + moveX
            const down = parseInt(canvas.style.top) + moveY
            const rightend = this.containerWidth - this.canvasWidth
            const downend = this.containerHeight - this.canvasHeight
            if (left > 0 || left < rightend) {
                moveX = 0
            }
            if(down > 0 || down < downend) {
                moveY = 0
            }
            canvas.style.left = `${parseInt(canvas.style.left || 0) + moveX}px`;
            canvas.style.top = `${parseInt(canvas.style.top || 0) + moveY}px`;

            // 双层大地图均需要移动
            const canvas2 = document.querySelector('.big-map');
            canvas2.style.left = `${parseInt(canvas2.style.left || 0) + moveX}px`;
            canvas2.style.top = `${parseInt(canvas2.style.top || 0) + moveY}px`;

            // 更新鼠标坐标
            this.originX = event.clientX;
            this.originY = event.clientY;
            // 更新容器坐标
            this.col = -parseInt(canvas.style.left) / (this.zoomFactor * 10)
            this.row = -parseInt(canvas.style.top) / (this.zoomFactor * 10)
            // 更新小地图
            this.smallMapMove() // 移动边框

            event.preventDefault();
        },
        zoom(event) {
            const canvas = document.querySelector('.big-map-background');

            // 监听鼠标滚轮事件
            const delta = Math.sign(event.deltaY);
            const maxScale = 2; // 最大缩放比例
            const minScale = 0.5; // 最小缩放比例

            if (delta > 0) { // 缩小时可能出现缩小后的画布小于屏幕，此时不可缩小
                if (this.smallMapCanvasHeight <= this.containerHeight / (10 * this.zoomFactor - delta) || this.smallMapCanvasWidth <= this.containerWidth / (10 * this.zoomFactor - delta)) {
                    return
                }
            }

            // 根据滚轮方向调整缩放比例
            this.zoomFactor = Math.max(minScale, Math.min(maxScale, Math.round((this.zoomFactor - delta * 0.1) * 10) / 10));
            this.canvasHeight = this.zoomFactor * this.originalCanvasHeight
            this.canvasWidth = this.zoomFactor * this.originalCanvasWidth
            this.gridSize = this.zoomFactor * 44

            // 小地图容器边框的长宽也需要同比例扩大/缩小
            this.smallMapContainerHeight = this.containerHeight * this.smallMapCanvasHeight / this.canvasHeight
            this.smallMapContainerWidth = this.containerWidth * this.smallMapCanvasWidth / this.canvasWidth

            // 设置canvas的缩放比例和平移位置
            if (delta > 0) { // 缩小时需要注意是否到达画布最右侧，如果到达，需要将容器位置平移
                const canvas2 = document.querySelector('.big-map');
                if (this.col > this.smallMapCanvasWidth - this.smallMapContainerWidth) { // 超出右侧范围
                    this.col = this.smallMapCanvasWidth - this.smallMapContainerWidth
                    canvas2.style.left = `${parseInt(this.containerWidth - this.canvasWidth)}px`;
                    canvas.style.left = `${parseInt(this.containerWidth - this.canvasWidth)}px`;
                } else if (this.row > this.smallMapCanvasHeight - this.smallMapContainerHeight) { // 超出下边范围
                    this.row = this.smallMapCanvasHeight - this.smallMapContainerHeight
                    canvas2.style.top = `${parseInt(this.containerHeight - this.canvasHeight)}px`;
                    canvas.style.top = `${parseInt(this.containerHeight - this.canvasHeight)}px`;
                }
            } else { // 放大时固定左上角
                canvas.style.transformOrigin = 'top left';
            }
      
            // 重新绘制画布
            let time1 = setTimeout(() => {  // 等待 100 毫秒后再绘制
                this.drawBigMapGrid() // 初始化大地图网格
                this.drawSmallMapBoxShadow() // 初始化小地图网格
                clearInterval(time1)
            }, 100);
        },
        smallMapMove() { // 小地图跟随大地图页面容器移动更新
            const canvas = this.$refs.smallmap
            const ctx = canvas.getContext('2d')
            // 清除原有的矩形边框
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // 绘制新的矩形边框
            ctx.strokeRect(this.col,this.row,this.smallMapContainerWidth,this.smallMapContainerHeight);
        },
        bigMapSelect() { // 大地图鼠标按下时选中某个区域，此区域高亮显示
            this.canMoveIn = true; // 显示进入该小区按钮
            // 根据鼠标按下时的坐标获取选中区域的矩形坐标
            const canvas = this.$refs.bigmap;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = this.originX - rect.left;
            const canvasY = this.originY - rect.top;
            this.landcol = Math.floor(canvasX / this.gridSize)
            this.landrow = Math.floor(canvasY / this.gridSize)
            const ctx = canvas.getContext('2d');
            // 清除原有的所有大地图选中元素
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // 绘制新的选中元素
            ctx.fillStyle = "orange"
            ctx.fillRect(this.landcol * this.gridSize + 1,this.landrow * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
        },
        smallMapMouseDown(event) { // 鼠标点中小地图
            this.isSmallMapMouseDown = true
            // 获取鼠标按下时在小地图画布上的坐标
            const canvas = this.$refs.smallmap;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = event.clientX - rect.left;
            const canvasY = event.clientY - rect.top;
            this.col = Math.max(Math.min(canvasX - this.smallMapContainerWidth * 0.5,this.smallMapCanvasWidth - this.smallMapContainerWidth),0)
            this.row = Math.max(Math.min(canvasY - this.smallMapContainerHeight * 0.5,this.smallMapCanvasHeight - this.smallMapContainerHeight),0)
            const ctx = canvas.getContext('2d')
            // 清除原有的矩形边框并绘制新的矩形边框
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeRect(this.col,this.row,this.smallMapContainerWidth,this.smallMapContainerHeight);
            // 大地图移动到相应位置
            const canvas2 = this.$refs.bigmapbackground
            canvas2.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
            canvas2.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
            const canvas3 = this.$refs.bigmap
            canvas3.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
            canvas3.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
            // 更新鼠标坐标
            this.originX = event.clientX;
            this.originY = event.clientY;
        },
        smallMapMouseMove(event) {
            if(!this.isSmallMapMouseDown) {
                return
            }
            // 获取当前容器显示的网格缓冲区位置，根据移动边界进行更新
            const canvas = this.$refs.smallmap;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = event.clientX - rect.left;
            const canvasY = event.clientY - rect.top;
            this.col = Math.max(Math.min(canvasX - this.smallMapContainerWidth * 0.5,this.smallMapCanvasWidth - this.smallMapContainerWidth),0)
            this.row = Math.max(Math.min(canvasY - this.smallMapContainerHeight * 0.5,this.smallMapCanvasHeight - this.smallMapContainerHeight),0)
            // 清除原有的矩形边框并绘制新的矩形边框
            const ctx = canvas.getContext('2d')
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeRect(this.col,this.row,this.smallMapContainerWidth,this.smallMapContainerHeight);
            // 大地图移动到相应位置
            const canvas2 = this.$refs.bigmapbackground
            canvas2.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
            canvas2.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
            const canvas3 = this.$refs.bigmap
            canvas3.style.left = `-${this.col * (this.zoomFactor * 10)}px`;
            canvas3.style.top = `-${this.row * (this.zoomFactor * 10)}px`;
        },
        smallMapMouseUp() {
            this.isSmallMapMouseDown = false
            // 绘制小地图网格
            this.drawSmallMapBoxShadow()
        },
        showLandInfor() { // 更新左侧菜单栏信息
            /* // 根据鼠标按下时的坐标获取选中区域的矩形坐标
            const canvas = this.$refs.bigmap;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = this.originX - rect.left;
            const canvasY = this.originY - rect.top;
            // 将位置坐标转化为土地块的坐标
            this.landcol = Math.floor(canvasX / this.gridSize)
            this.landrow = Math.floor(canvasY / this.gridSize) */
            // 二号土地坐标更新
            this.SecondLandCoordinates = this.landcol + this.landrow * this.colLength
        },
        floatingframe(x,y) { // 绘制悬浮框时获取坐标
            // 根据鼠标悬浮的坐标确定土地块坐标
            const canvas = this.$refs.bigmap;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = x - rect.left;
            const canvasY = y - rect.top;
            // 将位置坐标转化为土地块的坐标
            const landcol = Math.floor(canvasX / this.gridSize)
            const landrow = Math.floor(canvasY / this.gridSize)
            // 确定绘制的悬浮框坐标
            const drawX = landcol * this.gridSize + this.gridSize / 2
            const drawY = landrow * this.gridSize

            // 清除Canvas
            const ctx = canvas.getContext('2d')

            this.drawHoverBox(ctx,drawX,drawY,landcol,landrow)
        },
        drawHoverBox(ctx,drawX,drawY,landcol,landrow) { // 绘制悬浮框

            // 清除Canvas
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            // 绘制选中的小区网格
            if (this.landcol && this.landrow) {
                ctx.fillStyle = 'orange';
                ctx.fillRect(this.landcol * this.gridSize + 1,this.landrow * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
            }

            // 确定悬浮框的宽为80px，高为40px
            if (landcol === 0 && landrow !== 0) { // 左侧
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, drawY);
                ctx.lineTo(drawX + 5, drawY - 10);
                ctx.lineTo(drawX - 20, drawY - 10);
                ctx.lineTo(drawX - 20, drawY - 50);
                ctx.lineTo(drawX + 60, drawY - 50);
                ctx.lineTo(drawX + 60, drawY - 10);
                ctx.lineTo(drawX + 10, drawY - 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 10, y - 22);
            } else if (landrow === 0 && landcol !== this.colLength - 1) { // 上方
                const y = drawY + this.gridSize
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, y);
                ctx.lineTo(drawX + 5, y + 10);
                ctx.lineTo(drawX - 40, y + 10);
                ctx.lineTo(drawX - 40, y + 50);
                ctx.lineTo(drawX + 40, y + 50);
                ctx.lineTo(drawX + 40, y + 10);
                ctx.lineTo(drawX + 10, y + 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 30, y + 35);
            } else if (landcol === this.colLength - 1 && landrow !== 0) { // 右侧
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, drawY);
                ctx.lineTo(drawX - 10, drawY - 10);
                ctx.lineTo(drawX - 70, drawY - 10);
                ctx.lineTo(drawX - 70, drawY - 50);
                ctx.lineTo(drawX + 20, drawY - 50);
                ctx.lineTo(drawX + 20, drawY - 10);
                ctx.lineTo(drawX - 5, drawY - 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 65, drawY - 22);
            } else if (landcol === this.colLength - 1 && landrow === 0) { // 右上
                const y = drawY + this.gridSize
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, y);
                ctx.lineTo(drawX - 10, y + 10);
                ctx.lineTo(drawX - 60, y + 10);
                ctx.lineTo(drawX - 60, y + 50);
                ctx.lineTo(drawX + 20, y + 50);
                ctx.lineTo(drawX + 20, y + 10);
                ctx.lineTo(drawX - 5, y + 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 55, y + 35);
            } else {
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, drawY);
                ctx.lineTo(drawX + 5, drawY - 10);
                ctx.lineTo(drawX - 40, drawY - 10);
                ctx.lineTo(drawX - 40, drawY - 50);
                ctx.lineTo(drawX + 40, drawY - 50);
                ctx.lineTo(drawX + 40, drawY - 10);
                ctx.lineTo(drawX + 10, drawY - 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 35, drawY - 22);
            }
        },
        moveIn() {
            this.$router.push({
                path:'/land-operate/area-map?land=' + this.originallandid + '&area=' + this.SecondLandCoordinates})
        },
    },
}
</script>

<style scoped>
.canvas-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #fff;
}

.big-map-background {
    background-image: url('../assets/no-image.png');
    background-size: cover;
    position: absolute;
    z-index: 0;
}

.big-map {
    position: absolute;
    z-index: 1;
}

.small-map-background {
    position: absolute;
    right: 10px;
    top: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 1);
    z-index: 2;
}

.small-map {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 3;
}

/* 屏幕左侧土地信息栏 */
.land-information-menu {
    height: 600px;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    position: absolute;
    top: calc(50% - 300px);
    left: 0;
    background-color: #767676;
    color: #fff;
    font-size: 16px;
    z-index: 3;
}
.button-movein {
    height: 50px;
    width: 120px;
    margin-top: 20px;
    background-color: #fff;
    color: black;
}
/* 信息栏缩放图标 */
.land-information-menu-show {
    display: flex;
    align-items: center;
    height: 30px;
    width: 20px;
    position: absolute;
    top: calc(50% - 15px);
    left: 350px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #767676;
    z-index: 3;
}
.land-information-menu-hidden {
    display: flex;
    align-items: center;
    height: 30px;
    width: 20px;
    position: absolute;
    top: calc(50% - 15px);
    left: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #767676;
    z-index: 3;
}

/* 右上小地图显示/隐藏图标 */
.land-smallmap-show {
    height: 70px;
    width: 150px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #767676;
    border-radius: 12px;
    z-index: 2;
    color: #eeeeee;
    font-size: 20px;
    font-weight: bold;
}
</style>