<template>
    <div class="main-container3">
        <div class="upload-form-parent">
            <div class="upload-form-topic"><span>开发商账户身份认证</span></div>
            <div class="upload-form" style="flex-grow: 1;display: flex;">
                <div class="upload-form-left">
                    <div style="font-size: 20px;margin-bottom: 20px;">公司信息</div>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">公司名称:</span>
                        <span>{{ companyName }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">房屋所有权证编号:</span>
                        <span>{{ ownershipCertificate }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">企业联络人姓名:</span>
                        <span>{{ companyContact }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">企业联络人手机号码:</span>
                        <span>{{ companyContactPhone }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <div class="upload-form-item-text">企业签约人身份证:</div>
                        <!-- 身份证正面照 -->
                        <div class="upload-form-item-img" @mouseover="changeCursor">
                            <el-tooltip class="item" effect="dark" content="点击查看原图" placement="top-end">
                                <img :src="frontIdImg" alt="身份证正面照" class="upload-form-item-img-i" @click="show(1)">
                            </el-tooltip>
                            <!-- 弹框的显示和隐藏状态，根据 isfrontIdImg 变量决定 -->
                            <div class="lightbox" v-if="isfrontIdImg" @click="hide">
                                <img class="full-image" :src="frontIdImg"/>
                            </div>
                        </div>
                        <!-- 身份证背面照 -->
                        <div class="upload-form-item-img" @mouseover="changeCursor">
                            <el-tooltip class="item" effect="dark" content="点击查看原图" placement="top-end">
                                <img :src="reverseIdImg" alt="身份证背面照" class="upload-form-item-img-i" @click="show(2)">
                            </el-tooltip>
                            <!-- 弹框的显示和隐藏状态，根据 isreverseIdImg 变量决定 -->
                            <div class="lightbox" v-if="isreverseIdImg" @click="hide">
                                <img class="full-image" :src="reverseIdImg"/>
                            </div>
                        </div>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <div class="upload-form-item-text">土地无贷款证明:</div>
                        <!-- 土地无贷款证明 -->
                        <el-link :href="landcertificateImg" @mouseover="changeCursor" style="color: black;">点击下载查看</el-link>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <div class="upload-form-item-text">公司注册信息和注册证明:</div>
                        <!-- 公司注册信息 -->
                        <el-link :href="CPRTImg" @mouseover="changeCursor" style="color: black;">点击下载查看</el-link>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <div class="upload-form-item-text">平台合同扫描件:</div>
                        <!-- 平台合同扫描件 -->
                        <el-link :href="PFCTImg" @mouseover="changeCursor" style="color: black;">点击下载查看</el-link>
                    </el-row>
                </div>
                <div class="upload-form-right" style="margin-left: 20px;">
                    <div style="font-size: 20px;margin-bottom: 20px;">土地信息</div>
                    <el-row class="upload-form-item">
                        <span class="upload-form-item-label">土地名称:</span>
                        <span>{{ landname }}</span>
                    </el-row>
                    <el-row class="upload-form-item">
                        <span class="upload-form-item-label">详细地址:</span>
                        <span>{{ landaddress }}</span>
                    </el-row>
                    <el-row class="upload-form-item">
                        <span class="upload-form-item-label">地理坐标:</span>
                        <span>{{ longilati }}</span>
                    </el-row>
                    <el-row class="upload-form-item">
                        <span class="upload-form-item-label">土地类型:</span>
                        <span>{{ landtype }}</span>
                    </el-row>
                    <el-row class="upload-form-item">
                        <span class="upload-form-item-label">土地长度:</span>
                        <span>{{ landlength }}英尺</span>
                    </el-row>
                    <el-row class="upload-form-item">
                        <span class="upload-form-item-label">土地宽度:</span>
                        <span>{{ landwidth }}英尺</span>
                    </el-row>
                    <el-row class="upload-form-item">
                        <span class="upload-form-item-label">海拔:</span>
                        <span>{{ landheight }}英尺</span>
                    </el-row>
                    <el-row class="upload-form-item">
                        <div class="upload-form-item-text">土地照片:</div>
                        <!-- 土地照片 -->
                        <div class="upload-form-item-img" @mouseover="changeCursor">
                            <el-tooltip class="item" effect="dark" content="点击查看原图" placement="top-end">
                                <img :src="landUrl" alt="土地照片" class="upload-form-item-img-i" @click="show(7)">
                            </el-tooltip>
                            <!-- 弹框的显示和隐藏状态，根据 isCPRT 变量决定 -->
                            <div class="lightbox" v-if="islandimg" @click="hide">
                                <img class="full-image" :src="landUrl"/>
                            </div>
                        </div>
                    </el-row>
                </div>
            </div>
        </div>
        <div class="upload-button-parent">
            <button class="upload-button" @click="goBack">
                <span>返回</span>
            </button>
            <button class="upload-button" @click="apply">
                <span>通过申请</span>
            </button>
            <button class="upload-button" @click="refuse">
                <span>驳回申请</span>
            </button>
        </div>
    </div>
</template>

<script>
import axios from "axios";


export default {
    props: { // 父子组件传值
        landChild: { // 包含landChild.landID和landChild.isReview两个值
            type: Object,
            required: true
        }
    },
    data() {
        return {
            businessurl: 'http://192.168.1.145:8092/business/seeImages?', // 获取企业信息图片的后端接口，还需要value=73663ebd471246a38fac4aef32623037.png、businessid=c55468def68d414ea9cc4b74b573e52e
            landurl: 'http://192.168.1.145:8092/business/seeImages?', // 获取土地上传信息图片的后端接口,还需要value=fee424f5de534617b4a575f201d567b2.png&businessid=b64151dc874548e782ebec4fa8f701bf


            businessid: '', // 审核通过与否通过id向后端回复
            companyName: '',
            ownershipCertificate: '',
            companyContact: '',
            companyContactPhone: '',
            frontIdImg: require('../assets/no-image.png'), // 身份证正面照
            isfrontIdImg: false,
            reverseIdImg: require('../assets/no-image.png'), // 身份证反面照
            isreverseIdImg: false,
            landcertificateImg: require('../assets/no-image.png'), // 土地无贷款证明
            islandcertificate: false,
            CPRTImg: require('../assets/no-image.png'), // 公司注册信息
            isCPRT: false,
            CRImg: require('../assets/no-image.png'), // 公司注册证明
            isCR: false,
            PFCTImg: require('../assets/no-image.png'), // 平台合同扫描件
            isPFCT: false,
            createdate: '', // 企业认证日期


            // 土地信息
            landid: '', // 土地编号
            landname: '', // 土地名称
            landaddress: '', // 详细地址
            longilati: '', // 经纬度
            landtype: '', // 土地类型
            landlength: '', // 土地长度
            landwidth: '', // 土地宽度
            landheight: '', // 海拔
            landdate: '', // 土地上传日期
            landImage: '', // 土地照片
            islandimg: false,
            landUrl: require('../assets/no-image.png'), // 土地照片
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },

        /* 点击图片查看原图事件 */
        show(n) {
            if (n===1) {
                this.isfrontIdImg = true
            } else if (n===2) {
                this.isreverseIdImg = true
            } else if (n===3) {
                this.islandcertificate = true
            } else if (n===4) {
                this.isCPRT = true
            } else if (n===5) {
                this.isCR = true
            } else if (n===6) {
                this.isPFCT = true
            } else if (n===7) {
                this.islandimg = true
            }
        },
        hide() {
            this.isfrontIdImg = false; // 点击弹框外部时，隐藏弹框
            this.isreverseIdImg = false;
            this.islandcertificate = false;
            this.isCPRT = false;
            this.isCR = false;
            this.isPFCT = false;
            this.islandimg = false
        },
        

        /* 提交按钮事件 */
        goBack() {
            this.landChild.isReview = true
        },
        apply() {
            const data = {
                originallandid: this.landChild.landId,
                landstatus: '已上线'
            }
            // 向后端发送数据 测试地址http://192.168.1.145:8092/Administrator/passthrough
            this.$http.post('/v1/Administrator/passthrough', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                alert('审核通过，已录入系统！')
                window.location.reload(); // 刷新页面
            })
            .catch(error => {
                // 处理错误
                alert('审核通过录入系统失败！错误代码：' + error.data)
            });
        },
        refuse() {
            const data = {
                originallandid: this.landChild.landId,
                landstatus: '已驳回'
            }
            // 向后端发送数据 测试地址http://192.168.1.145:8092/Administrator/passthrough
            this.$http.post('/v1/Administrator/passthrough', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                alert('审核驳回，已录入系统！')
                window.location.reload(); // 刷新页面
            })
            .catch(error => {
                // 处理错误
                alert('审核驳回录入系统失败！错误代码：' + error.data)
            });
        },
    },
    created() {
        this.$http.get('/v1/business/seeInformation?originallandid=' + this.landChild.landId)
            .then(res => {
                // 处理企业信息返回值
                this.businessid = res.data.data.userBusiness.businessid                            // 企业id
                this.companyName = res.data.data.userBusiness.corporationname                      // 公司名称
                this.ownershipCertificate = res.data.data.userBusiness.housingownership            // 房屋所有权证编号
                this.companyContact = res.data.data.userBusiness.businessname                      // 企业负责人姓名
                this.companyContactPhone = res.data.data.userBusiness.businessphone                // 企业负责人联系电话
                this.createdate = res.data.data.userBusiness.createdate                            // 企业信息认证成功时间
                this.frontIdImg = this.businessurl + 'key=businesscard1&businessid=' + this.businessid + '&value=' + res.data.data.userBusiness.businesscard1              // 身份证正面照
                this.reverseIdImg = this.businessurl + 'key=businesscard2&businessid=' + this.businessid + '&value=' + res.data.data.userBusiness.businesscard2            // 身份证反面照
                this.landcertificateImg = this.businessurl + 'key=landcertificate&businessid=' + this.businessid + '&value=' + res.data.data.userBusiness.landcertificate    // 土地无贷款证明
                this.CPRTImg = this.businessurl + 'key=companyregistration&businessid=' + this.businessid + '&value=' + res.data.data.userBusiness.companyregistration           // 公司注册信息照
                this.CRImg = this.businessurl + 'key=certificateregistration&businessid=' + this.businessid + '&value=' + res.data.data.userBusiness.certificateregistration         // 公司注册证明照
                this.PFCTImg = this.businessurl + 'key=platformcontract&businessid=' + this.businessid + '&value=' + res.data.data.userBusiness.platformcontract              // 平台合同扫描件

                //处理土地返回值
                this.landid = res.data.data.originalLand.originallandid                // 土地id
                this.landname = res.data.data.originalLand.originallandname            // 土地名称
                this.landaddress = res.data.data.originalLand.detailedaddress          // 土地详细地址
                this.landtype = res.data.data.originalLand.landtype                    // 土地类型
                this.longilati = res.data.data.originalLand.geographicalcoordinates    // 土地经纬度
                this.landlength = res.data.data.originalLand.landlength                // 土地长度
                this.landwidth = res.data.data.originalLand.landwidth                  // 土地宽度
                this.landheight = res.data.data.originalLand.height                    // 土地海拔
                this.landdate = res.data.data.originalLand.createdate                  // 土地信息上传时间
                this.landUrl = this.landurl + 'key=landpicture&businessid=' + this.businessid + '&value=' + res.data.data.originalLand.landpicture
            })
            .catch(error => {
                // 处理错误
                alert('服务器异常！错误代码：' + error)
            });
    },
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container3 {
    height: 100%;
}


/* -----------------------------------------上传公司负责人信息填写表单------------------------------------------ */
.upload-form-parent {
    /* 父 `div` 容器设置为 `flex` 容器。然后，将子 `div` 容器设置为 `flex-grow: 1;`，将其自动填充父容器的剩余空间 */
    display: flex;
    flex-direction: column;
}
/* 表的标题 */
.upload-form-topic {
    color: #fff;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}
/* 表单层-更换颜色 */
.upload-form {
    display: flex;
    flex-grow: 1;
    background-color: #646064;
    padding: 20px;
    border-radius: 6px;
    white-space: nowrap;
}
.upload-form-left {
    vertical-align: top;
    width: 50%;
    padding: 20px;
    border-radius: 6px;
    border: 2px solid #000000;
}
.upload-form-right {
    vertical-align: top;
    flex-basis: 50%; /* 子 div 宽度为 30% */
    flex-wrap: wrap; /* 需要有换行，否则它们会溢出它们的容器 */
    padding: 20px;
    border-radius: 6px;
    border: 2px solid #000000;
}
/* 表单元素 */
.upload-form-item {
    padding: 20px;
    /* 父组件加入后让子组件平齐 */
    white-space: nowrap;
}
/* ------------------------------上传文本样式------------------------------- */
/* 表单元素-标签 */
.upload-form-item-label {
    width: 150px;
    display: inline-block;
    font-size: 16px;
    color: #fff;
    /* 子组件加入后让同行元素平行 */
    vertical-align: top;
}
/* ------------------------------上传图片样式------------------------------- */
/* 图片框 */
.upload-form-item-img {
    height: 160px;
    width: 240px;
    display: inline-block;
    margin-right: 30px;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
}
/* 图片需要自适应到父块大小 */
.upload-form-item-img-i {
    padding: 0;
    margin: 0;
    max-width: 100%;
    max-height: 100%;
}
/* 描述文本 */
.upload-form-item-text {
    font-size: 16px;
    color: #fff;
}
/* ------------------------------图片弹窗------------------------------- */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
  z-index: 1; /* 将弹框置于其他所有元素之上 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-image {
  max-width: 80%;
  max-height: 80%;
}


/* ------------------------------上传提交开发商用户注册信息表单按钮------------------------------- */
.upload-button-parent {
    margin-top: 20px;
    width: 100%;
    text-align: center;
}
.upload-button {
    margin-right: 50px;
    height: 50px;
    width: 100px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 1);
    border-radius: 6px;
    background-color: #dedcdc;
}
</style>