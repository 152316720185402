<template>
    <div style="height: 100vh; overflow-y: auto">
        <van-nav-bar class="bg-gray-50" :title="$t('MobileConfirmOrder.title')" :left-text="$t('MobileConfirmOrder.back')" left-arrow @click-left="onBack" />
        <van-cell :title="$t('MobileConfirmOrder.cellTitle')" center />
        <div>
            <my-items v-for="(land, index) in LandInfor" :key="index" :LandInfor="land" :show-delete="false" :index="index" ref="MyItems" :showSelect="false" />
        </div>
        <!--   是否开具纸质土地证明-->
        <van-cell center class="mt-5">
            <template #icon>
                {{$t('MobileConfirmOrder.paper')}}
                <van-switch v-model="needpaper" @click="()=>{if(needpaper){totalPrice-=2500} else {totalPrice+=2500};}" size="15px" class="ml-2" />
            </template>
        </van-cell>
<!--        选择地址-->
        <van-cell center class="mt-5" @click="chooseAddress">
            <template v-if="chosenAddressId===null" #icon>
                {{$t('MobileConfirmOrder.chooseAddress')}}
                <van-icon name="arrow" class="ml-2" />
            </template>
            <template v-else #icon>
                <div class="flex  items-center">
                    <div class="text-gray-500">
                        {{$t('MobileConfirmOrder.addressInput')}}：{{ chosenAddress.address }}<br/>
                        {{$t('MobileConfirmOrder.personInput')}}：{{ chosenAddress.name }}<br/>
                        {{$t('MobileConfirmOrder.phone')}}：{{ chosenAddress.tel }}
                    </div>
                    <span class="flex-grow"> </span>
                    <div class="round-md absolute right-3 text-xs text-blue-500 text-right" @click="chooseAddress">{{$t('MobileConfirmOrder.change')}} ></div>
                </div>
            </template>
        </van-cell>

        <div>
            <div v-html="paymentForm"></div>
        </div>
        <div class="mx-3">
            <div class="grid grid-cols-1 gap-2 text-lg">
                <!-- TODO 注释掉了支付宝和微信支付 -->
                <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mt-5 mb-2">
                    <div class="flex items-center">
                        <img src="../images/alipay.png" alt="" class="w-8 h-8 mr-2" />
                        {{$t('MobileConfirmOrder.aliPay')}}
                    </div>
                    <van-radio class="flex justify-center items-center" v-model="payWay" name="aliPay" disabled></van-radio>
                </div>
                <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                    <div class="flex items-center"><img src="../images/wechat.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.wechat')}}</div>
                    <van-radio class="flex justify-center items-center" v-model="payWay" name="wechat" disabled></van-radio>
                </div>
                <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                    <div class="flex items-center"><img src="../images/paypal.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.paypal')}}</div>
                    <van-radio class="flex justify-center items-center" v-model="payWay" name="paypal"></van-radio>
                </div>
                <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-20">
                    <div class="flex items-center"><img src="../images/balance.png" alt="" class="w-8 h-8 mr-2" />
                        {{$t('MobileConfirmOrder.balance')}}
                    </div>
                    <van-radio class="flex justify-center items-center" v-model="payWay" name="balance"></van-radio>
                </div>
            </div>
        </div>
        <van-submit-bar :loading="submitLoading" :price="totalPrice" :button-text="$t('MobileConfirmOrder.submitOrder')" @submit="onSubmit" />
        <van-dialog  :show-confirm-button="false" v-model="chooseAddressDialog" class="flex flex-col justify-end" style="height: calc(75vh);">
            <template #title>
                <div class="absolute top-3 right-0 left-0 justify-center items-center px-2">
                    <span>{{$t('MobileConfirmOrder.chooseAddress')}}</span>
                    <span class="cursor-pointer absolute top-0 right-3 text-xl" @click="chooseAddressDialog = false">×</span>
                </div>
            </template>
            <MobileAddressManagement class="absolute top-10 right-0 left-0" ref="addressManagement" :isChoosing="true"/>
            <div class="mb-16 py-2 mx-4 bg-blue-500 rounded-full text-white flex justify-center"  @click="onSubmitAddress">{{$t('MobileConfirmOrder.confirm')}}</div>
        </van-dialog>
        <van-popup id="popup" v-model="showPopup" closeable close-icon-position="top-left" position="bottom" round class="pb-14" @close="onClosePop">
            <div class="flex justify-center items-center text-center text-3xl mt-12" >
                <span>￥{{(totalPrice/100).toFixed(2)}}</span>
            </div>
            <div v-if="!showPasswordInput" class="flex justify-center items-center text-center text-sm text-gray-400 mt-1" >
                <span>{{$t('MobileConfirmOrder.balance')}} ￥{{(balance).toFixed(2) }}</span>
                <span class="ml-3 text-red-300">{{balance < totalPrice/100?$t('MobileConfirmOrder.insufficientBalance'):''}}</span>
                <router-link to="/usermobile/recharge" class="text-blue-500"> &nbsp;&nbsp;{{$t('MobileConfirmOrder.topup')}}</router-link>

            </div>
            <div class="mx-3" v-if="!showPasswordInput">
                <div class="mt-2">
                    <span class="text-blue-500 ">{{$t('MobileConfirmOrder.mixPay')}}</span>
                    <span >￥{{((totalPrice)/100-balance).toFixed(2)}}：</span>
                </div>
                <div class="grid grid-cols-1 gap-2 text-lg">
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mt-2 mb-2">
                        <div class="flex items-center">
                            <img src="../images/alipay.png" alt="" class="w-8 h-8 mr-2" />
                            {{$t('MobileConfirmOrder.aliPay')}}
                        </div>
                        <!-- TODO 注释了支付宝和微信 -->
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixAliPay" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                        <div class="flex items-center"><img src="../images/wechat.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.wechat')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixWechat" disabled></van-radio>
                    </div>
                    <div class="flex justify-between items-center text-center text-lg rounded-lg w-30 h-10 mb-2">
                        <div class="flex items-center"><img src="../images/paypal.png" alt="" class="w-8 h-8 mr-2" />{{$t('MobileConfirmOrder.paypal')}}</div>
                        <van-radio class="flex justify-center items-center" v-model="payWay" name="mixPaypal"></van-radio>
                    </div>
                </div>
            </div>
            <!-- 密码输入框 -->
            <van-password-input
                v-if="showPasswordInput"
                :value="payPassword"
                :focused="showKeyboard"
                :info="$t('MobileConfirmOrder.enterNewPassword')"
                :error-info="errorInfo"
                @focus="onFocusPassword"
                class="mb-60"
            />
            <div v-if="!showPasswordInput" @click="onPay" class="rounded-md hover:bg-blue-600 bg-blue-500 px-8 pt-2 pb-2 mx-32 mt-8 flex justify-center">
                <span class="text-lg self-center text-white">{{$t('MobileConfirmOrder.pay')}}</span>
            </div>

            <!-- 数字键盘 -->
            <van-number-keyboard
                v-model="payPassword"
                :show="showKeyboard"
                @blur="onBlurPassword"
            />
        </van-popup>
    </div>
</template>

<script>
import MyItems from "@/mobile/ShopCartInfor";
import MobileAddressManagement from "@/mobile/MobileAddressManagement";
import {Toast} from "vant";
export default {
    components: {
        // 土地市场组件
        MyItems, // 挂牌土地信息组件
        MobileAddressManagement, // 地址管理组件
    },
    name: "MobileConfirmOrder",
    data() {
        return {
            chooseAddressDialog: false,
            chosenAddressId: null,
            chosenAddress: {},
            totalPrice: 0,
            LandInfor: [],
            payWay: "paypal",
            needpaper: false,
            paymentForm: "",
            submitLoading: false,
            orderid: "",
            balance: 0,
            showPopup: false,
            popHeight: "60%",
            payPassword: "",
            showKeyboard: false,
            showPasswordInput: false,
            errorInfo: "",
        };
    },
    created() {
        if (this.$route.query.LandInfor) {
            this.LandInfor = JSON.parse(this.$route.query.LandInfor);
        }
        if (this.$route.query.totalPrice) {
            this.totalPrice = JSON.parse(this.$route.query.totalPrice) * 100;
        }
        //查询默认地址
        this.$http.get("/v1/address/select-address?isdefault=true",)
            .then((res) => {
                //todo 没有默认地址
                if(res.data.data === '没有收货地址'){
                    return;
                }
                this.chosenAddressId = res.data.data.id;
                this.$set(this.chosenAddress, "address", res.data.data.province + res.data.data.city + res.data.data.area + res.data.data.detailedaddress);
                this.$set(this.chosenAddress, "name", res.data.data.consigneename);
                this.$set(this.chosenAddress, "tel", res.data.data.contactinformation);
            })
            .catch((err) => {
                console.log(err);
            });

        //查询余额
        this.$http.get("/v1/recharge/check-balance")
            .then((res) => {
                this.balance = res.data.data.balance;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    methods: {
        onBack() {
            this.$router.go(-1);
        },
        chooseAddress() {
            this.chooseAddressDialog = true;
        },
        onSubmitAddress() {
            let addressList = this.$refs.addressManagement.addressList;
            this.chosenAddressId = this.$refs.addressManagement.chosenAddressId;
            for (let i = 0; i < addressList.length; i++) {
                if (addressList[i].id === this.chosenAddressId) {
                    this.chosenAddress = addressList[i];
                    break;
                }
            }
            if (this.chosenAddressId === -1) {
                this.$toast(this.$t('MobileConfirmOrder.alertNoAddress'));
                return;
            }
            this.chooseAddressDialog = false;
        },
        onClosePop(){
            this.payWay='balance';
            // 等待500ms
            setTimeout(() => {
                this.showPasswordInput = false;
            }, 500);
        },
        onFocusPassword() {
            this.showKeyboard = true;
            // document.querySelector('#popup').style.height = "80%";
        },
        onBlurPassword() {
            this.showKeyboard = false;
            //删除height的设置
            document.querySelector('#popup').style.height = "";
        },

        /** 提交订单&&正常支付*/
        onSubmit() {
            const unitlandids = this.LandInfor.map((item) => item.unitlandid);
            const data = {
                needpaper: this.needpaper,
                unitlandid: unitlandids,
                addressid: this.chosenAddressId,
            };
            if(this.chosenAddressId===null){
                this.$toast(this.$t('MobileConfirmOrder.alertNoAddress'));
                return ;
            }
            this.submitLoading = true;
            if (this.payWay === "aliPay") {
                this.$toast(this.$t('MobileConfirmOrder.alertalipay'));
                this.$http.post("/v1/alipay/pay", data).then((res) => {
                    this.paymentForm = res.data.html;
                    //todo 保存订单号后提示用户支付成功并做后续操作
                    this.orderid = res.data.orderid;
                    console.log(this.orderid);
                    // 等待1秒
                    setTimeout(() => {
                        this.submitLoading = false;
                        document.querySelector('form[name="punchout_form"]').submit();
                    }, 500);
                    // document.querySelector('form[name="punchout_form"]').submit();
                });
            } else if (this.payWay === "wechat") {
                this.$toast(this.$t('MobileConfirmOrder.alertWechat'));
            }
            else if (this.payWay==='paypal'){
                this.$toast(this.$t('MobileConfirmOrder.alertPaypal'));
                this.$http.post("/v1/paypal//pay", data).then((res) => {
                    //订单获批准时的逻辑
                    window.location.href = res.data.html; // 跳转到PayPal页面
                    this.$http.get("/v1/paypal/callback/success?orderid=" + res.data.orderid).then((res) => {
                        //订单获批准时的逻辑
                        this.$router.go(-1)
                    });
                    // 保存订单号后提示用户支付成功并做后续操作
                    this.orderid = res.data.orderid;
                });
            }
            else if (this.payWay==='balance'){
                this.submitLoading = false;
                this.showPopup = true;
                if(this.balance>=this.totalPrice/100){
                    this.showPasswordInput = true;
                }
            }
        },
        /** 点击余额支付*/
        onPay(){
            const unitlandids = this.LandInfor.map((item) => item.unitlandid);
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileConfirmOrder.alertLoading'),
                forbidClick: true,
            });
            const data = {
                needpaper: this.needpaper,
                unitlandid: unitlandids,
                addressid: this.chosenAddressId,
            };
            /** 检查是否选择地址*/
            if(this.chosenAddressId===null){
                this.$toast(this.$t('MobileConfirmOrder.alertNoAddress'));
                return ;
            }
            /** 检查余额是否足够*/
            if (this.payWay==='balance'){
                if(this.balance<this.totalPrice/100){
                    this.$toast(this.$t('MobileConfirmOrder.alertNoMoney'));
                    return ;
                }
                /** 余额足够*/
                else {
                    this.showPasswordInput = true;
                    return;
                }
            }
            else if(this.payWay==="mixAliPay"){
                this.$http.post("/v1/balance-payment/alipay-combination-payment", data)
                    .then((res) => {
                        toastLoading.clear();
                        if(res.data==='密码错误'){
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return ;
                        }
                        this.paymentForm = res.data.html;
                        //todo 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.orderid;
                        console.log(this.orderid);
                        // 等待1秒
                        setTimeout(() => {
                            this.submitLoading = false;
                            document.querySelector('form[name="punchout_form"]').submit();
                        }, 500);
                        // document.querySelector('form[name="punchout_form"]').submit();
                    });
            }
            else if(this.payWay==="mixWechat"){
                toastLoading.clear();
                console.log("微信支付");
            }
            else if(this.payWay==="mixPaypal"){
                this.$http.post("/v1/balance-payment/paypal-combination-payment", data)
                    .then((res) => {
                        toastLoading.clear();
                        if(res.data==='密码错误'){
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return ;
                        }
                        //访问paylink
                        window.location.href = res.data.html;
                        //todo 保存订单号后提示用户支付成功并做后续操作
                        this.orderid = res.data.orderid;
                    });
            }
            this.goPay();
        },
        /** 去支付*/
        goPay(){
            const unitlandids = this.LandInfor.map((item) => item.unitlandid);
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileConfirmOrder.alertLoading'),
                forbidClick: true,
            });
            const data = {
                needpaper: this.needpaper,
                unitlandid: unitlandids,
                addressid: this.chosenAddressId,
                paymentpassword:this.payPassword
            };
            if(this.payWay==='balance'){
                this.$http.post("/v1/balance-payment/pay", data)
                    .then((res) => {
                    toastLoading.clear();
                        if(res.data.data==='密码错误'){
                            this.errorInfo=this.$t('MobileConfirmOrder.alertPasswordError');
                            this.showKeyboard=false;
                            this.payPassword='';
                            return ;
                        }
                    //todo 保存订单号后提示用户支付成功并做后续操作
                    // this.orderid = res.data.data.orderid;
                    this.showPasswordInput = false;
                    this.showPopup = false;
                    this.$toast(this.$t('MobileConfirmOrder.alertPaySuccessfully'));
                    this.$router.push({
                        path: "/mobile/shopping-cart",
                    });
                });
            }
        },
    },
    watch:{
    //    密码输入完成
        payPassword(payPassword){
            if(payPassword.length===6){
                this.goPay();
            }
        }
    }
};
//
</script>

<style scoped></style>
