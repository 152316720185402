<template>
    <div class="mobile-associate-background">
        <div class="mobile-associate-home">
            <van-nav-bar class="bg-gray-50" :title="$t('MobileAssociated.title')" :left-text="$t('MobileAssociated.back')" left-arrow @click-left="goBack" style="width: 100%" />
            <div class="mobile-associate-box" @click="goEmailSet">
                <div class="mobile-associate-box-text">
                    <span :style="$i18n.locale === 'en'?'font-size: 16px':'font-size: 18px'">{{$t('MobileAssociated.email')}}</span>
                    <span style="font-size: 14px; color: #bebebe">{{ users.email }}</span>
                </div>
                <span class="mobile-associate-box-tip">{{ users.emailTip }}</span>
                <img src="../images/right.png" class="flex-none w-5 h-5 mobile-associate-box-img" />
            </div>
            <div class="mobile-associate-box" @click="goPhoneSet">
                <div class="mobile-associate-box-text">
                    <span :style="$i18n.locale === 'en'?'font-size: 16px':'font-size: 18px'">{{$t('MobileAssociated.phone')}}</span>
                    <span style="font-size: 14px; color: #bebebe">{{ users.phone }}</span>
                </div>
                <span class="mobile-associate-box-tip">{{ users.phoneTip }}</span>
                <img src="../images/right.png" class="flex-none w-5 h-5 mobile-associate-box-img" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            /* 几条文本 */
            users: {
                email: "",
                emailTip: "",
                phone: "",
                phoneTip: "",
            },
            isInfor: false, // 是否已经获取到数据，未获取到用户信息无法点击
        };
    },
    created() {
        // // 获取用户信息
        this.$http
            .get("/v1/user/personal-centerlist")
            .then((response) => {
                const data = response.data;
                if (data.status === 200) { // 获取邮箱
                    let userData = data.data;
                    if (userData.useremail) {
                        this.users.email = userData.useremail;
                        this.users.emailTip = this.$t('MobileAssociated.alertChange');
                    } else {
                        this.users.email = this.$t('MobileAssociated.alertNoEmail');
                        this.users.emailTip = this.$t('MobileAssociated.alertAdd');
                    }
                    if (userData.userphone) {
                        this.users.phone = userData.userphone;
                        this.users.phoneTip = this.$t('MobileAssociated.alertChange');
                    } else {
                        this.users.email = this.$t('MobileAssociated.alertNoMobile');
                        this.users.emailTip = this.$t('MobileAssociated.alertAdd');
                    }
                    this.isInfor = true;
                } else {
                    // 处理错误情况
                    console.error(data.msg);
                }
            })
            .catch((error) => {
                // 处理请求失败的情况
                console.error(error);
            });
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        goEmailSet() {
            if (!this.isInfor) {
                return;
            }
            if (this.users.emailTip === "点击绑定") {
                this.$router.push("/mobile/associate/set?type=email");
            } else if (this.users.emailTip === "点击换绑") {
                this.$router.push({
                    name: "MobileAssociatedReplace",
                    params: {
                        type: "email",
                        useremail: this.users.email,
                        userphone: null,
                    },
                });
            }
        },
        goPhoneSet() {
            if (!this.isInfor) {
                return;
            }
            if (this.users.emailTip === "点击绑定") {
                this.$router.push("/mobile/associate/set?type=phone");
            } else if (this.users.emailTip === "点击换绑") {
                this.$router.push({
                    name: "MobileAssociatedReplace",
                    params: {
                        type: "phone",
                        userphone: this.users.phone,
                        useremail: null,
                    },
                });
            }
        },
    },
};
</script>

<style scoped>
.mobile-associate-background {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}
.mobile-associate-home {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.mobile-associate-box {
    width: 80%;
    display: flex;
    flex-direction: row;
    border: none;
    border-bottom: 1px solid #eeeeee;
    margin-top: 20px;
    position: relative;
}
.mobile-associate-box-text {
    display: flex;
    flex-direction: column;
}
.mobile-associate-box-img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
}
.mobile-associate-box-tip {
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 12px;
    color: #bebebe;
}
</style>
