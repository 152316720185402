<template>
    <div class="mobile-shop-home" ref="landMarket">
        <van-pull-refresh v-model="refreshLoading" :success-text="$t('MobileShop.success')" @refresh="onRefresh">
            <template #pulling="props">
                <div class="text-white">{{$t('MobileShop.refresh')}}↓</div>
            </template>
            <template #loosing>
                <div class="text-white">{{$t('MobileShop.release')}}</div>
            </template>
            <template #loading>
                <div class="text-white">{{$t('MobileShop.refreshing')}}</div>
            </template>
            <div  v-for="(land, index) in LandInfor" :key="index" @click="turnToDetail(index)">
                <ShopCartInfor
                    :LandInfor="land"
                    :showSelect="false"
                    :show-delete="false"
                    :index="index"
                    ref="MobileLand"
                />
            </div>
            <van-skeleton v-if="LandInfor.length === 0" v-for="i in 5" class="mt-3" title avatar :row="3" :loading="loading" />
            <div class="mt-5 mb-10"></div>
        </van-pull-refresh>
    </div>
</template>

<script>
import ShopCartInfor from "@/mobile/ShopCartInfor";
export default {
    components: {
        ShopCartInfor, // 土地市场组件
    },
    created() {
        this.selectPage = this.$route.query.index; // 根据地址获取当前页的页号，跳转时需要携带此信息
    },
    mounted() {
        // 初始化土地市场列表
        this.$http
            .get("/v1/sale/get-saleselect-list?pageNum=" + "1" + "&pageSize=" + "10")
            .then((response) => {
                if (this.firstload) {
                    let newData = response.data.data.rows;
                    this.LandInfor = newData;
                    this.pageNum = 2;
                    this.firstload = false;
                    if (newData.length === 0) {
                        this.hasMoreData = false;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        // TODO 添加一个监听滚动条位置的事件处理函数
        // this.$refs.landMarket.addEventListener('scroll', this.handleScroll)
    },
    data() {
        return {
            loading: true,
            LandInfor: [            ],
            selectPage: "2", // 页键
            firstload: true, // 用来防止初始化两次
            hasMoreData: true, // 是否还有更多数据可加载
            pageNum: 1, // 页码
            flag: true, // 防抖
            refreshLoading: false, // 下拉刷新
        };
    },
    methods: {
        handleScroll() {
            let scrollHeight = this.$refs.landMarket.scrollHeight;
            let scrollTop = this.$refs.landMarket.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight;

            if (scrollHeight - scrollTop - clientHeight <= 0 && this.hasMoreData && this.flag) {
                this.flag = false;
                let timer = setTimeout(() => {
                    // 等待 300 毫秒后才能再触发
                    this.flag = true;
                    clearInterval(timer);
                }, 300);
                // 当滚动到底部并且还有更多数据可加载时
                this.loadMoreData();
            }
        },
        onRefresh() {
            // 下拉刷新
            this.refreshLoading = true;
            this.$http
                .get("/v1/sale/get-saleselect-list?pageNum=" + "1" + "&pageSize=" + "10")
                .then((response) => {
                    let newData = response.data.data.rows;
                    this.LandInfor = newData;
                    this.pageNum = 2;
                    this.refreshLoading = false;
                    if (newData.length === 0) {
                        this.hasMoreData = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        loadMoreData() {
            // 请求后端获取更多数据的逻辑
            // 在获取数据后将新数据添加到 LandInfor 数组中
            // 如果没有更多数据可加载，将 hasMoreData 设置为 false
            // 例如：使用 axios 发送异步请求
            this.$http
                .get("/v1/sale/get-saleselect-list?pageNum=" + this.pageNum + "&pageSize=" + "10")
                .then((response) => {
                    let newData = response.data.data.rows;
                    this.LandInfor = this.LandInfor.concat(newData);
                    this.loading = false;
                    this.pageNum += 1;
                    if (newData.length === 0) {
                        this.hasMoreData = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        turnToDetail(index) {
            const newpath = "/mobile/land/detail?index=2&land=" + this.LandInfor[index].originallandid + "&merch=" + this.LandInfor[index].unitlandid;
            this.$router.push({
                path: newpath,
            });
        },
    },
    beforeDestroy() {
        // 在组件销毁时移除监听事件
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style scoped>
.mobile-shop-home {
    height: 100%;
    width: 100%;
    overflow: auto;
}
.mobile-shop-land {
    display: inline-block;
    width: 100%;
    box-shadow: 0 2px 12px 0 #dcdcdc;
    border-radius: 6px;
    margin-top: 5px;
}
</style>
