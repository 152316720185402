<template>
    <div class="flex items-center justify-center h-screen flex-col relative main-container">
        <div class="home-page-header absolute top-0">
            <div class="home-page-header-logo">
                <img src="../icons/logo.png" style="object-fit: cover;height: 40px;" @click="backToHomePage" />
            </div>
            <!-- <div style="font-size: 20px; color: #fff; font-weight: bold">{{$t('mobileLogin.datuland')}}</div> -->
            <div style="font-size: 14px;flex: 1;text-align: right;margin-right: 20px;" @click="changeLanguage">
                <span :style="$i18n.locale === 'en'? 'color: blue' : ''">EN</span>
                <span>&nbsp;|&nbsp;</span>
                <span :style="$i18n.locale === 'zh'? 'color: blue' : ''">中文</span>
            </div>
        </div>
        <!-- 三合一密码登录 -->
        <div class="my-tab " v-if="mobileMode && !mobileRegister">
            <h1 class="text-xl mb-6 mt-5">{{$t('mobileLogin.passwordLogin')}}</h1>
            <div class="my-pointer" @click="changeToPhoneLogin">{{$t('mobileLogin.codeLogin')}} ></div>
            <div class="mb-6 flex items-center width-90">
                <span class="mr-1">{{$t('mobileLogin.account')}}：</span>
                <input type="email" v-model="mobile.account" class="my-input" :placeholder="$t('mobileLogin.passwordLoginAccountInput')" />
            </div>
            <div class="relative mb-6 flex items-center width-90">
                <span class="mr-1">{{$t('mobileLogin.password')}}：</span>
                <input :type="showPassword ? '' : 'password'" v-model="mobile.password" class="my-input" :placeholder="$t('mobileLogin.passwordLoginPasswordInput')" @input="mobileCheckPassword" />
                <button class="absolute right-0 top-0 bottom-0 ml-2 focus:outline-none" @click="togglePassword">
                    <img src="../images/not-visible.png" class="h-5" v-if="!showPassword" alt="Show Password" />
                    <img src="../images/visible.png" class="h-5" v-if="showPassword" alt="Hide Password" />
                </button>
            </div>

            <div class="mb-4">
                <button class="my-submit-button" @click="mobileLogin">{{$t('mobileLogin.login')}}</button>
            </div>
            <span class="mb-3 text-blue-500 hover:text-blue-700 cursor-pointer" @click="forgetPassword">{{$t('mobileLogin.forgotPassword')}}</span>

            <div>
                <span class="text-gray-600">{{$t('mobileLogin.goToRegistration1')}}</span>
                <span class="text-blue-500 cursor-pointer" @click="changeToRegister">{{$t('mobileLogin.goToRegistration2')}}</span>
            </div>
        </div>
        <!-- 手机验证码登录 -->
        <div class="my-tab " v-if="mobileMode && mobileRegister">
            <div class="text-xl mb-6 mt-5">{{$t('mobileLogin.codeLogin')}}</div>
            <div class="my-pointer" @click="changeToAccountLogin">{{$t('mobileLogin.passwordLogin')}} ></div>
            <div class="flex items-center mb-4 width-90">
                <span class="text-gray-700 mb-1">{{$t('mobileLogin.phone')}}：</span>
                <input type="tel" v-model="mobile.phone" class="my-input" :placeholder="$t('mobileLogin.codeLoginPhoneInput')" />
            </div>
            <div class="flex items-center justify-end mb-4 width-90">
                <!--            请输入图像验证码-->
                <span class="text-gray-700 mb-1">{{$t('mobileLogin.graphicCode')}}：</span>
                <input v-model="imgCode" :placeholder="$t('mobileLogin.codeLoginGraphicInput')" style="width: calc(100% - 145px)" class="my-input " />
                <img @click="loadCodeImg" class="h-8 w-20 " :src="imgCodeUrl" alt="验证码"/>
            </div>
            <div v-if="imgCode.length>=4" class="flex  items-center  animate__animated animate__flipInX width-90">
                <span class="text-gray-700 mb-1">{{$t('mobileLogin.code')}}：</span>
                <input v-model="mobile.code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" style="width: calc(100% - 145px)" class="my-input" />
                <template v-if="isCode">
                    <span @click="getMobilePhoneCode" class="text-sm px-2 py-2 text-white bg-yellow-500 rounded cursor-pointer hover:bg-yellow-600">{{$t('mobileLogin.getCode')}}</span>
                </template>
                <template v-else>
                    <span disabled class="text-sm px-2 py-2 text-gray-500 bg-gray-200 rounded opacity-50">{{$t('mobileLogin.resendCode')}}{{ mobile.timeCount }}s</span>
                </template>
            </div>
            <button class="my-submit-button" @click="mobilePhoneLogin">{{$t('mobileLogin.login')}}</button>
            <div>
                <span class="text-gray-600">{{$t('mobileLogin.goToRegistration1')}}</span>
                <span class="text-blue-500 cursor-pointer" @click="changeToRegister">{{$t('mobileLogin.goToRegistration2')}}</span>
            </div>
        </div>
        <!-- 邮箱注册 -->
        <div class="my-tab " v-if="!mobileMode && !mobileRegister">
            <div class="mb-5 mt-5 text-xl">{{$t('mobileLogin.emailRegistrationLabel')}}</div>
            <div class="my-pointer" @click="changeToPhoneRegister">{{$t('mobileLogin.phoneRegistrationLabel')}} ></div>
            <div class="flex items-center mb-4 width-90">
                <span class="text-gray-700 mb-1">{{$t('mobileLogin.email')}}：</span>
                <input type="email" v-model="mobile.email" class="my-input" :placeholder="$t('mobileLogin.emailRegistrationEmailInput')" />
            </div>
            <div class="relative mb-2 flex items-center width-90">
                <span class="">{{$t('mobileLogin.password')}}：</span>
                <input :type="showPassword ? '' : 'password'" v-model="mobile.password" class="my-input" :placeholder="$t('mobileLogin.passwordInput')" @input="mobileCheckPassword" />
                <button class="absolute right-0 top-0 bottom-0 ml-2 focus:outline-none" @click="togglePassword">
                    <img src="../images/not-visible.png" class="h-5" v-if="!showPassword" alt="Show Password" />
                    <img src="../images/visible.png" class="h-5" v-if="showPassword" alt="Hide Password" />
                </button>
            </div>
            <div v-show="showRules" class="w-64 mb-2 text-red-500 text-sm">{{$t('mobileLogin.rules1')}}{{$t('mobileLogin.rules2')}}{{$t('mobileLogin.rules3')}}</div>
            <div class="flex items-center justify-end mb-4 width-90">
                <!--            请输入图像验证码-->
                <span class="text-gray-700 mb-1">{{$t('mobileLogin.graphicCode')}}：</span>
                <input v-model="imgCode" :placeholder="$t('mobileLogin.codeLoginGraphicInput')" style="width: calc(100% - 145px)" class="my-input " />
                <img @click="loadCodeImg" class="h-8 w-20 " :src="imgCodeUrl" alt="验证码"/>
            </div>
            <button class="my-submit-button" @click="mobileEmailRegistration">{{$t('mobileLogin.emailRegister')}}</button>
            <div class="bottom-1 text-sm mt-5 mb-2">
                <van-checkbox icon-size="14px" v-model="accept">
                    {{$t('mobileLogin.licenseNumber')}}
                    <a href="#" class="text-primary text-blue-400 underline">{{$t('mobileLogin.businessLicense')}}</a>
                    {{$t('mobileLogin.and')}}
                    <a href="#" class="text-primary text-blue-400 underline">{{$t('mobileLogin.copyright')}}</a>
                </van-checkbox>
            </div>
            <span class="text-gray-700 mt-5 cursor-pointer" @click="changeToLoad">{{$t('mobileLogin.changeToLogin1')}}<span class="text-blue-500">{{$t('mobileLogin.changeToLogin2')}}</span></span>
            <div class="fixed inset-0 flex items-center justify-center" v-if="vmodal">
                <div class="absolute bg-white rounded-lg shadow-lg p-4">
                    <span>{{ errorText }}</span>
                </div>
            </div>
        </div>
        <!-- 手机号注册 -->
        <div class="my-tab " v-if="!mobileMode && mobileRegister">
            <div class="text-xl mb-6 mt-5">{{$t('mobileLogin.phoneRegistrationLabel')}}</div>
            <div class="my-pointer" @click="changeToEmailRegister">{{$t('mobileLogin.emailRegistrationLabel')}}></div>
            <div class="flex items-center mb-4 width-90">
                <span class="text-gray-700 mb-1 justify-end">{{$t('mobileLogin.phone')}}：</span>
                <input type="tel" v-model="mobile.phone" class="my-input" :placeholder="$t('mobileLogin.codeLoginPhoneInput')" />
            </div>
            <div class="flex items-center justify-end mb-4 width-90">
                <!--            请输入图像验证码-->
                <span class="text-gray-700 mb-1">{{$t('mobileLogin.graphicCode')}}：</span>
                <input v-model="imgCode" :placeholder="$t('mobileLogin.codeLoginGraphicInput')" style="width: calc(100% - 145px)" class="my-input " />
                <img @click="loadCodeImg" class="h-8 w-20 " :src="imgCodeUrl" alt="验证码"/>
            </div>
            <div v-if="imgCode.length>=4" class="flex items-center justify-end  animate__animated animate__flipInX width-90" >
                <span class="text-gray-700 mb-1">{{$t('mobileLogin.code')}}：</span>
                <input v-model="mobile.code" :placeholder="$t('mobileLogin.codeLoginCodeInput')" style="width: calc(100% - 145px)" class="my-input" />
                <template v-if="isCode">
                    <span @click="getMobilePhoneCode" class="text-sm px-2 py-2 text-white bg-yellow-500 rounded cursor-pointer hover:bg-yellow-600">{{$t('mobileLogin.getCode')}}</span>
                </template>
                <template v-else>
                    <span disabled class="text-sm px-2 py-2 text-gray-500 bg-gray-200 rounded opacity-50">{{$t('mobileLogin.resendCode')}}{{ mobile.timeCount }}s</span>
                </template>
            </div>
            <button class="my-submit-button" @click="mobilePhoneRegistration">{{$t('mobileLogin.register')}}</button>
            <div class="bottom-1 text-sm mt-5 mb-2">
                <van-checkbox icon-size="14px" v-model="accept">
                    {{$t('mobileLogin.licenseNumber')}}
                    <a href="#" class="text-primary text-blue-400 underline">{{$t('mobileLogin.businessLicense')}}</a>
                    {{$t('mobileLogin.and')}}
                    <a href="#" class="text-primary text-blue-400 underline">{{$t('mobileLogin.copyright')}}</a>
                </van-checkbox>
            </div>
            <span class="text-gray-700 mt-5 cursor-pointer" @click="changeToLoad">{{$t('mobileLogin.changeToLogin1')}}<span class="text-blue-500">{{$t('mobileLogin.changeToLogin2')}}</span></span>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        if ((this.lastLoginMethod = localStorage.getItem("loginMethod"))) {
        } else {
            this.lastLoginMethod = "email"; // 获取上次用户登陆的方式，没有的时候是null
        }
    },
    data() {
        return {
            showPassword: false, // 改变密码显示/隐藏状态的参数

            showRules: false, // 密码格式校验

            mobileMode: true, // 手机端登录/注册模式，true为登录，false为注册

            mobileRegister: false, // 手机号注册模式,true为手机号,false为邮箱

            isCode: true, // 是否可以获取验证码,初始为true,表示可以获取验证码,false时变为重新发送倒计时
            imgCode: "", // 手机端图像验证码
            imgCodeUrl: "", // 手机端图像验证码的url
            mobile: {
                account: "", // 三合一版账号
                email: "", // 手机端邮箱登录/注册时输入的邮箱统一为这个
                phone: "", // 手机端手机号登录/注册时输入的手机号统一为这个
                username: "", // 用户名，同上
                password: "", // 手机端邮箱以及手机号登录/注册时输入的密码统一为这个
                code: "", // 手机端注册输入的验证码统一为这个
                timeCount: 0, // 手机端获取验证码后的倒计时
                timer: null, // 手机端计时器

            },

            accept: false, // 选择是否同意协议

            vmodal: false, // 手机端邮箱注册时获取验证码的弹窗，由于邮箱验证码获取太慢，给用户一个提示
            errorText: "", // 显示提示的文本

            lastLoginMethod: "", // 根据用户上一次登录方式自动选择登录方式
        };
    },
    methods: {
        /* --------------------------------------------通用------------------------------------------------ */

        /* 点击眼睛图标显示/隐藏密码 */
        togglePassword() {
            this.showPassword = !this.showPassword;
        },

        /* 忘记密码 */
        forgetPassword() {
            this.$router.push("/mobile/login/forget-password");
        },

        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },

        /*加载图像验证码*/
        loadCodeImg(){
            ///register/captcha
            this.$http
                .get("/v1/register/captcha",
                    {
                        responseType: "blob", // 设置响应类型为blob
                    })
                .then((response) => {
                    // 处理返回值
                    const imageBlob = response.data;
                    this.imgCodeUrl = URL.createObjectURL(imageBlob);
                })
        },

        changeLanguage() {
            if (this.$i18n.locale === 'en') {
                this.$i18n.locale = 'zh'
                localStorage.setItem('language', 'zh'); // 存储语言选择到localStorage
            } else {
                this.$i18n.locale = 'en'
                localStorage.setItem('language', 'en'); // 存储语言选择到localStorage
            }
            document.title = this.$t('pageTitle')
        },

        backToHomePage() {
            this.$router.push('/')
        },

        /* --------------------------------------------手机端------------------------------------------------ */

        /* 手机端改为注册模式 */
        changeToRegister() {
            this.loadCodeImg();
            this.mobileMode = false;
        },

        /* 手机端改为登录模式 */
        changeToLoad() {
            this.mobileMode = true;
        },

        /* 手机端改为邮箱注册模式 */
        changeToEmailRegister() {
            this.mobileMode = false;
            this.mobileRegister = false;
        },

        /* 手机端改为手机号注册模式 */
        changeToPhoneRegister() {
            this.mobileMode = false;
            this.mobileRegister = true;
        },

        /* 手机端改为手机验证码登陆模式 */
        changeToPhoneLogin() {
            this.loadCodeImg();
            this.mobileMode = true;
            this.mobileRegister = true;
        },

        /* 手机端改为三合一账号登陆模式 */
        changeToAccountLogin() {
            this.mobileMode = true;
            this.mobileRegister = false;
        },

        /* 手机端登录/注册点击密码输入框后检查密码格式是否错误 */
        mobileCheckPassword() {
            const re = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/;
            this.showRules = !re.test(this.mobile.password);
        },

        /* 登录方式三合一 */
        mobileLogin() {
            if (this.mobile.account.length === 0) {
                this.$toast(this.$t('mobileLogin.alertNoAccountInput'));
            }
            const data = {
                loginname: this.mobile.account,
                loginpassword: this.mobile.password,
            };
            this.$http
                .post("/v1/index/login", data, {
                    headers: {
                        'Access-Control-Allow-Origin': 'http://test.datuland.com/',
                        'Access-Control-Allow-Credentials': 'true'
                    }
                })
                .then((response) => {
                    console.log(response);
                    // 处理返回值
                    if (response.data.status === 200) {
                        const token = response.headers.authorization;
                        console.log(token);
                        // this.$store.commit("user/setToken", token);
                        this.$store.dispatch("user/fetchUserInfo");
                        this.$router.push("/mobile?index=1");
                    } else {
                        this.$toast(response.data.data);
                    }
                })
                .catch((error) => {
                    // 处理错误
                    this.$toast(this.$t('mobileLogin.alertAxiosError'));
                });
        },

        /* 邮箱登陆 */
        /* mobileEmailLogin() {
            if (!this.mobile.email.includes('@') || this.mobile.password === '' || this.showRules) {
                this.$toast('请检查邮箱和密码')
                return
            }
            const data = {
                useremail: this.mobile.email,
                userpassword: this.mobile.password
            }
            this.$http
            .post('/index/login', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(response => {
                // 处理返回值
                if (response.data.msg==="登陆成功") {
                    // 保存用户选择的登录方式
                    localStorage.setItem('loginMethod', 'email');
                    this.$router.push('/usermobile?index=1')
                } else {
                    this.loading = false
                    this.$toast(response.data.data)
                }
            }).catch(error => {
                // 处理错误
                this.$toast(error.data.data)
                this.loading = false
            });
        }, */
        /* 手机号登陆 */
        mobilePhoneLogin() {
            if (!this.mobile.phone.length === 0 || this.mobile.password === "" || this.showRules) {
                this.$toast(this.$t('mobileLogin.alertInforError'));
                return;
            }
            const data = {
                userphone: this.mobile.phone,
                remark: this.mobile.code,
            };
            this.$http
                .post("/v1/index/loginyzm", data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    // 处理返回值
                    if (response.data.status === 200) {
                        const token = response.headers.authorization;
                        this.$store.commit("user/setToken", token);
                        this.$store.dispatch("user/fetchUserInfo");
                        this.$router.push("/mobile?index=1");
                    } else {
                        this.$toast(response.data.data);
                    }
                })
                .catch((error) => {
                    // 处理错误
                    this.$toast(this.$t('mobileLogin.alertAxiosError'));
                });
        },
        /* 用户名登陆 */
        /* mobileNameLogin() {
            if (!this.mobile.username === '' || this.mobile.password === '' || this.showRules) {
                this.$toast('请检查用户名和密码')
                return
            }
            const data = {
                username: this.mobile.username,
                userpassword: this.mobile.password
            }
            this.$http
            .post('/index/login', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(response => {
                // 处理返回值
                if (response.data.msg==="登陆成功") {
                    // 保存用户选择的登录方式
                    localStorage.setItem('loginMethod', 'username');
                    this.$router.push('/usermobile?index=1')
                } else {
                    this.loading = false
                    this.$toast(response.data.data)
                }
            }).catch(error => {
                // 处理错误
                this.$toast(error.data.data)
                this.loading = false
            });
        }, */

        /* 获取验证码后计时重新发送 */
        startCountdown() {
            // 设置倒计时60s
            this.mobile.timeCount = 60;
            this.isCode = false;
            // 计时器
            this.mobile.timer = setInterval(() => {
                if (this.mobile.timeCount > 0) {
                    this.mobile.timeCount--;
                } else {
                    this.isCode = true;
                    clearInterval(this.mobile.timer);
                    this.mobile.timer = null;
                }
            }, 1000);
        },

        closeModal() {
            this.vmodal = false;
        },

        /* 手机端的手机号注册页面点击获取验证码 */
        getMobilePhoneCode() {
            // 手机端点击获取验证码
            if (this.mobile.phone.length === 0) {
                this.$toast(this.$t('mobileLogin.codeLoginPhoneInput'));
                return;
            }
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('mobileLogin.alertLoading'),
            });
            this.$http
                .post("/v1/register/phone-verificationcode?userphone=" + this.mobile.phone+"&code="+this.imgCode)
                .then((response) => {
                    loadingToast.clear();
                    if(response.data.status===200){
                        this.startCountdown();
                    }
                    else {
                        this.$toast(response.data.data);
                    }
                    this.loadCodeImg();
                })
                .catch((error) => {
                    // 处理错误
                    this.$toast(this.$t('mobileLogin.alertAxiosError'));
                });
        },

        /* 手机号注册按钮事件 */
        mobilePhoneRegistration() {
            if (this.mobile.phone.length === 0) {
                this.$toast(this.$t('mobileLogin.codeLoginPhoneInput'));
                return;
            }
            if (this.mobile.code === "") {
                this.$toast(this.$t('mobileLogin.alertNoCodeInput'));
                return;
            }
            if (!this.accept) {
                this.$toast(this.$t('mobileLogin.alertNoAgreement'));
                return;
            }
            const data = {
                userphone: this.mobile.phone,
                remark: this.mobile.code,
            };
            this.$http
                .post("/v1/register/userphone-register", data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    // 处理返回值
                    if (res.data.status === 200) {
                        this.$router.push("/mobile?index=1");
                    } else {
                        this.$toast(res.data.data);
                    }
                    this.loadCodeImg();
                })
                .catch((error) => {
                    // 处理错误
                    this.$toast(this.$t('mobileLogin.alertAxiosError'));
                });
        },

        /* 手机端的邮箱注册 */
        mobileEmailRegistration() {
            if (!this.mobile.email.includes("@")) {
                this.$toast(this.$t('mobileLogin.alertEmailError'));
                return;
            }
            if (this.mobile.password === "" || this.showRules) {
                this.$toast(this.$t('mobileLogin.alertPasswordError'));
                return;
            }
            if(this.imgCode===""){
                this.$toast(this.$t('mobileLogin.alertNoCodeInput'));
                return;
            }
            if (!this.accept) {
                this.$toast(this.$t('mobileLogin.alertNoAgreement'));
                return;
            }

            const data = {
                useremail: this.mobile.email,
                userpassword: this.mobile.password,
                code:this.imgCode
            };
            const loadingToast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: this.$t('mobileLogin.alertLoading'),
            });
            this.$http
                .post("/v1/register/user-mail-register", data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    loadingToast.clear();
                    if(res.data.status===200){
                        this.$toast(this.$t('mobileLogin.alertSendSuccessfully'));
                    }
                    else {
                        this.$toast(res.data().data);
                    }
                    this.loadCodeImg();
                })
                .catch((error) => {
                    this.vmodal = false;
                    this.$toast(this.$t('mobileLogin.alertAxiosError'));
                });
        },
    },
};
</script>

<style scoped>
/* ------------------------------------------------以下是手机端---------------------------------------------------- */
.main-container {
    background-image: linear-gradient(to bottom, #0083e0 5%, #ffffff);
    background-repeat: no-repeat;
}
.home-page-header {
    width: 100%;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    flex-direction: row;
    background-color: #ffffff;
}
.home-page-header-logo {
    /* logo区域外层 */
    padding: 20px;
}
.mobile-form-container {
    /* 手机端登陆页面 */
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgb(199, 194, 194);
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    flex-direction: column;
}
.mobile-form-topic {
    /* 手机端登录注册标题 */
    font-size: 20px;
    padding: 10px;
}
.mobile-form-input {
    /* 输入框样式 */
    height: 50px;
    width: 80%;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
}
.mobile-form-button {
    /* 按钮样式 */
    height: 50px;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #f6f35c;
    border-radius: 12px;
    box-shadow: 0 2px 12px 0 rgb(255, 255, 255);
    margin-top: 10px;
    margin-bottom: 20px;
}
.mobile-form-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
.mobile-form-icon-img {
    max-height: 50px;
    max-width: 50px;
    height: 100%; /* 根据输入框的高度来设置图片大小 */
    width: auto;
}
.mobile-form-fogetpassword {
    /* 忘记密码 */
    position: absolute;
    right: 0;
    font-size: 14px;
    color: #b9b9b9;
}
.mobile-form-changemobel {
    /* 改变注册登录模式 */
    position: absolute;
    border-bottom: 2px solid #b9b9b9;
    color: #b9b9b9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.mobile-form-changemobel-mobile {
    width: 80%;
    text-align: right;
    font-size: 14px;
    color: #b9b9b9;
}
.mobile-box-input-parent {
    /* 输入框样式 */
    width: 80%;
    display: flex;
    flex-direction: row;
    position: relative;
}
.mobile-box-input-labal {
    /* 输入框前文字信息样式 */
    width: 70px;
    display: flex;
    align-items: center; /* 水平居中 */
}
.mobile-box-input {
    /* 输入框样式 */
    height: 50px;
    width: 70%;
    font-size: 18px;
    color: #dcdcdc;
    border: none;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 10px;
}
.mobile-box-code-button {
    /* 获取验证码按钮 */
    height: 40px;
    width: 100px;
    border: none;
    border-left: 1px solid #dcdcdc;
    display: flex;
    align-items: center; /* 水平居中 */
    justify-content: center;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 5px;
}
/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    height: 150px;
    width: 300px;
    padding: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}

.my-input {
    width: calc(100% - 70px);
    @apply text-sm py-2 px-1 border-b border-gray-300 focus:outline-none;
}
.my-tab {
    width: 100%;
    @apply relative mx-3 p-6 rounded-lg shadow-md bg-white flex flex-col items-center justify-center;
}
.my-pointer {
    @apply absolute top-3 right-3 text-gray-500 text-sm cursor-pointer;
}
.my-submit-button {
    min-width: 100px;
    @apply mt-3  mb-2 py-2 px-1 text-sm bg-blue-500 hover:bg-blue-600 text-white rounded-lg focus:outline-none;
}
.width-90 {
    width: 100%;
}
</style>
