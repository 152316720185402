<template>
    <div class="mobile-email-home">
        <van-nav-bar class="bg-gray-50" :title="$t('MobileServiceQ.title')" :left-text="$t('MobileServiceQ.back')" left-arrow @click-left="goBack" />
        <div class="rounded-lg shadow-lg bg-white pt-3 pb-5 mx-3 mt-3 animate__animated animate__fadeInUp">
            <van-field v-model="inputText" rows="20" autosize :label="$t('MobileServiceQ.message')" type="textarea" maxlength="1000" :placeholder="$t('MobileServiceQ.placeholder')" show-word-limit />
        </div>
        <div class="flex-shrink-0 fixed bottom-2 left-0 right-0 flex justify-center">
            <button class="py-3 px-10 bg-blue-500 text-white rounded-full" @click="sendEmail">{{$t('MobileServiceQ.send')}}</button>
        </div>
        <!-- 发送完成后显示信息 -->
        <div class="mobile-email-aftersend" v-if="!beforeSend">
            <span>{{$t('MobileServiceQ.text1')}}</span>
            <span>{{$t('MobileServiceQ.text2')}}</span>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        // 不要忘了获取传输过来的土地信息
        if (this.$route.query.thread) {
            this.threadId = this.$route.query.thread;
        }
    },
    data() {
        return {
            threadId: null, // 主题id，有才用
            inputText: "", // 用户输入的内容
            beforeSend: true, // 发送完成后显示已发送，该参数为false时隐藏输入框和按钮
        };
    },
    methods: {
        handleKeyup() {
            const textarea = this.$refs.myInput;
            textarea.scrollTop = textarea.scrollHeight;
        },
        uploadImage() {},
        sendEmail() {
            // 发送信息
            const data = {
                unitlandid: this.LandInfor.unitlandid,
                emailscontent: this.inputText,
                threadid: this.threadId,
                landname: this.LandInfor.landname,
                recipientid: this.receiveId,
            };
            this.$http
                .post("/v1/mail/write-message", data, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    alert(res.data.data);
                    this.beforeSend = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
.mobile-email-home {
    height: 100%;
    width: 100%;
    background-color: #f9fafb;
    position: relative;
    overflow: hidden;
}
/* ---------------------------------顶部：返回图标与标题------------------------------- */
.mobile-email-top {
    height: 70px;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgb(220, 220, 220);
    position: sticky;
    top: 0;
}
.mobile-email-top-topic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
}
.mobile-email-top-goback {
    max-height: 50px;
    max-width: 50px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
}
/* ---------------------------------第二格：用户输入聊天信息框------------------------------- */
.mobile-email-input {
    display: flex;
    height: 100%;
    width: 100%;
    margin-top: 10px;
    background-color: #fff;
    flex-direction: column;
    text-align: center;
}
.mobile-email-input-text {
    height: 60%;
    width: calc(100% - 6px);
    font-size: 20px;
    resize: none;
    overflow-y: auto;
}
/* ---------------------------------底部：上传图片与发送按钮------------------------------- */
.mobile-email-bottom {
    height: 70px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
}
.mobile-email-bottom-upload {
    height: 50px;
    width: 100px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 18px;
    font-weight: bold;
    background-color: #31e4ff;
    color: #ff1323;
}
.mobile-email-bottom-send {
    height: 50px;
    width: 80%;
    box-shadow: 0 2px 12px 0 rgb(220, 220, 220);
    border-radius: 30px;
    font-size: 18px;
    background-color: #fda1ff;
    color: #fff;
    font-weight: bold;
}
/* ---------------------------------发送完成后清理页面------------------------------- */
.mobile-email-aftersend {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 20px;
    font-weight: bold;
    color: #dcdcdc;
    background-color: #fff;
    flex-direction: column;
}
</style>
