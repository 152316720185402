import { render, staticRenderFns } from "./MobileHomePage.vue?vue&type=template&id=552d6cea&scoped=true&"
import script from "./MobileHomePage.vue?vue&type=script&lang=js&"
export * from "./MobileHomePage.vue?vue&type=script&lang=js&"
import style0 from "./MobileHomePage.vue?vue&type=style&index=0&id=552d6cea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "552d6cea",
  null
  
)

export default component.exports