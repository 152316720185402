<template>
    <!-- 挂牌弹框-显示和隐藏状态根据 sellLandChild.vmodal 变量决定 -->
        <div class="land-sell-modal" v-if="sellLandChild.vmodal">
            <div class="land-sell-modal-box">
                <span style="margin-top: 20px;">形式</span>
                <el-select v-model="type" placeholder="请选择">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="land-sell-modal-box-price">
                    <span>土地块编号:{{ landid }}</span>
                    <span>单位土地售价:<input class="input-price-text" type="text" v-model="price">元</span>
                    <span style="font-size: 10px;color: red;">*默认出售土地的数字权和地表权</span>
                    <span style="margin-top: 10px;">块数:{{ landnum }}</span>
                    <span v-if="totalprice">总价:{{ totalprice }}</span>
                    <div style="margin-top: 20px;display: flex;flex-direction: row;">
                        <button class="modal-button" @click="sellLand" style="margin-right: 30px;" @mouseover="changeCursor">确定</button>
                        <button class="modal-button" @click="closeSellModal" @mouseover="changeCursor">取消</button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    props: { // 父子组件传值
        sellLandChild: {
            /*  originallandid: '', // 向子组件传入原始土地编号
                landnumber1: '', // 二号土地坐标
                landnumber2: '', // 三号土地坐标
                landid: '', // 土地块id号
                landnum: null, // 土地块数
                vmodal: false // 是否打开该窗口 */
            type: Object,
            required: true
        }
    },
    watch: {
        price(n) {
            this.totalprice = n * this.landnum
        },
    },
    data() {
        return {
            options: [{
                value: '立即购买',
                label: '立即购买'
            }/* , {
                value: '拍卖',
                label: '拍卖'
            } */],
            type: '立即购买',
            price: null,
            totalprice: null,
        }
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        sellLand() {
            const data = {
                originallandid: this.originallandid,
                unitlandid: this.landid,
                salesmethods: this.type,
                pricenum: this.price
            }
            this.$http.post('/v1/sale/addSaleList',data , {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            }).then(res => {
                // 处理返回值
                alert(res.data.data)
            }).catch(err => {
                // 处理错误
                alert('出现错误')
            });
        },
        closeSellModal() {
            this.sellLandChild.vmodal = false
        }
    }
}
</script>

<style scoped>
/* ----------------------------------------挂牌售卖弹窗---------------------------------------- */
.land-sell-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 3; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.land-sell-modal-box {
    height: auto;
    width: 300px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.land-sell-modal-box-price {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    flex-direction: column;
}
.input-price-text {
    width: 50px;
    border-radius: 3px;
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 16px;
    border-radius: 6px;
    color: #fff;
}
</style>