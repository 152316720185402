<template>
  <div class="main-container">
    <el-form label-width="0px" class="form-add">
        <el-form-item class="my-el-form-item">
            <div class="form-item-cells">
                <el-col :span="3">
                    <div class="users-lvImg">
                        <el-tooltip class="item" effect="dark" :content="levelText()" placement="right-start">
                            <img :src="users.lvUrl" style="height: 70px; width: 60px;">
                        </el-tooltip>
                    </div>
                </el-col>
                <el-col :span="10" style="font-size: 20px;">
                    <i class="el-icon-user">&nbsp;&nbsp;&nbsp;等级: Lv {{ users.lv }}</i>
                    <br>
                    <i>总积分: {{ users.totalIntegral }}&nbsp;</i>
                    <el-tooltip class="item" effect="dark" content="过往总分:不包含当月新增分数,每月首日会随近一年情况进行一定程度衰减。" placement="top-end">
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                    <i>&nbsp;&nbsp;&nbsp;&nbsp;本月新增: {{ users.increaseIntegral }}&nbsp;</i>
                    <el-tooltip class="item" effect="dark" content="当月新增分数:每日更新,当月结束后,分数计入过往总分。" placement="top-end">
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </el-col>
                <el-col :span="11" style="font-size: 20px;">
                    <div style="text-align: right; margin-right: 20px;">
                        <i>最近更新时间:  {{ users.updateTime }}</i>
                    </div>
                </el-col>
            </div>
        </el-form-item>
        <el-form-item class="my-el-form-item">
            <div class="form-item-cells">
                <div style="text-aligh: right;" @mouseover="changeCursor">
                    <i>查看详细月报 →</i>
                </div>
                <el-col :span="6" style="font-size: 20px;">
                    <i>总收入</i>
                    <br>
                    <i class="el-icon-coin">&nbsp;{{ users.totalIncome }}</i>
                    <br>
                    <i class="update-data">昨日 {{ updateData(users.totalIncome,users.lastTotalIncome) }}</i>
                </el-col>
                <el-col :span="6" style="font-size: 20px;">
                    <i>买家</i>
                    <br>
                    <i>&nbsp;{{ users.customer }}</i>
                    <br>
                    <i class="update-data">昨日 {{ updateData(this.users.customer,this.users.lastCustomer) }}</i>
                </el-col>
                <el-col :span="6" style="font-size: 20px;">
                    <i>平均交易额</i>
                    <br>
                    <i class="el-icon-coin">&nbsp;{{ users.average }}</i>
                    <br>
                    <i class="update-data">上月 {{ updateData(this.users.average,this.users.lastAverage) }}</i>
                </el-col>
                <el-col :span="6" style="font-size: 20px;">
                    <i>最小单位平均地价</i>
                    <br>
                    <i class="el-icon-coin">&nbsp;{{ users.unitPrice }}</i>
                    <br>
                    <i class="update-data">上月 {{ updateData(this.users.unitPrice,this.users.lastUnitPrice) }}</i>
                </el-col>
            </div>
        </el-form-item>
        <el-form-item class="my-el-form-item">
            <div class="form-item-cells">
                <div style="white-space: nowrap;">
                    <span style="vertical-align: top; display: inline-block;">我的土地</span>
                    <div class="users-topic-buttons">
                        <button class="users-topic-button" @mouseover="changeCursor" @click="landControl">进入土地</button>
                        <button class="users-topic-button" @mouseover="changeCursor" @click="gotoLand">测试:进入三号土地</button>
                    </div>
                </div>
                <el-row :gutter="15" class="users-information-row">
                    <el-col :span="7"><i>土地编号</i></el-col>
                    <el-col :span="7"><i>土地名称</i></el-col>
                    <el-col :span="1"><i>块数</i></el-col>
                    <el-col :span="2"><i>状态</i></el-col>
                    <el-col :span="7"><i>操作</i></el-col>
                </el-row>
                <el-row :gutter="15" v-for="(user, index) in users_land1" :key="index" class="users-information-row">
                    <div class="users-information-col">
                        <el-col :span="7"><i>{{ user.id }}</i></el-col>
                        <el-col :span="7"><i>{{ user.name }}</i></el-col>
                        <el-col :span="1"><i>{{ user.count }}</i></el-col>
                        <el-col :span="2"><i>{{ user.state }}</i></el-col>
                        <el-col :span="7">
                            <i>&nbsp;</i>
                            <i @mouseover="changeCursor" style="text-decoration: underline;" v-if="user.state === '未上线'">上线</i>
                            <i v-if="user.state === '未上线'">&nbsp;</i>
                            <i @mouseover="changeCursor" style="text-decoration: underline;" v-if="user.state === '已上线'">下线</i>
                            <i v-if="user.state === '已上线'">&nbsp;</i>
                            <i @mouseover="changeCursor" style="text-decoration: underline;" v-if="user.state === '已上线'">出租</i>
                            <i v-if="user.state === '已上线'">&nbsp;</i>
                            <i @mouseover="changeCursor" style="text-decoration: underline;" v-if="user.state === '已上线'">一口价出售</i>
                            <i v-if="user.state === '已上线'">&nbsp;</i>
                            <i @mouseover="changeCursor" style="text-decoration: underline;" v-if="user.state === '已上线'">发起拍卖</i>
                            <i v-if="user.state === '已上线'">&nbsp;</i>
                            <i @mouseover="changeCursor" style="text-decoration: underline;" v-if="user.state === '已下线'">重新上线</i>
                            <i v-if="user.state === '已下线'">&nbsp;</i>
                            <i @mouseover="changeCursor" style="text-decoration: underline;">查看</i>
                        </el-col>
                    </div>
                </el-row>
            </div>
        </el-form-item>
        <el-form-item class="my-el-form-item">
            <div class="form-item-cells">
                <div style="white-space: nowrap;">
                    <span style="vertical-align: top; display: inline-block;">近期交易</span>
                    <div class="users-topic-buttons">
                        <span @mouseover="changeCursor">查看详细交易 →</span>
                    </div>
                </div>
                <el-row :gutter="15" class="users-information-row">
                    <el-col :span="6"><i>时间</i></el-col>
                    <el-col :span="6"><i>土地编号</i></el-col>
                    <el-col :span="6"><i>所有人</i></el-col>
                    <el-col :span="3"><i>售价</i></el-col>
                    <el-col :span="3"><i>买卖形式</i></el-col>
                </el-row>
                <el-row :gutter="15" v-for="(user, index) in users_trade1" :key="index" class="users-information-row">
                    <div class="users-information-col">
                        <el-col :span="6"><i>{{ user.time }}</i></el-col>
                        <el-col :span="6"><i>{{ user.id }}</i></el-col>
                        <el-col :span="6"><i>{{ user.buyer }}</i></el-col>
                        <el-col :span="3"><i>{{ user.price }}</i></el-col>
                        <el-col :span="3"><i>{{ user.state }}</i></el-col>
                    </div>
                </el-row>
            </div>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
    data() {
        return {
            users: {
                lvURL: '../assets/logo.png', // 用户等级应用的等级图标
                lv: 1, // 用户等级
                totalIntegral: 2000, // 用户总积分
                increaseIntegral: 1000, // 用户当月新增积分
                updateTime: 'xxxx-x-xx', // 更新时间？
                totalIncome: 5000, // 当前总收入
                lastTotalIncome: 4000, // 昨日总收入
                customer: 90, // 买家
                lastCustomer: 87, // 昨日买家
                average: 1100, // 平均交易额
                lastAverage: 1000, // 上月平均交易额
                unitPrice: 1000, // 最小单位土地平均地价
                lastUnitPrice: 1100, // 上月最小单位土地平均地价
            },

            users_land1: [ // ‘我的土地’测试用例
                {
                    id: 'mylandisbig12345',
                    name: '大图智能科技有限公司',
                    count: '100',
                    state: '已上线',
                },
                {
                    id: 'mylandisnotbig12345',
                    name: '大图智能科技有限公司',
                    count: '50',
                    state: '未上线',
                },
                {
                    id: 'mylandissmall12345',
                    name: '大图智能科技有限公司',
                    count: '10',
                    state: '已下线',
                },
            ],
            users_land: [], // axios后端所用的data参数

            users_trade1: [ // ‘近期交易’测试用例
                {
                    time: '2023-3-29 09:42:56',
                    id: 'mylandisbig12345',
                    buyer: '大图智能科技有限公司',
                    price: 1100,
                    state: '一口价'
                },
                {
                    time: '2023-3-29 11:22:33',
                    id: 'mylandisnotbig12345',
                    buyer: '大图智能科技有限公司',
                    price: 1100,
                    state: '拍卖'
                },
                {
                    time: '2023-3-30 17:01:10',
                    id: 'mylandissmall12345',
                    buyer: '大图智能科技有限公司',
                    price: 1100,
                    state: '一口价'
                },
            ],
            users_trade: [], // axios后端所用的data参数
        }
    },


    methods: {
        /* 鼠标悬停到等级图标上显示的文本 */
        levelText() {
            if(this.users.lv < 4) {
                return '初级开发商:持有土地1~5块,等级小于4级'
            }
        },
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        /* 计算新增数据 */
        updateData(now,last) {
            const data = now - last
            if (data >= 0) {
                return '+' + data.toString()
            } else {
                return data.toString()
            }
        },
        /* 合并、分割按钮-跳转到合并分割土地页面 */
        landControl(n) {
            this.$router.push('/land-operate/area-map')
        },
        gotoLand() {
            this.$router.push({
                path: '/land-operate/area-map',
                name: 'Thirdland',
                params: {
                    landid: 'TD012023042096919',
                    SecondLandCoordinates: '2',
                    ThirdLandCoordinates: '6'
                }})
        }
    }

}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container {
    height: 100%;
    background-color: black;
}
/* el-form组件没有占满全屏，增加一个style样式 */
.form-add {
    height: 100%;
    width: 100%;
}
/* el-form-item组件添加设置 */
.el-form-item {
    padding: 20px;
}
/* item下的div层，更换背景色 */
.my-el-form-item >>> .el-form-item__content { /* 增加深度选择器 */
    border: 2px solid #646262;
    border-radius: 12px;
    padding: 20px;
    background-color: #fff;
}
/* 给用户各部分信息区域设置一个父块，来控制行内元素 */
.form-item-cells {
    height: 100%;
    padding: 0;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
}




/* -------------------------------------个人信息——等级、资产等------------------------------------- */
/* 首先是图标小图片，后续可以设置为跟用户等级有关彰显用户身份的王冠等图片 */
.users-lvImg {
    padding: 10px;
    /* 采用`flex`布局，则可以通过设置`justify-content`和`align-items`属性来实现水平和垂直居中 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
}


/* -------------------------------------个人信息——总收入、相比昨日的新增收入等------------------------------------- */
/* 昨日/上月新增 */
.update-data {
    background-color: #646262;
    border: 2px solid #646262;
    border-radius: 12px;
    font-size: 14px;
    padding: 10px;
}


/* -------------------------------------个人信息——我的地产与近期交易------------------------------------- */
/* 第一行右对齐文本/按钮 */
.users-topic-buttons {
    width: 90%;
    text-align: right;
    vertical-align: top;
    display: inline-block;
}
/* 我的地产合并、分割按钮 */
.users-topic-button {
    background-color: #ddd3fd;
    border-radius: 12px;
    margin-left: 20px;
}
/* 信息栏样式 */
.users-information-row {
    padding: 10px;
    text-align: center;
}
.users-information-col {
    display: flex;
    background-color: #a7a5a5;
    border: 2px solid #a7a5a5;
    border-radius: 12px;
}
</style>