<template>
    <div class="main-container3">
        <div class="upload-form-parent">
            <div class="upload-form-topic" v-if="companyChild.whichtab === '已通过'"><span>正在修改已认证的开发商账户身份认证</span></div>
            <div class="upload-form-topic" v-if="companyChild.whichtab === '待审核'"><span>开发商账户身份认证</span></div>
            <div class="upload-form-topic" v-if="companyChild.whichtab === '已驳回'"><span>正在修改已驳回的开发商账户身份认证</span></div>
            <div class="upload-form">
                <div class="upload-form-left">
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">公司名称:</span>
                        <span>{{ companyName }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">房屋所有权证编号:</span>
                        <span>{{ ownershipCertificate }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">企业联络人姓名:</span>
                        <span>{{ companyContact }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">企业联络人手机号码:</span>
                        <span>{{ companyContactPhone }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <div class="upload-form-item-text">企业签约人身份证:</div>
                        <!-- 身份证正面照 -->
                        <div class="upload-form-item-img" @mouseover="changeCursor">
                            <el-tooltip class="item" effect="dark" content="点击查看原图" placement="top-end">
                                <img :src="frontIdImg" alt="身份证正面照" class="upload-form-item-img-i" @click="show(1)">
                            </el-tooltip>
                            <!-- 弹框的显示和隐藏状态，根据 isfrontIdImg 变量决定 -->
                            <div class="lightbox" v-if="isfrontIdImg" @click="hide">
                                <img class="full-image" :src="frontIdImg"/>
                            </div>
                        </div>
                        <!-- 身份证背面照 -->
                        <div class="upload-form-item-img" @mouseover="changeCursor">
                            <el-tooltip class="item" effect="dark" content="点击查看原图" placement="top-end">
                                <img :src="reverseIdImg" alt="身份证背面照" class="upload-form-item-img-i" @click="show(2)">
                            </el-tooltip>
                            <!-- 弹框的显示和隐藏状态，根据 isreverseIdImg 变量决定 -->
                            <div class="lightbox" v-if="isreverseIdImg" @click="hide">
                                <img class="full-image" :src="reverseIdImg"/>
                            </div>
                        </div>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <div class="upload-form-item-text">土地无贷款证明:</div>
                        <!-- 土地无贷款证明 -->
                        <el-link :href="landcertificateImg" @mouseover="changeCursor" style="color: black;">点击下载查看</el-link>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <div class="upload-form-item-text">公司注册信息和注册证明:</div>
                        <!-- 公司注册信息与注册证明 -->
                        <el-link :href="CPRTImg" @mouseover="changeCursor" style="color: black;">点击下载查看</el-link>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <div class="upload-form-item-text">平台合同扫描件:</div>
                        <!-- 平台合同扫描件 -->
                        <el-link :href="PFCTImg" @mouseover="changeCursor" style="color: black;">点击下载查看</el-link>
                    </el-row>
                </div>
                <div class="upload-button-parent">
                    <button class="upload-button" @click="goBack">
                        <span>返回</span>
                    </button>
                    <button class="upload-button" @click="apply" v-if="!(companyChild.whichtab === '已通过')">
                        <span>通过申请</span>
                    </button>
                    <button class="upload-button" @click="refuse" v-if="!(companyChild.whichtab === '已驳回')">
                        <span>驳回申请</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { // 父子组件传值
        companyChild: { // 包含landChild.landID和landChild.isReview两个值
            type: Object,
            required: true
        }
    },
    mounted() {
        this.$http.get('/v1/business/CompanyInformationOne?businessid=' + this.companyChild.businessid)
            .then(res => {
                this.companyName = res.data.data.corporationname
                this.ownershipCertificate = res.data.data.housingownership
                this.companyContact = res.data.data.businessname
                this.companyContactPhone = res.data.data.businessphone
                this.frontIdImg = this.url + 'key=businesscard1&businessid=' + this.companyChild.businessid + '&value=' + res.data.data.businesscard1
                this.reverseIdImg = this.url + 'key=businesscard2&businessid=' + this.companyChild.businessid + '&value=' + res.data.data.businesscard2
                this.landcertificateImg = this.pdfurl + 'key=landcertificate&businessid=' + this.companyChild.businessid + '&value=' + res.data.data.landcertificate
                this.CPRTImg = this.pdfurl + 'key=companyregistration&businessid=' + this.companyChild.businessid + '&value=' + res.data.data.companyregistration
                this.PFCTImg = this.pdfurl + 'key=platformcontract&businessid=' + this.companyChild.businessid + '&value=' + res.data.data.platformcontract
            }).catch(error => {
                // 处理错误
            });
    },
    data() {
        return {
            loading: true, // 控制加载项
            companyName: '大图智能科技有限公司',
            ownershipCertificate: 'mine1234',
            companyContact: 'Winston Liu',
            companyContactPhone: '12345678901',


            url: 'http://192.168.1.145:8092/business/seeImages?', // 所有企业图片的baseUrl
            pdfurl: 'http://192.168.1.145:8092/business/seepdfFile?',
            frontIdImg: require('../assets/no-image.png'), // 身份证正面照
            isfrontIdImg: false,
            reverseIdImg: require('../assets/no-image.png'), // 身份证反面照
            isreverseIdImg: false,
            landcertificateImg: require('../assets/no-image.png'), // 土地证明
            islandcertificate: false,
            CPRTImg: require('../assets/no-image.png'), // 公司注册信息
            isCPRT: false,
            CRImg: require('../assets/no-image.png'), // 公司注册证明
            isCR: false,
            PFCTImg: require('../assets/no-image.png'), // 平台合同扫描件
            isPFCT: false,
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },

        /* 点击图片查看原图事件 */
        show(n) {
            if (n===1) {
                this.isfrontIdImg = true
            } else if (n===2) {
                this.isreverseIdImg = true
            } else if (n===3) {
                this.islandcertificate = true
            } else if (n===4) {
                this.isCPRT = true
            } else if (n===5) {
                this.isCR = true
            } else if (n===6) {
                this.isPFCT = true
            }
        },
        hide() {
            this.isfrontIdImg = false; // 点击弹框外部时，隐藏弹框
            this.isreverseIdImg = false;
            this.islandcertificate = false;
            this.isCPRT = false;
            this.isCR = false;
            this.isPFCT = false;
        },
        

        /* 提交按钮事件 */
        goBack() {
            this.companyChild.isReview = false
        },
        apply() {
            const data = {
                businessid: this.companyChild.businessid,
                verificationstatus: '已通过'
            }
            // 向后端发送数据 测试地址http://192.168.1.145:8092/Administrator/businesspassthrough
            this.$http.post('/v1/Administrator/businesspassthrough', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                alert('审核通过，已录入系统！')
                window.location.reload(); // 刷新页面
            })
            .catch(error => {
                // 处理错误
                alert('审核通过录入系统失败！错误代码：' + error.data)
            });
        },
        refuse() {
            const data = {
                businessid: this.companyChild.businessid,
                verificationstatus: '已驳回'
            }
            // 向后端发送数据 测试地址http://192.168.1.145:8092/Administrator/businesspassthrough
            this.$http.get('/v1/Administrator/businesspassthrough', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                alert('审核驳回，已录入系统！')
                window.location.reload(); // 刷新页面
            })
            .catch(error => {
                // 处理错误
                alert('审核驳回录入系统失败！错误代码：' + error.data)
            });
        },
    }
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container3 {
    height: 100%;
    overflow: auto;
}


/* -----------------------------------------上传公司负责人信息填写表单------------------------------------------ */
.upload-form-parent {
    /* 父 `div` 容器设置为 `flex` 容器。然后，将子 `div` 容器设置为 `flex-grow: 1;`，将其自动填充父容器的剩余空间 */
    display: flex;
    flex-direction: column;
    padding: 20px;
}
/* 表的标题 */
.upload-form-topic {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding: 20px;
}
/* 表单层-更换颜色 */
.upload-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #bebebe;
    padding: 20px;
    border-radius: 6px;
    white-space: nowrap;
}
.upload-form-left {
    vertical-align: top;
    padding: 20px;
    border-radius: 6px;
    border: 2px solid #000000;
}
/* 表单元素 */
.upload-form-item {
    padding: 10px;
    /* 父组件加入后让子组件平齐 */
    white-space: nowrap;
}
/* ------------------------------上传文本样式------------------------------- */
/* 表单元素-标签 */
.upload-form-item-label {
    width: 200px;
    display: inline-block;
    font-size: 16px;
    color: #fff;
    /* 子组件加入后让同行元素平行 */
    vertical-align: top;
}
/* ------------------------------上传图片样式------------------------------- */
/* 图片框 */
.upload-form-item-img {
    height: 160px;
    width: 240px;
    display: inline-block;
    padding: 10px;
    margin-right: 30px;
}
/* 图片需要自适应到父块大小 */
.upload-form-item-img-i {
    padding: 0;
    margin: 0;
    max-width: 100%;
    max-height: 100%;
}
/* 描述文本 */
.upload-form-item-text {
    font-size: 16px;
    color: #fff;
}
/* ------------------------------图片弹窗------------------------------- */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
  z-index: 1; /* 将弹框置于其他所有元素之上 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-image {
  max-width: 80%;
  max-height: 80%;
}


/* ------------------------------上传提交开发商用户注册信息表单按钮------------------------------- */
.upload-button-parent {
    width: 100%;
    margin-top: 20px;
    text-align: center;
}
.upload-button {
    margin-right: 50px;
    height: 50px;
    width: 100px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 1);
    border-radius: 6px;
    background-color: #dedcdc;
}
</style>