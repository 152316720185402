<template>
    <div>
        <van-nav-bar v-if="!isChoosing" class="bg-gray-50" :title="$t('MobileAddressManagement.title')" :left-text="$t('MobileAddressManagement.back')" left-arrow @click-left="onBack" />
        <van-address-list  v-model="chosenAddressId" :list="addressList" default-tag-text="默认" @add="onAdd" @edit="onEdit" />
        <van-dialog v-model="editAddress" :show-confirm-button="false" show-cancel-button>
            <van-address-edit
                :area-list="areaList"
                show-delete
                show-set-default
                show-search-result
                :area-columns-placeholder="[$t('MobileAddressManagement.choose'), $t('MobileAddressManagement.choose'), $t('MobileAddressManagement.choose')]"
                :address-info="addressList[editing]"
                @save="onEditSave"
                @delete="onEditDelete"
            />
        </van-dialog>
        <van-dialog v-model="addAddress" :show-confirm-button="false" show-cancel-button>
            <van-address-edit :area-list="areaList" show-postal show-set-default :area-columns-placeholder="[$t('MobileAddressManagement.choose'), $t('MobileAddressManagement.choose'), $t('MobileAddressManagement.choose')]" @save="onAddSave" />
        </van-dialog>
    </div>
</template>

<script>
import { areaList } from "@vant/area-data";
export default {
    name: "MobileAddressManagement",
    props: {
        isChoosing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            chosenAddressId: -1,
            addressList: [],
            editAddress: false,
            addAddress: false,
            areaList,
            editing: 0,

        };
    },
    created() {
        this.onLoad();
    },
    methods: {
        onBack() {
            this.$router.go(-1);
        },
        onLoad(){
            this.$http
                .get("/v1/address/select-address")
                .then((response) => {
                    const data = response.data;
                    if (data.status === 200) {
                        let userData = data.data;
                        let addressList = [];
                        for (let i = 0; i < userData.length; i++) {
                            addressList.push({
                                id: userData[i].id,
                                name: userData[i].consigneename,
                                tel: userData[i].contactinformation,
                                province: userData[i].province,
                                city: userData[i].city,
                                county: userData[i].area,
                                addressDetail: userData[i].detailedaddress,
                                address: userData[i].province + userData[i].city + userData[i].area + userData[i].detailedaddress,
                                isDefault: userData[i].isdefault,
                                areaCode: userData[i].provincecode,
                                areaCode: userData[i].citycode,
                                areaCode: userData[i].areacode,
                                // 这里好离谱 连续设置三遍???
                            });
                        }
                        this.addressList = addressList;
                        if (this.addressList.length === 0) {
                            // this.addAddress = true;
                            this.$toast(this.$t('MobileAddressManagement.alertAddAddress'));
                        }
                        else {
                            this.chosenAddressId = this.addressList[0].id;
                        }

                    } else {
                        // 处理错误情况
                        console.error(data.msg);
                    }
                })
                .catch((error) => {
                    // 处理请求失败的情况
                    console.error(error);
                });
        },
        onEdit(item, index) {
            this.editAddress = true;
            this.editing = index;
        },
        onEditSave(addressInfo) {
            if (addressInfo.isDefault) {
                for (let i = 0; i < this.addressList.length; i++) {
                    if (i === this.editing) continue;
                    if (this.addressList[i].isDefault) {
                        this.addressList[i].isDefault = false;
                        let t = this.editing;
                        this.editing = i;
                        this.onEditSave(this.addressList[i]);
                        this.editing = t;
                        break;
                    }
                }
            }
            this.addressList[this.editing].name = addressInfo.name;
            this.addressList[this.editing].tel = addressInfo.tel;
            this.addressList[this.editing].province = addressInfo.province;
            this.addressList[this.editing].city = addressInfo.city;
            this.addressList[this.editing].county = addressInfo.county;
            this.addressList[this.editing].areaCode = addressInfo.areaCode;
            this.addressList[this.editing].addressDetail = addressInfo.addressDetail;
            this.addressList[this.editing].address = addressInfo.province + addressInfo.city + addressInfo.county + addressInfo.addressDetail;
            this.addressList[this.editing].isDefault = addressInfo.isDefault;
            this.editAddress = false;
            this.$http({
                method: "put",
                url: "/v1/address/update-address",
                data: {
                    id: this.addressList[this.editing].id,
                    consigneename: this.addressList[this.editing].name,
                    contactinformation: this.addressList[this.editing].tel,
                    province: this.addressList[this.editing].province,
                    city: this.addressList[this.editing].city,
                    area: this.addressList[this.editing].county,
                    detailedaddress: this.addressList[this.editing].addressDetail,
                    isdefault: this.addressList[this.editing].isDefault,
                },
            })
                .then((res) => {
                    console.log(res);
                    this.editing = -1;
                })
                .catch((err) => {
                    console.log(err);
                    this.editing = -1;
                });
        },
        onEditDelete(addressInfo) {
            this.editAddress = false;
            this.$http({
                method: "delete",
                url: "/v1/address/delete-address",
                params: {
                    id: this.addressList[this.editing].id,
                },
            })
                .then((res) => {
                    console.log(res);
                    this.addressList.splice(this.editing, 1);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        onEditCancel() {
            this.editAddress = false;
        },
        onAdd() {
            this.addAddress = true;
            // this.$http({ TODO 
            //     method: "post",
            //     url: "/v1/user/addAddress",
            //     data: {
            //         name: this.addressList.name,
            //         tel: this.addressList.tel,
            //         address: this.addressList.address,
            //         isdefault: this.addressList.isDefault,
            //     },
            // })
            //     .then((res) => {
            //         console.log(res);
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //     });
        },
        onAddSave(addressInfo) {
            if (addressInfo.isDefault) {
                for (let i = 0; i < this.addressList.length; i++) {
                    if (i === this.editing) continue;
                    if (this.addressList[i].isDefault) {
                        this.addressList[i].isDefault = false;
                        let t = this.editing;
                        this.editing = i;
                        this.onEditSave(this.addressList[i]);
                        // this.onLoad();
                        this.editing = t;
                        break;
                    }
                }
            }
            this.addressList.push({
                name: addressInfo.name,
                tel: addressInfo.tel,
                address: addressInfo.province + addressInfo.city + addressInfo.county + addressInfo.addressDetail,
                isDefault: addressInfo.isDefault,
            });
            this.$http({
                method: "post",
                url: "/v1/address/create-address",
                data: {
                    consigneename: addressInfo.name,
                    contactinformation: addressInfo.tel,
                    province: addressInfo.province,
                    city: addressInfo.city,
                    area: addressInfo.county,
                    detailedaddress: addressInfo.addressDetail,
                    isdefault: addressInfo.isDefault,
                },
            })
                .then((res) => {
                    this.onLoad();

                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
            this.addAddress = false;
        },
    },
};
</script>

<style scoped></style>
