<template>
    <div class="flex flex-col h-full bg-gray-100 msger">
        <!-- 聊天页面的头部，包含菜单和清除聊天记录按钮 -->
        <div class="flex items-center justify-between p-2 bg-white shadow-md">
            <div class="flex items-center space-x-2 flex-1">
                <h1 class="text-lg font-bold text-gray-700 flex-1 text-center">
                    客服
                </h1>
            </div>
        </div>

        <!-- 聊天记录区域 -->
        <div class="flex-1 p-4 overflow-y-auto relative" ref="messageList">
            <!-- 在这里循环渲染聊天记录 -->


            <!-- 对话框 -->
            <AppendMessage v-for="(message, index) in messages" :key="index" :name="message.isme? message.fromname : message.recipientname" :img="message.isme? null : message.avatarUrl"
                :side="message.isme? 'right' : 'left'" :text="message.emailscontent" :id="message.id" :time="message.createdate" :progress="message.progress"
                :file="message.file" @forward="onForward(message.id)" />
        
        </div>

        <!-- 输入框和发送按钮 -->
        <form class="flex md:flex-row items-center p-2 bg-white shadow-md relative">
            <div class="flex-1 flex items-center">
                <textarea ref="inputBox"
                    class="flex-1 h-12 px-4 py-2 text-gray-700 bg-gray-100 rounded-md focus:outline-none focus:shadow-outline"
                    placeholder="请描述您的问题" rows="2" v-model="text" @keyup.enter="sendMessage"></textarea>
            </div>
            <div class="flex-none ml-2">
                <button @click.prevent="sendMessage"
                    class="px-4 py-2 text-white bg-blue-500 rounded-md focus:outline-none focus:shadow-outline hover:bg-blue-600">
                    发送
                </button>
            </div>
        </form>

    </div>
</template>
<script>
import AppendMessage from "../admin/AppendMessage.vue";
export default {
    props: {
        ThreadId: String,
    },
    components: {
        AppendMessage,
    },
    mounted() {
        this.$http.get('/v1/csmail/csselect-by-serviceid?serviceid=' + this.ThreadId)
            .then(res => {
                this.messages = res.data.data
                this.avatarUrl = URL.createObjectURL(res.data.msg)
            }).catch(error => {
                console.error(error.data)// 处理错误
            });
    },
    data() {
        return {
            example: [{
                id: 6175,
                serviceid: "301435703712633003",
                fromid: "43a89960781b41de9e16c2575772dc54",
                fromname: "dt1234563",
                recipientid: "10002",
                recipientname: "高级管理员",
                emailscontent: "你好，客服",
                createdate: "2023-08-23T16:00:38",
                receivedate: null,
                fromdelete: false,
                recipientdelete: false,
                isme: false,
                avatarUrl: require("../assets/no-image.png")
            },{
                id: 6176,
                serviceid: "301435703712633003",
                fromid: "43a89960781b41de9e16c2575772dc54",
                fromname: "dt1234563",
                recipientid: "10002",
                recipientname: "高级管理员",
                emailscontent: "我有问题想要问",
                createdate: "2023-08-23T16:01:38",
                receivedate: null,
                fromdelete: false,
                recipientdelete: false,
                isme: false,
                avatarUrl: require("../assets/no-image.png")
            }],
            messages: null,
            avatarUrl: require("../assets/no-image.png"), // 用户头像，后端接口的res.data.msg获取图片
            text: '', // 文本，发送的消息
        }
    },
    methods: {

        // 发送文字函数
        async sendMessage() {
            const text = this.$refs.inputBox.value.trim();
            try {
                if (this.ThreadId) {
                    const { data } = await this.$http.post('/v1/csmail/cswrite-message', { emailscontent: this.text,threadid: this.ThreadId })
                    const newMessageId = this.messageId + 1;
                    this.messageId = newMessageId;
                    this.appendMessage("我", "img-url", 'right', text, newMessageId);
                    // 清空输入框中的内容
                    this.text = '';
                } else {
                    const { data } = await this.$http.post('/v1/csmail/cswrite-message', { emailscontent: this.text,threadid: '' })
                    const newMessageId = this.messageId + 1;
                    this.messageId = newMessageId;
                    this.appendMessage("我", "img-url", 'right', text, newMessageId);
                    // 清空输入框中的内容
                    this.text = '';
                }
            } catch (error) {
                console.log(error)
            }
        },

        appendMessage(name, img, side, text, id, time, progressdown, file) {
            const now = new Date();
            const message = {
                id: id,
                serviceid: this.ThreadId,
                fromname: '我',
                side: 'right',
                createdate: `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}T${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`,
                emailscontent: text,
                isme: true,
            };
            this.messages.push(message);

            // 滚动到最新消息
            this.$nextTick(() => {
                const messageList = this.$refs.messageList;
                messageList.scrollTop = messageList.scrollHeight;
            });
        },
    },

}
</script>
<style scoped>
.msger {
    min-width: 768px;
    max-width: 867px;
    margin: auto;
}

@media (min-width: 768px) {

    /* 对于宽度大于等于768px的设备 */
    .input-container {
        flex-direction: row;
    }

    .input-container textarea {
        flex: 1;
        height: auto;
        max-height: 80px;
    }

    .input-container button {
        width: 120px;
    }
}

.relative {
    position: relative;
}
</style>