<template>
    <div class="main-container2">
        <div class="tabs">
            <div class="tabs-head">
                <div class="tabs-head-item" :class="isTab('1')" @click="tabsControl('1')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">土地上传审核</span>
                </div>
                <div class="tabs-head-item" :class="isTab('2')" @click="tabsControl('2')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">土地下线申请</span>
                </div>
                <div class="tabs-head-item" :class="isTab('3')" @click="tabsControl('3')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">订单</span>
                </div>
                <div class="tabs-head-item" :class="isTab('4')" @click="tabsControl('4')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">土地上传审核</span>
                </div>
            </div>
            <div v-if="landChild.whichtab==='1'">
                <div v-if="landChild.isReview" style="height: 80%;">
                    <el-row :gutter="15" class="tabs-content-row">
                        <el-col :span="3"><i>时间</i></el-col>
                        <el-col :span="4"><i>土地编号</i></el-col>
                        <el-col :span="3"><i>土地名称</i></el-col>
                        <el-col :span="5"><i>所有者ID</i></el-col>
                        <el-col :span="4"><i>详细地址</i></el-col>
                        <el-col :span="3"><i>地理坐标</i></el-col>
                        <el-col :span="2"><i>操作</i></el-col>
                    </el-row>
                    <el-row :gutter="15" v-for="(apply, index) in view" :key="index" class="tabs-content-row">
                        <div class="tabs-content-col">
                            <el-col :span="3"><i>{{ apply.createdate }}</i></el-col>
                            <el-col :span="4"><i>{{ apply.originallandid }}</i></el-col>
                            <el-col :span="3"><i>{{ apply.originallandname }}</i></el-col>
                            <el-col :span="5"><i>{{ apply.ownerid }}</i></el-col>
                            <el-col :span="4"><i>{{ apply.detailedaddress }}</i></el-col>
                            <el-col :span="3"><i>{{ apply.geographicalcoordinates }}</i></el-col>
                            <el-col :span="2"><i @mouseover="changeCursor" style="text-decoration: underline;" @click="landReview(apply.originallandid)">资格审查</i></el-col>
                        </div>
                    </el-row>
                    <el-pagination
                        background
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
                <LandUploadReview v-if="!landChild.isReview" :landChild='landChild'></LandUploadReview>
            </div>
            <div class="tabs-content" v-if="whichtab==='2'"></div>
            <div class="tabs-content" v-if="whichtab==='3'"></div>
            <div class="tabs-content" v-if="whichtab==='4'"></div>
        </div>
    </div>
</template>

<script>
import LandUploadReview from "./LandReview.vue";

export default {
    components: { // 管理员组件
        LandUploadReview // 土地上传审核组件
    },
    mounted() { // 初始化
        this.$http.get('/v1/business/OriginalLandInformation')
            .then(res => {
                // 处理返回值
                this.landUploadApply = res.data.data
                this.initializePage()
            })
            .catch(error => {
                // 处理错误
                alert('服务器异常！错误代码：' + error.data)
            });
    },
    data() {
      return {
        whichtab: '1', // 选定哪一个页面，这里有四个页面
        currentPage: 1,
        pageSize: 10,
        showLength: 0,

        landUploadApply: null, // 土地上传审核
        landDownLineApply: null, // 土地下线审核
        view: null,


        landChild: { // 父子组件传值
            whichtab: '1', // 选定哪一个页面，这里有四个页面
            landId: '', // 向子组件传入土地编号，以获取土地上传信息
            isReview: true // 土地上传资格审查详情页是否点入
        },
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.landChild.whichtab = n
            this.landChild.isReview = true
            if (!this.landDownLineApply) {} // 向后端申请
            this.initializePage()
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.landChild.whichtab) {
                return 'tabs-head-item-select'
            } else {
                return
            }
        },
        initializePage() { // 初始化
            this.currentPage = 1
            if (this.landChild.whichtab === '1') {
                this.showLength = this.landUploadApply.length
                this.view = this.landUploadApply.slice(0,this.pageSize - 1)
            } else if (this.landChild.whichtab === '已驳回') {
                this.showLength = this.landDownLineApply.length
                this.view = this.landDownLineApply.slice(0,this.pageSize - 1)
            }
        },
        pageSizeChange(val) {
            // 计算索引值
            const start = (this.currentPage - 1) * val
            const end = this.currentPage * val - 1
            // 更新显示的数据
            if (this.landChild.whichtab === '1') {
                this.view = this.landUploadApply.slice(start,end)
            } else if (this.landChild.whichtab === '2') {
                this.view = this.landDownLineApply.slice(start,end)
            }
            // 更新页距
            this.pageSize = val
        },
        pageCurrentChange(val) {
            // 计算索引值
            const start = (val - 1) * this.pageSize
            const end = this.pageSize * val - 1
            // 更新显示的数据
            if (this.landChild.whichtab === '1') {
                this.view = this.landUploadApply.slice(start,end)
            } else if (this.landChild.whichtab === '2') {
                this.view = this.landDownLineApply.slice(start,end)
            }
            // 更新页码
            this.currentPage = val
        },
        landReview(id) {
            this.landChild.isReview = false
            this.landChild.landId = id
        }
    },
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container2 {
    min-height: 100%;
    background-color: black;
    padding: 50px;
}
.tabs {
    height: 100%;
    width: 100%;
}



/* --------------------------------------------选项卡样式-------------------------------------------- */
.tabs-head {
    width: 100%;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
}
.tabs-head-item {
    display: inline-block;
    height: 50px;
    width: 100px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 14px;
    color: #fff;
    position: relative;
}
.tabs-head-item-select {
    padding: 0;
    margin: 0;
    border-bottom: none;
    background-color: #383838;
    color: aqua;
    font-size: 16px;
}
.tabs-head-item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}



/* --------------------------------------------管理页面样式-------------------------------------------- */
/* -------------------------------土地上传管理页面------------------------------- */
/* 信息栏样式 */
.tabs-content-row {
    padding: 10px;
    text-align: center;
    color: #fff;
}
.tabs-content-col {
    height: 50px;
    /* 采用`flex`布局，则可以通过设置`justify-content`和`align-items`属性来实现水平和垂直居中 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    background-color: #a7a5a5;
    border: 2px solid #a7a5a5;
    border-radius: 6px;
}
</style>