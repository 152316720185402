<template>
    <div class="main-container3">
        <div class="upload-form-parent">
            <div class="upload-form-topic"><span>开发商账户身份认证</span></div>
                <div class="upload-form" style="flex-grow: 1;display: flex;">
                <el-row :gutter="15" class="upload-form-item">
                    <span class="upload-form-item-label">公司名称:</span>
                    <input v-model="companyName" class="upload-form-item-input" placeholder="请输入与营业执照一致的公司全称">
                </el-row>
                <el-row :gutter="15" class="upload-form-item">
                    <span class="upload-form-item-label">房屋所有权证编号:</span>
                    <input v-model="ownershipCertificate" class="upload-form-item-input">
                </el-row>
                <el-row :gutter="15" class="upload-form-item">
                    <span class="upload-form-item-label">企业联络人姓名:</span>
                    <input v-model="companyContact" class="upload-form-item-input">
                </el-row>
                <el-row :gutter="15" class="upload-form-item">
                    <span class="upload-form-item-label">企业联络人手机号码:</span>
                    <input v-model="companyContactPhone" class="upload-form-item-input">
                </el-row>
                <el-row :gutter="15" class="upload-form-item">
                    <div class="upload-form-item-text">请上传身份证:</div>
                    <!-- 身份证正面照 -->
                    <div class="upload-form-item-img" @mouseover="changeCursor">
                        <img src="../icons/id-card-front.png" v-if="isfrontIdImg" alt="身份证正面照未上传" class="upload-form-item-img-i" @click="handleClick(1)">
                        <img :src="frontIdImgUrl" v-if="!isfrontIdImg" alt="身份证正面照" class="upload-form-item-img-i" @click="handleClick(1)">
                        <input type="file" ref="frontIdImgInput" @change="handleFileInputChange('frontIdImgInput')" hidden>
                    </div>
                    <!-- 身份证背面照 -->
                    <div class="upload-form-item-img" @mouseover="changeCursor">
                        <img src="../icons/id-card-reverse.png" v-if="isreverseIdImg" alt="身份证背面照未上传" class="upload-form-item-img-i" @click="handleClick(2)">
                        <img :src="reverseIdImgUrl" v-if="!isreverseIdImg" alt="身份证背面照" class="upload-form-item-img-i" @click="handleClick(2)">
                        <input type="file" ref="reverseIdImgInput" @change="handleFileInputChange('reverseIdImgInput')" hidden>
                    </div>
                    <div class="upload-form-item-text">上传图片文件大小不能超过5M</div>
                </el-row>
                <el-row :gutter="15" class="upload-form-item">
                    <div class="upload-form-item-text">土地无贷款证明:</div>
                    <!-- 土地无贷款证明 -->
                    <div class="upload-form-item-pdf" @mouseover="changeCursor">
                        <input type="file" ref="landcertificateInput" @change="handleFileInputChange('landcertificateInput')">
                    </div>
                    <div class="upload-form-item-text">请上传相关pdf文件,上传文件大小不能超过5M</div>
                </el-row>
                <el-row :gutter="15" class="upload-form-item">
                    <div class="upload-form-item-text">公司注册信息和注册证明:</div>
                    <!-- 公司注册信息 -->
                    <div class="upload-form-item-pdf" @mouseover="changeCursor">
                        <input type="file" ref="CPRTInput" @change="handleFileInputChange('CPRTInput')">
                    </div>
                    <div class="upload-form-item-text">请上传相关pdf文件,上传文件大小不能超过5M</div>
                </el-row>
                <el-row :gutter="15" class="upload-form-item">
                    <div class="upload-form-item-text">平台合同扫描件:</div>
                    <!-- 平台合同扫描件 -->
                    <div class="upload-form-item-pdf" @mouseover="changeCursor">
                        <input type="file" ref="PFCTInput" @change="handleFileInputChange('PFCTInput')">
                    </div>
                    <div class="upload-form-item-text">请上传相关pdf文件,上传文件大小不能超过5M</div>
                </el-row>
            </div>
            <div class="upload-button-parent">
                <button class="upload-button" @click="showModal">
                    <span>提交申请</span>
                </button>
                <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
                <div class="modal" v-if="vmodal" @click="closeModal">
                    <div class="modal-box">
                        <span>请注意:上传审核后,需等待管理员审核</span>
                        <span>审核期间无法修改上传信息,无法操作此网页</span>
                        <span>是否上传审核？</span>
                        <div style="margin-top: 50px;">
                            <button class="modal-button" @click="upload" style="margin-right: 30px;">确定</button>
                            <button class="modal-button" @click="closeModal">取消</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    props: { // 父子组件传值
        active: { // 包含landChild.landID和landChild.isReview两个值
            type: Number,
            required: true
        }
    },
    data() {
        return {
            companyName: '',
            ownershipCertificate: '',
            companyContact: '',
            companyContactPhone: '',
            frontIdImg: '', // 身份证正面照
            isfrontIdImg: true,
            frontIdImgUrl: '',
            reverseIdImg: '', // 身份证反面照
            isreverseIdImg: true,
            reverseIdImgUrl: '',
            landcertificateImg: '', // 土地证明
            islandcertificate: true,
            landcertificateUrl: '',
            CPRTImg: '', // 公司注册信息
            isCPRT: true,
            CPRTUrl: '',
            PFCTImg: '', // 平台合同扫描件
            isPFCT: true,
            PFCTUrl: '',
            
            
            vmodal: false, // 显示弹窗
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },

        /* 上传图片功能样式 */
        handleClick(n) {
            // 点击时执行相应逻辑
            // 触发文件选择器
            if(n===1) {
                this.$refs.frontIdImgInput.click();
            }else if(n===2) {
                this.$refs.reverseIdImgInput.click();
            }else if(n===3) {
                this.$refs.landcertificateInput.click();
            }else if(n===4) {
                this.$refs.CPRTInput.click();
            }else if(n===5) {
                this.$refs.CRInput.click();
            }else if(n===6) {
                this.$refs.PFCTInput.click();
            }
        },
        handleFileInputChange(ref) { // 获取用户选择的文件
            if(ref === 'frontIdImgInput') { // 获取身份证正面照并显示照片
                // 获取用户选择的文件对象
                this.frontIdImg = this.$refs.frontIdImgInput.files[0];
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.frontIdImg);
                // 更新当前头像 URL
                this.frontIdImgUrl = url;
                // 把原图清除换为用户上传的图片
                this.isfrontIdImg = false
            } else if (ref === 'reverseIdImgInput') { // 获取身份证背面照并显示照片
                // 获取用户选择的文件对象
                this.reverseIdImg = this.$refs.reverseIdImgInput.files[0];
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.reverseIdImg);
                // 更新当前头像 URL
                this.reverseIdImgUrl = url;
                // 把原图清除换为用户上传的图片
                this.isreverseIdImg = false
            } else if (ref === 'landcertificateInput') { // 获取土地无贷款证明并显示照片
                // 获取用户选择的文件对象
                this.landcertificateImg = this.$refs.landcertificateInput.files[0];
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.landcertificateImg);
                // 更新当前头像 URL
                this.landcertificateUrl = url;
                // 把原图清除换为用户上传的图片
                this.islandcertificate = false
            } else if (ref === 'CPRTInput') { // 获取公司注册信息并显示照片
                // 获取用户选择的文件对象
                this.CPRTImg = this.$refs.CPRTInput.files[0];
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.CPRTImg);
                // 更新当前头像 URL
                this.CPRTUrl = url;
                // 把原图清除换为用户上传的图片
                this.isCPRT = false
            } else if (ref === 'PFCTInput') { // 获取平台合同扫面件并显示照片
                // 获取用户选择的文件对象
                this.PFCTImg = this.$refs.PFCTInput.files[0];
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.PFCTImg);
                // 更新当前头像 URL
                this.PFCTUrl = url;
                // 把原图清除换为用户上传的图片
                this.isPFCT = false
            } else {
                return
            }
        },

        showModal() {
            this.vmodal = true
        },
        closeModal() {
            this.vmodal = false
        },

        /* 提交按钮事件 */
        upload() {
            // 将文件转化为 FormData
            const formData = new FormData();
            formData.append('businessname', this.companyContact);
            formData.append('businessphone', this.companyContactPhone);
            formData.append('corporationname', this.companyName);
            formData.append('housingownership', this.ownershipCertificate);
            formData.append('businesscard1', this.frontIdImg);
            formData.append('businesscard2', this.reverseIdImg);
            formData.append('landcertificate', this.landcertificateImg);
            formData.append('companyregistration', this.CPRTImg);
            formData.append('platformcontract', this.PFCTImg);
            
      
            // 发送 Ajax 请求
            this.$http.post('/v1/business/PersonalInformation', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then(response => {
                this.active++
            }).catch(error => {
                alert(error.data);
            });
        }
    }
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container3 {
    width: 80%;
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}


/* -----------------------------------------上传公司负责人信息填写表单------------------------------------------ */
.upload-form-parent {
    /* 父 `div` 容器设置为 `flex` 容器。然后，将子 `div` 容器设置为 `flex-grow: 1;`，将其自动填充父容器的剩余空间 */
    flex-direction: column;
    flex: 1; /* 元素填充满父块 */
    padding: 20px;
    overflow-y: auto; /* 超出父块高度的区域通过滚动条滚动 */
}
/* 表的标题 */
.upload-form-topic {
    color: #fff;
    font-size: 20px;
    text-align: center;
}
/* 表单层-更换颜色 */
.upload-form {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    flex-grow: 1;
    background-color: #646064;
    padding: 20px;
    border-radius: 6px;
    white-space: nowrap;
}
/* 表单元素 */
.upload-form-item {
    padding: 10px;
    /* 父组件加入后让子组件平齐 */
    white-space: nowrap;
}
/* ------------------------------上传文本样式------------------------------- */
/* 表单元素-标签 */
.upload-form-item-label {
    width: 150px;
    display: inline-block;
    font-size: 16px;
    color: #fff;
    /* 子组件加入后让同行元素平行 */
    vertical-align: top;
}
/* 表单元素-输入框 */
.upload-form-item-input {
    height: 20.67px;
    width: 300px;
    display: inline-block;
    margin-left: 20px;
    border-radius: 6px;
    /* 子组件加入后让同行元素平行 */
    vertical-align: top;
}
/* ------------------------------上传图片样式------------------------------- */
/* 图片框 */
.upload-form-item-img {
    height: 160px;
    width: 240px;
    display: inline-block;
    padding: 10px;
    margin-right: 30px;
}
/* 图片需要自适应到父块大小 */
.upload-form-item-img-i {
    padding: 0;
    margin: 0;
    max-width: 100%;
    max-height: 100%;
}
/* 描述文本 */
.upload-form-item-text {
    font-size: 16px;
    color: #fff;
}
/* pdf文件 */
.upload-form-item-pdf {
    margin-top: 40px;
    margin-bottom: 40px;
}


/* ------------------------------上传提交开发商用户注册信息表单按钮------------------------------- */
.upload-button-parent {
    margin-top: 50px;
    width: 100%;
    text-align: center;
}
.upload-button {
    height: 50px;
    width: 100px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 1);
    border-radius: 6px;
    background-color: #dedcdc;
}


/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    height: 150px;
    width: 300px;
    background-color: #a7a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>