<template>
    <div class="mobile-homepage-home">
        <div class="mx-3 my-3">
            <van-swipe :loop="false" :autoplay="3000" :width="400" class="rounded-lg shadow-md">
                <van-swipe-item v-for="(image, index) in images" :key="index">
                    <img v-lazy="image" />
                </van-swipe-item>
            </van-swipe>
        </div>
        <div style="margin-left: 20px">{{$t('homePage.title')}}</div>
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
            <div v-if="!loaded" v-for="i in [1]*5" >
                <van-skeleton avatar  title :row="2" />
            </div>

            <MobileHomeLand v-if="loaded" class="mx-3 mt-3" v-for="landinfor in LandInfor" :LandInfor="landinfor" ref="MobileLand"></MobileHomeLand>
        </div>
    </div>
</template>

<script>
import MobileHomeLand from "./MobileHomeLand.vue";
export default {
    components: {
        // 土地市场组件
        MobileHomeLand, // 挂牌土地信息组件
    },
    created() {
        this.$store.dispatch("user/fetchUserInfo");

    },
    mounted() {
        this.screenWidth = window.innerWidth;

        // 初始化土地市场列表
        this.$http.get('/v1/sale/get-sale-selectlist-recommend')
            .then(response =>
            {
                if(response.data.status === 200) {
                    this.LandInfor = response.data.data;
                    this.loaded=true;
                }
                else {
                    this.$toast.fail(response.data.message)
                }
            }
        ).catch(error => {
            console.log(error)
        })
        // TODO 添加一个监听滚动条位置的事件处理函数
        // this.$refs.landMarket.addEventListener('scroll', this.handleScroll)
    },
    data() {
        return {
            loaded:false,
            screenWidth: 400,
            selectPage: "1", // 页键
            hasMoreData: true, // 是否还有更多数据可加载
            pageNum: 1, // 页码
            flag: true, // 防抖
            images: [
                require("../images/propagandizeImage1.jpg"),
                require("../images/propagandizeImage2.jpg"),
                require("../images/propagandizeImage3.jpg"),
                require("../images/propagandizeImage4.jpg"),
                /* 'https://img01.yzcdn.cn/vant/apple-1.jpg',
                'https://img01.yzcdn.cn/vant/apple-2.jpg', */
            ],
            LandInfor: [],
        };
    },
    methods: {
        handleScroll() {
            let scrollHeight = this.$refs.landMarket.scrollHeight;
            let scrollTop = this.$refs.landMarket.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight;

            if (scrollHeight - scrollTop - clientHeight <= 0 && this.hasMoreData && this.flag) {
                this.flag = false;
                let timer = setTimeout(() => {
                    // 等待 300 毫秒后才能再触发
                    this.flag = true;
                    clearInterval(timer);
                }, 300);
                // 当滚动到底部并且还有更多数据可加载时
                this.loadMoreData();
            }
        },
        loadMoreData() {
            // 请求后端获取更多数据的逻辑
            // 在获取数据后将新数据添加到 LandInfor 数组中
            // 如果没有更多数据可加载，将 hasMoreData 设置为 false
            // 例如：使用 axios 发送异步请求
            this.$http
                .get("/v1/sale/get-saleselect-list?pageNum=" + this.pageNum + "&pageSize=" + "10")
                .then((response) => {
                    let newData = response.data.data.rows;
                    this.LandInfor = this.LandInfor.concat(newData);
                    this.pageNum += 1;
                    if (newData.length === 0) {
                        this.hasMoreData = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
.mobile-homepage-home {
    height: 100%;
    width: 100%;
    overflow: auto;
}
.mobile-homepage-swipe .van-swipe-item {
    height: 80%;
    width: 80%;
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
}
.mobile-homepage-land {
    padding: 10px;
    display: flex;
    flex-direction: row;
}
</style>
