<template>
    <div class="main-container2">
        <div class="tabs">
            <div class="tabs-head">
                <div class="tabs-head-item" :class="isTab('1')" @click="tabsControl('1')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">全部</span>
                </div>
                <div class="tabs-head-item" :class="isTab('2')" @click="tabsControl('2')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">拍卖</span>
                </div>
                <div class="tabs-head-item" :class="isTab('3')" @click="tabsControl('3')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">立即购买</span>
                </div>
            </div>
            <!-- <div class="search-container">
                <div class="search-box">
                    <div class="search-input">
                        <span class="search-input-label">商品名称</span>
                        <el-input v-model="search.landname" placeholder="请输入挂牌土地名称"></el-input>
                    </div>
                    <div class="search-input">
                        <span class="search-input-label">卖家昵称</span>
                        <el-input v-model="search.seller" placeholder="请输入卖家昵称"></el-input>
                    </div>
                    <div class="search-input">
                        <span class="search-input-label">土地位置</span>
                        <el-input v-model="search.id" placeholder="请输入位置名称"></el-input>
                    </div>
                </div>
                <div class="search-box">
                    <div class="search-input">
                        <span class="search-input-label">挂牌时间</span>
                        <el-date-picker
                            v-model="search.date"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                    <div class="search-input">
                        <span class="search-input-label">价格区间</span>
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="search-box">
                    <div class="search-input">
                        <el-button @click="searchProduct">查询</el-button>
                        <el-button style="margin-left: 20px;" @click="resetProduct">重置</el-button>
                    </div>
                </div>
            </div> -->
            <div class="tabs-content" v-if="whichtab==='1'">
                <div style="height: 80%;">
                    <div  v-for="(land, index) in apply" :key="index" @click="turnToDetail(index)" @mouseover="changeCursor">
                        <LandMarketWindows :LandInfor="land" ref="MobileLand" :index="index" />
                    </div>
                    <el-pagination
                        background
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="apply.length">
                    </el-pagination>
                </div>
            </div>
            <div class="tabs-content" v-if="whichtab==='2'"></div>
            <div class="tabs-content" v-if="whichtab==='3'">
                <div style="height: 80%;">
                    <el-row :gutter="15" class="tabs-content-row">
                        <el-col :span="5"><i>所属原始土地编号</i></el-col>
                        <el-col :span="5"><i>土地名称</i></el-col>
                        <el-col :span="3"><i>卖家</i></el-col>
                        <el-col :span="2"><i>当前单位土地价格</i></el-col>
                        <el-col :span="5"><i>详细地址</i></el-col>
                        <el-col :span="2"><i>挂牌方式</i></el-col>
                        <el-col :span="2"><i>操作</i></el-col>
                    </el-row>
                    <el-row :gutter="15" v-for="(apply, index) in apply" :key="index" class="tabs-content-row">
                        <div class="tabs-content-col">
                            <el-col :span="5"><i>{{ apply.originallandid }}</i></el-col>
                            <el-col :span="5"><i>{{ apply.mergelandname }}</i></el-col>
                            <el-col :span="3"><i>{{ apply.seller }}</i></el-col>
                            <el-col :span="2"><i>{{ apply.pricenum }}</i></el-col>
                            <el-col :span="5"><i>{{ '未完成' }}</i></el-col>
                            <el-col :span="2"><i>{{ apply.salesmethods }}</i></el-col>
                            <el-col :span="2"><i @mouseover="changeCursor" style="text-decoration: underline;" @click="landReview(apply.originallandid,apply.unitlandid)">查看详情</i></el-col>
                        </div>
                    </el-row>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="apply.length">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LandMarketWindows from "@/landshop/LandShopList";
export default {
    components: {
        LandMarketWindows, // 土地市场组件
    },
    /* mounted() { // 初始化
        this.$http.get('/v1/sale/get-saleselect-list')
            .then(res => {
                // 处理返回值
                this.apply = res.data.data
            })
            .catch(error => {
                // 处理错误
                alert('服务器异常！错误代码：' + error.data)
            });
    }, */
    data() {
      return {
        whichtab: '1', // 选定哪一个页面，这里有三个页面
        currentPage: 1, // 土地市场页码
        pageSize: 10, // 每页有多少条数据
        productNum: 0, // 商品总量，初始化时根据后端获取

        search: {
            landname: '',
            seller: '',
        },
        options: [{
          value: '0-1000',
          label: '小于1k'
        }, {
          value: '1000-5000',
          label: '1k-5k'
        }, {
          value: '5000-10000',
          label: '5k-10k'
        }, {
          value: '10000-99999',
          label: '10k以上'
        }],
        value: '', // 价格区间放不进搜索列表里，只能移出来了
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },

        apply: [{
            originallandid: 'TD012023042096969',
            landname: "合并测试土地1号",
            unitlandid: '60', // 用于确定该土地块的关键词
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },{
            originallandid: 'TD012023042096969',
            unitlandid: '60', // 用于确定该土地块的关键词
            landname: '合并测试土地1号',
            seller: 'Mr.Wang',
            pricenum: '1000', // 单位土地售价
            salesmethods: '立即购买',
            originalLand: {
                landpicture: '',
                geographicalcoordinates: '青岛市',
                originallandname: '盆地',
            },
        },],
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'tabs-head-item-select'
            } else {
                return
            }
        },
        updatePage() { // 更新显示的数据
            this.$http
                .get("/v1/sale/get-saleselect-list?pageNum=" + this.currentPage + "&pageSize=" + this.pageSize)
                .then((response) => {
                    let newData = response.data.data.rows;
                    this.LandInfor = newData;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        pageSizeChange(val) {
            // 更新页距
            this.pageSize = val
            // 更新显示的数据
            this.updatePage()
        },
        pageCurrentChange(val) {
            // 更新页码
            this.currentPage = val
            // 更新显示的数据
            this.updatePage()
        },
        searchProduct() {},
        resetProduct() {},
        turnToDetail(index) {
            const newpath = "/pc/land-market/detail?land=" + this.apply[index].originallandid + "&merch=" + this.apply[index].unitlandid;
            this.$router.push({
                path: newpath,
            });
        },
    },
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container2 {
    background-color: black;
    padding: 50px;
}
.tabs {
    width: 100%;
}



/* --------------------------------------------选项卡样式-------------------------------------------- */
.tabs-head {
    width: 100%;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
}
.tabs-head-item {
    display: inline-block;
    height: 50px;
    width: 100px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 14px;
    color: #fff;
    position: relative;
}
.tabs-head-item-select {
    padding: 0;
    margin: 0;
    border-bottom: none;
    background-color: #383838;
    color: aqua;
    font-size: 16px;
}
.tabs-head-item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}


/* --------------------------------------------搜索栏样式-------------------------------------------- */
.search-container {
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    color: #fff;
}
.search-box {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}
.search-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.search-input-label {
    width: 100px;
}



/* --------------------------------------------土地列表样式-------------------------------------------- */
.tabs-content {
    padding: 20px;
    padding-top: 0;
}
/* 信息栏样式 */
.tabs-content-row {
    padding: 10px;
    text-align: center;
    color: #fff;
}
.tabs-content-col {
    height: 50px;
    /* 采用`flex`布局，则可以通过设置`justify-content`和`align-items`属性来实现水平和垂直居中 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    background-color: #a7a5a5;
    border: 2px solid #a7a5a5;
    border-radius: 6px;
}
</style>