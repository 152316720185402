<template>
    <div>
        <van-nav-bar class="bg-gray-50" :title="$t('MobileRecharge.title')" :left-text="$t('MobileRecharge.back')" left-arrow @click-left="onBack" />
        <div class="flex flex-col items-center">
            <span class="text-md text-gray-500">{{$t('MobileRecharge.balance')}}</span>
            <span class="text-3xl mt-3">{{ balance + "币" }}</span>
        </div>
        <div class="my-4 px-8 mt-8">
            <hr class="border-t-2 border-gray-200" />
        </div>
        <div class="mx-5">
            <span class="text-lg">{{$t('MobileRecharge.recharge')}}</span>
            <div class="grid grid-cols-3 gap-2 text-lg">
                <div
                    v-for="number in numberList"
                    :key="number"
                    @click="onNumberSelect(number)"
                    class="bg-gray-100 flex justify-center items-center text-center text-lg rounded-lg w-30 h-20"
                    :class="selectedNumber === number ? 'bg-blue-200 border-2 border-blue-300' : ''"
                >
                    {{ number }}
                </div>
            </div>
            <div v-if="customNumber != null" class="flex justify-center mt-2 mx-8">
                <div class="flex items-center">
                    <span class="text-md w-full text-center">{{$t('MobileRecharge.customize')}}:</span>
                    <input type="number" class="w-full rounded-lg bg-gray-100 py-3 px-8 border-solid border-1 border-current" v-model="selectedNumber" :placeholder="$t('MobileRecharge.sum')" />
                </div>
            </div>
        </div>
        <div class="my-4 px-8 mt-8">
            <hr class="border-t-2 border-gray-200" />
        </div>
        <div>
            <span class="px-8 text-lg">{{$t('MobileRecharge.method')}}</span>
            <div class="grid grid-cols-3 gap-2 px-8 text-lg">
                <div class="flex justify-center items-center text-center text-lg rounded-lg w-30 h-20">
                    <img src="../images/alipay.png" alt="" class="w-9 h-9 mr-1" />
                    {{$t('MobileRecharge.alipay')}}
                </div>
                <div class="flex justify-center items-center text-center text-lg rounded-lg w-30 h-20"><img src="../images/wechat.png" alt="" class="w-10 h-10 mr-1" />{{$t('MobileRecharge.wechat')}}</div>
                <div class="flex justify-center items-center text-center text-lg rounded-lg w-30 h-20"><img src="../images/paypal.png" alt="" class="w-10 h-10 mr-1" />{{$t('MobileRecharge.paypal')}}</div>
            </div>

            <!-- TODO 注释掉了支付宝和微信支付 -->
            <van-radio-group class="grid grid-cols-3 gap-2 px-8 text-lg" v-model="payWay" direction="horizontal">
                <van-radio class="flex justify-center items-center" name="aliPay" disabled></van-radio>
                <van-radio class="flex justify-center items-center" name="wechat" disabled />
                <van-radio class="flex justify-center items-center" name="paypal" />
            </van-radio-group>

            <div @click="onSubmit" class="rounded-lg shadow-md hover:bg-blue-600 bg-blue-500 px-8 pt-2 pb-2 mx-6 mt-8 flex justify-center">
                <span class="text-lg self-center text-white">{{$t('MobileRecharge.button')}}</span>
            </div>
        </div>
<!--        支付表单-->
        <div>
            <div v-html="paymentForm"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MobileRecharge",
    data() {
        return {
            balance: 0.0,
            numberList: [10, 20, 50, 100, 200],
            customNumber: null,
            selectedNumber: 10,
            payWay: "paypal",
            paymentForm: "",
        };
    },
    created() {
        this.numberList.push(this.$t('MobileRecharge.customize'))
        this.$http.get("/v1/recharge/check-balance")
            .then((res) => {
                console.log(res);
                this.balance = res.data.data.balance;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    methods: {
        onBack() {
            this.$router.go(-1);
        },
        onNumberSelect(number) {
            this.selectedNumber = number;
            if (number === this.$t('MobileRecharge.customize')) {
                this.customNumber = 0;
            } else {
                this.customNumber = null;
            }
        },
        /** 提交订单*/
        onSubmit() {
            console.log("this.selectedNumber");
            // this.submitLoading = true;
            const data = {
                "balance": this.selectedNumber===this.$t('MobileRecharge.customize')?this.customNumber:this.selectedNumber,
            };
            if(data.balance <= 0){
                this.$toast(this.$t('MobileRecharge.alertNoRecharge'));
                return;
            }
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: this.$t('MobileRecharge.alertGoPay'),
                forbidClick: true,
            });
            if (this.payWay === "aliPay") {
                this.$http.post("/v1/recharge/alipay-add", data,{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((res) => {
                    this.paymentForm = res.data.html;
                    //todo 保存订单号后提示用户支付成功并做后续操作
                    // 等待1秒
                    setTimeout(() => {
                        this.submitLoading = false;
                        document.querySelector('form[name="punchout_form"]').submit();
                        toastLoading.clear();
                    }, 500);
                    // document.querySelector('form[name="punchout_form"]').submit();
                });
            }
            else if (this.payWay === "wechat") {
                // this.$toast("微信支付");
            }
            else if (this.payWay==='paypal'){ // TODO paypal充值
                // this.$toast("PayPal支付");
                /* this.$http.post("/v1/recharge/paypal-add", data).then((res) => {
                    //访问paylink
                    window.location.href = res.data.html;
                    //todo 保存订单号后提示用户支付成功并做后续操作
                    this.orderid = res.data.orderid;
                    toastLoading.clear();
                }); */
                toastLoading.clear();
            }
        },
    },
};
</script>

<style scoped></style>
