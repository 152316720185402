<template>
    <div class="mobile-service-home">
        <van-nav-bar class="bg-gray-50" :title="$t('MobileService.title')" :left-text="$t('MobileService.back')" left-arrow @click-left="goBack" />

        <div class="flex justify-center items-center mx-3 bg-gray-100 mt-3">
            <div class="max-w-3xl mx-auto px-4 py-4 bg-white rounded-lg shadow-md">
                <div class="flex items-center">
                    <div class="flex-none mr-4">
                        <img src="../images/service.png" class="w-16 h-16" alt="Customer Service Icon" />
                    </div>
                    <div>
                        <h2 class="text-lg font-bold mb-2">{{$t('MobileService.normal')}}</h2>
                        <p class="text-gray-600 text-sm">{{$t('MobileService.introduce')}}</p>
                    </div>
                </div>
                <div class="mt-8">
                    <h3 class="text-lg font-bold mb-4">{{$t('MobileService.list')}}</h3>
                    <ul class="list-inside">
                        <li class="mb-2" v-for="(question, index) in questions" :key="index">
                            <h4 class="font-bold">· {{ question.title }}</h4>
                            <p v-for="(answer, index) in question.answer" :key="index">{{ answer }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="rounded-lg shadow-md hover:bg-gray-200 bg-white px-4 pt-4 pb-3 mx-3 mt-2 flex" @click="sendEmail">
            <img class="w-5 h-5 place-self-center mr-1" src="../images/question.png" />
            <span class="flex-grow text-lg items-center">{{$t('MobileService.title')}}</span>
            <img src="../images/right.png" class="flex-none w-5 h-5 place-self-center" />
        </div>
        <div class="rounded-lg shadow-md hover:bg-gray-200 bg-white px-4 pt-4 pb-3 mx-3 mt-2 flex mb-20" @click="help">
            <img class="w-5 h-5 place-self-center mr-1" src="../images/search.png" />
            <span class="flex-grow text-lg items-center">{{$t('MobileService.help')}}</span>
            <img src="../images/right.png" class="flex-none w-5 h-5 place-self-center" />
        </div>
    </div>
</template>

<script>
export default {
    created() {
        // 不要忘了获取传输过来的土地信息
        this.selectPage = this.$route.query.index;
    },
    data() {
        return {
            selectPage: null, // 页码
            questions: [
                {
                    title: "问题1:宣传轮播图无法显示或无法正常切换",
                    answer: ["●在手机浏览器中，尝试清除缓存并重新加载页面，以确保最新的资源和图片可以正确加载。","●如果问题仍然存在，可以尝试使用其他浏览器访问网站。"],
                },
                {
                    title: "问题2:加载速度缓慢或页面无响应",
                    answer: ["●在手机浏览器设置中清除缓存，然后重新加载页面。","●尽量使用稳定且高速的 Wi-Fi 网络，或切换到更好的网络信号。"],
                },
                // 其他常见问题
            ],
        };
    },
    methods: {
        sendEmail() {
            this.$router.push({
                path: "/mobile/service/send?index=" + this.selectPage,
            });
        },
        help() {
            this.$router.push({
                path: "/mobile/help",
            });
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
.mobile-service-home {
    height: 100%;
    width: 100%;
    background-color: #f9fafb;
    position: relative;
    overflow: auto;
}
</style>
