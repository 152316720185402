<template>
    <div class="first-main-container">
        <!-- 左上角返回 -->
        <van-nav-bar class="bg-gray-50" :title="topicTitle" :left-text="$t('MobileAssociatedSet.back')" left-arrow @click-left="goBack" />
        <!-- 设置邮箱账号 -->
        <div class="mobile-box-container" v-if="setType === 'email'">
            <div class="mobile-box-input-parent">
                <div class="mobile-box-input-labal"><span>{{$t('MobileAssociatedSet.email')}}:</span></div>
                <input type="email" v-model="email" class="mobile-box-input" :placeholder="$t('MobileAssociatedSet.emailInput')" />
            </div>
            <div class="mobile-box-input-parent">
                <div class="mobile-box-input-labal"><span>{{$t('MobileAssociatedSet.verify')}}:</span></div>
                <input type="text" v-model="code" :placeholder="$t('MobileAssociatedSet.codeInput')" class="mobile-box-input" />
                <span @click="getEmailCode" v-if="isCode" class="mobile-box-code-button">{{$t('MobileAssociatedSet.getCode')}}</span>
                <span v-if="!isCode" disabled class="mobile-box-code-button">{{$t('MobileAssociatedSet.resendCode')}}{{ time }}s</span>
            </div>
            <div style="width: 80%; margin-bottom: 10px">
                <button class="mobile-box-button" @click="AddEmailAssociated">{{$t('MobileAssociatedSet.confirm')}}</button>
                <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
                <div class="modal" v-if="showError" @click="closeModal">
                    <div class="modal-box">
                        <div>{{ errorText }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 设置手机号码 -->
        <div class="mobile-box-container" v-else-if="setType === 'phone'">
            <div class="mobile-box-input-parent">
                <div class="mobile-box-input-labal"><span>{{$t('MobileAssociatedSet.phone')}}:</span></div>
                <input type="tel" v-model="phone" class="mobile-box-input" :placeholder="$t('MobileAssociatedSet.phoneInput')" />
            </div>
            <div class="mobile-box-input-parent">
                <div class="mobile-box-input-labal"><span>{{$t('MobileAssociatedSet.verify')}}:</span></div>
                <input type="text" v-model="code" placeholder="请输入验证码" class="mobile-box-input" />
                <span @click="getPhoneCode" v-if="isCode" class="mobile-box-code-button">{{$t('MobileAssociatedSet.getCode')}}</span>
                <span v-if="!isCode" disabled class="mobile-box-code-button">{{$t('MobileAssociatedSet.resendCode')}}{{ time }}s</span>
            </div>
            <div style="width: 80%; margin-bottom: 10px">
                <button class="mobile-box-button" @click="AddPhoneAssociated">{{$t('MobileAssociatedSet.confirm')}}</button>
                <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
                <div class="modal" v-if="showError" @click="closeModal">
                    <div class="modal-box">
                        <div>{{ errorText }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 应该不会出现的情景 -->
        <div class="mobile-box-container" v-else>
            <div class="mobile-box-topic">页面出错</div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.setType = this.$route.query.type;
        if (this.setType === "email") {
            this.topicTitle = this.$t('MobileAssociatedSet.bundEmail');
        } else if (this.setType === "phone") {
            this.topicTitle = this.$t('MobileAssociatedSet.bundPhone');
        }
    },
    data() {
        return {
            topicTitle: "添加关联", // 标题名称,根据是邮箱还是手机号修改名称
            setType: "", // 本次添加关联的类型，分为邮箱email和手机号phone
            email: "", // 邮箱
            phone: "", // 手机号
            code: "", // 验证码
            time: 0, // 发送验证码后重新发送需要等待60s
            isCode: true,
            timer: null, // 计时器

            showError: false, // 显示弹窗
            errorText: "", // 弹窗文本
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        closeModal() {
            this.showError = false;
        },
        checkPassword() {
            // 点击密码输入框后检查密码格式是否错误
            const re = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/;
            this.showRules = !re.test(this.changePassword);
        },
        setMyInterval() {
            // 设置计时器
            this.time = 60;
            // 计时器
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    this.isCode = true;
                    clearInterval(this.timer);
                }
            }, 1000);
            this.isCode = false;
        },

        getEmailCode() {
            // 获取邮箱验证码
            if (!this.email.includes("@")) {
                this.errorText = this.$t('MobileAssociatedSet.alertEmailError');
                this.showError = true;
                return;
            }
            this.setMyInterval();
            this.errorText = this.$t('MobileAssociatedSet.alertLoading');
            this.showError = true;
            this.$http
                .post("/v1/index/get-verificationcode-topassword?useremail=" + this.email)
                .then((res) => {
                    this.errorText = res.data.data;
                    this.showError = true;
                })
                .catch((err) => {
                    this.errorText = this.$t('MobileAssociatedSet.alertAxiosError');
                    this.showError = true;
                });
        },
        getPhoneCode() {
            // 获取手机验证码
            if (!this.phone.test(/^[0-9]*$/)) {
                this.errorText = this.$t('MobileAssociatedSet.alertPhoneError');
                this.showError = true;
            }
            this.setMyInterval();
            this.errorText = this.$t('MobileAssociatedSet.alertLoading');
            this.showError = true;
            this.$http
                .post("/v1/register/phone-verificationcode?userphone=" + this.phone)
                .then((res) => {
                    this.errorText = res.data.data;
                    this.showError = true;
                })
                .catch((err) => {
                    this.errorText = this.$t('MobileAssociatedSet.alertAxiosError');
                    this.showError = true;
                });
        },

        AddEmailAssociated() {
            if (!this.email.includes("@")) {
                this.errorText = this.$t('MobileAssociatedSet.alertEmailError');
                this.showError = true;
                return;
            }
            if (this.code === "") {
                this.errorText = this.$t('MobileAssociatedSet.alertNoCodeInput');
                this.showError = true;
                return;
            }
            this.errorText = this.$t('MobileAssociatedSet.alertLoading');
            this.showError = true;
            this.$http
                .post("/v1/register/association?contact=" + this.email + "&code=" + this.code)
                .then((res) => {
                    this.errorText = res.data.data;
                    this.showError = true;
                })
                .catch((err) => {
                    this.errorText = this.$t('MobileAssociatedSet.alertAxiosError');
                    this.showError = true;
                });
        },
        AddPhoneAssociated() {
            if (!this.phone.test(/^[0-9]*$/)) {
                this.errorText = this.$t('MobileAssociatedSet.alertPhoneError');
                this.showError = true;
            }
            if (this.code === "") {
                this.errorText = this.$t('MobileAssociatedSet.alertNoCodeInput');
                this.showError = true;
                return;
            }
            this.errorText = this.$t('MobileAssociatedSet.alertLoading');
            this.showError = true;
            this.$http
                .post("/v1/register/association?contact=" + this.phone + "&code=" + this.code)
                .then((res) => {
                    this.errorText = res.data.data;
                    this.showError = true;
                })
                .catch((err) => {
                    this.errorText = this.$t('MobileAssociatedSet.alertAxiosError');
                    this.showError = true;
                });
        },
    },
};
</script>

<style scoped>
.first-main-container {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    height: 100%; /* 容器高度为屏幕高度 */
}
.mobile-box-input-parent {
    /* 输入框样式 */
    width: 80%;
    display: flex;
    flex-direction: row;
    position: relative;
}
.mobile-box-input-labal {
    /* 输入框前文字信息样式 */
    width: 70px;
    display: flex;
    align-items: center; /* 水平居中 */
}
.mobile-box-input {
    /* 输入框样式 */
    height: 50px;
    width: 70%;
    font-size: 14px;
    color: #dcdcdc;
    border: none;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 10px;
}
.mobile-box-code-button {
    /* 获取验证码按钮 */
    height: 40px;
    width: 100px;
    border: none;
    border-left: 1px solid #dcdcdc;
    display: flex;
    align-items: center; /* 水平居中 */
    justify-content: center;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 5px;
}
.mobile-box-container {
    /* 手机端登陆页面 */
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    flex-direction: column;
}
.mobile-box-button {
    /* 按钮样式 */
    height: 50px;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(to bottom right, #5a4de2, #274dba);
    border-radius: 30px;
    box-shadow: 0 2px 12px 0 rgb(255, 255, 255);
    margin-top: 10px;
    margin-bottom: 20px;
}
/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    height: 150px;
    width: 300px;
    padding: 20px;
    background-color: #a7a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
</style>
