<template>
  <div class="email-verification">
    <div>
      <div style="text-align: center;">
        <h1>{{$t('EmailVerification.title')}}</h1>
      </div>
      <div v-if="isLoading">{{$t('EmailVerification.loading')}}...</div>
      <div v-else>
        <div v-if="verificationSuccess">{{$t('EmailVerification.success1')}}{{ TimeCount }}{{$t('EmailVerification.success2')}}</div>
        <div style="text-align: center;"><el-button @click="Login">{{$t('EmailVerification.login')}}</el-button></div>
        <div v-if="verificationError">{{$t('EmailVerification.fail')}}:{{ verificationError }} {{ token }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isMobile: false,
      email: null,
      remark: null,
      isLoading: true,
      verificationSuccess: false,
      verificationError: false,
      TimeCount: 0,
      timer: null,
    };
  },

  methods: {
    Login() {
      this.TimeCount = 0
    }
  },
  created() {
    this.email = this.$route.query.useremail;
    this.remark = this.$route.query.remark;
  },
  mounted() {
      this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        // http://localhost:8081/verify-email?VerificationCode=qx6W5AN7g92d0y1mWdQzqpRLTym6Fu4ATwYcIC8X
      /* const params = new URLSearchParams(window.location.search);
      const email = params.get('useremail');
      const remark = params.get('remark'); */
          // 向后端发送请求，这里要将邮箱、验证码一起发送过去
          this.$http.get('/v1/register/email-verificationcode-pc?useremail=' + this.email + '&remark=' + this.remark).then(response => {
            // 处理返回值
            this.isLoading = false
            if (response.data.data==="用户验证成功") {
                this.verificationSuccess = true;
                // 设置倒计时60s
                this.TimeCount = 10;
                // 计时器
                this.timer = setInterval(() => {
                    if (this.TimeCount > 0) {
                        this.TimeCount--;
                    } else {
                        if(this.isMobile) {
                            this.$router.push('/mobile/home-page?index=1')
                      } else {
                        this.$router.push('/pc/home-page?')
                      }
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
          }).catch(error => {
            // 处理错误
            this.$toast.fail(this.$t('EmailVerification.error'));
          });
  },

  /* 生命周期结束时清除计时器 */
  destroyed() {
    if (this.code.timer) {
      clearInterval(this.code.timer);
      this.timer = null;
    }
  },
}
</script>

<style scoped>
.email-verification {
  /* 在这里添加样式 */
  height: 100%;
  /* 采用`flex`布局，则可以通过设置`justify-content`和`align-items`属性来实现水平和垂直居中 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
</style>