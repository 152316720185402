import { render, staticRenderFns } from "./UserManage.vue?vue&type=template&id=71ca416c&scoped=true&"
import script from "./UserManage.vue?vue&type=script&lang=js&"
export * from "./UserManage.vue?vue&type=script&lang=js&"
import style0 from "./UserManage.vue?vue&type=style&index=0&id=71ca416c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71ca416c",
  null
  
)

export default component.exports