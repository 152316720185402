<template>
    <div class="main-container2">
        <div class="tabs">
            <div class="tabs-head">
                <div class="tabs-head-item" :class="isTab('退款中')" @click="tabsControl('退款中')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">待审核</span>
                </div>
                <div class="tabs-head-item" :class="isTab('已驳回')" @click="tabsControl('已驳回')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">已驳回</span>
                </div>
            </div>
            <div class="tabs-content">
                <div v-if="!companyChild.isReview">
                    <el-row :gutter="15" class="tabs-content-row">
                        <el-col :span="4"><i>申请时间</i></el-col>
                        <el-col :span="4"><i>订单编号</i></el-col>
                        <el-col :span="4"><i>卖家id</i></el-col>
                        <el-col :span="4"><i>买家id</i></el-col>
                        <el-col :span="4"><i>退款金额</i></el-col>
                        <el-col :span="4"><i>操作</i></el-col>
                    </el-row>
                    <el-skeleton :loading="loading" :rows="10" animated />
                    <el-row :gutter="15" v-for="(apply, index) in showCompany" :key="index" class="tabs-content-row" v-show="!loading">
                        <div class="tabs-content-col">
                            <el-col :span="4"><i>{{ apply.refundtime }}</i></el-col>
                            <el-col :span="4"><i>{{ apply.separateOrderId }}</i></el-col>
                            <el-col :span="4"><i>{{ apply.sellerid }}</i></el-col>
                            <el-col :span="4"><i>{{ apply.buyerid }}</i></el-col>
                            <el-col :span="4"><i>{{ apply.totalprice }}</i></el-col>
                            <el-col :span="4"><i @mouseover="changeCursor" style="text-decoration: underline;" @click="landReview(apply)">审核</i></el-col>
                        </div>
                    </el-row>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
                <RefundCheck v-if="companyChild.isReview" :companyChild='companyChild' :refundInfor='refundInfor'></RefundCheck>
            </div>
        </div>
    </div>
</template>

<script>
import RefundCheck from "./RefundCheckDetail.vue";

export default {
    components: { // 管理员组件
        RefundCheck,  // 开发商身份认证组件
    },
    mounted() {
        this.$http.get('/v1/administrator/select-ordermanage-refund?status=' + this.companyChild.whichtab)
            .then(res => {
                this.refundChecking = res.data.data
                this.initializePage()
            }).catch(error => {
                // 处理错误
            });
    },
    data() {
      return {
        loading: true, // 控制骨架屏
        refundChecking: null, // 从后端接收的待审核退款申请
        refundReject: null, // 从后端接收的已驳回退款申请
        showCompany: null, // 显示的当页公司信息，根据页码页距控制
        showLength: 0, // 条数
        currentPage: 1, // 页码
        pageSize: 10, // 每页条数


        companyChild: { // 父子组件传值
            whichtab: '退款中', // 两种状态
            isReview: false // 土地上传资格审查详情页是否点入
        },
        refundInfor: {}, // 子组件详情信息
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.companyChild.whichtab = n
            this.companyChild.isReview = false
            if (this.companyChild.whichtab === '已驳回' && !this.refundReject) { // 已驳回未向后端申请时先申请
                this.loading = true
                this.$http.get('/v1/administrator/select-ordermanage-refund?status=' + this.companyChild.whichtab)
                .then(res => {
                    this.refundReject = res.data.data
                    this.initializePage()
                }).catch(error => {
                    // 处理错误
                    console.error(error.data)
                });
            }
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.companyChild.whichtab) {
                return 'tabs-head-item-select'
            } else {
                return
            }
        },
        initializePage() { // 初始化
            this.currentPage = 1
            if (this.companyChild.whichtab === '退款中') {
                this.showLength = this.refundChecking.length
                this.showCompany = this.refundChecking.slice(0,this.pageSize - 1)
            } else if (this.companyChild.whichtab === '已驳回') {
                this.showLength = this.refundReject.length
                this.showCompany = this.refundReject.slice(0,this.pageSize - 1)
            }
            this.loading = false
        },
        pageSizeChange(val) {
            // 计算索引值
            const start = (this.currentPage - 1) * val
            const end = this.currentPage * val - 1
            // 更新显示的数据
            if (this.companyChild.whichtab === '退款中') {
                this.showCompany = this.refundChecking.slice(start,end)
            } else if (this.companyChild.whichtab === '已驳回') {
                this.showCompany = this.refundReject.slice(start,end)
            }
            // 更新页距
            this.pageSize = val
        },
        pageCurrentChange(val) {
            // 计算索引值
            const start = (val - 1) * this.pageSize
            const end = this.pageSize * val - 1
            // 更新显示的数据
            if (this.companyChild.whichtab === '退款中') {
                this.showCompany = this.refundChecking.slice(start,end)
            } else if (this.companyChild.whichtab === '已驳回') {
                this.showCompany = this.refundReject.slice(start,end)
            }
            // 更新页码
            this.currentPage = val
        },
        landReview(apply) {
            this.refundInfor = apply
            this.companyChild.isReview = true
        },
    },
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container2 {
    height: 100%;
    background-color: black;
    padding: 50px;
}
.tabs {
    height: 100%;
    width: 100%;
}



/* --------------------------------------------选项卡样式-------------------------------------------- */
.tabs-head {
    width: 100%;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
    /* 在父块加上position: relative;并在子块加上position: absolute;来控制子块在父块内的位置 */
    position: relative;
}
.tabs-head-item {
    display: inline-block;
    height: 50px;
    width: 100px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 14px;
    color: #fff;
    position: relative;
}
.tabs-head-item-select {
    padding: 0;
    margin: 0;
    border-bottom: none;
    background-color: #383838;
    color: aqua;
    font-size: 16px;
}
.tabs-head-item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
/* 新增企业按钮 */
.tabs-head-add {
    display: inline-block;
    position: absolute;
    right: 0;
}
.tabs-head-add-button {
    height: 50px;
    width: 100px;
    border: 1px solid #383838;
    border-radius: 6px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}



/* --------------------------------------------管理页面样式-------------------------------------------- */
/* -------------------------------土地上传管理页面------------------------------- */
.tabs-content {
    padding: 20px;
}
/* 信息栏样式 */
.tabs-content-row {
    padding: 10px;
    text-align: center;
    color: #fff;
}
.tabs-content-col {
    height: 50px;
    /* 采用`flex`布局，则可以通过设置`justify-content`和`align-items`属性来实现水平和垂直居中 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    background-color: #a7a5a5;
    border: 2px solid #a7a5a5;
    border-radius: 6px;
}
</style>