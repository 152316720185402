<template>
    <div class="main-container3">
        <div @click="goBack" class="goback-parent">
            <img src="../icons/左箭头.png" alt="返回图标" class="goback-img">
            <span @mouseover="changeCursor">返回</span>
        </div>
        <div class="upload-form-parent">
            <div class="upload-form-topic"><span>订单详情</span></div>
            <div class="upload-form">
                <div class="upload-form-inn">
                    <div style="font-size: 20px;margin-bottom: 20px;">订单信息</div>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">订单编号:</span>
                        <span>{{ orderInfor.separateOrderId }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">交易时间:</span>
                        <span>{{ orderInfor.ordertime }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">交易金额:</span>
                        <span>{{ orderInfor.totalprice }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">卖家编号:</span>
                        <span>{{ orderInfor.sellerid }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">买家编号:</span>
                        <span>{{ orderInfor.buyerid }}</span>
                    </el-row>
                    <el-divider v-if="orderInfor.refundtime"><span>退款详情</span></el-divider>
                    <el-row :gutter="15" class="upload-form-item" v-if="orderInfor.refundtime">
                        <span class="upload-form-item-label">退款金额:</span>
                        <span>{{ orderInfor.totalprice }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item" v-if="orderInfor.refundtime">
                        <span class="upload-form-item-label">退款原因:</span>
                        <span>{{ orderInfor.refundreason }}</span>
                    </el-row>
                </div>
                <div class="upload-form-inn" style="margin-top: 20px;">
                    <div style="font-size: 20px;margin-bottom: 20px;">原始土地信息</div>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">原始土地编号:</span>
                        <span>{{ orderInfor.originalLand.originallandid }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">土地名称:</span>
                        <span>{{ orderInfor.originalLand.originallandname }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">地址:</span>
                        <span>{{ orderInfor.originalLand.detailedaddress }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">开发商编号:</span>
                        <span>{{ orderInfor.originalLand.ownerid }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">创建时间:</span>
                        <span>{{ orderInfor.originalLand.createdate }}</span>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.orderid = this.$route.query.id;
    },
    mounted() {
        this.$http.get('/v1/administrator/select-ordermanage-byid?id=' + this.orderid)
            .then(res => {
                this.orderInfor = res.data.data
            })
            .catch(error => {
                // 处理错误
                console.error('服务器异常！错误代码：' + error)
            });
    },
    data() {
        return {
            orderid: null, // 订单编号，从网址获取
            orderInfor: { // 范例数据
                "id": 140,
                "separateOrderId": "",
                "ordertime": "",
                "unitlandid": "",
                "landid": "",
                "landname": "",
                "salemeans": "",
                "totalprice": "",
                "buyerid": "",
                "sellerid": "",
                "addressid": "",
                "needpaper": true,
                "orderstatus": 1,
                "processstatus": null,
                "refundreason": null,
                "isdelete": null,
                "refundtime": null,
                "originalLand": {
                    "id":1,
                    "originallandid":"",
                    "ownershipnumber":"",
                    "originallandname":"",
                    "detailedaddress":"",
                    "landcertificate":"",
                    "warrantydeed":null,
                    "ownerid":"",
                    "entrance":"",
                    "geographicalcoordinates":"",
                    "landlength":"",
                    "landwidth":"",
                    "height":"",
                    "digitalright":"",
                    "surfaceright":"",
                    "landpicture":"",
                    "createdate":"",
                    "landstatus":""
                }
            }, // 订单数据，从后端获取
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        /* 返回按钮事件 */
        goBack() {
            this.companyChild.isReview = false
        },
    },
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container3 {
    height: 100%;
    overflow: auto;
}

/* -----------------------------------------返回图标------------------------------------------ */
.goback-parent {
    display: flex;
    align-items: center; /* 垂直居中 */
    font-size: 20px;
    margin-left: 20px;
    margin-top: 20px;
}
.goback-img {
    height: 20.67px;
    width: 40px;
}


/* -----------------------------------------上传公司负责人信息填写表单------------------------------------------ */
.upload-form-parent {
    /* 父 `div` 容器设置为 `flex` 容器。然后，将子 `div` 容器设置为 `flex-grow: 1;`，将其自动填充父容器的剩余空间 */
    display: flex;
    flex-direction: column;
    padding: 20px;
}
/* 表的标题 */
.upload-form-topic {
    font-size: 20px;
    text-align: center;
    padding: 20px;
}
/* 表单层-更换颜色 */
.upload-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #bebebe;
    padding: 20px;
    border-radius: 6px;
    white-space: nowrap;
}
.upload-form-inn {
    padding: 20px;
    border-radius: 6px;
    border: 2px solid #000000;
}
/* 表单元素 */
.upload-form-item {
    width: 200px;
    padding: 10px;
    /* 父组件加入后让子组件平齐 */
    white-space: nowrap;
    word-wrap: break-word; /* 当单词太长时，允许在单词内部进行换行 */
}
/* ------------------------------上传文本样式------------------------------- */
/* 表单元素-标签 */
.upload-form-item-label {
    width: 150px;
    display: inline-block;
    font-size: 16px;
    color: #fff;
    /* 子组件加入后让同行元素平行 */
    vertical-align: top;
}
</style>