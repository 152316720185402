import Vue from "vue";
import VueRouter from "vue-router";

// 登录页面
import HomePage from "../views/Login.vue";
// 手机端有了登陆前的首页，需要从首页进入注册/登陆页
import UserMobileLogin from "../views/MobileLogin.vue";
// 邮箱验证
import EmailVerification from "../components/EmailVerification.vue";
// 修改密码页面
import ForgetPassword from "../views/ForgetPassword.vue";
// 手机端修改密码页面
import MobileForgetPassword from "../views/MobileForgetPassword.vue";

// 管理员界面
import Admin from "../admin/Administrator.vue";
// 管理员处理用户上传土地申请页面
import AdminApply from "../admin/ApplyManage.vue";
// 管理员处理用户上传企业认证页面
import AdminCompany from "../admin/CompanyManage.vue";
// 超级管理员注册新企业用户
import AdminCompanyAdd from "../admin/CompanyAdd.vue";
// 管理员用户管理-封禁账户
import AdminUserManage from "../admin/UserManage.vue";
// 管理员挂牌管理
import AdminSellManage from "../admin/SellManage.vue";
// 管理员订单列表页面
import AdminOrderManage from "../admin/OrderManage.vue";
// 管理员退款审核列表页面
import AdminRefund from "../admin/RefundCheck.vue";
// 管理员订单详情页面
import AdminOrderDetail from "../admin/OrderDetail.vue";
// 管理员客服页面
import AdminChat from "../admin/ChatMain.vue";

// 开发商用户界面
import Home from "../companyUsers/UserHome.vue";
// 用户账户信息界面
import Account from "../companyUsers/UserAccount.vue";
// 企业用户第一次登录进行注册
import CompanyUserRegister from "../companyUsers/CompanyRegister.vue";
// 企业用户上传原始土地
import LandUpload from "../companyUsers/LandRegister.vue";
// 企业用户查看上传的原始土地凭证
import RealEstate from "../companyUsers/LandReview.vue";
// 企业用户查看公司信息
import CompanyInfor from "../companyUsers/MyCompany.vue";
// 企业用户查看挂牌土地信息
import SelleLandList from "../companyUsers/LandSellList.vue";
// 企业用户查看挂牌土地详情信息
import SelleLandDetail from "../companyUsers/LandSellDetail.vue";
// 企业用户查看系统消息
import CompanySystemMessage from "../companyUsers/SystemMessage.vue";
// 企业用户查看聊天消息
import CompanyChatMessage from "../companyUsers/ChatMessage.vue";
// 企业用户联系客服
import CompanyChatCustomer from "../companyUsers/ChatMain.vue";

// 普通用户页面
import userhome from "../normalUsers/UserMenu.vue";
// 普通用户信息页面
import UserInfor from "../normalUsers/UserAccount.vue";

// 普通用户手机端页面
import UserMobile from "../mobile/MobileHome.vue";
// 普通用户手机端页面主页
import UserMobileHome from "../mobile/HomePage.vue";
// 普通用户手机端页面土地市场
import UserMobileShop from "../mobile/MobileShop.vue";
// 普通用户手机端页面土地详情
import UserMobileDetail from "../mobile/MobileLandDetail.vue";
// 普通用户手机端页面卖家信息
import UserMobileSeller from "../mobile/SellerHome.vue";
// 普通用户手机端页面联系卖家发邮件
import MobileSendEmail from "../mobile/MobileEmailSend.vue";
// 普通用户手机端页面购物车
import UserMobileCart from "../mobile/ShopCart.vue";
// 普通用户手机端页面购物车结算MobileConfirmOrder
import MobileConfirmOrder from "../mobile/MobileConfirmOrder.vue";
// 普通用户手机端页面我的信息
import UserMobileInfor from "../mobile/Infor.vue";
// 普通用户手机端页面买家卖家联系信息列表
import MobileMessage from "../mobile/MobileMessage.vue";
// 普通用户手机端页面买家卖家联系信息详情
import MobileMessageWindow from "../mobile/MobileMessageWindow.vue";
// 普通用户手机端页面联系客服
import MobileService from "../mobile/MobileService.vue";
// 普通用户手机端页面联系客服反馈意见
import MobileServiceSend from "../mobile/MobileServiceQ.vue";
// 普通用户手机端页面联系客服查看消息
import MobileServiceSearch from "../mobile/MobileServiceA.vue";
// 普通用户手机端页面查看我的土地
import MobileMyLand from "../mobile/MobileMyLand.vue";
// 普通用户手机端页面查看购买的土地详情
import MobilePurchaseDetails from "@/mobile/MobilePurchaseDetails";
// 普通用户手机端页面修改信息
import MobileUserEditInfo from "../mobile/MobileUserEditInfo.vue";
// 普通用户手机端页面我的钱包
import MobileMyWallet from "@/mobile/MobileMyWallet";
// 普通用户手机端页面地址管理
import MobileAddressManagement from "@/mobile/MobileAddressManagement";
// 普通用户手机端充值页面
import MobileRecharge from "@/mobile/MobileRecharge";
// 普通用户手机端账号与安全页面
import MobileAccountAndSafe from "@/mobile/MobileSafe";
// 普通用户手机端修改密码页面
import MobileChangePassword from "@/mobile/MobileChangePassword";
// 普通用户手机端修改支付密码页面
import MobileChangePayPassword from "@/mobile/MobileChangePayPassword";
// 普通用户手机端关联账号页面
import MobileAssociatedAccount from "@/mobile/MobileAssociated";
// 普通用户手机端账号绑定页面
import MobileAssociatedSet from "@/mobile/MobileAssociatedSet";
// 普通用户手机端账号换绑页面
import MobileAssociatedReplace from "@/mobile/MobileAssociatedReplace";
// 普通用户手机端我的订单页面
import MobileOrderList from "@/mobile/MobileOrderList";
// 普通用户手机端我的订单详情页面
import MobileOrderDetail from "@/mobile/MobileOrderDetail";
// 普通用户手机端退款进度查看页面
import MobileRefundView from "@/mobile/MobileRefundDetail";
// 普通用户手机端使用帮助页面
import MobileHelp from "@/mobile/MobileHelp";


// 操作土地合并分割的基础页面
import Operation from "../landmap/LandOperate.vue";
// 原始土地界面
import LandMap from "../landmap/LandMap1.vue";
// 二号土地(1英亩)界面
import LandMap2 from "../landmap/LandMap2.vue";
// 三号土地(22英尺x22英尺)界面
import LandMap3 from "../landmap/LandMap3.vue";

// 普通用户土地市场页面
import LandMarket from "../landshop/LandShop.vue";
// 土地详情页面
import LandDetail from "../landshop/LandShopDetail.vue";

Vue.use(VueRouter);

const routes = [
    {
        // 输入网站时首先重定向到登录页面
        path: "/",
        name: "HomePage",
        component: HomePage,
    },
    {
        path: "/mobile/login",
        name: "mobileLogin",
        component: UserMobileLogin,
        // component: () => import('../views/Login.vue')
    },
    {
        // 邮箱注册验证码链接处理
        // 当用户访问 /email/login/?token=your-token 这样的 URL 时，Vue Router 会渲染 EmailVerification 组件并处理激活链接。
        path: "/email/login/",
        name: "EmailVerification",
        component: EmailVerification,
    },
    {
        // 登陆页面忘记密码功能
        path: "/login/forget-password",
        name: "ForgetPassword",
        component: ForgetPassword,
    },
    {
        // 登陆页面忘记密码手机端版
        path: "/mobile/login/forget-password",
        name: "MobileForgetPassword",
        component: MobileForgetPassword,
    },
    {
        // 管理员页面
        path: "/admin",
        name: "admin",
        component: Admin,
        /* 路由重定向主页地图页面，初始打开子页面 */
        redirect: "/admin/company",
        /* 主页子页面 */
        children: [
            { path: "/admin/apply", component: AdminApply } /* 管理员处理用户上传土地申请页面 */,
            { path: "/admin/company", component: AdminCompany } /* 管理员处理用户上传企业认证页面 */,
            { path: "/admin/company-register", component: AdminCompanyAdd } /* 超级管理员注册新企业用户 */,
            { path: "/admin/user-manage", component: AdminUserManage } /* 管理员处理用户账户封禁 */,
            { path: "/admin/sell-manage", component: AdminSellManage } /* 管理员挂牌管理 */,
            { path: "/admin/sell-manage/detail", component: LandDetail } /* 管理员挂牌管理查看挂牌详情页面 */,
            { path: "/admin/order-manage", component: AdminOrderManage } /* 管理员订单查询 */,
            { path: "/admin/refund", component: AdminRefund } /* 管理员退款审核 */,
            { path: "/admin/order-manage/detail", component: AdminOrderDetail } /* 管理员订单详情 */,
            { path: "/admin/chat", component: AdminChat } /* 管理员订单详情 */,
        ],
    },
    {
        // 企业用户第一次登录注册信息页面
        path: "/login/register",
        name: "CompanyUserRegister",
        component: CompanyUserRegister,
    },
    {
        // 开发商用户页面
        path: "/developer",
        name: "UserHome",
        component: Home,
        /* 路由重定向主页地图页面，初始打开子页面 */
        redirect: "/developer/home-page",
        /* 主页子页面 */
        children: [
            /* 用户账户信息界面 */
            { path: "/developer/home-page", component: Account },
            /* 企业用户上传原始土地 */
            { path: "/developer/land-upload", component: LandUpload },
            /* 企业用户查看上传的原始土地凭证 */
            { path: "/developer/land-view", component: RealEstate },
            /* 企业用户查看公司注册信息 */
            { path: "/developer/my-company", component: CompanyInfor },
            /* 企业用户查看挂牌土地 */
            { path: "/developer/my-sale", component: SelleLandList },
            /* 企业用户查看挂牌土地详情 */
            { path: "/developer/my-sale/detail", component: SelleLandDetail },
            /* 企业用户查看系统消息 */
            { path: "/developer/message/system", component: CompanySystemMessage },
            /* 企业用户查看系统消息 */
            { path: "/developer/message/chat", component: CompanyChatMessage },
            /* 企业用户联系客服 */
            { path: "/developer/message/service", component: CompanyChatCustomer },
        ],
    },
    {
        /* 用户合并分割土地页面 */
        path: "/land-operate",
        name: "LandOperate",
        component: Operation,
        /* 路由重定向主页地图页面，初始打开子页面 */
        redirect: "/land-operate/big-map",
        /* 主页子页面 */
        children: [
            /* 土地地图 */
            {
                path: "/land-operate/big-map",
                name: "Originland",
                component: LandMap,
                props: (route) => ({ landid: route.params.landid, landName: route.params.landName }),
            }, // 原始土地
            {
                path: "/LandOperate/secondland",
                name: "Secondland",
                component: LandMap2,
                props: (route) => ({ landid: route.params.landid, landName: route.params.landName, SecondLandCoordinates: route.params.SecondLandCoordinates }),
            }, // 二号土地
            {
                path: "/land-operate/area-map",
                name: "Thirdland",
                component: LandMap3,
                props: (route) => ({
                    landid: route.params.landid,
                    landName: route.params.landName,
                    SecondLandCoordinates: route.params.SecondLandCoordinates,
                    ThirdLandCoordinates: route.params.ThirdLandCoordinates,
                }),
            }, // 三号土地
        ],
    },
    {
        // 普通用户页面
        path: "/pc",
        name: "usermenu",
        component: userhome,
        /* 路由重定向主页地图页面，初始打开子页面 */
        redirect: "/pc/home-page",
        /* 主页子页面 */
        children: [
            /* 普通用户信息页面 */
            { path: "/pc/home-page", component: UserInfor },
            /* 普通用户土地市场页面 */
            {
                path: "/pc/land-market",
                component: LandMarket,
            },
            /* 土地详情 */
            {
                path: "/pc/land-market/detail",
                name: "LandDetail",
                component: LandDetail,
                props: (route) => ({ originallandid: route.params.originallandid, unitlandid: route.params.unitlandid }),
            },
        ],
    },
    {
        // 普通用户手机端页面
        path: "/mobile",
        name: "usermobile",
        component: UserMobile,
        /* 路由重定向主页地图页面，初始打开子页面 */
        redirect: "/mobile/home-page",
        /* 主页子页面 */
        children: [
            /* ------------------------------------------第一部分：首页--------------------------------------------- */
            /* 普通用户手机端主页 */
            { path: "/mobile/home-page", component: UserMobileHome },
            /* ------------------------------------------第二部分：土地市场--------------------------------------------- */
            /* 普通用户手机端土地市场页面 */
            {
                path: "/mobile/land-market",
                component: UserMobileShop,
                props: (route) => ({ LandInfor: route.params.LandInfor }),
            },
            
            /* ------------------------------------------第三部分：购物车--------------------------------------------- */
            /* 普通用户手机端购物车页面 */
            {
                path: "/mobile/shopping-cart",
                component: UserMobileCart,
            },
            /* ------------------------------------------第四部分：我的--------------------------------------------- */
            /* 普通用户手机端我的信息页面 */
            {
                path: "/mobile/my-infor",
                component: UserMobileInfor,
            },
        ],
    },
    /* 普通用户手机端查看消息列表页面 */
    {
        path: "/mobile/message",
        name: "mobilemessage",
        component: MobileMessage,
    },
    /* 普通用户手机端查看消息主题消息页面 */
    {
        path: "/mobile/message/thread",
        name: "mobilemessagewindow",
        component: MobileMessageWindow,
    },
    /* 普通用户手机端联系客服主页面 */
    {
        path: "/mobile/service",
        name: "mobileservice",
        component: MobileService
    },
    /* 普通用户手机端联系客服发送意见反馈页面 */
    {
        path: "/mobile/service/send",
        name: "mobileservicesend",
        component: MobileServiceSend
    },
    /* 普通用户手机端联系客服查看客服回复页面 */
    {
        path: "/mobile/service/reply",
        name: "mobileservicereply",
        component: MobileServiceSearch
    },
    /* 普通用户手机端查看我的土地页面 */
    {
        path: "/mobile/my-land",
        name: "mobilemyland",
        component: MobileMyLand
    },
    {
        path: "/mobile/purchase-detail",
        name: "purchasedetail",
        component: MobilePurchaseDetails
    },
    /* 普通用户手机端土地详情页面 */
    {
        path: "/mobile/land/detail",
        name: "mobilelanddetail",
        component: UserMobileDetail,
        props: (route) => ({ sellerId: route.params.sellerId, LandInfor: route.params.LandInfor }),
    },
    /* 普通用户手机端查看卖家账户页面 */
    {
        path: "/mobile/seller-home",
        name: "mobilesellerhome",
        component: UserMobileSeller,
    },
    /* 普通用户手机端联系卖家发送邮件页面 */
    {
        path: "/mobile/private-chat",
        name: "mobilesendemail",
        component: MobileSendEmail,
    },
    {
        /* 普通用户手机端用户个人信息页 */
        path: "/mobile/my-infor/detail",
        name: "mobileuserinfor",
        component: MobileUserEditInfo,
    },
    {
        /* 普通用户手机端用户钱包 */
        path: "/mobile/my-wallet",
        name: "mobilemywallet",
        component: MobileMyWallet,
    },
    {
        /* 普通用户手机端用户地址管理 */
        path: "/mobile/address-manage",
        name: "MobileAddressManagement",
        component: MobileAddressManagement,
    },
    {
        /* 普通用户手机端用户充值 */
        path: "/mobile/recharge",
        name: "MobileRecharge",
        component: MobileRecharge,
    },
    //MobileConfirmOrder
    {
        /* 普通用户手机端用户确认订单 */
        path: "/mobile/confirm-order",
        name: "MobileConfirmOrder",
        component: MobileConfirmOrder,
    },
    {
        /* 普通用户手机端账号与安全页面 */
        path: "/mobile/account-safe",
        name: "MobileAccountAndSafe",
        component: MobileAccountAndSafe
    },
    {
        /* 普通用户手机端修改密码页面 */
        path: "/mobile/change-password",
        name: "MobileChangePassword",
        component: MobileChangePassword
    },
    {
      /* 普通用户手机端修改支付密码页面 */
        path: "/mobile/change-password/pay",
        name: "MobileChangePayPassword",
        component: MobileChangePayPassword
    },
    {
        /* 普通用户手机端关联账号页面 */
        path: "/mobile/associate",
        name: "MobileAssociatedAccount",
        component: MobileAssociatedAccount,
    },
    {
        /* 普通用户手机端账号绑定页面 */
        path: "/mobile/associate/set",
        name: "MobileAssociatedSet",
        component: MobileAssociatedSet
    },
    {
        /* 普通用户手机端账号换绑页面 */
        path: "/mobile/associate/replace",
        name: "MobileAssociatedReplace",
        component: MobileAssociatedReplace
    },
    {
        /* 普通用户手机端我的订单页面 */
        path: "/mobile/my-order",
        name: "MobileOrderList",
        component: MobileOrderList
    },
    {
        /* 普通用户手机端我的订单详情页面 */
        path: "/mobile/my-order/detail",
        name: "MobileOrderDetail",
        component: MobileOrderDetail
    },
    {
        /* 普通用户手机端退款进度查看页面 */
        path: "/mobile/my-order/refund",
        name: "MobileRefundView",
        component: MobileRefundView
    },
    {
        /* 普通用户手机端使用帮助页面 */
        path: "/mobile/help",
        name: "MobileHelp",
        component: MobileHelp
    },
];

const router = new VueRouter({
    mode: "history",
    // mode: "hash",
    base: process.env.BASE_URL,
    // history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
