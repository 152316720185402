<template>
    <div class="mobile-shop-home" ref="landMarket">
        <van-nav-bar class="bg-gray-50" :title="$t('MobileMyLand.title')" :left-text="$t('MobileMyLand.back')" left-arrow @click-left="onBack" />
        <van-skeleton  v-for="i in 10" class="mt-3" title avatar :row="3" :loading="loading" />
        <van-empty v-if="LandInfor.length === 0" :description="$t('MobileMyLand.topic1')" />

        <div v-for="(land, index) in LandInfor" :key="index" @click="turnToDetail(index)">
            <ShopCartInfor class="animate__animated animate__fadeInUp" :LandInfor="land" :show-delete="false" :index="index" :showSelect="false" />
        </div>

    </div>
</template>

<script>
import MobileLand from "./MobileLand.vue";
import ShopCartInfor from "@/mobile/ShopCartInfor";
export default {
    components: {
        ShopCartInfor, // 土地市场组件
        MobileLand, // 挂牌土地信息组件
    },
    created() {
        this.selectPage = this.$route.query.index; // 根据地址获取当前页的页号，跳转时需要携带此信息
    },
    mounted() {
        // 初始化土地市场列表
        this.$http.get('/v1/myland/show-myland').then(response => {
            if (this.firstload) {
                this.LandInfor = response.data.data
                this.firstload = false
                this.loading = false
            }
        }).catch(error => {
            console.log(error)
        })
        /* // 添加一个监听滚动条位置的事件处理函数
        this.$refs.landMarket.addEventListener('scroll', this.handleScroll) */
    },
    data() {
        return {
            loading: true,
            LandInfor: [ ],
            selectPage: "2", // 页键
            firstload: true, // 用来防止初始化两次
            hasMoreData: true, // 是否还有更多数据可加载
            pageNum: 1, // 页码
            flag: true, // 防抖
        };
    },
    methods: {
        onBack() {
            this.$router.go(-1);
        },
        handleScroll() {
            let scrollHeight = this.$refs.landMarket.scrollHeight;
            let scrollTop = this.$refs.landMarket.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight;

            if (scrollHeight - scrollTop - clientHeight <= 0 && this.hasMoreData && this.flag) {
                this.flag = false;
                let timer = setTimeout(() => {
                    // 等待 300 毫秒后才能再触发
                    this.flag = true;
                    clearInterval(timer);
                }, 300);
                // 当滚动到底部并且还有更多数据可加载时
                // this.loadMoreData();
            }
        },
        loadMoreData() {
            // 请求后端获取更多数据的逻辑
            // 在获取数据后将新数据添加到 LandInfor 数组中
            // 如果没有更多数据可加载，将 hasMoreData 设置为 false
            // 例如：使用 axios 发送异步请求
            this.$http.get("/v1/sale/get-saleselect-list?pageNum=" + this.pageNum + "&pageSize=" + "10")
                .then((response) => {
                    let newData = response.data.data.rows;
                    this.LandInfor = this.LandInfor.concat(newData);
                    this.pageNum += 1;
                    if (newData.length === 0) {
                        this.hasMoreData = false;
                    }
                    t
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        turnToDetail(index) {
            const newpath = "/mobile/purchase-detail?unitlandid="+this.LandInfor[index].unitlandid + "&orderid=" + this.LandInfor[index].orderid
            this.$router.push({
                path: newpath,
            });
        },
    },
    beforeDestroy() {
        // 在组件销毁时移除监听事件
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style scoped>
.mobile-shop-home {
    height: 100%;
    width: 100%;
    overflow: auto;
}
.mobile-shop-land {
    display: inline-block;
    width: 100%;
    box-shadow: 0 2px 12px 0 #dcdcdc;
    border-radius: 6px;
    margin-top: 5px;
}
/* ------------------------------把列表页放到这个文件里了-------------------------------- */
.market-background {
    height: 170px;
    width: calc(100% - 20px);
    background-color: #fff;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 6px;
}
.market-image {
    /* 图片区域 */
    height: 150px;
    width: 150px;
    max-height: 150px;
    max-width: 150px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
.market-landname {
    /* 土地名称 */
    position: absolute;
    top: 30px;
    left: 170px;
    font-size: 20px;
}
.market-landprice {
    /* 土地价格 */
    position: absolute;
    bottom: 20px;
    left: 170px;
    font-size: 20px;
}
.market-landaddress {
    /* 地址（后续可能会改为购买按钮） */
    height: 30px;
    width: 80px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #582d7e;
    border-radius: 12px;
    color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 14px;
}
.market-landtag {
    /* 小标签 */
    height: 20px;
    width: 80px;
    position: absolute;
    top: 90px;
    left: 210px;
    background-color: #582d7e;
    border-radius: 12px;
    color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 14px;
}
</style>
