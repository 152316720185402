<template>
    <div class="market-background" @click="turnToDetail">
        <img :src="'https://datuland.com'+LandInfor.originalland.landpicture" class="market-image" />
        <div class="market-landname">{{ LandInfor.landname }}</div>
        <div class="market-landprice">{{ LandInfor.pricenum }}</div>
        <div class="market-landaddress">
            <span>{{ LandInfor.originalLand.geographicalcoordinates }}</span>
        </div>
        <div class="market-landtag"><span>{{ LandInfor.originalLand.originallandname }}</span></div>
    </div>
</template>

<script>
export default {
    props: {
        // 父子组件传值
        LandInfor: {
            // 包含图片链接image、landName、landPrice、landAddress
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            landInfor: { // 测试数据
                originallandid: 'TD012023042096969',
                unitlandid: '60', // 用于确定该土地块的关键词
                seller: 'Mr.Wang',
                pricenum: '1000', // 单位土地售价
                salesmethods: '立即购买',
                landname: "合并测试土地1号",
                originalLand: null,
            }
        }
    },
    methods: {
        turnToDetail() {
            this.$emit("turnToDetail", this.LandInfor);
        },
    },
}
</script>

<style scoped>
.market-background {
    height: 170px;
    width: calc(100% - 20px);
    background-color: #fff;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 6px;
}
.market-image {
    /* 图片区域 */
    height: 150px;
    width: 150px;
    max-height: 150px;
    max-width: 150px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
.market-landname {
    /* 土地名称 */
    position: absolute;
    top: 30px;
    left: 170px;
    font-size: 20px;
}
.market-landprice {
    /* 土地价格 */
    position: absolute;
    bottom: 20px;
    left: 170px;
    font-size: 20px;
}
.market-landaddress {
    /* 地址（后续可能会改为购买按钮） */
    height: 30px;
    width: 80px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #582d7e;
    border-radius: 12px;
    color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 14px;
}
.market-landtag {
    /* 小标签 */
    height: 20px;
    width: 80px;
    position: absolute;
    top: 90px;
    left: 210px;
    background-color: #582d7e;
    border-radius: 12px;
    color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    font-size: 14px;
}
</style>