import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/index.js";
import VueCookies from 'vue-cookies';


// 导入axios
import axios from "axios";
import "tailwindcss/tailwind.css";
import 'tailwindcss/base.css';
import 'tailwindcss/components.css';
import 'tailwindcss/utilities.css';
import 'animate.css';
// 安装element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 安装vue-i18n
import VueI18n from 'vue-i18n'
// 导入vant
import {
    AddressEdit,
    AddressList,
    Button,
    Card,
    Cell,
    CellGroup,
    Checkbox,
    CheckboxGroup,
    Col,
    Collapse,
    CollapseItem,
    DatetimePicker,
    Dialog,
    Divider,
    DropdownItem,
    DropdownMenu,
    Empty,
    Field,
    Icon,
    Lazyload,
    NavBar,
    PullRefresh,
    Radio,
    RadioGroup,
    Row,
    Skeleton,
    Step,
    Steps,
    SubmitBar,
    Swipe,
    SwipeCell,
    SwipeItem,
    Switch,
    Tab,
    Tabs,
    Tag,
    Toast,
    Popup,
    PasswordInput,
    NumberKeyboard,
    Notify,
} from "vant"; // 图片懒加载，同样在手机端首页 // 轮播，用在手机端首页
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(VueCookies);


Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Popup);
Vue.use(ElementUI);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(PullRefresh);
Vue.use(Toast);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Button);
Vue.use(Skeleton);
Vue.use(Field);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(SwipeCell);
Vue.use(SubmitBar);
Vue.use(Tag);
Vue.use(Card);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Col);
Vue.use(Row);
Vue.use(Dialog);
Vue.use(DatetimePicker);
Vue.use(NavBar);
Vue.use(AddressList);
Vue.use(AddressEdit);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Divider);
Vue.use(Collapse);
Vue.use(CollapseItem);

Vue.use(VueI18n); // vue-i18n

// 在Vue.js原型上添加axios属性
Vue.prototype.$http = axios.create({
    // baseURL: "http://192.168.1.134:8080/",
    // baseURL: "http://8.218.39.16:8092/",
    // https://test.datuland.com
    // withCredentials: true,
    // baseURL: "/api/",
    // TODO 上线时要改端口
    baseURL: "/",
});
// Vue.prototype.$http.defaults.withCredentials = true;

// Vue.prototype.$http.interceptors.request.use(config => {
//     // 从本地存储中获取cookie值
//     if (config.url !== "/index/login" && config.url!=="/index/loginYZM") { // 非登录请求时才执行添加token的逻辑
//         console.log("config", config)
//         const token = this.$store.state.user.token;
//         if (token) {
//             config.headers['Authorization'] = `Bearer ${token}`;
//         }
//     }
//     return config;
// }, error => {
//     // 处理请求错误
//     return Promise.reject(error);
// });

// 响应拦截器
// Vue.prototype.$http.interceptors.response.use(response => {
//     // 在收到响应后，可以对响应进行处理
//     return response;
// }, error => {
//     // 处理响应错误
//     return Promise.reject(error);
// });

const i18n = new VueI18n({
    locale: localStorage.getItem('language') || 'en', // 默认语言为英文
    fallbackLocale: 'en', // 如果找不到对应语言的文本，则使用英文
    messages: {
      en: require('./locales/en.json'), // 英文翻译文件的路径
      zh: require('./locales/zh.json') // 中文翻译文件的路径
    }
  });

  // 在main.js中配置全局导航守卫
  router.beforeEach((to, from, next) => {
    // 根据路由的meta信息判断是用户页面还是管理员页面
    if (to.meta.role === 'usermobile') {
      // method1
    } else if (to.meta.role === 'admin') {
      // method2()
    }
    // mita识别标识需要在index.js中配置,下面是范例
    /* {
      path: '/users',
      component: UserComponent,
      meta: {
        role: 'user' // 根据需要验证的页面角色设置为'user'
      }
    } */
    next()
  })


new Vue({
    router,
    store,
    render: (h) => h(App),
    i18n,
}).$mount("#app");

// // 解决移动端APP延迟
// document.addEventListener('deviceready', function() {
//     new Vue({
//         router,
//         store,
//         render: (h) => h(App),
//     }).$mount("#app");
// }, false);

export default Vue;
