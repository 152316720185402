<template>
  <div class="main-container">
    <el-form label-width="0px" class="form-add">
        <el-form-item class="my-el-form-item">
            <div class="form-item-cells">
                <el-col :span="3">
                    <div class="users-lvImg">
                        <el-tooltip class="item" effect="dark" :content="levelText()" placement="right-start">
                            <img :src="users.lvUrl" style="height: 70px; width: 60px;">
                        </el-tooltip>
                    </div>
                </el-col>
                <el-col :span="10" style="font-size: 20px;">
                    <i class="el-icon-user">&nbsp;&nbsp;&nbsp;{{$t('useraccount.level')}}: Lv {{ users.lv }}</i>
                    <br>
                    <i>{{$t('useraccount.totalIntegral')}}: {{ users.totalIntegral }}&nbsp;</i>
                    <el-tooltip class="item" effect="dark" :content="$t('useraccount.totalIntegralDescription')" placement="top-end">
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                    <i>&nbsp;&nbsp;&nbsp;&nbsp;{{$t('useraccount.increaseThisMonth')}}: {{ users.increaseIntegral }}&nbsp;</i>
                    <el-tooltip class="item" effect="dark" :content="$t('useraccount.increaseThisMonthDescription')" placement="top-end">
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </el-col>
                <el-col :span="11" style="font-size: 20px;">
                    <div style="text-align: right; margin-right: 20px;">
                        <i>{{$t('useraccount.updateTime')}}:  {{ users.updateTime }}</i>
                    </div>
                </el-col>
            </div>
        </el-form-item>
        <el-form-item class="my-el-form-item">
            <div class="form-item-cells">
                <div style="text-aligh: right;" @mouseover="changeCursor">
                    <i>{{$t('useraccount.monthlyReport')}} →</i>
                </div>
                <el-col :span="6" style="font-size: 20px;">
                    <i>{{$t('useraccount.totalIncome')}}</i>
                    <br>
                    <i class="el-icon-coin">&nbsp;{{ users.totalIncome }}</i>
                    <br>
                    <i class="update-data">{{$t('useraccount.yesterday')}} {{ updateData(users.totalIncome,users.lastTotalIncome) }}</i>
                </el-col>
                <el-col :span="6" style="font-size: 20px;">
                    <i>{{$t('useraccount.buyer')}}</i>
                    <br>
                    <i>&nbsp;{{ users.customer }}</i>
                    <br>
                    <i class="update-data">{{$t('useraccount.yesterday')}} {{ updateData(this.users.customer,this.users.lastCustomer) }}</i>
                </el-col>
                <el-col :span="6" style="font-size: 20px;">
                    <i>{{$t('useraccount.averageTransactionVolume')}}</i>
                    <br>
                    <i class="el-icon-coin">&nbsp;{{ users.average }}</i>
                    <br>
                    <i class="update-data">{{$t('useraccount.lastMonth')}} {{ updateData(this.users.average,this.users.lastAverage) }}</i>
                </el-col>
                <el-col :span="6" style="font-size: 20px;">
                    <i>{{$t('useraccount.averageLandPrice')}}</i>
                    <br>
                    <i class="el-icon-coin">&nbsp;{{ users.unitPrice }}</i>
                    <br>
                    <i class="update-data">{{$t('useraccount.lastMonth')}} {{ updateData(this.users.unitPrice,this.users.lastUnitPrice) }}</i>
                </el-col>
            </div>
        </el-form-item>
        <el-form-item class="my-el-form-item">
            <div class="form-item-cells">
                <div style="white-space: nowrap;">
                    <span style="vertical-align: top; display: inline-block;">{{$t('useraccount.myRealEstate')}}</span>
                    <div class="users-topic-buttons">
                        <button class="users-topic-button" @mouseover="changeCursor" @click="uploadland">{{$t('useraccount.landUpload')}}</button>
                        <button class="users-topic-button" @mouseover="changeCursor" @click="gotoland">测试:进入土地</button>
                    </div>
                </div>
                <el-row :gutter="15" class="users-information-row">
                    <el-col :span="7"><i>{{$t('useraccount.landName')}}</i></el-col>
                    <el-col :span="7"><i>{{$t('useraccount.developersName')}}</i></el-col>
                    <el-col :span="3"><i>{{$t('useraccount.statu')}}</i></el-col>
                    <el-col :span="7"><i>{{$t('useraccount.control')}}</i></el-col>
                </el-row>
                <el-row :gutter="15" v-for="(land, index) in users_land1" :key="index" class="users-information-row">
                    <div class="users-information-col">
                        <el-col :span="7"><i>{{ land.id }}</i></el-col>
                        <el-col :span="7"><i>{{ land.name }}</i></el-col>
                        <el-col :span="3"><i>{{ land.state }}</i></el-col>
                        <el-col :span="7">
                            <i>&nbsp;</i>
                            <i @mouseover="changeCursor" style="text-decoration: underline;" v-if="land.state === '已上线'" @click="gotoland(land.id)">{{$t('useraccount.goToLand')}}</i>
                            <i>&nbsp;</i>
                            <i @mouseover="changeCursor" style="text-decoration: underline;" v-if="land.state === '已上线'">{{$t('useraccount.downline')}}</i>
                            <i @mouseover="changeCursor" style="text-decoration: underline;" v-if="land.state === '待审核'" @click="gotolandregister(land.id)">{{$t('useraccount.progress')}}</i>
                            <i @mouseover="changeCursor" style="text-decoration: underline;" v-if="land.state === '已下线'">{{$t('useraccount.online')}}</i>
                            <i>&nbsp;</i>
                            <i @mouseover="changeCursor" style="text-decoration: underline;" @click="checkLand(land.originallandid)">{{$t('useraccount.certificate')}}</i>
                        </el-col>
                    </div>
                </el-row>
            </div>
        </el-form-item>
        <el-form-item class="my-el-form-item">
            <div class="form-item-cells">
                <div style="white-space: nowrap;position: relative;">
                    <span style="vertical-align: top; display: inline-block;">{{$t('useraccount.transaction')}}</span>
                    <span style="position: absolute;right: 0;" @mouseover="changeCursor">{{$t('useraccount.detailedTransaction')}} →</span>
                </div>
                <el-row :gutter="15" class="users-information-row">
                    <el-col :span="6"><i>{{$t('useraccount.order')}}</i></el-col>
                    <el-col :span="3"><i>{{$t('useraccount.time')}}</i></el-col>
                    <el-col :span="6"><i>{{$t('useraccount.buyer')}}</i></el-col>
                    <el-col :span="3"><i>{{$t('useraccount.price')}}</i></el-col>
                    <el-col :span="6"><i>{{$t('useraccount.landid')}}</i></el-col>
                </el-row>
                <el-row :gutter="15" v-for="(user, index) in users_trade1" :key="index" class="users-information-row">
                    <div class="users-information-col">
                        <el-col :span="6"><i>{{ user.订单编号 }}</i></el-col>
                        <el-col :span="3"><i>{{ user.交易时间 }}</i></el-col>
                        <el-col :span="6"><i>{{ user.买家 }}</i></el-col>
                        <el-col :span="3"><i>{{ user.售价 }}</i></el-col>
                        <el-col :span="6"><i>{{ user.原始土地编号 }}</i></el-col>
                    </div>
                </el-row>
            </div>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
import router from '@/router';
export default {
    data() {
        return {
            users: {
                lvURL: '../assets/logo.png', // 用户等级应用的等级图标
                lv: 1, // 用户等级
                totalIntegral: 2000, // 用户总积分
                increaseIntegral: 1000, // 用户当月新增积分
                updateTime: 'xxxx-x-xx', // 更新时间？
                totalIncome: 5000, // 当前总收入
                lastTotalIncome: 4000, // 昨日总收入
                customer: 90, // 买家
                lastCustomer: 87, // 昨日买家
                average: 1100, // 平均交易额
                lastAverage: 1000, // 上月平均交易额
                unitPrice: 1000, // 最小单位土地平均地价
                lastUnitPrice: 1100, // 上月最小单位土地平均地价
            },
            landid: 'TD012023042096919',
            landName: '测试土地1号',

            users_land1: [ // ‘我的土地’测试用例
                {
                    id: 'mylandisbig12345',
                    name: '大图智能科技有限公司',
                    count: '100',
                    state: '已上线',
                    originallandid: 'S136'
                },
                {
                    id: 'mylandisnotbig12345',
                    name: '大图智能科技有限公司',
                    count: '50',
                    state: '待审核',
                    originallandid: 'S137'
                },
                {
                    id: 'mylandissmall12345',
                    name: '大图智能科技有限公司',
                    count: '10',
                    state: '已下线',
                    originallandid: 'S138'
                },
            ],
            users_land: [], // axios后端所用的data参数

            users_trade1: [ // ‘近期交易’测试用例
                {
                    time: '2023-3-29 09:42:56',
                    id: 'mylandisbig12345',
                    buyer: '大图智能科技有限公司',
                    price: 1100,
                    state: '一口价'
                },
                {
                    time: '2023-3-29 11:22:33',
                    id: 'mylandisnotbig12345',
                    buyer: '大图智能科技有限公司',
                    price: 1100,
                    state: '拍卖'
                },
                {
                    time: '2023-3-30 17:01:10',
                    id: 'mylandissmall12345',
                    buyer: '大图智能科技有限公司',
                    price: 1100,
                    state: '一口价'
                },
            ],
            users_trade: [], // axios后端所用的data参数
        }
    },


    methods: {
        /* 鼠标悬停到等级图标上显示的文本 */
        levelText() {
            if(this.users.lv < 4) {
                return '初级开发商:持有土地1~5块,等级小于4级'
            }
        },
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        /* 计算新增数据 */
        updateData(now,last) {
            const data = now - last
            if (data >= 0) {
                return '+' + data.toString()
            } else {
                return data.toString()
            }
        },
        uploadland() {
            this.$router.push('/developer/land-upload')
        },
        gotolandregister(id) { // 对于待审核的土地，用户点击查看进度
            this.$router.push({
                path: '/developer/land-view?land=' + id,
            })
        },
        /* 我的土地-查看-进入土地 */
        landControl(id,name) {
            this.$router.push({
                path: '/land-operate/big-map',
                name: 'Originland',
                params: {
                    landid: this.landid,
                    landName: name,
                }
            })
        },
        gotoland(id) {
            this.$router.push({
                path: '/land-operate/big-map?land=' + id})
        },
        checkLand(id) {
            this.$router.push('/developer/land-view?land=' + id)
        },
    }

}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container {
    height: auto;
    background-color: black;
}
/* el-form组件没有占满全屏，增加一个style样式 */
.form-add {
    height: auto;
    width: 100%;
}
/* el-form-item组件添加设置 */
.el-form-item {
    padding: 20px;
}
/* item下的div层，更换背景色 */
.my-el-form-item >>> .el-form-item__content { /* 增加深度选择器 */
    border: 2px solid #646262;
    border-radius: 12px;
    padding: 20px;
    background-color: #fff;
}
/* 给用户各部分信息区域设置一个父块，来控制行内元素 */
.form-item-cells {
    height: 100%;
    padding: 0;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
}




/* -------------------------------------个人信息——等级、资产等------------------------------------- */
/* 首先是图标小图片，后续可以设置为跟用户等级有关彰显用户身份的王冠等图片 */
.users-lvImg {
    padding: 10px;
    /* 采用`flex`布局，则可以通过设置`justify-content`和`align-items`属性来实现水平和垂直居中 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
}


/* -------------------------------------个人信息——总收入、相比昨日的新增收入等------------------------------------- */
/* 昨日/上月新增 */
.update-data {
    background-color: #646262;
    border: 2px solid #646262;
    border-radius: 12px;
    font-size: 14px;
    padding: 10px;
}


/* -------------------------------------个人信息——我的地产与近期交易------------------------------------- */
/* 第一行右对齐文本/按钮 */
.users-topic-buttons {
    width: 90%;
    text-align: right;
    vertical-align: top;
    display: inline-block;
}
/* 我的地产合并、分割按钮 */
.users-topic-button {
    height: 50px;
    width: 100px;
    background-color: #ddd3fd;
    border-radius: 12px;
    margin-left: 20px;
}
/* 信息栏样式 */
.users-information-row {
    padding: 10px;
    text-align: center;
}
.users-information-col {
    display: flex;
    background-color: #a7a5a5;
    border: 2px solid #a7a5a5;
    border-radius: 12px;
}
</style>