<template>
    <div class="first-main-container">
        <!-- 左上角返回，返回登录页面 -->
        <van-nav-bar title="忘记密码" left-text="返回" left-arrow @click-left="goBack" />
        <!-- 电脑端忘记密码 -->
        <div class="add-parent">
            <div class="add-box">
                <div style="height: 500px;width: 500px;" v-if="step">
                    <div class="add-input-parent">
                        <span>{{$t('forgetPassword.account')}}:</span>
                        <!-- 邮箱名需要用户输入，后缀名通过下拉菜单选择 -->
                        <el-input v-model="account" style="width:400px" :placeholder="$t('forgetPassword.passwordLoginAccountInput')"></el-input>
                    </div>
                    <div class="add-input-parent">
                        <span>{{$t('forgetPassword.verify')}}:</span>
                        <el-input v-model="code" style="width:400px" :placeholder="$t('forgetPassword.codeLoginCodeInput')">
                            <el-button slot="append" @click="getVerificationCode" v-if="isCode">{{$t('forgetPassword.getCode')}}</el-button>
                            <el-button slot="append"  v-if="!isCode" disabled>{{$t('forgetPassword.resendCode')}}{{ time }}s</el-button>
                        </el-input>
                    </div>
                    <button class="add-button" @click="InforNext" @mouseover="changeCursor">{{$t('forgetPassword.informationAuthentication')}}</button>
                </div>
                <div style="height: 500px;width: 500px;" v-if="!step">
                    <div class="add-input-parent">
                        <span>{{$t('forgetPassword.newPassword')}}:</span>
                        <el-input :type="showPassword ? '' : 'password'" v-model="changePassword" style="width:400px" :placeholder="$t('forgetPassword.setNewPassword')" @input="checkPassword">
                            <el-tooltip class="item" effect="dark" :content="$t('forgetPassword.showOrHidePassword')" placement="top-end"  slot="append">
                                <span :class="showPassword ? 'el-icon-view' : 'el-icon-lock'" @click="togglePassword()" @mouseover="changeCursor"></span>
                            </el-tooltip>
                        </el-input>
                        <p v-show="showRules" style="font-size: 12px;">{{$t('forgetPassword.rule1')}}</p>
                        <p v-show="showRules" style="font-size: 12px;">{{$t('forgetPassword.rule2')}}</p>
                        <p v-show="showRules" style="font-size: 12px;">{{$t('forgetPassword.rule3')}}</p>
                    </div>
                    <button class="add-button" @click="add" @mouseover="changeCursor">{{$t('forgetPassword.resetPassword')}}</button>
                </div>
                <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
                <div class="modal" v-show="vmodal" @click="closeModal">
                    <div class="modal-box" v-if="!showError">
                        <span>{{$t('forgetPassword.resureChange')}}</span>
                        <div style="margin-top: 50px;">
                            <button class="modal-button" @click="add" style="margin-right: 30px;">{{$t('forgetPassword.yes')}}</button>
                            <button class="modal-button" @click="closeModal">{{$t('forgetPassword.no')}}</button>
                        </div>
                    </div>
                    <div class="modal-box" v-if="showError">
                        <div>{{ errorText }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            step: true, // 第一步：身份验证,第二步：修改密码
            mobilechange: false, // 手机号改密和邮箱改密，true为手机，false为邮箱

            account: '', // 账号
            changePassword: '',
            showPassword: false,
            code: '', // 验证码
            time: 0, // 发送验证码后重新发送需要等待60s
            isCode: true,
            timer: null, // 计时器


            vmodal: false, // 显示弹窗
            showRules: false, // 输入新密码时显示的密码规则
            showError: false, // 输入完密码后点击确认按钮时验证密码格式是否正常
            errorText: '',
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        goBack() {
            this.$router.go(-1)
        },
        showModal() {
            const re = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/;
            if (re.test(this.changePassword)) {
                this.showError = false
            } else {
                // 非法操作
                this.showError = true;
                this.errorText = $t('forgetPassword.alertPasswordError')
            }
            if (this.code === '') {
                this.showError = true;
                this.errorText = $t('forgetPassword.alertNoCodeInput')
            }
            this.vmodal = true
        },
        closeModal() {
            this.vmodal = false
        },
        checkPassword() { // 点击密码输入框后检查密码格式是否错误
            const re = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/;
            this.showRules = !re.test(this.changePassword);
        },
        setMyInterval() { // 设置计时器
            this.time = 60
            // 计时器
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    this.isCode = true;
                    clearInterval(this.timer);
                }
            }, 1000);
            this.isCode = false
        },
        getVerificationCode() {
            if(this.account === '') {
                this.showError = true;
                this.errorText = $t('forgetPassword.alertNoAccountInput')
                this.vmodal = true
                return
            }
            this.setMyInterval()
            this.showError = true;
            this.errorText = $t('forgetPassword.alertLoading')
            this.vmodal = true
            this.$http.post('/v1/index/get-verification-code?loginname=' + this.account)
                .then(res => {
                    this.showError = true;
                    this.errorText = res.data.data
                    this.vmodal = true
                }).catch(err => {
                    this.showError = true;
                    this.errorText = $t('forgetPassword.alertAxiosError')
                    this.vmodal = true
                })
        },
        InforNext() { // 下一步
            if (this.account.length === 0) {
                this.showError = true;
                this.errorText = $t('forgetPassword.alertNoAccountInput')
                this.vmodal = true
                return
            }
            if (this.code.length === 0) {
                this.showError = true;
                this.errorText = $t('forgetPassword.alertNoCodeInput')
                this.vmodal = true
                return
            }
            const data = {
                loginname: this.account,
                code: this.code
            }
            this.$http.post('/v1/register/user-update-password-authentication', data)
                .then(res => {
                    this.showError = true;
                    this.errorText = res.data.data
                    this.vmodal = true
                    if (res.data.data === '用户验证成功') {
                        this.step = false
                    }
                }).catch(err => {
                    this.showError = true;
                    this.errorText = $t('forgetPassword.alertAxiosError')
                    this.vmodal = true
                })
        },
        add() {
            if (this.changePassword.length === 0 || this.showRules) {
                this.showError = true;
                this.errorText = $t('forgetPassword.alertNewPasswordError')
                this.vmodal = true
                return
            }
            const data = {
                loginname: this.account,
                loginpassword: this.changePassword
            }
            this.$http.post('/v1/register/update-password', data)
                .then(response => {
                    // 处理返回值
                    this.showError = true;
                    this.errorText = $t('forgetPassword.alertResetSuccessfully')
                    this.vmodal = true
                    this.$router.go(-1)
                }).catch(error => {
                    this.showError = true;
                    this.errorText = $t('forgetPassword.alertAxiosError')
                    this.vmodal = true
                });
        },
    },
}
</script>

<style scoped>
.first-main-container {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    height: 100vh; /* 容器高度为屏幕高度 */
}
.goback-parent {
    display: flex;
    align-items: center; /* 垂直居中 */
    font-size: 20px;
    margin-left: 20px;
    margin-top: 20px;
}
.goback-img {
    height: 20.67px;
    width: 40px;
}
.add-parent {
    padding: 50px;
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    justify-content: center; /* 水平居中 */
    flex: 1; /* 占据剩余空间 */
    overflow-y: auto; /* 添加滚动条，超出屏幕高度时可以滚动 */
}
.el-steps {
    display: flex;
    justify-content: center; /* 水平居中 */
}
.add-box {
    height: 100%;
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    flex-direction: column;
    background-color: #a7a7a7;
    color: #fff;
}
.add-input-parent {
    padding: 20px;
    font-size: 30px;
}
.add-input {
    height: 34px;
    margin-left: 10px;
    vertical-align: top;
}
.add-button {
    height: 40px;
    width: 500px;
    margin-top: 30px;
    background-color: #ddd3fd;
    color: #fff;
}
/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    height: 150px;
    width: 300px;
    background-color: #a7a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>