<template>
    <div class="main-container">
        <div class="left-chatmenu">
            <div
                v-for="(apply, index) in chatThread"
                :key="index" class="left-chatmenu-chatthread"
                :style="selectServeId === apply.serviceid? 'background-color: #bebebe' : ''"
                @mouseover="changeCursor"
                @click="changeServeId(apply.serviceid)">
                <div class="circle-image shadow-md border-gray top-0 left-0" style="height: 75px;width: 75px;">
                    <img :src="apply.avatarUrl" alt="头像" />
                </div>
                <div class="left-chatmenu-chatthread-name">{{ apply.fromname }}</div>
                <div class="left-chatmenu-chatthread-time">{{ apply.createdate }}</div>
                <div class="left-chatmenu-chatthread-message">{{ apply.emailscontent }}</div>
                <div class="left-chatmenu-chatthread-icon circle-image" v-show="apply.isme">
                    <img style="background-color: red;" />
                    <!-- <span class="left-chatmenu-chatthread-icon-number">1</span> -->
                </div>
            </div>
        </div>
        <chatMessage :ThreadId="selectServeId"></chatMessage>
    </div>
</template>

<script>
import chatMessage from "../companyUsers/ChatCustomer.vue";
export default {
    components: {
        chatMessage,
    },
    mounted() {
        this.$http.get('/v1/csmail/csselect-message')
            .then(res => {
                this.chatThread = res.data.data
            }).catch(error => {
                console.error(error.data)// 处理错误
            });
    },
    data() {
        return {
            selectServeId: null, // 选中的服务id
            example: [{
                id: 6175,
                serviceid: "301435703712633003",
                fromid: "43a89960781b41de9e16c2575772dc54",
                fromname: "dt1234563",
                recipientid: "10002",
                recipientname: "高级管理员",
                emailscontent: "你好，客服",
                createdate: "2023-08-23T16:00:38",
                receivedate: null,
                fromdelete: false,
                recipientdelete: false,
                isme: false,
                avatarUrl: require("../assets/no-image.png")
            }],
            chatThread: [], // 聊天主题，后端获取，客服需要获取多个客户发来的主题并进行回复，这里截取最新一条消息
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        changeServeId(id) { // 选中该位置
            this.selectServeId = id
        }
    },
}
</script>

<style scoped>
.main-container {
    height: 100%;
    display: flex;
    flex-direction: row;
}
.left-chatmenu {
    width: 400px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: #eeeeee;
}
.left-chatmenu-chatthread {
    height: 150px;
    position: relative;
    padding: 10px;
    box-shadow: 0 1px 12px 0 rgb(0, 0, 0);
    background-color: #fff;
    margin-top: 10px;
}
.left-chatmenu-chatthread-name {
    position: absolute;
    left: 100px;
    top: 20px;
    font-size: 20px;
}
.left-chatmenu-chatthread-time {
    position: absolute;
    right: 10px;
    top: 40px;
    font-size: 16px;
}
.left-chatmenu-chatthread-message {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    position: absolute;
    left: 100px;
    top: 70px;
    max-width: 150px;
    font-size: 16px;
    color: #ded5d5;
    word-wrap: break-word;
}
.left-chatmenu-chatthread-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
}
.left-chatmenu-chatthread-icon-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}
</style>