import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)



const adminCheckingCount = {
  namespaced: true,
  state: {
    landUploadCheckingCount: 0,
    refundCheckingCount: 0,
  },

  mutations: {
    landUploadIncrement(state) {
      state.adminCheckingCount.landUploadCheckingCount++;
    },

    landUploadDecrement(state) {
      state.adminCheckingCount.landUploadCheckingCount--;
    },

    set_landUploadCheckingCount(state,data) {
      state.adminCheckingCount.landUploadCheckingCount = data
    },

    refundIncrement(state) {
      state.adminCheckingCount.landUploadCheckingCount++;
    },

    refundDecrement(state) {
      state.adminCheckingCount.landUploadCheckingCount--;
    },

    set_refundCheckingCount(state,data) {
      state.adminCheckingCount.refundCheckingCount = data
    }
  },

  actions: {
    increment(context) {
      context.commit('increment');
    },

    decrement(context) {
      context.commit('decrement');
    }
  },

  getters: {
    getLandUploadCount: state => state.landUploadCheckingCount,
    getRefundCount: state => state.refundCheckingCount,
  }
};

const user = {
  namespaced: true,
  state: {
    userInfo: {
      id: -1,
      userid: "",
      username: "",
      useremail: "",
      userphone: "",
      userpart: "",
      userrealname: "",
      usercard: "",
      usersex: "",
      birthdate: new Date(),
      createdate: "",
      updatedate: "",
      status: true,
      remark: null,
      verificationstatus: "",
      avatarUrl: require("../images/logo.png"),
      isbanned: true,
      bantime: "",
      bandays: 0
    },
    token: "",
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setUserAvatar(state, avatarUrl) {
      state.userInfo.avatarUrl = avatarUrl;
    },
    setToken(state, token) {
      console.log(token);
      state.token = token;
    }
  },
  actions: {
    fetchUserInfo({ commit,dispatch }) {
      user.state.session = this._vm.$cookies.get("SESSION");
      console.log(this._vm.$cookies.get("SESSION"));
      // 异步获取用户信息
      this._vm.$http
          .get("/user/personalCenterlist")
          .then((response) => {
            const data = response.data;
            if (data.status === 200) {
              let userInfo = data.data;
              userInfo.birthdate = new Date(userInfo.birthdate);
              commit("setUserInfo", userInfo);
              dispatch("loadAvatar");
            } else {
              // 处理错误情况
              console.error(data.msg);
            }
          })
          .catch((error) => {
            // 处理请求失败的情况
            console.error(error);
          });
    },
    loadAvatar({commit}) {
      this._vm.$http
          .get("/user/seeUserPhoto")
          .then((response) => {
            if(response.data){
              commit("setUserAvatar", response.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo;
    }
  }
};

export default new Vuex.Store({
  modules:{
    adminCheckingCount: adminCheckingCount,
    user: user
  },
  plugins: [
    createPersistedState({
      paths: ['user'],
    })
  ]
})