<template>
    <div class="main-container3">
        <div class="upload-form-parent">
            <div class="upload-form-topic"><span>订单详情</span></div>
            <div class="upload-form">
                <div class="upload-form-inn">
                    <div style="font-size: 20px;margin-bottom: 20px;">订单信息</div>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">订单编号:</span>
                        <span>{{ refundInfor.separateOrderId }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">交易时间:</span>
                        <span>{{ refundInfor.ordertime }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">交易金额:</span>
                        <span>{{ refundInfor.totalprice }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">卖家编号:</span>
                        <span>{{ refundInfor.sellerid }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">买家编号:</span>
                        <span>{{ refundInfor.buyerid }}</span>
                    </el-row>
                    <el-divider v-if="refundInfor.refundtime"><span>退款详情</span></el-divider>
                    <el-row :gutter="15" class="upload-form-item" v-if="refundInfor.refundtime">
                        <span class="upload-form-item-label">退款金额:</span>
                        <span>{{ refundInfor.totalprice }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item" v-if="refundInfor.refundtime">
                        <span class="upload-form-item-label">退款原因:</span>
                        <span>{{ refundInfor.refundreason }}</span>
                    </el-row>
                </div>
                <div class="upload-form-inn" style="margin-top: 20px;">
                    <div style="font-size: 20px;margin-bottom: 20px;">原始土地信息</div>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">原始土地编号:</span>
                        <span>{{ refundInfor.originalLand.originallandid }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">土地名称:</span>
                        <span>{{ refundInfor.originalLand.originallandname }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">地址:</span>
                        <span>{{ refundInfor.originalLand.detailedaddress }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">开发商编号:</span>
                        <span>{{ refundInfor.originalLand.ownerid }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">创建时间:</span>
                        <span>{{ refundInfor.originalLand.createdate }}</span>
                    </el-row>
                </div>
            </div>
        </div>
        <div class="upload-button-parent">
            <button class="upload-button" @click="goBack">
                <span>返回</span>
            </button>
            <button class="upload-button" @click="showModal(false)">
                <span>通过申请</span>
            </button>
            <button class="upload-button" @click="showModal(true)">
                <span>驳回申请</span>
            </button>
        </div>
        <!-- 弹窗 -->
        <div class="modal" v-show="modal.vmodal" @click="closeModal">
            <div class="modal-box">
                <span>{{ modal.text }}</span>
                <el-input
                    type="textarea"
                    v-model="modal.refundText"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="请输入驳回原因"
                    maxlength="30"
                    show-word-limit
                    v-show="modal.isRefund"></el-input>
                <div class="text-red-500" v-show="modal.refundFailed">驳回原因不可为空</div>
                <div style="margin-top: 50px;">
                    <button class="modal-button" @click="apply" style="margin-right: 30px;" v-if="!isRefund">确定</button>
                    <button class="modal-button" @click="refuse" style="margin-right: 30px;" v-else-if="isRefund">确定</button>
                    <button class="modal-button" @click="closeModal">取消</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { // 父子组件传值
        companyChild: { // 包含companyChild.whichtab和companyChild.isReview两个值
            type: Object,
            required: true
        },
        refundInfor: { // 所有数据都由父组件传入
            type: Object,
            required: true
        },
    },
    data() {
        return {
            modal: {
                vmodal: false, // 弹窗开关
                text: '', // 弹窗内容
                isRefund: false, // true是驳回，false是通过
                refundText: '', // 驳回原因
                refundFailed: false, // 没有写驳回原因时设置为true提醒
            },
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },

        /* 提交按钮事件 */
        goBack() {
            this.companyChild.isReview = false
        },
        closeModal() {
            this.modal.refundFailed = false
            this.modal.vmodal = false
        },
        showModal(boolean) {
            if (boolean) {
                this.modal.text = '驳回原因'
                this.modal.isRefund = true
                this.modal.vmodal = true
            } else {
                this.modal.text = '确定审核通过？'
                this.modal.isRefund = false
                this.modal.vmodal = true
            }
        },
        apply() {
            const number = this.refundInfor.separateOrderId
            const data = {
                number
            }
            // 向后端发送数据 测试地址http://192.168.1.145:8092/Administrator/passthrough
            this.$http.post('/v1/administrator/application-approved', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                alert('审核通过，已录入系统！')
                window.location.reload(); // 刷新页面
            })
            .catch(error => {
                // 处理错误
                alert('审核通过录入系统失败！错误代码：' + error.data)
            });
        },
        refuse() {
            if (modal.refundText === '') {
                this.modal.refundFailed = true
                return
            }
            const data = {
                separateOrderId: this.refundInfor.separateOrderId,
                rejectionreason: this.modal.refundText
            }
            // 向后端发送数据 测试地址http://192.168.1.145:8092/Administrator/passthrough
            this.$http.post('/v1/administrator/reject-application', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                alert('审核驳回，已录入系统！')
                window.location.reload(); // 刷新页面
            })
            .catch(error => {
                // 处理错误
                alert('审核驳回录入系统失败！错误代码：' + error.data)
            });
        },
    },
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container3 {
    height: 100%;
}


/* -----------------------------------------上传公司负责人信息填写表单------------------------------------------ */
.upload-form-parent {
    /* 父 `div` 容器设置为 `flex` 容器。然后，将子 `div` 容器设置为 `flex-grow: 1;`，将其自动填充父容器的剩余空间 */
    display: flex;
    flex-direction: column;
    padding: 20px;
}
/* 表的标题 */
.upload-form-topic {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding: 20px;
}
/* 表单层-更换颜色 */
.upload-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #646064;
    padding: 20px;
    border-radius: 6px;
    white-space: nowrap;
}
.upload-form-inn {
    padding: 20px;
    border-radius: 6px;
    border: 2px solid #000000;
}
/* 表单元素 */
.upload-form-item {
    padding: 10px;
    /* 父组件加入后让子组件平齐 */
    white-space: nowrap;
}
/* ------------------------------上传文本样式------------------------------- */
/* 表单元素-标签 */
.upload-form-item-label {
    width: 150px;
    display: inline-block;
    font-size: 16px;
    color: #fff;
    /* 子组件加入后让同行元素平行 */
    vertical-align: top;
}


/* ------------------------------上传提交开发商用户注册信息表单按钮------------------------------- */
.upload-button-parent {
    margin-top: 50px;
    width: 100%;
    text-align: center;
}
.upload-button {
    margin-right: 50px;
    height: 50px;
    width: 100px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 1);
    border-radius: 6px;
    background-color: #dedcdc;
}
</style>