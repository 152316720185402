<template>
    <div class="rounded-lg shadow-md hover:bg-gray-200 bg-white px-2 py-3 mx-3 mt-2 mb-1 relative flex" @click="goToDetail">
        <div class="flex-shrink-0">
            <img :src="userhead" class="w-12 h-12 rounded-full" />
        </div>
        <div class="flex flex-col ml-4">
            <div class="flex items-center mb-1">
                <span class="font-semibold text-sm w-40">{{ Message.fromname }}</span>
                <span class="text-xs text-gray-500 absolute top-3 right-3">{{ Message.createdate }}</span>
            </div>
            <div class="mb-6 text-gray-600 text-sm">
                <span>{{ Message.emailscontent }}</span>
            </div>
            <div class="inline-block text-blue-500 text-xs rounded absolute bottom-3 right-3">
                <span>{{$t('MobileMessageForm.topic1')}}:{{ Message.landname }}{{$t('MobileMessageForm.topic2')}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 父子组件传值
        Message: {
            // 包含信息在data查看
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            userhead: require("../images/logo.png"),
        };
    },
    methods: {
        goToDetail() {
            this.$router.push({
                path: "/mobile/message/thread?index=4&thread=" + this.Message.threadid + "&land=" + this.Message.unitlandid,
            });
        },
    },
};
</script>

<style scoped>
.mobile-message-box {
    height: 150px;
    width: 100%;
    position: relative;
    overflow: auto;
    border: none;
}
/* 用户头像图片设为圆形显示 */
.mobile-message-box-head {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 10px;
    /* 通过 `border-radius: 50%` 把 `<div>` 元素变成圆形，并通过 `overflow: hidden` 隐藏图片元素超出圆形区域的部分 */
    border-radius: 50%;
    overflow: hidden;
}
/* 将图片的宽度设置为 `100%`，以适应圆形区域的大小。 */
.mobile-message-box-head > img {
    display: block;
    width: 100%;
    height: 100%;
    /* 它会按比例缩放图片并填满整个圆形区域 */
    object-fit: cover; /* 调整图片表现方式 */
}
.mobile-message-box-user {
    /* 包括该条信息的发送人和时间 */
    position: absolute;
    top: 20px;
    left: 70px;
    display: flex;
    flex-direction: column;
}
.mobile-message-box-message {
    height: calc(100% - 70px);
    width: calc(100% - 70px);
    position: absolute;
    top: 60px;
    left: 70px;
    white-space: normal; /* 控制是否换行 */
    overflow-wrap: break-word; /* 控制是否在单词内换行 */
    overflow-y: auto;
}
.mobile-message-box-land {
    height: 30px;
    width: 60%;
    position: absolute;
    bottom: 10px;
    left: 10%;
    font-size: 14px;
    background-color: #eeeeee;
    color: #fff;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    border-radius: 12px;
}
</style>
