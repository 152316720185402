<template>
        <div class="upload-form">
                <div style="font-size: 20px;margin-bottom: 20px;">土地信息</div>
                <el-row>
                    <div class="upload-form-item-parent">
                        <div class="upload-form-item-label">土地产权编号:</div>
                        <input v-model="ownershipnumber" class="upload-form-item-input">
                    </div>
                </el-row>
                <el-row>
                    <div class="upload-form-item-parent">
                        <div class="upload-form-item-label">土地名称:</div>
                        <input v-model="originallandname" :maxlength="40" @input="checkInput" class="upload-form-item-input" style="display: inline-block;">
                        <div style="display: inline-block;">{{ originallandname.length }}/40</div>
                        <div v-if="error" class="error">{{ error }}</div>
                    </div>
                </el-row>
                <el-row>
                    <div class="upload-form-item-parent">
                        <div class="upload-form-item-label">详细地址:</div>
                        <input v-model="detailedaddress" class="upload-form-item-input" style="height: 80px;">
                    </div>
                </el-row>
                <el-row :gutter="15" class="upload-form-item">
                    <div class="upload-form-item-label">土地无贷款证明:</div>
                    <!-- 土地无贷款证明 -->
                    <div class="upload-form-item-pdf">
                        <input type="file" ref="landcertificateInput" @change="handleFileInputChange(0)" @mouseover="changeCursor">
                        <span class="upload-form-item-text">请上传相关pdf文件,上传文件大小不能超过5M</span>
                    </div>
                </el-row>
                <el-row :gutter="15" class="upload-form-item">
                    <div class="upload-form-item-label">担保地契：(General Warranty Deed):</div>
                    <!-- 土地无贷款证明 -->
                    <div class="upload-form-item-pdf">
                        <input type="file" ref="warrantydeed" @change="handleFileInputChange(1)" @mouseover="changeCursor">
                        <span class="upload-form-item-text">请上传相关pdf文件,上传文件大小不能超过5M</span>
                    </div>
                </el-row>
                <el-row>
                    <div class="upload-form-item-parent">
                        <div class="upload-form-item-label">地理坐标:</div>
                        <input v-model="geographicalcoordinates" class="upload-form-item-input" placeholder="格式:纬度,经度">
                        <span>※请查证后填写准确信息</span>
                    </div>
                </el-row>
                <el-row>
                    <div class="upload-form-item-parent">
                        <div class="upload-form-item-label">土地入口方位:</div>
                        <el-dropdown @command="handleCommand" class="upload-form-item-dropdown">
                            <span class="el-dropdown-link">
                                {{ entrance }}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="东">东</el-dropdown-item>
                                <el-dropdown-item command="西">西</el-dropdown-item>
                                <el-dropdown-item command="南">南</el-dropdown-item>
                                <el-dropdown-item command="北">北</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <span>※请查证后填写精确方向</span>
                    </div>
                </el-row>
                <el-row>
                    <div class="upload-form-item-parent">
                        <div class="upload-form-item-label">土地长度:</div>
                        <input v-model="landlength" class="upload-form-item-input" placeholder="单位: 英尺">
                        <span>※请查证后填写准确信息</span>
                    </div>
                </el-row>
                <el-row>
                    <div class="upload-form-item-parent">
                        <div class="upload-form-item-label">土地宽度:</div>
                        <input v-model="landwidth" class="upload-form-item-input" placeholder="单位: 英尺">
                        <span>※请查证后填写准确信息</span>
                    </div>
                </el-row>
                <el-row>
                    <div class="upload-form-item-parent">
                        <div class="upload-form-item-label">海拔:</div>
                        <input v-model="landheight" class="upload-form-item-input" placeholder="单位: 英尺">
                        <span>※请查证后填写准确信息</span>
                    </div>
                </el-row>
                <el-row>
                    <div class="upload-form-item-img" @mouseover="changeCursor">
                        <img src="../icons/image-upload.png" v-if="islandimg" alt="图片未上传" class="upload-form-item-img-i" @click="handleClick">
                        <img :src="landUrl" v-if="!islandimg" alt="图片已上传" class="upload-form-item-img-i" @click="handleClick">
                        <input type="file" ref="landInput" @change="handleFileInputChange(2)" hidden>
                    </div>
                    <div class="upload-form-item-text">上传图片文件大小不能超过5M</div>
                </el-row>
                <div class="upload-button-parent">
                    <button class="upload-button" @click="upload">
                        <span>提交申请</span>
                    </button>
            </div>
        </div>
</template>

<script>
import axios from "axios";


export default {
    data() {
        return {

            // 土地信息
            ownershipnumber: '', // 土地产权编号
            originallandname: '第一块测试土地', // 土地名称
            error: '',
            detailedaddress: '虚拟世界1号', // 详细地址
            landcertificate: '', // 土地无贷款证明
            warrantydeed: '', // 担保地契
            geographicalcoordinates: '', // 地理坐标
            entrance: '东', // 土地入口方位
            landtype: '不限', // 土地类型
            landlength: '100', // 土地长度
            landwidth: '100', // 土地宽度
            landheight: '100', // 海拔
            landImage: '', // 土地照片
            islandimg: true,
            landUrl: require('../icons/image-upload.png'),
        }
    },
    methods: {
        goBack() { // 返回文本按钮事件
            this.$router.go(-1)
        },
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        

        /* 上传图片功能样式 */
        handleClick() {
            // 点击时执行相应逻辑
            // 触发文件选择器
            this.$refs.landInput.click();
        },
        handleFileInputChange(n) { // 获取用户选择的文件
            if(n === 1) {
                // 获取用户选择的文件对象
                this.warrantydeed = this.$refs.warrantydeed.files[0];
            } else if (n === 0) {
                this.landcertificate = this.$refs.landcertificate.files[0];
            } else if (n === 2) {
                this.landImage = this.$refs.landInput.files[0];
                // 将文件对象转换为 URL
                const url = URL.createObjectURL(this.landImage);
                // 更新当前头像 URL
                this.landUrl = url;
                // 把原图清除换为用户上传的图片
                this.islandimg = false
            }
        },

        /* 限制土地名称格式 */
        checkInput() {
            const regex = new RegExp('^[a-zA-Z0-9\u4E00-\u9FA5]*$');
            if (this.originallandname.length === 40) {
                this.error = '输入字数已达上限';
            } else if (!regex.test(this.originallandname)) {
                this.error = '只允许输入数字、汉字和英文字母';
            } else {
                this.error = '';
            }
        },

        /* 土地详情-土地类型下拉菜单事件 */
        handleCommand(command) {
            this.landtype = command;
        },

        /* 提交按钮事件 */
        upload() {
            // 将文件转化为 FormData
            const formData = new FormData();
            formData.append('ownershipnumber', this.ownershipnumber);
            formData.append('originallandname', this.originallandname);
            formData.append('detailedaddress', this.detailedaddress);
            formData.append('landcertificate', this.landcertificate);
            formData.append('warrantydeed', this.warrantydeed);
            formData.append('geographicalcoordinates', this.geographicalcoordinates);
            formData.append('entrance', this.entrance);
            formData.append('landlength', this.landlength + '英尺');
            formData.append('landwidth', this.landwidth + '英尺');
            formData.append('height', this.landheight + '英尺');
            formData.append('landpicture', this.landImage);
            
      
            // 发送 Ajax 请求
            this.$http.post('/v1/originalLand/PersonalInformation', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then(response => {
                alert(response.data.data);
            }).catch(error => {
                console.error(error);
                alert(error.data);
            });
        }
    }
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container {
    display: block;
    width: 100%;
    background-color: black;
}



/* -----------------------------------------返回文本按钮------------------------------------------ */
/* 返回文本按钮 */
.go-back-button {
    padding: 20px;
    white-space: nowrap;
}
/* 返回文本按钮左箭头图标 */
.go-back-button-img {
    vertical-align: top;
    display: inline-block;
    height: 26.67px;
    width: 40px;
}
/* 返回文本按钮文本 */
.go-back-button-text {
    vertical-align: top;
    display: inline-block;
    color: #fff;
    font-size: 20px;
}



/* -----------------------------------------上传公司负责人信息填写表单------------------------------------------ */
/* 表单层-更换颜色 */
.upload-form {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    background-color: #646064;
    padding: 20px;
    border-radius: 6px;
    white-space: nowrap;
}
.upload-form-item-parent {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
/* 表单元素 */
.upload-form-item {
    padding: 10px;
    /* 父组件加入后让子组件平齐 */
    white-space: nowrap;
}
/* ------------------------------上传文本样式------------------------------- */
/* 表单元素-标签 */
.upload-form-item-label {
    width: 150px;
    display: inline-block;
    font-size: 16px;
    color: #fff;
    /* 子组件加入后让同行元素平行 */
    vertical-align: top;
}
/* 表单元素-输入框 */
.upload-form-item-input {
    height: 20.67px;
    width: 300px;
    display: inline-block;
    margin-left: 20px;
    border-radius: 4px;
    /* 子组件加入后让同行元素平行 */
    vertical-align: top;
}
/* ------------------------------上传图片样式------------------------------- */
/* 图片框 */
.upload-form-item-img {
    height: 160px;
    width: 240px;
    display: inline-block;
    padding: 10px;
    margin-right: 30px;
}
/* 图片需要自适应到父块大小 */
.upload-form-item-img-i {
    padding: 0;
    margin: 0;
    max-width: 100%;
    max-height: 100%;
}
/* 描述文本 */
.upload-form-item-text {
    font-size: 16px;
    color: #fff;
}
/* ------------------------------上传pdf文件样式------------------------------- */
/* pdf文件 */
.upload-form-item-pdf {
    display: inline-block;
    margin-top: 40px;
    margin-bottom: 40px;
    /* 子组件加入后让同行元素平行 */
    vertical-align: top;
}



/* -----------------------------------------上传土地信息填写表单------------------------------------------ */
/* 土地名称输入错误提示 */
.error {
    color: red;
    font-size: 12px;
}
/* 土地类型下拉菜单 */
.upload-form-item-dropdown {
    height: 20.67px;
    width: 100px;
    display: inline-block;
    margin-left: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 1);
    border-radius: 6px;
    background-color: #fff;
    /* 子组件加入后让同行元素平行 */
    vertical-align: top;
}
/* 土地类型下拉菜单组件 */
.el-dropdown-link {
    cursor: pointer;
    color: #646064;
    margin-left: 60%;
}
.el-icon-arrow-down {
    font-size: 12px;
}
/* ------------------------------上传提交土地信息表单按钮------------------------------- */
.upload-button-parent {
    margin-top: 50px;
    width: 100%;
    text-align: center;
}
.upload-button {
    height: 50px;
    width: 100px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 1);
    border-radius: 6px;
    background-color: #dedcdc;
}
</style>