<template>
    <div class="home-page">
        <div class="home-page-header">
            <div class="home-page-header-logo">
                <img src="../icons/logo.png" style="object-fit: cover; height: 48px;" />
            </div>
            <!-- <div style="margin-top: 20px; font-size: 20px; color: #fff; font-weight: bold">大图智能科技有限公司</div> -->
        </div>
        <div class="home-page-main">
            <keep-alive>
                <router-view />
            </keep-alive>
        </div>
        <div class="home-page-bottom">
            <div class="home-page-bottom-row">
                <div style="display: flex; flex-direction: column; align-items: center" @click="TurnToHome">
                    <img src="../images/house-g.png" class="home-page-bottom-icon" v-show="selectPage !== '1'" />
                    <img src="../images/house-p.png" class="home-page-bottom-icon" v-show="selectPage === '1'" />
                    <span style="text-align: center" :style="$i18n.locale === 'en'?'font-size: 12px':''">{{$t('MobileHome.page1')}}</span>
                </div>
            </div>
            <div class="home-page-bottom-row">
                <div style="display: flex; flex-direction: column; align-items: center" @click="TurnToShop">
                    <img src="../images/market-g.png" class="home-page-bottom-icon" v-show="selectPage !== '2'" />
                    <img src="../images/market-p.png" class="home-page-bottom-icon" v-show="selectPage === '2'" />
                    <span style="text-align: center" :style="$i18n.locale === 'en'?'font-size: 12px':''">{{$t('MobileHome.page2')}}</span>
                </div>
            </div>
            <div class="home-page-bottom-row">
                <div style="display: flex; flex-direction: column; align-items: center" @click="TurnToCart">
                    <img src="../images/shopping-trolley-g.png" class="home-page-bottom-icon" v-show="selectPage !== '3'" />
                    <img src="../images/shopping-trolley-p.png" class="home-page-bottom-icon" v-show="selectPage === '3'" />
                    <span style="text-align: center" :style="$i18n.locale === 'en'?'font-size: 12px':''">{{$t('MobileHome.page3')}}</span>
                </div>
            </div>
            <div class="home-page-bottom-row">
                <div style="display: flex; flex-direction: column; align-items: center" @click="TurnToInfor">
                    <img src="../images/user-g.png" class="home-page-bottom-icon" v-show="selectPage !== '4'" />
                    <img src="../images/user-p.png" class="home-page-bottom-icon" v-show="selectPage === '4'" />
                    <span style="text-align: center" :style="$i18n.locale === 'en'?'font-size: 12px':''">{{$t('MobileHome.page4')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.selectPage = this.$route.query.index;
    },
    watch: {
        "$route.query.index": function (newIndex) {
            this.selectPage = newIndex;
        },
    },
    data() {
        return {
            selectPage: "1", // 选中的页面
        };
    },
    methods: {
        TurnToHome() {
            this.selectPage = "1";
            this.$router.push("/mobile/home-page?index=1").catch((err) => {
                if (err.name !== "NavigationDuplicated") {
                    // 检查错误的名称是否为 "NavigationDuplicated"，如果不是则抛出错误。
                    throw err;
                }
            });
        },
        TurnToShop() {
            this.selectPage = "2";
            this.$router.push("/mobile/land-market?index=2").catch((err) => {
                if (err.name !== "NavigationDuplicated") {
                    // 检查错误的名称是否为 "NavigationDuplicated"，如果不是则抛出错误。
                    throw err;
                }
            });
        },
        TurnToCart() {
            this.selectPage = "3";
            this.$router.push("/mobile/shopping-cart?index=3").catch((err) => {
                if (err.name !== "NavigationDuplicated") {
                    // 检查错误的名称是否为 "NavigationDuplicated"，如果不是则抛出错误。
                    throw err;
                }
            });
        },
        TurnToInfor() {
            this.selectPage = "4";
            this.$router.push("/mobile/my-infor?index=4").catch((err) => {
                if (err.name !== "NavigationDuplicated") {
                    // 检查错误的名称是否为 "NavigationDuplicated"，如果不是则抛出错误。
                    throw err;
                }
            });
        },
    },
};
</script>

<style scoped>
.home-page {
    /* 第一层 */
    height: 100%;
    width: 100%;
    position: relative;
}
.home-page-header {
    height: 366px;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    background-image: linear-gradient(to bottom, #0083e0 10%, #f9fafb);
}
.home-page-header-logo {
    /* logo区域外层 */
    margin-left: 20px;
    margin-top: 10px;
}
.home-page-bottom {
    /* 底部 */
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
}
.home-page-bottom-row {
    /* 底部分区 */
    height: 100%;
    width: 25%;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
}
.home-page-bottom-icon {
    /* 图标 */
    height: 25px;
    width: 25px;
    max-height: 30px;
    max-width: 30px;
}
.home-page-main {
    /* 主页区域 */
    position: absolute;
    top: 70px;
    width: 100%;
    height: calc(100% - 129px);
}
</style>
