<template>
    <!-- container布局容器,包括头部和子页面区域 -->
    <div class="container-body">
        <div style="height: auto;">
            <div class="header-container">
                <img src="../icons/LeftArrow-white.png" alt="goBackButton" class="header-goback-button" @mouseover="changeCursor" @click="goBack">
                <div class="header-logo-parent">
                    <img src="../assets/no-image.png" alt="logo" class="header-logo-img">
                    <div class="header-logo-text">
                        <h1>大图智能科技有限公司</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-container">
            <!-- 路由占位符 -->
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        goBack() {
            this.$router.go(-1)
        },
    }
}
</script>

<style scoped>
.container-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.header-container {
    height: 100px;
    position: relative;
    background-color: #4a4a4a;
}
.header-goback-button {
    /* 图片自适应到合适大小 */
    max-width: 80%;
    max-height: 80%;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
.header-logo-parent {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}
.header-logo-img {
    padding: 10px;
    height: 80px;
    width: 80px;
}
.header-logo-text {
    display: inline-block;
    vertical-align: top;
    color: #fff;
}
.main-container {
    flex: 1;
}
</style>