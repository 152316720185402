<template>
    <div class="main-container2">
        <div class="tabs">
            <div class="tabs-head">
                <div class="tabs-head-item" :class="isTab('交易成功')" @click="tabsControl('交易成功')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">交易成功</span>
                </div>
                <div class="tabs-head-item" :class="isTab('已退款')" @click="tabsControl('已退款')">
                    <span class="tabs-head-item-text" @mouseover="changeCursor">已退款</span>
                </div>
            </div>
            <div class="search-container">
                <div class="search-box">
                    <div class="search-input">
                        <span class="search-input-label">订单编号</span>
                        <el-input v-model="search.id" placeholder="请输入订单编号"></el-input>
                    </div>
                    <div class="search-input" style="margin-top: 20px;">
                        <span class="search-input-label">卖家编号</span>
                        <el-input v-model="search.sellerid" placeholder="请输入卖家编号"></el-input>
                    </div>
                </div>
                <div class="search-box">
                    <div class="search-input">
                        <span class="search-input-label">买家编号</span>
                        <el-input v-model="search.buyerid" placeholder="请输入买家编号"></el-input>
                    </div>
                    <div class="search-input" style="margin-top: 20px;">
                        <span class="search-input-label">时间范围</span>
                        <el-date-picker
                            v-model="search.date"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                </div>
                <div class="search-box">
                    <div class="search-input">
                        <el-button @click="searchOrder">查询</el-button>
                        <el-button style="margin-left: 20px;" @click="resetOrder">重置</el-button>
                    </div>
                    <div class="search-input" style="color: red;">*请尽可能输入短的关键词，宁可缺字少字也不要错字漏字！</div>
                </div>
            </div>
            <div class="tabs-content">
                <div v-if="!order.isReview">
                    <el-row :gutter="15" class="tabs-content-row">
                        <el-col :span="4"><i>{{ order.whichtab === '交易成功' ? '交易时间' : '申请时间' }}</i></el-col>
                        <el-col :span="4"><i>订单编号</i></el-col>
                        <el-col :span="4"><i>卖家id</i></el-col>
                        <el-col :span="4"><i>买家id</i></el-col>
                        <el-col :span="4"><i>{{ order.whichtab === '交易成功' ? '交易金额' : '退款金额' }}</i></el-col>
                        <el-col :span="4"><i>操作</i></el-col>
                    </el-row>
                    <el-skeleton :loading="loading" :rows="10" animated />
                    <el-row :gutter="15" v-for="(apply, index) in showCompany" :key="index" class="tabs-content-row" v-show="!loading">
                        <div class="tabs-content-col">
                            <el-col :span="4"><i>{{ order.whichtab === '交易成功' ? apply.ordertime : apply.refundtime }}</i></el-col>
                            <el-col :span="4"><i>{{ apply.separateOrderId }}</i></el-col>
                            <el-col :span="4"><i>{{ apply.sellerid }}</i></el-col>
                            <el-col :span="4"><i>{{ apply.buyerid }}</i></el-col>
                            <el-col :span="4"><i>{{ apply.totalprice }}</i></el-col>
                            <el-col :span="4">
                                <i @mouseover="changeCursor" style="text-decoration: underline;" @click="deleteOrder(apply.id)">取消订单</i>
                                &nbsp;
                                <i @mouseover="changeCursor" style="text-decoration: underline;" @click="landReview(apply.id)">查看详情</i>
                            </el-col>
                        </div>
                    </el-row>
                    <el-pagination
                        @size-change="pageSizeChange"
                        @current-change="pageCurrentChange"
                        background
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="showLength">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$http.get('/v1/administrator/select-ordermanage-refund?status=' + this.order.whichtab)
            .then(res => {
                this.orderSuccess = res.data.data
                this.initializePage()
            }).catch(error => {
                // 处理错误
            });
    },
    data() {
      return {
        example: { // 纯测试数据，不显示
            "id": 193,
            "separateOrderId": "169174718447578536",
            "ordertime": "2023-08-11T17:46:24.476324",
            "unitlandid": "6003",
            "landid": "S725",
            "landname": null,
            "salemeans": "立即购买",
            "totalprice": 1301.0,
            "buyerid": "43a89960781b41de9e16c2575772dc54",
            "sellerid": null,
            "addressid": "171",
            "needpaper": false,
            "orderstatus": 1,
            "processstatus": "退款中",
            "refundreason": "不想要了",
            "isdelete": null,
            "refundtime": "2023-08-21T15:49:03",
            "originalLand": null
        },
        loading: true, // 控制骨架屏
        orderSuccess: null, // 从后端接收的待审核退款申请
        orderRefund: null, // 从后端接收的已驳回退款申请
        showCompany: null, // 显示的当页订单信息，根据页码页距控制
        showLength: 0, // 条数
        currentPage: 1, // 页码
        pageSize: 10, // 每页条数

        search: {
            id: null,
            sellerid: null,
            buyerid: null,
            date: null,
        },
        pickerOptions: { // 选择日期的组件代码
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },

        order: { // 父子组件传值
            whichtab: '交易成功', // 两种状态
            id: '',
            isReview: false // 土地上传资格审查详情页是否点入
        },
      };
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.order.whichtab = n
            this.order.isReview = false
            if (this.order.whichtab === '已退款' && !this.orderRefund) { // 已退款的数据未向后端申请时先申请
                this.loading = true
                this.$http.get('/v1/administrator/select-ordermanage-refund?status=' + this.order.whichtab)
                .then(res => {
                    this.orderRefund = res.data.data
                    this.initializePage()
                }).catch(error => {
                    // 处理错误
                    console.error(error.data)
                });
            }
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.order.whichtab) {
                return 'tabs-head-item-select'
            } else {
                return
            }
        },
        initializePage() { // 初始化
            this.currentPage = 1
            if (this.order.whichtab === '交易成功') {
                this.showLength = this.orderSuccess.length
                this.showCompany = this.orderSuccess.slice(0,this.pageSize - 1)
            } else if (this.order.whichtab === '已退款') {
                this.showLength = this.orderRefund.length
                this.showCompany = this.orderRefund.slice(0,this.pageSize - 1)
            }
            this.loading = false
        },
        pageSizeChange(val) {
            // 计算索引值
            const start = (this.currentPage - 1) * val
            const end = this.currentPage * val - 1
            // 更新显示的数据
            if (this.order.whichtab === '交易成功') {
                this.showCompany = this.orderSuccess.slice(start,end)
            } else if (this.order.whichtab === '已退款') {
                this.showCompany = this.orderRefund.slice(start,end)
            }
            // 更新页距
            this.pageSize = val
        },
        pageCurrentChange(val) {
            // 计算索引值
            const start = (val - 1) * this.pageSize
            const end = this.pageSize * val - 1
            // 更新显示的数据
            if (this.order.whichtab === '交易成功') {
                this.showCompany = this.orderSuccess.slice(start,end)
            } else if (this.order.whichtab === '已退款') {
                this.showCompany = this.orderRefund.slice(start,end)
            }
            // 更新页码
            this.currentPage = val
        },
        searchOrder() {
            if (this.order.whichtab === '交易成功') {
                if (!this.search.id && !this.search.sellerid && !this.search.buyerid && !this.search.date) {
                    return
                }
                let array = this.orderSuccess
                if (this.search.id) {
                    array = array.filter(function(item){
                        return item.id === this.search.id
                    })
                }
                if (this.search.sellerid) {
                    array = array.filter(function(item){
                        return item.sellerid === this.search.sellerid
                    })
                }
                if (this.search.buyerid) {
                    array = array.filter(function(item){
                        return item.buyerid === this.search.buyerid
                    })
                }
                if (this.search.date) {
                    array = array.filter(function(item){
                        if (this.order.whichtab === '交易成功') {
                            return item.ordertime >= this.search.date[0] && item.ordertime <= this.search.date[1]
                        }
                    })
                }
                this.currentPage = 1
                this.showCompany = array.slice(0,this.pageSize - 1)
            }
        },
        resetOrder() {
            this.search.id = null
            this.search.sellerid = null
            this.search.buyerid = null
            this.search.date = null
            this.initializePage()
        },
        landReview(id) {
            this.$router.push('/admin/order-manage/detail?id=' + id)
        },
        deleteOrder(id) { // TODO取消订单,不知道传什么参数
            this.$http.get('/v1/order/cancel-order?status=' + this.order.whichtab)
                .then(res => {
                    this.orderRefund = res.data.data
                    this.initializePage()
                }).catch(error => {
                    // 处理错误
                    console.error(error.data)
                });
        },
    },
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container2 {
    height: 100%;
    background-color: black;
    padding: 50px;
}
.tabs {
    height: 100%;
    width: 100%;
}



/* --------------------------------------------选项卡样式-------------------------------------------- */
.tabs-head {
    width: 100%;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
    /* 在父块加上position: relative;并在子块加上position: absolute;来控制子块在父块内的位置 */
    position: relative;
}
.tabs-head-item {
    display: inline-block;
    height: 50px;
    width: 100px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 14px;
    color: #fff;
    position: relative;
}
.tabs-head-item-select {
    padding: 0;
    margin: 0;
    border-bottom: none;
    background-color: #383838;
    color: aqua;
    font-size: 16px;
}
.tabs-head-item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}
/* 新增企业按钮 */
.tabs-head-add {
    display: inline-block;
    position: absolute;
    right: 0;
}
.tabs-head-add-button {
    height: 50px;
    width: 100px;
    border: 1px solid #383838;
    border-radius: 6px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}

/* --------------------------------------------搜索栏样式-------------------------------------------- */
.search-container {
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    color: #fff;
}
.search-box {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}
.search-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.search-input-label {
    width: 100px;
}



/* --------------------------------------------管理页面样式-------------------------------------------- */
/* -------------------------------土地上传管理页面------------------------------- */
.tabs-content {
    padding: 20px;
}
/* 信息栏样式 */
.tabs-content-row {
    padding: 10px;
    text-align: center;
    color: #fff;
}
.tabs-content-col {
    height: 50px;
    /* 采用`flex`布局，则可以通过设置`justify-content`和`align-items`属性来实现水平和垂直居中 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    background-color: #a7a5a5;
    border: 2px solid #a7a5a5;
    border-radius: 6px;
}
</style>