<template>
    <div class="first-main-container">
        <!-- 左上角返回，返回登录页面 -->
        <div @click="goBack" class="goback-parent">
            <img src="../icons/左箭头.png" alt="返回图标" class="goback-img">
            <span @mouseover="changeCursor">土地详情</span>
        </div>
        <div class="detail-parent">
            <div class="detail-box">
                <div class="detail-box-in" style="width: 100%;">
                    <canvas ref="bigmap" :width="canvasSize" :height="canvasSize" class="mobile-detail-map mx-1 mb-3 shadow-lg rounded-lg  border-3 border-gray"></canvas>
                    <img src="../assets/no-image.png" class="detail-box-img">
                    <div class="detail-box-text">
                        <span style="font-size: 20px;font-weight: bold;">土地名称:{{ LandInfor.landname }}</span>
                        <span style="margin-top: 10px;">土地块数:{{ LandInfor.unitLand.landblocks }}</span>
                        <span style="margin-top: 50px;margin-bottom: 50px;">当前价格:{{ LandInfor.totalprice }}</span>
                        <button class="bg-blue-500 text-white hover:bg-blue-600 rounded px-4 py-2 w-26" @click="addLandCart" @mouseover="changeCursor" v-if="haveAddIn">加入购物车</button>
                        <button class="bg-gray-300 text-gray-600 rounded px-4 py-2 flex items-center w-28" @mouseover="changeCursor" v-if="!haveAddIn">已在购物车</button>
                        <button class="detail-buy-button" @click="showModal" @mouseover="changeCursor">立即购买</button>
                    </div>
                </div>
            </div>
            <div class="detail-box-row">
                <div class="detail-box-in" style="justify-content: center;align-items: center;">
                    <span>卖家信息</span>
                    <div class="circle-image">
                        <img :src="users.avatarUrl" alt="用户头像" class="p-0 m-0" />
                    </div>
                    <div class="flex flex-col" style="padding: 20px;">
                        <span style="margin-top: 20px;">ID:{{ LandInfor.sellerid }}</span>
                        <span style="margin-top: 20px;">昵称:{{ LandInfor.seller }}</span>
                        <button class="detail-buy-button" @click="TalkSeller" @mouseover="changeCursor" style="margin-top: 50px;">联系卖家</button>
                        <span style="margin-top: 20px;">查看用户其他挂牌信息></span>
                    </div>
                </div>
                <div class="detail-box-in" style="margin-left: 10px;">
                    <div class="flex flex-row">
                        <span style="margin-top: 20px;margin-left:10px;" :style="tabControl? 'text-decoration: underline;' : ''" @mouseover="changeCursor" @click="tabControl = true">详情信息</span>
                        <span style="margin-top: 20px;margin-left:10px;" :style="tabControl? '' : 'text-decoration: underline;'" @mouseover="changeCursor" @click="tabControl = false">原始土地信息</span>
                    </div>
                    <div class="flex flex-col" style="padding: 20px;" v-show="tabControl">
                        <span>区域坐标:{{ LandInfor.unitLand.communitynumber }}</span>
                        <span style="margin-top: 20px;">编号:{{ LandInfor.unitlandid }}</span>
                        <span style="margin-top: 20px;">海拔:{{ LandInfor.originalLand.height }}</span>
                        <span style="margin-top: 20px;">土地长度:{{ LandInfor.originalLand.landlength }}</span>
                        <span style="margin-top: 20px;">土地宽度:{{ LandInfor.originalLand.landwidth }}</span>
                    </div>
                    <div class="flex flex-col" style="padding: 20px;" v-show="!tabControl">
                        <span>原始土地编号:{{ LandInfor.originalLand.originallandid }}</span>
                        <span style="margin-top: 20px;">原始土地名称:{{ LandInfor.originalLand.originallandname }}</span>
                        <span style="margin-top: 20px;">开发商ID:{{ LandInfor.originalLand.ownerid }}</span>
                        <span style="margin-top: 20px;">详细地址:{{ LandInfor.originalLand.detailedaddress }}</span>
                        <span style="margin-top: 20px;">地理坐标:({{ LandInfor.originalLand.geographicalcoordinates }})</span>
                    </div>
                </div>
            </div>
            <div class="detail-box">
                <div class="detail-box-in" style="width: 100%">
                    <span style="margin-top: 20px;margin-left:10px;">注意事项</span>
                    <span style="padding: 20px;">交易完成默认给予地表权及数字权</span>
                    <span style="padding: 20px;">地表权：拥有地表权的用户具有使用、改良、售卖该块土地的权利。</span>
                    <span style="padding: 20px;">数字权: 拥有数字权的用户具有在该块土地对应的虚拟土地上进行操作、交易的权利。</span>
                </div>
            </div>
                        <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
                        <!-- <div class="modal" v-if="vmodal" @click="closeModal">
                            <div class="modal-box" v-if="!showError">
                                <span>确定修改密码？</span>
                                <div style="margin-top: 50px;">
                                    <button class="modal-button" @click="add" style="margin-right: 30px;">确定</button>
                                    <button class="modal-button" @click="closeModal">取消</button>
                                </div>
                            </div>
                            <div class="modal-box" v-if="showError">
                                <div>{{ errorText }}</div>
                            </div>
                        </div> -->
        </div>
    </div>
</template>

<script>
export default {
    created() {
        // 不要忘了获取传输过来的土地信息
        this.originallandid = this.$route.query.land;
        this.unitlandid = this.$route.query.merch;
    },
    mounted() {
        this.canvasSize=this.$refs.bigmap.clientWidth;

        // 获取土地详情信息
            this.$http
                .get("/v1/sale/get-saleselect-one?unitlandid=" + this.unitlandid)
                .then((res) => {
                    this.LandInfor = res.data.data;
                    this.LandInfor.createtime = this.LandInfor.createtime.split("")[0];
                    let time1 = setTimeout(() => {
                        // 等待 100 毫秒后再绘制
                        const canvas = this.$refs.bigmap; // 获取 canvas 元素
                        canvas.style.backgroundImage = "url(https://datuland.com" + this.LandInfor.originalLand.landpicture + ")";
                        this.drawGrid();
                        this.drawLand();
                        clearInterval(time1);
                    }, 100);
                })
                .catch((error) => {
                    console.log(error);
                });

        this.isInMyCart();
    },
    data() {
        return {
            originallandid: null, // 与unitlandid共同获取该商品详细信息
            unitlandid: null, // 与originallandid共同获取该商品详细信息
            haveAddIn: true, // 表示是否已加入购物车，已经加入的为false
            LandInfor: {
                id: 138,
                originallandid: " ",
                unitlandid: " ",
                landname: " ",
                sellerid: " ",
                seller: " ",
                salesmethods: " ",
                expirydate: " ",
                unitlandprice: null,
                pricenum: ' ',
                unitlandminprice: null,
                minimumprice: null,
                totalprice: ' ',
                saleslandpicture: null,
                createtime: " ",
                status: " ",
                remark: null,
                originalLand: {
                    id: 0,
                    originallandid: " ",
                    ownershipnumber: null,
                    originallandname: " ",
                    detailedaddress: " ",
                    needpaper: " ",
                    warrantydeed: null,
                    ownerid: " ",
                    entrance: null,
                    geographicalcoordinates: " ",
                    landlength: " ",
                    landwidth: " ",
                    height: " ",
                    digitalright: null,
                    surfaceright: null,
                    landpicture: " ",
                    createdate: " ",
                    landstatus: " ",
                },
                unitLand: {
                    id: 0,
                    communitynumber: " ",
                    originallandid: " ",
                    mergelandname: " ",
                    landnumber: " ",
                    landblocks: 0,
                    landcoordinates: " ",
                    virtualownership: " ",
                    remark: " ",
                    status: " ",
                    createdate: " ",
                },
            },

            canvasSize: 0,
            gridSize: 0, // 保存网格边长，用来绘制土地块时使用
            tabControl: true, // 第三格土地详情栏tab控制参数，true是土地详情，false是原始土地详情
            users: {
                avatarUrl: require("../assets/no-image.png"),
            },


            vmodal: false, // 显示弹窗
            showRules: false, // 输入新密码时显示的密码规则
            showError: false, // 输入完密码后点击确认按钮时验证密码格式是否正常
            errorText: '',
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        goBack() {
            this.$router.push('/mobile/land-market')
        },
        drawGrid() {
            // 绘制大地图网格
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            canvas.width=this.canvasSize;
            canvas.height=this.canvasSize;
            this.gridSize = this.canvasSize / 22;// 清除原有的所有大地图画布元素
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = "#5eee45"; // 设置网格颜色
            ctx.lineWidth = 0.1; // 设置线宽
            for (let i = 0; i < 22; i++) {
                // 绘制大地图网格
                for (let j = 0; j < 22; j++) {
                    ctx.strokeRect(i * this.gridSize, j * this.gridSize, this.gridSize, this.gridSize);
                }
            }
        },
        drawLand() {
            // 提取坐标数据
            const coordinatesPattern = /\((\d+),(\d+)\)~\((\d+),(\d+)\)/;
            const matches = this.LandInfor.unitLand.landcoordinates.replace(/\s/g, '').match(coordinatesPattern);
            // 存储坐标数据
            const start = {
                col: parseInt(matches[1]),
                row: parseInt(matches[2]),
            };

            const end = {
                col: parseInt(matches[3]),
                row: parseInt(matches[4]),
            };

            // 设置长宽参数
            const width = end.col - start.col;
            const height = end.row - start.row;

            // 绘制土地块
            const canvas = this.$refs.bigmap; // 获取 canvas 元素
            const ctx = canvas.getContext("2d"); // 获取绘画环境
            // ctx.fillStyle = "red"; // 设置网格颜色
            ctx.fillStyle = "rgba(0,171,151,0.61)";
            ctx.fillRect(start.col * this.gridSize + 1, start.row * this.gridSize + 1, width * this.gridSize - 2, height * this.gridSize - 2);
        },
        isInMyCart() {
            // 判断该商品是否已经加入购物车
            this.$http
                .get("/v1/shoppingcart/get-landcart")
                .then((res) => {
                    let LandInfor = res.data.data;
                    for (let i = 0; i < LandInfor.length; i++) {
                        if (LandInfor[i].unitlandid === this.unitlandid) {
                            this.haveAddIn = false;
                            break;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        addLandCart() {
            // 加入购物车
            const data = {
                unitlandid: this.LandInfor.unitlandid,
                // landnumber: this.LandInfor.originallandid,
                // landname: this.LandInfor.landname,
                // unitprice: "1000",
                // blocks: this.LandInfor.unitLand.landblocks,
                // totalprice: this.LandInfor.pricenum,
            };
            this.$http
                .post("/v1/shoppingcart/add-landcart", data, {
                    withCredentials: true,
                })
                .then((res) => {
                    this.haveAddIn = false;
                    this.$message({
                        message: "加入购物车成功",
                        type: "success",
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        OpenSeller() {
            // 查看卖家
            this.$router.push({
                path: "/mobile/seller-home?index=" + this.selectPage + "&land=" + this.originallandid + "&merch=" + this.unitlandid + "&seller=" + this.LandInfor.sellerid,
            });
        },
        TalkSeller() {
            // 私聊卖家
            this.$router.push({
                path: "/mobile/private-chat?index=" + this.selectPage + "&land=" + this.originallandid + "&merch=" + this.unitlandid + "&receive=" + this.LandInfor.sellerid,
            });
        },
        showModal() {
            this.vmodal = true
        },
        closeModal() {
            this.vmodal = false
        },
        add() {
            return
        },
    },
}
</script>

<style scoped>
.first-main-container {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.goback-parent {
    display: flex;
    align-items: center; /* 垂直居中 */
    font-size: 20px;
    padding: 20px;
}
.goback-img {
    height: 20.67px;
    width: 40px;
}
.detail-parent {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    flex: 1; /* 占据剩余空间 */
}
.detail-box {
    padding: 20px;
    margin: 0;
}
.detail-box-in {
    height: 400px;
    width: 50%;
    position: relative;
    background-color: #eeeeee;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}
.detail-box-row {
    padding: 20px;
    display: flex;
    flex-direction: row;
}
/* 土地详情图片 */
.detail-box-img {
    height: 350px;
    width: 350px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
}
/* 文本在框内的位置 */
.detail-box-text {
    position: absolute;
    left: 400px;
    top: 25px;
    display: flex;
    flex-direction: column;
}
/* 地理位置坐标的小标签 */
.detail-box-icon {
    position: absolute;
    top: 25px;
    height: 50px;
    width: 100px;
    border-radius: 12px;
    background-color: #eeeeee;
}
.land-map {
    margin-left: 10px;
    background-image: url('../assets/no-image.png');
    background-size: cover;
}
.detail-buy-button {
    height: 40px;
    width: 150px;
    margin-top: 30px;
    background-color: #ddd3fd;
    color: #fff;
    border-radius: 6px;
}
/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    height: 150px;
    width: 300px;
    background-color: #a7a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>