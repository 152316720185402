<template>
    <div ref="container3" class="canvas-landmap3-container">
        <canvas
            ref="backgroundcanvas"
            :width="containerWidth"
            :height="containerHeight"
            class="land-map-background"></canvas>
        <canvas
            ref="canvas"
            :width="containerWidth"
            :height="containerHeight"
            class="land-map"
            @mousedown="onMouseDown"
            @mouseup="onMouseUp"
            @mousemove="onMouseMove"
            @mouseover="changeCursor"></canvas>


        <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
        <div class="modal" v-if="vmodal" @click="closeModal">
            <div class="modal-box">
                <span>{{ vmodalText }}</span>
            </div>
        </div>


        <!-- 左侧土地信息栏 -->
        <div class="land-information-menu" v-if="isLandInforMenu">
            <span style="padding: 10px;">所属原始土地名称：{{ landName }}</span>
            <span style="padding: 10px;">土地坐标：{{ '第' + SecondLandCoordinates + '号二号土地' }}</span>
            <span style="padding: 10px;">{{ '第' + ThirdLandCoordinates + '号三号土地' }}</span>
            <div class="land-information-userland" v-if="userLandView.id">
                <span style="padding: 10px;">当前单位土地坐标:{{ '(' + col + ',' + row + ')' }}</span>
                <span style="padding: 10px;">
                    当前土地名称:{{ userLandView.name }}
                    <el-tooltip class="item" effect="dark" content="点击修改该土地块名称" placement="top-end">
                        <i class="el-icon-edit" v-if="sellThisLandId" @click="changenamevmodal = true"></i>
                    </el-tooltip>
                </span>
                <span>土地块数:{{ userLandView.num }}</span>
                <button class="land-information-menu-button" @mouseover="changeCursor" @click="openSellModal" v-if="sellThisLandId !== null">挂牌销售</button>
            </div>
        </div>
        <!-- 缩放图标 -->
        <div class="land-information-menu-show el-icon-arrow-left" v-if="isLandInforMenu" @click="isLandInforMenu = false" @mouseover="changeCursor"></div>
        <div class="land-information-menu-hidden el-icon-arrow-right" v-if="!isLandInforMenu" @click="isLandInforMenu = true" @mouseover="changeCursor"></div>
        <!-- 弹框的显示和隐藏状态，根据 changenamevmodal 变量决定 -->
        <div class="modal" v-if="changenamevmodal" @click="closeModal">
            <div class="modal-box">
                <span>请输入修改后的土地名称:</span>
                <el-input v-model="changeLandName" placeholder="请输入1~20个字符"></el-input>
                <p v-show="showError" style="font-size: 12px;color: red;">密码格式错误</p>
                <div style="margin-top: 50px;">
                    <button class="modal-button" @click="changeMyLandName" style="margin-right: 30px;" @mouseover="changeCursor">确定</button>
                    <button class="modal-button" @click="closeModal" @mouseover="changeCursor">取消</button>
                </div>
            </div>
        </div>
        <!-- 挂牌弹框-显示和隐藏状态根据 sellLandChild.vmodal 变量决定 -->
        <SellUploadLand v-if="sellLandChild.vmodal" :sellLandChild='sellLandChild'></SellUploadLand>


        <!-- 右侧合并侧边栏 -->
        <div class="land-merge-menu" v-if="whichtab==='1' && isLandMergeMenu">
            <span>土地名称：<input type="text" placeholder="请输入1~20个字符" v-model="mergeLandName"></span>
            <span>土地块数：{{ selectLand.length }}块</span>
            <button class="button-merge" @click="LandMerge" @mouseover="changeCursor">我选好了</button>
        </div>
        <!-- 缩放图标 -->
        <div class="land-merge-menu-show el-icon-arrow-right" v-if="isLandMergeMenu && whichtab==='1'" @click="isLandMergeMenu = false" @mouseover="changeCursor"></div>
        <div class="land-merge-menu-show el-icon-arrow-left" v-if="!isLandMergeMenu && whichtab==='1'" @click="isLandMergeMenu = true" @mouseover="changeCursor"></div>


        <!-- 右侧分割侧边栏 -->
        <div class="land-partition-menu" v-if="whichtab==='2' && isLandPartition">
            <div class="land-partition-tabs">
                <div class="tabs-partition-select-item" :class="isPartitionTab('0')" @click="PartitionTabsControl('0')">
                    <span @mouseover="changeCursor" class="tabs-select-item-text">自动分割</span>
                </div>
                <div class="tabs-partition-select-item" :class="isPartitionTab('1')" @click="PartitionTabsControl('1')">
                    <span @mouseover="changeCursor" class="tabs-select-item-text">手动分割</span>
                </div>
            </div>
            <div class="land-partition-text" v-if="whichpartitiontab==='0'">
                <span>土地名称：{{ userLandName }}</span>
                <span>土地块数：{{ LandPartition.length - 1 }}块</span>
                <el-radio-group v-model="landPartitionAutoRadio" style="display: flex;flex-direction: column;">
                    <el-radio :label="1" style="padding: 20px;color: #fff;">1x1</el-radio>
                    <el-radio :label="2" style="padding: 20px;color: #fff;">2x2</el-radio>
                    <el-radio :label="3" style="padding: 20px;color: #fff;">3x3</el-radio>
                </el-radio-group>
                <button class="button-merge" @click="LandPartitionAuto" @mouseover="changeCursor">我选好了</button>
            </div>
            <div class="land-partition-text" v-if="whichpartitiontab==='1'">
                <span>土地名称：{{ userLandName }}</span>
                <span>土地块数：{{ LandPartition.length - 1 }}块</span>
                <span>分割数量：{{ selectLand.length }}</span>
                <button class="button-merge" @click="LandPartitionHand" @mouseover="changeCursor">我选好了</button>
            </div>
        </div>
        <!-- 缩放图标 -->
        <div class="land-merge-menu-show el-icon-arrow-right" v-if="isLandPartition && whichtab==='2'" @click="isLandPartition = false" @mouseover="changeCursor"></div>
        <div class="land-merge-menu-show el-icon-arrow-left" v-if="!isLandPartition && whichtab==='2'" @click="isLandPartition = true" @mouseover="changeCursor"></div>
        <!-- 弹框的显示和隐藏状态，根据 partitionvmodal 变量决定 -->
        <div class="modal" v-if="partitionvmodal" @click="closeModal">
            <div class="modal-box">
                <span>确定更改选中的土地块？</span>
                <div style="margin-top: 50px;">
                    <button class="modal-button" @click="removePartitionLand" style="margin-right: 30px;" @mouseover="changeCursor">确定</button>
                    <button class="modal-button" @click="closeModal" @mouseover="changeCursor">取消</button>
                </div>
            </div>
        </div>


        <!-- 合并/分割按钮 -->
        <div class="tabs-select">
            <div class="tabs-select-item" :class="isTab('0')" @click="tabsControl('0')">
                <span class="tabs-select-item-text" @mouseover="changeCursor">查看</span>
            </div>
            <div class="tabs-select-item" :class="isTab('1')" @click="tabsControl('1')">
                <span class="tabs-select-item-text" @mouseover="changeCursor">合并</span>
            </div>
            <div class="tabs-select-item" :class="isTab('2')" @click="tabsControl('2')">
                <span class="tabs-select-item-text" @mouseover="changeCursor">分割</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import SellUploadLand from "../landshop/SellUploadLand.vue"; // 土地挂牌组件


export default {
    name: 'Thirdland',
    props: {
        landid: String,
        landName: String,
        SecondLandCoordinates: Number,
        ThirdLandCoordinates: Number,
    },
    components: { // 挂牌组件
        SellUploadLand
    },
    mounted() {
        /* // 所有用户的土地块
        axios.post(this.$oururl + 'originalLand/SelectUnitLand?originallandid=' + this.landid + '&twoland=' + this.SecondLandCoordinates + '&threeland=' + this.ThirdLandCoordinates, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            }).then(res => {
                // 处理返回值
                if(res.data.data === '该土地未分割') { // 开发商用户第一次分割，整块三号土地都属于该用户
                    this.myLandMenu = [{
                        originallandid: this.landid,
                        mergelandname: this.landName,
                        minX: 0,
                        minY: 0,
                        maxX: 21,
                        maxY: 21
                    },]
                    this.ispartition = false
                } else {
                    this.originLandMenu = res.data.data
                }
            }).catch(err => {
                // 处理错误
                alert('出现错误')
            });
        // 当前用户的土地块
        axios.post(this.$oururl + 'originalLand/SelectThisUserUnitLand?originallandid=' + this.landid + '&twoland=' + this.SecondLandCoordinates + '&threeland=' + this.ThirdLandCoordinates, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            }).then(res => {
                // 处理返回值
                if(res.data.data === '该土地未分割') { // 开发商用户第一次分割，整块三号土地都属于该用户
                    this.myLandMenu = [{
                        originallandid: this.landid,
                        mergelandname: this.landName,
                        minX: 0,
                        minY: 0,
                        maxX: 21,
                        maxY: 21
                    },]
                    this.ispartition = false
                } else {
                    this.myLandMenu = res.data.data
                }
            }).catch(err => {
                // 处理错误
                alert('出现错误')
            }); */
        let time1 = setTimeout(() => {  // 等待 100 毫秒后再绘制
            const canvas = this.$refs.backgroundcanvas;  // 获取 canvas 元素
            if (!canvas) { // 判断 canvas 元素是否存在
                console.error('Canvas element is not found');
            } else {
                this.drawBigMapGrid() // 初始化大地图网格
                this.addOriginUserLand()
                this.drawOriginUserLand()
                clearInterval(time1)
            }
        }, 100);
    },
    /* watch: {
        col(newX) {
            this.updateTooltipPosition(newX, this.row)
            if (this.whichtab==='1') { // 将选中的土地方块渲染为橙色，以此表示选中此土地块
                this.bigMapSelect() // 在合并模式，选中的格子为橙色，相邻格子显示为绿色
            } else if(this.whichtab==='2') {
                this.selectLandPartition() // 在分割模式，选中格子为橙色，但是有单位土地边框
            } else {
                this.drawLandSelect() // 在仅查看模式，选中格子不做处理，只显示土地块信息
            }
        },
        row(newY) {
            this.updateTooltipPosition(this.col, newY)
            if (this.whichtab==='1') { // 将选中的土地方块渲染为橙色，以此表示选中此土地块
                this.bigMapSelect() // 在合并模式，选中的格子为橙色，相邻格子显示为绿色
            } else if(this.whichtab==='2') {
                this.selectLandPartition() // 在分割模式，选中格子为橙色，但是有单位土地边框
            } else {
                this.drawLandSelect() // 在仅查看模式，选中格子不做处理，只显示土地块信息
            }
        },
    }, */
    data() {
        return {
            sellLandChild: { // 挂牌界面-父子组件传值
                originallandid: '', // 向子组件传入原始土地编号
                landnumber1: '', // 二号土地坐标
                landnumber2: '', // 三号土地坐标
                vmodal: false // 是否打开该窗口
            },
            testLandId: 'TD012023042096919', // 测试用土地编号
            userLandView: {
                id: null, // 土地id
                name: null, // 土地名称
                num: null, // 土地块数
            },
            ispartition: true, // 是否是第一次进行分割，如果未分割过，不能进行挂牌
            vmodalText: '',
            containerHeight: 1100,
            containerWidth: 1100,
            gridSize: 50,  // 单位土地边长
            colLength: 22, // 计算横坐标方向有多少小方块
            rowLength: 22,
            originX: 0, // 鼠标当前的位置
            originY: 0,
            col: 0, // 鼠标选中的单位土地坐标
            row: 0,
            originLandMenu: [
                {
                    id: 37,
                    originallandid: 'TD012023042096919',
                    mergelandname: '工业土地',
                    childrenid: '5',
                    createdate: '2023-04-27 13:54:28',
                        minX: 1,
                        minY: 2,
                        maxX: 1,
                        maxY: 3
                },
            ], // 用户已经合并/分割后的土地,后端获取
            userLand: [], // 通过上一条计算得出具体土地坐标
            myLandMenu: [
                {
                    id: 36,
                    originallandid: 'TD012023042096919',
                    mergelandname: '工业土地1号',
                    childrenid: '5',
                    createdate: '2023-04-27 13:54:28',
                        minX: 8,
                        minY: 8,
                        maxX: 13,
                        maxY: 15
                },
                {
                    id: 35,
                    originallandid: 'TD012023042096919',
                    mergelandname: '工业土地2号',
                    childrenid: '5',
                    createdate: '2023-04-27 13:54:28',
                        minX: 17,
                        minY: 6,
                        maxX: 18,
                        maxY: 10
                },
                {
                    id: 34,
                    originallandid: 'TD012023042096919',
                    mergelandname: '工业土地3号',
                    childrenid: '5',
                    createdate: '2023-04-27 13:54:28',
                        minX: 16,
                        minY: 6,
                        maxX: 16,
                        maxY: 9
                },
                {
                    id: 33,
                    originallandid: 'TD012023042096919',
                    mergelandname: '工业土地4号',
                    childrenid: '5',
                    createdate: '2023-04-27 13:54:28',
                        minX: 16,
                        minY: 10,
                        maxX: 16,
                        maxY: 10
                },
            ], // 属于该用户自己的所有土地块,后端获取
            myLand: [], // 属于该用户的所有单位土地的集合

            sellThisLandId: null, // 查看-选中的土地可以进行拍卖
            landSelect: {}, // 查看-选中的土地块
            changenamevmodal: false, // 查看-修改该土地块名称-弹框显示
            changeLandName: null, // 查看-修改该土地块名称-修改后名称
            showError: false, // 查看-修改该土地块名称-弹框-名称校验错误

            selectLand: [], // 合并-已经选中的单位土地坐标集合
            isSelectLand: [], // 合并-可以选中的单位土地坐标集合
            mergeLandName: '', // 合并-选中合并土地后取名
            mergeLandNum: [], // 合并-被选中的土地块id

            LandPartition: [], // 分割-选中的用户土地块的单位土地数组集
            partitionLandId: 0, // 分割-选中的待分割土地块在用户土地块二维数组集中的一维下标

            isLandInforMenu: true, // 左侧信息栏是否显示
            isLandMergeMenu: true, // 右侧合并栏是否显示
            isLandPartition: true, // 右侧分割栏是否显示
            whichtab: '0', // 当前模式，1为合并，2为分割
            isMouseDown: false, // 鼠标是否按下
            whichpartitiontab: '0', // 分割的模式:0为自动分割，1为手动分割
            landPartitionAutoRadio: 1, // 自动分割选中的分割块
            vmodal: false, // 确定/取消弹窗
            partitionvmodal: false, // 分割土地的弹窗显示
        }
    },
    methods: {
        changeCursor(event) {
            // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },


/* -------------------------------------------------------------------初始化功能区------------------------------------------------------------------------------ */



        drawBigMapGrid() { // 绘制大地图网格
            const canvas = this.$refs.backgroundcanvas;  // 获取 canvas 元素
            const ctx = canvas.getContext("2d");  // 获取绘画环境
            // 清除原有的所有大地图画布元素
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (let i = 0;i < this.colLength;i++) {  // 绘制大地图网格
                for (let j = 0;j < this.rowLength;j++) {
                    ctx.strokeRect(i*this.gridSize,j*this.gridSize,this.gridSize,this.gridSize)
                }
            }
        },
        addOriginUserLand() { // 根据后端传值传来的用户已经合并分割过的大块土地存入土地坐标
            for(let i = 0;i < this.originLandMenu.length;i++) {
                const land = []
                for(let a = this.originLandMenu[i].minX;a <= this.originLandMenu[i].maxX;a++) {
                    for(let b = this.originLandMenu[i].minY;b <= this.originLandMenu[i].maxY;b++) {
                        land.push({col:a,row:b})
                    }
                }
                land.push({
                    id: this.originLandMenu[i].id,
                    originallandid: this.originLandMenu[i].originallandid,
                    mergelandname: this.originLandMenu[i].mergelandname,
                    childrenid: this.originLandMenu[i].childrenid,
                    createdate: this.originLandMenu[i].createdate,
                    minX:this.originLandMenu[i].minX,
                    maxX:this.originLandMenu[i].maxX,
                    minY:this.originLandMenu[i].minY,
                    maxY:this.originLandMenu[i].maxY})
                this.userLand.push(land)
            }
            for(let i = 0;i < this.myLandMenu.length;i++) { // 录入属于该用户自己的所有土地块包含的单位土地信息
                const land = []
                for(let a = this.myLandMenu[i].minX;a <= this.myLandMenu[i].maxX;a++) {
                    for(let b = this.myLandMenu[i].minY;b <= this.myLandMenu[i].maxY;b++) {
                        land.push({col:a,row:b})
                    }
                }
                land.push({
                    id: this.myLandMenu[i].id,
                    originallandid: this.myLandMenu[i].originallandid,
                    mergelandname: this.myLandMenu[i].mergelandname,
                    childrenid: this.myLandMenu[i].childrenid,
                    createdate: this.myLandMenu[i].createdate,
                    minX:this.myLandMenu[i].minX,
                    maxX:this.myLandMenu[i].maxX,
                    minY:this.myLandMenu[i].minY,
                    maxY:this.myLandMenu[i].maxY})
                this.myLand.push(land)
            }
        },
        drawOriginUserLand() { // 绘制用户土地块
            const canvas = this.$refs.backgroundcanvas;  // 获取 canvas 元素
            const ctx = canvas.getContext("2d");  // 获取绘画环境
            for(let i = 0;i < this.userLand.length;i++) { // 加载所有用户土地
                const max = this.userLand[i].length - 1
                const minX = this.userLand[i][max].minX
                const minY = this.userLand[i][max].minY
                const maxX = this.userLand[i][max].maxX
                const maxY = this.userLand[i][max].maxY
                const n = this.gridSize
                const width = maxX - minX + 1
                const height = maxY - minY + 1
                // 填充颜色
                let j = 0
                ctx.fillStyle = 'rgb(' + Math.floor(255-42.5*i) + ',' + Math.floor(255-42.5*j) + ',0)';
                if(this.userLand[i].length === 2) { // 只有一个单位土地
                    ctx.fillRect(minX * n + 1,minY * n + 1,n - 2,n - 2)
                } else { // 有多个单位土地
                    ctx.fillRect(minX * n + 1,minY * n + 1,width * n - 2,height * n - 2)
                }
            }
            for(let i = 0;i < this.myLand.length;i++) { // 加载所有属于本用户的土地，用绿色表示
                const max = this.myLand[i].length - 1
                const minX = this.myLand[i][max].minX
                const minY = this.myLand[i][max].minY
                const maxX = this.myLand[i][max].maxX
                const maxY = this.myLand[i][max].maxY
                const n = this.gridSize
                const width = maxX - minX + 1
                const height = maxY - minY + 1
                // 填充颜色-绿色
                ctx.fillStyle = 'green';
                if(this.myLand[i].length === 2) { // 只有一个单位土地
                    ctx.fillRect(minX * n + 1,minY * n + 1,n - 2,n - 2)
                } else { // 有多个单位土地
                    ctx.fillRect(minX * n + 1,minY * n + 1,width * n - 2,height * n - 2)
                }
            }
        },
        initializeBigmap() {
            const canvas = this.$refs.canvas;  // 获取 canvas 元素
            const ctx = canvas.getContext("2d");  // 获取绘画环境
            // 清除原有的所有大地图画布元素
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        },
        


/* -------------------------------------------------------------------屏幕页面功能区,包括监听鼠标事件、获取点击位置等------------------------------------------------------------------------------ */
        showLandInfor() {
            // 根据鼠标按下时的坐标获取选中区域的矩形坐标
            const canvas = this.$refs.canvas;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = this.originX - rect.left;
            const canvasY = this.originY - rect.top;
            // 将位置坐标转化为土地块的坐标
            this.col = Math.floor(canvasX / this.gridSize)
            this.row = Math.floor(canvasY / this.gridSize)
        },
        showLandDetail(array,num) { // 更新左侧信息栏信息
            const data = array[array.length - 1]
            this.userLandView.id = data.id
            this.userLandView.name = data.mergelandname
            this.userLandView.num = num
        },
        // 鼠标按下土地地图事件
        onMouseDown(event) {
            this.isMouseDown = true
            this.originX = event.clientX
            this.originY = event.clientY
            if(this.partitionLandId) { // 分割模式选中土地块后，每次操作不能清空土地块颜色
                this.drawLandPartition()
            }
            this.showLandInfor() // 获取土地坐标
            if (this.whichtab==='1') { // 将选中的土地方块渲染为橙色，以此表示选中此土地块
                this.initializeBigmap() // 清空之前的绘图
                this.bigMapSelect() // 在合并模式，选中的格子为橙色，相邻格子显示为绿色
            } else if(this.whichtab==='2') {
                this.selectLandPartition() // 在分割模式，选中格子为橙色，但是有单位土地边框
            } else {
                this.initializeBigmap() // 清空之前的绘图
                this.drawLandSelect() // 在仅查看模式，选中格子不做处理，只显示土地块信息
            }
        },
        onMouseMove(event) {
            if (!this.isMouseDown) {
                if (this.whichtab === '0') {
                    this.floatingframe(event.clientX,event.clientY)
                }
                return;
            }
            if (this.whichtab==='1') { // 合并模式
                this.originX = event.clientX
                this.originY = event.clientY
                this.showLandInfor() // 拖动显示新区域
            }
        },
        onMouseUp() {
            this.isMouseDown = false
        },
        floatingframe(x,y) { // 绘制悬浮框时获取坐标
            // 根据鼠标悬浮的坐标确定土地块坐标
            const canvas = this.$refs.canvas;
            // 获取 canvas 的位置信息
            const rect = canvas.getBoundingClientRect();
            const canvasX = x - rect.left;
            const canvasY = y - rect.top;
            // 将位置坐标转化为土地块的坐标
            const landcol = Math.floor(canvasX / this.gridSize)
            const landrow = Math.floor(canvasY / this.gridSize)
            // 确定绘制的悬浮框坐标
            const drawX = landcol * this.gridSize + this.gridSize / 2
            const drawY = landrow * this.gridSize

            // 清除Canvas
            const ctx = canvas.getContext('2d')

            this.drawHoverBox(ctx,drawX,drawY,landcol,landrow)
        },
        drawHoverBox(ctx,drawX,drawY,landcol,landrow) { // 绘制悬浮框

            // 清除Canvas
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            this.drawLandSelectLoop()

            // 确定悬浮框的宽为80px,高为40px,最上层悬浮框设置为下方,右侧设置为左侧,其他情况设置为上方
            if (landrow === 0 && landcol !== this.colLength - 1) { // 上方
                const y = drawY + this.gridSize
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, y);
                ctx.lineTo(drawX + 5, y + 10);
                ctx.lineTo(drawX - 40, y + 10);
                ctx.lineTo(drawX - 40, y + 50);
                ctx.lineTo(drawX + 40, y + 50);
                ctx.lineTo(drawX + 40, y + 10);
                ctx.lineTo(drawX + 10, y + 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 30, y + 35);
            } else if (landcol === this.colLength - 1 && landrow !== 0) { // 右侧
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, drawY);
                ctx.lineTo(drawX - 10, drawY - 10);
                ctx.lineTo(drawX - 70, drawY - 10);
                ctx.lineTo(drawX - 70, drawY - 50);
                ctx.lineTo(drawX + 20, drawY - 50);
                ctx.lineTo(drawX + 20, drawY - 10);
                ctx.lineTo(drawX - 5, drawY - 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 65, drawY - 22);
            } else if (landcol === this.colLength - 1 && landrow === 0) { // 右上
                const y = drawY + this.gridSize
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, y);
                ctx.lineTo(drawX - 10, y + 10);
                ctx.lineTo(drawX - 60, y + 10);
                ctx.lineTo(drawX - 60, y + 50);
                ctx.lineTo(drawX + 20, y + 50);
                ctx.lineTo(drawX + 20, y + 10);
                ctx.lineTo(drawX - 5, y + 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 55, y + 35);
            } else {
                // 绘制悬浮框的边框
                ctx.beginPath();
                ctx.moveTo(drawX, drawY);
                ctx.lineTo(drawX + 5, drawY - 10);
                ctx.lineTo(drawX - 40, drawY - 10);
                ctx.lineTo(drawX - 40, drawY - 50);
                ctx.lineTo(drawX + 40, drawY - 50);
                ctx.lineTo(drawX + 40, drawY - 10);
                ctx.lineTo(drawX + 10, drawY - 10);
                ctx.closePath();
                ctx.strokeStyle = 'black';
                ctx.stroke();

                // 绘制当前网格的坐标文本
                ctx.font = '20px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`(${landcol}, ${landrow})`, drawX - 35, drawY - 22);
            }
        },
        tabsControl(n) { // 点击选项头选中此页面
            this.whichtab = n
            this.selectLand = []
            this.mergeLandNum = []
            this.LandPartition = []
            this.partitionLandId = undefined
            this.showTooltip = false
            this.userLandName = null
            this.mergeLandName = ''
            this.sellThisLandId = null
            this.initializeBigmap()
        },
        isTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichtab) {
                return 'tabs-head-item-select'
            } else {
                return
            }
        },
        closeModal() { // 关闭弹窗
            this.vmodal = false
            this.partitionvmodal = false
            this.changenamevmodal = false
            this.userLandView.id = null
            this.showError = false
        },
        updateMyLand() { // 在合并/分割结束后，更新本用户土地块，更新本用户单位土地集
            for(let i = 0;i < this.myLandMenu.length;i++) { // 录入属于该用户自己的所有土地块包含的单位土地信息
                const land = []
                for(let a = this.myLandMenu[i].minX;a <= this.myLandMenu[i].maxX;a++) {
                    for(let b = this.myLandMenu[i].minY;b <= this.myLandMenu[i].maxY;b++) {
                        land.push({col:a,row:b})
                    }
                }
                land.push({
                    id: this.myLandMenu[i].id,
                    originallandid: this.myLandMenu[i].originallandid,
                    mergelandname: this.myLandMenu[i].mergelandname,
                    childrenid: this.myLandMenu[i].childrenid,
                    createdate: this.myLandMenu[i].createdate,
                    minX:this.myLandMenu[i].minX,
                    maxX:this.myLandMenu[i].maxX,
                    minY:this.myLandMenu[i].minY,
                    maxY:this.myLandMenu[i].maxY})
                this.myLand.push(land)
            }
        },



/* -------------------------------------------------------------------查看模式功能区------------------------------------------------------------------------------ */
        drawLandSelect() { // 绘制所有被选中的土地格，用橙色标注
            this.sellThisLandId = null
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "orange"
            const result = this.myLand.find(item => item.some(({ col: itemCol, row: itemRow }) => itemCol === this.col && itemRow === this.row));
            if(result !== undefined) { // 属于自己的土地块
                this.showLandDetail(result,result.length - 1)
                this.sellThisLandId = this.myLand.findIndex(item => item.some(({ col, row }) => col === this.col && row === this.row)); // 获取选中的土地块在我的土地集中的一维下标
                // 绘制土地块
                const array = this.myLandMenu[this.sellThisLandId]
                const width = array.maxX - array.minX + 1
                const height = array.maxY - array.minY + 1
                ctx.fillRect(array.minX * this.gridSize + 1,array.minY * this.gridSize + 1,this.gridSize * width - 2,this.gridSize * height - 2)
                this.landSelect = array
            } else {
                const otherland = this.userLand.find(item => item.some(({ col: itemCol, row: itemRow }) => itemCol === this.col && itemRow === this.row));
                if(otherland !== undefined) { // 属于别人的土地块
                    this.showLandDetail(otherland,otherland.length - 1)
                    // 绘制土地块
                    const id = this.userLand.findIndex(item => item.some(({ col: itemCol, row: itemRow }) => itemCol === this.col && itemRow === this.row));
                    const array = this.originLandMenu[id]
                    const width = array.maxX - array.minX + 1
                    const height = array.maxY - array.minY + 1
                    ctx.fillRect(array.minX * this.gridSize + 1,array.minY * this.gridSize + 1,this.gridSize * width - 2,this.gridSize * height - 2)
                    this.landSelect = array
                } else {
                    ctx.fillRect(this.col * this.gridSize + 1,this.row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
                    this.userLandView.id = null
                    this.landSelect = {
                        minX: this.col,
                        minY: this.row,
                        maxX: this.col,
                        maxY: this.row
                    }
                }
            }
        },
        drawLandSelectLoop() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "orange"
            const width = this.landSelect.maxX - this.landSelect.minX + 1
            const height = this.landSelect.maxY - this.landSelect.minY + 1
            ctx.fillRect(this.landSelect.minX * this.gridSize + 1,this.landSelect.minY * this.gridSize + 1,this.gridSize * width - 2,this.gridSize * height - 2)
        },
        changeMyLandName() { // 修改该土地块名称
            if(this.changeLandName.length === 0 || this.changeLandName.length > 20) {
                // 校验名称格式
                this.showError = true
            }
            // 后端组件
        },
        openSellModal() { // 左侧信息栏点击挂牌按钮打开挂牌弹窗
            if(!this.ispartition) {
                this.vmodalText = '请先对土地进行分割'
                this.vmodal = true
                return;
            }
            this.sellLandChild.originallandid = this.landid
            this.sellLandChild.landnumber1 = this.SecondLandCoordinates
            this.sellLandChild.landnumber2 = this.ThirdLandCoordinates
            this.sellLandChild.landid = this.myLandMenu[this.sellThisLandId].id
            this.sellLandChild.landnum = this.myLand[this.sellThisLandId].length - 1
            this.sellLandChild.vmodal = true
        },



/* -------------------------------------------------------------------合并模式功能区------------------------------------------------------------------------------ */



        bigMapSelect() { // 在合并模式，大地图鼠标按下时选中某个区域，此区域高亮显示
            if(this.selectLand.length < 1) { // 第一次选中某个单位土地时，直接加入
            } else if(this.isSelectLand.find(item => item.col === this.col && item.row === this.row) !== undefined){ // 在可选坐标集中
            } else { // 不符合相邻规则，清空原数据重新录入
                this.selectLand = []
                this.isSelectLand = []
            }
            const result = this.myLand.find(item => item.some(({ col: itemCol, row: itemRow }) => itemCol === this.col && itemRow === this.row));
            if(result !== undefined && !this.mergeLandNum.includes(result[result.length - 1].id)) { // 点到属于用户自己的非单位土地
                this.showLandDetail(result,result.length - 1)
                this.mergeLandNum.push(result[result.length - 1].id) // 计数，选中的土地块数
                for(let i = 0;i < result.length - 1;i++) {
                    this.selectLand.push({col:result[i].col,row:result[i].row})
                }
            } else {
                this.vmodalText = '不可选中该土地'
                this.vmodal = true
                this.selectLand = []
                this.isSelectLand = []
                this.mergeLandNum = []
            }
            this.confirmSlectLand()
            this.drawBigMapSelect()
        },
        confirmSlectLand() { // 根据已选中的坐标集计算出可选的坐标集
            for(let i = 0;i < this.selectLand.length;i++) {
                const col = this.selectLand[i].col
                const row = this.selectLand[i].row
                const array1 = {col:col+1,row:row}
                const array2 = {col:col-1,row:row}
                const array3 = {col:col,row:row+1}
                const array4 = {col:col,row:row-1}
                if(this.isSelectLand.find(item => item.col === array1.col && item.row === array1.row) == undefined) {
                    this.isSelectLand.push(array1)
                }
                if (this.isSelectLand.find(item => item.col === array2.col && item.row === array2.row) == undefined) {
                    this.isSelectLand.push(array2)
                }
                if (this.isSelectLand.find(item => item.col === array3.col && item.row === array3.row) == undefined) {
                    this.isSelectLand.push(array3)
                }
                if (this.isSelectLand.find(item => item.col === array4.col && item.row === array4.row) == undefined) {
                    this.isSelectLand.push(array4)
                }
            }
        },
        drawBigMapSelect() { // 合并模式根据选中的单位土地集和可选的单位土地集改变相应土地格颜色进行标注
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "green"
            for(let i = 0;i < this.isSelectLand.length;i++) { // 绘制所有可选择的土地格，用绿色标注
                ctx.fillRect(this.isSelectLand[i].col * this.gridSize + 1,this.isSelectLand[i].row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
            }
            ctx.fillStyle = "orange"
            for(let i = 0;i < this.selectLand.length;i++) { // 绘制所有被选中的土地格，用橙色标注
                ctx.fillRect(this.selectLand[i].col * this.gridSize + 1,this.selectLand[i].row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
            }
        },
        LandMerge() { // 合并按钮
            if (this.selectLand.length === 0 || this.mergeLandNum.length < 2) {
                this.vmodalText = '请至少选择两块土地.'
                this.vmodal = true
                return;
            }
            if (this.mergeLandName === '') {
                this.vmodalText = '请为您的新土地命名.'
                this.vmodal = true
                return;
            } else if(this.mergeLandName.length > 20) {
                this.vmodalText = '名称过长.'
                this.vmodal = true
                return;
            }
            const minX = Math.min(...this.selectLand.map(coord => coord.col));
            const minY = Math.min(...this.selectLand.map(coord => coord.row));
            const maxX = Math.max(...this.selectLand.map(coord => coord.col));
            const maxY = Math.max(...this.selectLand.map(coord => coord.row));
            const width = maxX - minX + 1;
            const height = maxY - minY + 1;
            const aspectRatio = width / height;
            if (aspectRatio >= 4 || aspectRatio <= 0.25 || this.selectLand.length < width * height) {
                this.vmodalText = '已选中的坐标集合组成的新网格必须为正方形或长宽比小于4的长方形.'
                this.vmodal = true
                return;
            }
            // 符合要求，进行后续操作
            const data = { // 设置向后端传输的数据
                originallandid: this.landid, 
                mergelandname: this.mergeLandName,
                landnumber1: this.SecondLandCoordinates.toString(),
                landnumber2: this.ThirdLandCoordinates.toString(),
                mergeList: {
                    minX: minX,
                    minY: minY,
                    maxX: maxX,
                    maxY: maxY
                }
            }

            axios.post(this.$oururl + 'originalLand/SaveMergeUnitLand', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            }).then(res => {
                // 处理返回值
                this.vmodalText = res.data.msg
                this.vmodal = true
                this.myLandMenu = res.data.data
                this.updateMyLand() // 更新该用户土地列表
                this.drawBigMapGrid() // 清除之前的高亮网格并重新绘制网格
                this.drawOriginUserLand() // 绘制所有用户土地
                return
            }).catch(err => {
                // 处理错误
                alert('出现错误')
            });
        },



/* -------------------------------------------------------------------分割模式功能区------------------------------------------------------------------------------ */



        selectLandPartition() { // 在分割模式，选中用户自己的大土地块时，将其加入分割数组集
            const result = this.myLand.find(item => item.some(({ col: itemCol, row: itemRow }) => itemCol === this.col && itemRow === this.row)); // 获取土地块的单位土地数组集
            if(result !== undefined && result.length > 1 && this.partitionLandId === undefined) { // 第一次选中该用户的土地中其中一块
                this.showLandDetail(result,result.length - 1)
                this.partitionLandId = this.myLand.findIndex(item => item.some(({ col, row }) => col === this.col && row === this.row)); // 获取选中的土地块在我的土地集中的一维下标
                this.LandPartition = result
                this.drawLandPartition()
            } else if (result !== undefined && result.length > 1 && this.partitionLandId !== undefined) { // 在已选中该用户土地时,需要判断点的是否为已选中的土地块
                const flag = this.LandPartition.find(item => item.col === this.col && item.row === this.row)
                if(flag && this.whichpartitiontab === '1') { // 在选中的土地块中，录入到单位土地集
                    this.initializeBigmap() // 清空之前的绘图
                    this.selectLand.push({col:this.col,row:this.row})
                    this.drawLandPartition()
                    this.drawLandPartition2()
                } else if(flag && this.whichpartitiontab === '0'){
                    this.initializeBigmap() // 清空之前的绘图
                    // 在自动分割模式中，不进行录入，但要保留选中的橙色
                    this.drawLandPartition()
                } else { // 点中了其他该用户土地块，询问是否更改
                    this.partitionvmodal = true
                }
            } else {
                this.initializeBigmap() // 清空之前的绘图
                this.vmodalText = '不可选中该土地块.'
                this.vmodal = true
            }
        },
        removePartitionLand() { // 在已选中某个土地块时更改土地块
            this.initializeBigmap() // 清空之前的绘图
            const result = this.myLand.find(item => item.some(({ col: itemCol, row: itemRow }) => itemCol === this.col && itemRow === this.row)); // 获取选中土地块一维数组
            this.showLandDetail(result,result.length - 1)
            this.partitionLandId = this.myLand.findIndex(item => item.some(({ col, row }) => col === this.col && row === this.row)); // 获取选中的土地块在我的土地集中的一维下标
            this.LandPartition = result
            this.drawLandPartition()
            this.partitionvmodal = false
        },
        drawLandPartition() { // 选中分割土地块后，将其颜色填充为橙色
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "orange"
            for(let i = 0;i < this.LandPartition.length;i++) {
                ctx.fillRect(this.LandPartition[i].col * this.gridSize + 1,this.LandPartition[i].row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
            }
        },
        drawLandPartition2() { // 在手动分割模式中，用户自己点选该土地块的单位土地
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "yellow"
            for(let i = 0;i < this.selectLand.length;i++) {
                ctx.fillRect(this.selectLand[i].col * this.gridSize + 1,this.selectLand[i].row * this.gridSize + 1,this.gridSize - 2,this.gridSize - 2)
            }
        },
        PartitionTabsControl(n) {
            this.whichpartitiontab = n
        },
        isPartitionTab(n) { // 确定未被选中的选项头样式和选中的样式
            if(n === this.whichpartitiontab) {
                return 'tabs-head-item-select'
            } else {
                return
            }
        },
        /* ------------------------------------------------自动分割------------------------------------------- */
        LandPartitionAuto() { // 自动分割土地
            if(this.partitionLandId === undefined) { // 未选中土地块，不可操作
                this.vmodalText = '请选中一块土地.'
                this.vmodal = true
                return
            }
            const array = this.myLandMenu[this.partitionLandId]
            // 符合要求，进行后续操作
            const data = { // 设置向后端传输的数据
                originallandid: this.landid, 
                mergelandname: '未命名',
                landnumber1: this.SecondLandCoordinates.toString(),
                landnumber2: this.ThirdLandCoordinates.toString(),
                mergeList: {
                    minX: array.minX,
                    minY: array.minY,
                    maxX: array.maxX,
                    maxY: array.maxY
                },
                size: this.landPartitionAutoRadio
            }

            axios.post(this.$oururl + 'originalLand/divideLand', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            }).then(res => {
                // 处理返回值
                this.vmodalText = res.data.msg
                this.vmodal = true
                this.myLandMenu = res.data.data
                this.updateMyLand() // 更新该用户土地列表
                this.drawBigMapGrid() // 清除之前的高亮网格并重新绘制网格
                this.drawOriginUserLand() // 绘制所有用户土地
                return
            }).catch(err => {
                // 处理错误
                alert('出现错误')
            });
        },
        /* ------------------------------------------------手动分割------------------------------------------- */
        LandPartitionHand() { // 手动分割土地
            if (this.selectLand.length === 0) {
                alert('请至少选中一块土地。');
                return;
            }
            const minX = Math.min(...this.selectLand.map(coord => coord.col));
            const minY = Math.min(...this.selectLand.map(coord => coord.row));
            const maxX = Math.max(...this.selectLand.map(coord => coord.col));
            const maxY = Math.max(...this.selectLand.map(coord => coord.row));
            const width = maxX - minX + 1;
            const height = maxY - minY + 1;
            const aspectRatio = width / height;
            const complement = this.myLand[this.partitionLandId].filter((item) => {
                return !this.selectLand.includes(item);
            });
            const minX2 = Math.min(...complement.map(coord => coord.col));
            const minY2 = Math.min(...complement.map(coord => coord.row));
            const maxX2 = Math.max(...complement.map(coord => coord.col));
            const maxY2 = Math.max(...complement.map(coord => coord.row));
            const width2 = maxX2 - minX2 + 1;
            const height2 = maxY2 - minY2 + 1;
            const aspectRatio2 = width2 / height2;
            if (aspectRatio >= 4 || aspectRatio <= 0.25 || this.selectLand.length < width * height) {
                alert('已选中的坐标集合组成的新网格必须为正方形或长宽比小于4的长方形。');
                return;
            } else {
                if(aspectRatio2 >= 4 || aspectRatio2 <= 0.25 || complement.length < width2 * height2) {
                    alert('分割后的两块土地均必须为正方形或长宽比小于4的长方形。');
                    return;
                }
            }
            // 符合要求，进行后续操作
            const data = { // 设置向后端传输的数据
                originallandid: this.landid, 
                mergelandname: '未命名',
                landnumber1: this.SecondLandCoordinates.toString(),
                landnumber2: this.ThirdLandCoordinates.toString(),
                id: this.myLandMenu[this.partitionLandId].id,
                partitionland1: {
                    minX: minX,
                    minY: minY,
                    maxX: maxX,
                    maxY: maxY,
                },
                partitionland2: {
                    minX: minX2,
                    minY: minY2,
                    maxX: maxX2,
                    maxY: maxY2,
                },
            }

            axios.post(this.$oururl + 'originalLand/ManualSegmentation', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            }).then(res => {
                // 处理返回值
                this.vmodalText = res.data.msg
                this.vmodal = true
                this.myLandMenu = res.data.data
                this.updateMyLand() // 更新该用户土地列表
                this.drawBigMapGrid() // 清除之前的高亮网格并重新绘制网格
                this.drawOriginUserLand() // 绘制所有用户土地
                return
            }).catch(err => {
                // 处理错误
                alert('出现错误')
            });
        },
    }
}
</script>

<style scoped>
.canvas-landmap3-container {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  background-color: #fff;
}

.land-map-background {
  background-image: url('../assets/no-image.png');
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.land-map {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}


/* ----------------------------------------悬浮坐标---------------------------------------- */
.tooltip {
  position: absolute;
  height: 25px;
  width: 50px;
  background-color: black;
  color: #fff;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 6px;
  z-index: 1;
}


/* ----------------------------------------屏幕左侧土地信息栏---------------------------------------- */
.land-information-menu {
  height: 600px;
  width: 350px;
  position: absolute;
  top: calc(50% - 300px);
  left: 0;
  background-color: #767676;
  color: #fff;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居中 */
  justify-content: center; /* 垂直居中 */
  z-index: 1;
}
/* 信息栏缩放图标 */
.land-information-menu-show {
  height: 30px;
  width: 20px;
  position: absolute;
  top: calc(50% - 15px);
  left: 350px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #767676;
  font-size: 30px;
  color: #fff;
  z-index: 1;
}
.land-information-menu-hidden {
  height: 30px;
  width: 20px;
  position: absolute;
  top: calc(50% - 15px);
  left: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #767676;
  font-size: 30px;
  color: #fff;
  z-index: 1;
}
.land-information-userland {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居中 */
  justify-content: center; /* 垂直居中 */
}
/* 挂牌及其他左侧信息栏内按钮 */
.land-information-menu-button {
  height: 50px;
  width: 120px;
  margin-top: 20px;
  background-color: #fff;
}


/* ----------------------------------------屏幕右侧合并信息栏---------------------------------------- */
.land-merge-menu {
    height: 300px;
    width: 350px;
    position: absolute;
    top: 20%;
    right: 0;
    background-color: #767676;
    color: #fff;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center; /* 水平居中 */
    justify-content: center; /* 垂直居中 */
    z-index: 1;
}
/* 右侧合并信息栏缩放图标 */
.land-merge-menu-show {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 20%;
    right: 0;
    font-size: 30px;
    background-color: #767676;
    z-index: 2;
}
/* 合并按钮 */
.button-merge {
  height: 50px;
  width: 120px;
  margin-top: 20px;
  font-size: 24px;
  background-color: #fff;
}


/* ----------------------------------------屏幕右侧分割信息栏---------------------------------------- */
.land-partition-menu {
    height: 600px;
    width: 350px;
    position: absolute;
    top: 20%;
    right: 0;
    background-color: #767676;
    color: #fff;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    z-index: 1;
}
.land-partition-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center; /* 水平居中 */
    justify-content: center; /* 垂直居中 */
}
.land-partition-tabs {
    height: 100px;
    width: 100%;
}
.tabs-partition-select-item {
    display: inline-block;
    height: 100%;
    width: calc(50% - 2px);
    padding: 0;
    margin: 0;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 20px;
    color: #fff;
    position: relative;
}


/* ----------------------------------------合并/分割选项卡---------------------------------------- */
.tabs-select {
    position: absolute;
    bottom: 50px;
    right: 100px;
    /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
    white-space: nowrap;
}
.tabs-select-item {
    display: inline-block;
    height: 50px;
    width: 100px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #383838;
    background-color: #a7a7a7;
    /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
    vertical-align: top;
    font-size: 20px;
    color: #fff;
    position: relative;
}
.tabs-head-item-select {
    padding: 0;
    margin: 0;
    border-bottom: none;
    background-color: #383838;
    color: aqua;
    font-size: 20px;
}
.tabs-select-item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将子元素向左、向上移动自身宽高的一半 */
}



/* ----------------------------------------确定/取消的弹窗---------------------------------------- */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 3; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    height: 150px;
    width: 300px;
    background-color: #a7a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>