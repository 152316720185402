<template>
    <div ref="sellerlands" class="mobile-shop-home">
        <van-nav-bar class="bg-gray-50" :title="$t('SellerHome.title')" :left-text="$t('SellerHome.back')" left-arrow @click-left="goBack" />
        <div class="users_profix_parent animate__animated animate__fadeInUp">
            <div class="circle-image">
                <img :src="users.avatarUrl" alt="用户头像" class="p-0 m-0" />
            </div>
            <!-- TODO 没有卖家邮箱-未测试是否有用 -->
            <div class="text-gray-600 text-sm tm-2">{{$t('SellerHome.email')}}:{{sellerEmail?sellerEmail:$t('SellerHome.noEmail')}}</div>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold mt-2 py-2 px-4 rounded" @click="TalkSeller">{{$t('SellerHome.contact')}}</button>
        </div>
        <div class="border-t border-gray-300 mb-2 mt-2"></div>
        <span class="mx-6 text-gray-400 text-sm">{{$t('SellerHome.all')}}:({{ totalNum }}){{$t('SellerHome.num')}}</span>
        <div v-for="(land, index) in LandInfor" :key="index" @click="turnToDetail(index)">
            <ShopCartInfor
                class="animate__animated animate__fast animate__fadeInUp"
                :LandInfor="land"
                :showSelect="false"
                :show-delete="false"
                :index="index"
                ref="MobileLand"
            />
        </div>
        <van-skeleton v-if="LandInfor.length === 0" v-for="i in 5" class="mt-3" title avatar :row="3" :loading="LandInfor.length === 0" />
        <div class="mt-5"></div>
    </div>
</template>

<script>
import ShopCartInfor from "./ShopCartInfor.vue";
export default {
    components: {
        // 土地市场组件
        ShopCartInfor,
    },
    created() {
        this.sellerId = this.$route.query.seller;
        this.selectPage = this.$route.query.index;
        this.originallandid = this.$route.query.land;
        this.unitlandid = this.$route.query.merch;
        this.loadAvatar();
    },
    mounted() {
        // 初始化土地市场列表
        this.$http
            .get("/v1/sale/get-saleselect-byseller?pageNum=" + "1" + "&pageSize=" + "5" + "&sellerid=" + this.sellerId)
            .then((response) => {
                if (this.firstload) {
                    const newData = response.data.data.rows;
                    this.LandInfor = newData;
                    this.pageNum = 2;
                    this.firstload = false;
                    this.totalNum = response.data.data.total;
                    if (newData.length === 0) {
                        this.hasMoreData = false;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        // TODO 添加一个监听滚动条位置的事件处理函数
        // this.$refs.sellerlands.addEventListener('scroll', this.handleScroll)
    },
    data() {
        return {
            // 测试数据
            flag: true, // 控制滚动条监听器触发频率的参数
            hasMoreData: true, // 当没有更多数据时变为false，停止调用接口
            sellerId: null, // 顾名思义，卖家id
            sellerEmail: null, // 卖家邮箱
            selectPage: null, // 下方选项头的属性
            originallandid: null, // 与unitlandid共同获取该商品详细信息
            unitlandid: null, // 与originallandid共同获取该商品详细信息
            LandInfor: [], // 后端获取该卖家名下所有挂牌商品信息
            pageNum: 0,
            firstload: true,
            totalNum: 0, // 该卖家全部商品数量
            users: {
                avatarUrl: require("../assets/no-image.png"),
            },
        };
    },
    methods: {
        loadAvatar() {
            // 加载卖家头像
            this.$http
                .get("/v1/user/see-userphoto", {
                    params: {
                        userid: this.sellerId, // 将用户ID作为请求参数发送给服务器
                    },
                    responseType: "blob", // 设置响应类型为blob
                })
                .then((response) => {
                    const imageBlob = response.data;
                    const imageUrl = URL.createObjectURL(imageBlob);
                    this.$set(this.users, "avatarUrl", imageUrl);
                })
                .catch((error) => {
                    console.error(error);
                });
            // TODO 加载卖家邮箱
            /* this.$http
                .get("/v1/user/personal-centerlist", {
                    params: {
                        userid: this.sellerId, // 将用户ID作为请求参数发送给服务器
                    },
                    responseType: "blob", // 设置响应类型为blob
                })
                .then((response) => {
                    const imageBlob = response.data;
                    const imageUrl = URL.createObjectURL(imageBlob);
                    this.$set(this.users, "avatarUrl", imageUrl);
                })
                .catch((error) => {
                    console.error(error);
                }); */
        },
        handleScroll() {
            let scrollHeight = this.$refs.sellerlands.scrollHeight;
            let scrollTop = this.$refs.sellerlands.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight;

            if (scrollHeight - scrollTop - clientHeight <= 0 && this.hasMoreData) {
                this.flag = false;
                let timer = setTimeout(() => {
                    // 等待 300 毫秒后才能再触发
                    this.flag = true;
                    clearInterval(timer);
                }, 300);
                // 当滚动到底部并且还有更多数据可加载时
                this.loadMoreData();
            }
        },
        loadMoreData() {
            // 请求后端获取更多数据的逻辑
            // 在获取数据后将新数据添加到 LandInfor 数组中
            // 如果没有更多数据可加载，将 hasMoreData 设置为 false
            // 例如：使用 axios 发送异步请求
            this.$http
                .get("/v1/sale/get-saleselect-byseller?pageNum=" + this.pageNum + "&pageSize=" + "5" + "&sellerid=" + this.sellerId)
                .then((response) => {
                    let newData = response.data.data.rows;
                    this.LandInfor = this.LandInfor.concat(newData);
                    this.pageNum += 1;
                    if (newData.length === 0) {
                        this.hasMoreData = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        turnToDetail(index) {
            const newpath = "/mobile/land/detail?index=2&land=" + this.LandInfor[index].originallandid + "&merch=" + this.LandInfor[index].unitlandid;
            this.$router.push({
                path: newpath,
            });
        },
        TalkSeller() {
            // 私聊卖家
            this.$router.push({
                path: "/mobile/private-chat?index=" + this.selectPage + "&land=" + this.originallandid + "&merch=" + this.unitlandid + "&receive=" + this.sellerId,
            });
        },
        goBack() {
            this.$router.go(-1);
        },
    },
    beforeDestroy() {
        // 在组件销毁时移除监听事件
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style scoped>
.mobile-shop-home {
    height: 100%;
    width: 100%;
    overflow: auto;
}
/* ---------------------------------顶部：返回功能与图标------------------------------- */
.mobile-email-top {
    height: 70px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #747474;
    position: relative;
    top: 0;
}
.mobile-email-top-topic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
}
.mobile-email-top-goback {
    max-height: 50px;
    max-width: 50px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
}
.mobile-shop-land {
    display: inline-block;
    width: 100%;
}
/* 用户头像的父块布局，里面包含了头像图片框和文字 */
.users_profix_parent {
    width: 200px;
    margin: 0 auto; /* 左右自动居中 */
    /* 使用了 flex 布局，并通过 `justify-content: center` 和 `align-items: center` 属性将上下两个块垂直对齐。 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* 设置一点边角距离 */
    margin-top: 30px;
}
/* 用户头像布局 */
.users_profix {
    width: auto; /* 设置块的宽度 */
    height: auto; /* 设置块的高度 */
}
/* 用户头像图片设为圆形显示 */
.circle-image {
    width: 100px;
    height: 100px;
    /* 通过 `border-radius: 50%` 把 `<div>` 元素变成圆形，并通过 `overflow: hidden` 隐藏图片元素超出圆形区域的部分 */
    border-radius: 50%;
    overflow: hidden;
}
/* 将图片的宽度设置为 `100%`，以适应圆形区域的大小。 */
.circle-image > img {
    display: block;
    width: 100%;
    height: 100%;
    /* 它会按比例缩放图片并填满整个圆形区域 */
    object-fit: cover; /* 调整图片表现方式 */
}
/* 联系卖家按钮 */
.mobile-land-seller-button {
    height: 40px;
    width: 100%;
    border-radius: 12px;
    background-color: #fda1ff;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
