<template>
    <div class="mobile-email-home">
        <div class="mobile-email-menu relative mb-2">
            <van-dropdown-menu>
                <van-dropdown-item style="height: 100%" v-model="value1" :options="option1" />
            </van-dropdown-menu>
            <div class="absolute top-2 left-0 van-nav-bar__left" @click="goBack">
                <i style="color: #1989fb" class="van-icon van-icon-arrow-left van-nav-bar__arrow"></i>
                <span class="van-nav-bar__text">{{$t('MobileMessage.back')}}</span>
            </div>
            <van-button class="text-sm text-gray-400 absolute top-0 right-0" style="height: 48px; border: 0">{{$t('MobileMessage.clear')}}</van-button>
        </div>
        <van-skeleton v-if="!loaded" v-for="i in [0,1,2,3,4,5]" class="mt-5" avatar  title :row="2" />
        <van-swipe-cell v-if="loaded" v-for="(message, index) in Message" :key="index" class="animate__animated animate__fast animate__lightSpeedInRight">
            <MobileMessage :Message="message" />
            <template #right>
                <van-button square :text="$t('MobileMessage.delete')" type="danger" style="height: 100%" class="rounded-lg shadow-md" />
            </template>
        </van-swipe-cell>
    </div>
</template>

<script>
import MobileMessage from "./MobileMessageForm.vue";

export default {
    components: {
        // 组件
        MobileMessage, // 对话信息
    },
    mounted() {
        if (this.flag) {
            this.flag = false;
            this.$http.get('/v1/mail/select-threadid', {
                withCredentials: true,
            }).then(res => {
                this.Message = res.data.data
                this.loaded = true;
            }).catch(error => {
                console.log(error)
            })
        }
        /* let timer = setTimeout(() => {  // 等待 100 毫秒后再绘制
            this.flag = !this.flag
            clearInterval(timer)
        }, 300); */
    },
    data() {
        return {
            loaded: false,
            value1: 0,
            option1: [
                { text: this.$t('MobileMessage.all'), value: 0 },
                { text: this.$t('MobileMessage.seller'), value: 1 },
                { text: this.$t('MobileMessage.service'), value: 2 },
            ],

            flag: true, // 防抖
            Message: [],
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
.mobile-email-home {
    height: 100%;
    width: 100%;
    background-color: #f9fafb;
    position: relative;
    overflow: auto;
}
/* ---------------------------------顶部：返回与发送图标------------------------------- */
.mobile-email-top {
    height: 70px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #747474;
    position: sticky;
    top: 0;
}
.mobile-email-top-topic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
}
.mobile-email-top-goback {
    max-height: 50px;
    max-width: 50px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
}
/* ---------------------------------第一格：下拉菜单对信息进行筛选------------------------------- */
.mobile-email-menu {
    height: 40px;
    width: 100%;
    background-color: #eeeeee;
}
/* ---------------------------------第二格：用户输入聊天信息框------------------------------- */
.mobile-email-input {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #fff;
    position: relative;
}
/* 删除该条主题信息 */
.mobile-email-box-delete {
    max-height: 30px;
    max-width: 30px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
}
.mobile-email-input-inn {
    margin-left: 20px;
    width: 80%;
    background-color: #fff;
    border: none;
}
</style>
