<template>
    <div class="main-container3">
        <div class="upload-form-parent">
            <div class="go-back-button" @click="goBack" @mouseover="changeCursor">
                <img src="../icons/左箭头.png" class="go-back-button-img">
                <span class="go-back-button-text">{{$t('back.back')}}</span>
            </div>
            <div class="upload-form">
                <div class="upload-form-inn">
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">{{$t('myCompany.companyName')}}:</span>
                        <span>{{ companyName }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">{{$t('myCompany.certificateNumber')}}:</span>
                        <span>{{ ownershipCertificate }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">{{$t('myCompany.companyContactName')}}:</span>
                        <span>{{ companyContact }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <span class="upload-form-item-label">{{$t('myCompany.companyPhoneNumber')}}:</span>
                        <span>{{ companyContactPhone }}</span>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <div class="upload-form-item-text">{{$t('myCompany.idCard')}}:</div>
                        <!-- 身份证正面照 -->
                        <div class="upload-form-item-img" @mouseover="changeCursor">
                            <el-tooltip class="item" effect="dark" :content="$t('myCompany.seeImage')" placement="top-end">
                                <img :src="frontIdImg" alt="身份证正面照" class="upload-form-item-img-i" @click="show(1)">
                            </el-tooltip>
                            <!-- 弹框的显示和隐藏状态，根据 isfrontIdImg 变量决定 -->
                            <div class="lightbox" v-if="isfrontIdImg" @click="hide">
                                <img class="full-image" :src="frontIdImg"/>
                            </div>
                        </div>
                        <!-- 身份证背面照 -->
                        <div class="upload-form-item-img" @mouseover="changeCursor">
                            <el-tooltip class="item" effect="dark" :content="$t('myCompany.seeImage')" placement="top-end">
                                <img :src="reverseIdImg" alt="身份证背面照" class="upload-form-item-img-i" @click="show(2)">
                            </el-tooltip>
                            <!-- 弹框的显示和隐藏状态，根据 isreverseIdImg 变量决定 -->
                            <div class="lightbox" v-if="isreverseIdImg" @click="hide">
                                <img class="full-image" :src="reverseIdImg"/>
                            </div>
                        </div>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <div class="upload-form-item-text">{{$t('myCompany.loanCertificate')}}:</div>
                        <!-- 土地无贷款证明 -->
                        <el-link :href="landcertificateImg" @mouseover="changeCursor">{{$t('myCompany.downLoad')}}</el-link>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <div class="upload-form-item-text">{{$t('myCompany.companyRegistration')}}:</div>
                        <!-- 公司注册信息与注册证明 -->
                        <el-link :href="CPRTImg" @mouseover="changeCursor">{{$t('myCompany.downLoad')}}</el-link>
                    </el-row>
                    <el-row :gutter="15" class="upload-form-item">
                        <div class="upload-form-item-text">{{$t('myCompany.platformContract')}}:</div>
                        <!-- 平台合同扫描件 -->
                        <el-link :href="PFCTImg" @mouseover="changeCursor">{{$t('myCompany.downLoad')}}</el-link>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    /* mounted() { // TODO 未解封
        this.$http.get('/v1/business/CompanyInformationOne?businessid=' + this.companyChild.businessid)
            .then(res => {
                this.companyName = res.data.data.corporationname
                this.ownershipCertificate = res.data.data.housingownership
                this.companyContact = res.data.data.businessname
                this.companyContactPhone = res.data.data.businessphone
                this.frontIdImg = this.url + 'key=businesscard1&businessid=' + this.companyChild.businessid + '&value=' + res.data.data.businesscard1
                this.reverseIdImg = this.url + 'key=businesscard2&businessid=' + this.companyChild.businessid + '&value=' + res.data.data.businesscard2
                this.landcertificateImg = this.pdfurl + 'key=landcertificate&businessid=' + this.companyChild.businessid + '&value=' + res.data.data.landcertificate
                this.CPRTImg = this.pdfurl + 'key=companyregistration&businessid=' + this.companyChild.businessid + '&value=' + res.data.data.companyregistration
                this.PFCTImg = this.pdfurl + 'key=platformcontract&businessid=' + this.companyChild.businessid + '&value=' + res.data.data.platformcontract
            }).catch(error => {
                // 处理错误
            });
    }, */
    data() {
        return {
            companyName: '大图智能科技有限公司',
            ownershipCertificate: 'mine1234',
            companyContact: 'Winston Liu',
            companyContactPhone: '12345678901',


            url: 'http://192.168.1.145:8092/business/seeImages?', // 所有企业图片的baseUrl
            pdfurl: 'http://192.168.1.145:8092/business/seepdfFile?',
            /* 图片url */
            frontIdImg: require('../assets/no-image.png'), // 身份证正面照
            isfrontIdImg: false,
            reverseIdImg: require('../assets/no-image.png'), // 身份证反面照
            isreverseIdImg: false,
            /* 下载链接 */
            landcertificateImg: '', // 土地证明
            CPRTImg: '', // 公司注册信息
            CRImg: '', // 公司注册证明
            PFCTImg: '', // 平台合同扫描件
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        /* 返回按钮事件 */
        goBack() {
            this.$router.go(-1)
        },

        /* 点击图片查看原图事件 */
        show(n) { // 点击图片显示弹窗
            if (n===1) {
                this.isfrontIdImg = true
            } else if (n===2) {
                this.isreverseIdImg = true
            }
        },
        hide() { // 点击弹框外部时，隐藏弹框
            this.isfrontIdImg = false;
            this.isreverseIdImg = false;
        },
    }
}
</script>

<style scoped>
/* body之下第一层，设置黑色背景色 */
.main-container3 {
    height: 100%;
    overflow: auto;
}

/* -----------------------------------------返回文本按钮------------------------------------------ */
/* 返回文本按钮 */
.go-back-button {
    padding: 20px;
    white-space: nowrap;
}
/* 返回文本按钮左箭头图标 */
.go-back-button-img {
    vertical-align: top;
    display: inline-block;
    height: 26.67px;
    width: 40px;
}
/* 返回文本按钮文本 */
.go-back-button-text {
    vertical-align: top;
    display: inline-block;
    font-size: 20px;
}


/* -----------------------------------------上传公司负责人信息填写表单------------------------------------------ */
.upload-form-parent {
    /* 父 `div` 容器设置为 `flex` 容器。然后，将子 `div` 容器设置为 `flex-grow: 1;`，将其自动填充父容器的剩余空间 */
    display: flex;
    flex-direction: column;
    padding: 20px;
}
/* 表的标题 */
.upload-form-topic {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding: 20px;
}
/* 表单层-更换颜色 */
.upload-form {
    min-height: 100%;
    display: flex;
    flex-grow: 1;
    background-color: #eeeeee;
    padding: 20px;
    border-radius: 6px;
    white-space: nowrap;
    flex-direction: column;
}
.upload-form-inn {
    padding: 20px;
    border-radius: 6px;
    border: 2px solid #000000;
}
/* 表单元素 */
.upload-form-item {
    margin-top: 20px;
    padding: 10px;
    /* 父组件加入后让子组件平齐 */
    white-space: nowrap;
}
/* ------------------------------上传文本样式------------------------------- */
/* 表单元素-标签 */
.upload-form-item-label {
    width: 200px;
    display: inline-block;
    font-size: 16px;
    /* 子组件加入后让同行元素平行 */
    vertical-align: top;
}
/* ------------------------------上传图片样式------------------------------- */
/* 图片框 */
.upload-form-item-img {
    height: 160px;
    width: 240px;
    display: inline-block;
    padding: 10px;
    margin-right: 30px;
}
/* 图片需要自适应到父块大小 */
.upload-form-item-img-i {
    padding: 0;
    margin: 0;
    max-width: 100%;
    max-height: 100%;
}
/* 描述文本 */
.upload-form-item-text {
    font-size: 16px;
}
/* ------------------------------图片弹窗------------------------------- */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
  z-index: 1; /* 将弹框置于其他所有元素之上 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-image {
  max-width: 80%;
  max-height: 80%;
}
</style>