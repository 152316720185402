<template>
    <div class="bg-gray-50 pb-20 h-screen flex flex-col">
        <van-nav-bar :title="$t('MobileMessageWindow.topic1') + (LandInfor.seller===undefined?$t('MobileMessageWindow.name'):LandInfor.seller) + $t('MobileMessageWindow.topic2')" :left-text="$t('MobileMessageWindow.back')" left-arrow @click-left="goBack" />
        <div @click="turnToDetail">
            <ShopCartInfor class="animate__animated animate__fadeInDown" :LandInfor="LandInfor" :showSelect="false" :show-delete="false" :index="0" ref="MobileLand" />
        </div>
        <div class="mt-2 pt-2 mx-3 bg-white rounded-lg shadow-md overflow-y-auto flex-grow" ref="messageContainer">
            <MobileMessageInfor v-for="(message, index) in Message" :key="index" :Message="message" />
        </div>
        <div class="fixed bottom-2 left-0 right-0 flex justify-center">
            <button class="py-3 px-10 bg-blue-500 text-white rounded-full" @click="reply">回复</button>
        </div>
    </div>
</template>

<script>
import MobileMessageInfor from "./MobileMessageInfor.vue";
import ShopCartInfor from "./ShopCartInfor.vue";
export default {
    components: {
        // 土地市场组件
        MobileMessageInfor, // 对话信息
        ShopCartInfor,
    },
    created() {
        // 不要忘了获取传输过来的土地信息
        this.selectPage = this.$route.query.index;
        this.threadId = this.$route.query.thread;
        this.unitlandid = this.$route.query.land;
    },
    mounted() {

        // 获取主题消息
        this.$http.get('/v1/mail/select-message?threadid=' + this.threadId, {
            withCredentials: true,
        }).then(res => {
            this.Message = res.data.data
        }).catch(error => {
            console.log(error)
        })
        this.$http.get("/v1/sale/get-saleselect-one?unitlandid=" + this.unitlandid)
            .then((res) => {
                this.LandInfor = res.data.data;

                this.$nextTick(() => {// 滚动到底部
                    const container = this.$refs.messageContainer;
                    container.scrollTop = container.scrollHeight;
                });
            })
            .catch((error) => {
                console.log(error);
            });
    },
    data() {
        return {
            selectPage: null, // 从地址获取
            threadId: null, // 同上
            unitlandid: null, // 挂牌土地信息查询id
            LandInfor: {
                originalLand: {
                    geographicalcoordinates: " ",
                },
            }, // 挂牌土地信息
            Message: [],
            inputText: "", // 用户输入的内容
        };
    },
    methods: {
        turnToDetail(index) {
            const newpath = "/mobile/land/detail?index=2&land=" + this.LandInfor.originallandid + "&merch=" + this.LandInfor.unitlandid;
            this.$router.push({
                path: newpath,
            });
        },
        goBack() {
            this.$router.go(-1);
        },
        reply() {
            // 在消息下回复
            const from = this.Message[0].fromid;
            const receive = this.Message[0].recipientid;
            const flag = this.Message[0].isme;
            let receiveid = null;
            if (flag) {
                receiveid = receive;
            } else {
                receiveid = from;
            }
            this.$router.push({
                path: "/mobile/private-chat?index=" + this.selectPage + "&land=" + this.LandInfor.originallandid + "&merch=" + this.unitlandid + "&receive=" + receiveid + "&thread=" + this.threadId,
            });
        },
    },
};
</script>

<style scoped>
.mobile-email-home {
    height: 100%;
    width: 100%;
    background-color: #f9fafb;
    position: relative;
    overflow: auto;
}
/* ---------------------------------顶部：返回与发送图标------------------------------- */
.mobile-email-top {
    height: 70px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #747474;
    position: sticky;
    top: 0;
}
.mobile-email-top-topic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
}
.mobile-email-top-send {
    height: 50px;
    width: 100px;
    box-shadow: 0 2px 12px 0 rgb(220, 220, 220);
    border-radius: 12px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 18px;
    background-color: #fda1ff;
    color: #fff;
    font-weight: bold;
}
.mobile-email-top-goback {
    max-height: 50px;
    max-width: 50px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
}
/* ---------------------------------第一格：土地商品列表信息------------------------------- */
.mobile-email-land {
    width: 100%;
    background-color: #eeeeee;
}
/* ---------------------------------第二格：用户输入聊天信息框------------------------------- */
.mobile-email-input {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #fff;
}
.mobile-email-input-inn {
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #dcdcdc;
}
/* ---------------------------------底部：回复按钮------------------------------- */
.mobile-message-bottom {
    height: 50px;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgb(220, 220, 220);
    position: sticky;
    bottom: 0;
}
.mobile-message-bottom-button {
    height: 80%;
    width: 60%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    background-color: #fda1ff;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}
</style>
