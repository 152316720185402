<template>
  <!-- 页面布局容器 -->
  <el-container style="overflow: hidden">
    <!-- 页面头部区域 -->
    <el-header style="height: 100px">
      <div class="header_div">
        <img src="@/assets/logo.png" class="header_img"/>
        <span class="header_text">大图智能科技有限公司</span>
      </div>
      <div class="header_dropdown">
        <p class="header_dropdown_welcome">欢迎您</p>
        <el-dropdown class="header_dropdown_menu">
          <span class="el-dropdown-link">{{ users.name }}<i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
          <!-- 点击下拉框触发的事件到methods里找 -->
          <el-dropdown-item command="myEstate">我的地产</el-dropdown-item>
          <el-dropdown-item command="myTrade">我的交易</el-dropdown-item>
          <el-dropdown-item command="myOrder">我的订单</el-dropdown-item>
          <el-dropdown-item command="myWallet">我的钱包</el-dropdown-item>
          <el-dropdown-item command="myAuction">我的拍卖</el-dropdown-item>
          <el-dropdown-item command="mySub">我的订阅</el-dropdown-item>
          <el-dropdown-item command="setting">设置</el-dropdown-item>
          <el-dropdown-item command="unload">切换账号/退出</el-dropdown-item>
        </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container style="height: calc(100% - 100px)">
      <!-- 页面侧边框 -->
      <el-aside :width="isCollapse ? '64px' : '20%'">
        <el-row class="tac">
          <el-col :span="12">
            <h3>
              <el-tooltip class="item" effect="dark" content="点击折叠/展开菜单" placement="top"  slot="append">
                  <div class="toggle-button toggle_button_container" @click="toggleButton">
                      <i class="el-icon-folder-opened"></i>
                      <span style="color:#fff; margin-left: 10px" font-size="64px">主菜单</span>
                  </div>
              </el-tooltip>
            </h3>
            <div class="users_profix_parent">
              <el-tooltip class="item" effect="dark" content="点击更改头像" placement="top">
                <div @mouseover="changeCursor" class="circle-image">
                  <img src="../assets/logo.png" alt="初始头像" v-if="users.isCollapse" style="padding: 0; margin: 0;" @click="handleClick">
                  <img :src="users.avatarUrl" alt="用户头像" v-if="!users.isCollapse" style="padding: 0; margin: 0;" @click="handleClick">
                  <input type="file" ref="fileInput" hidden @change="handleFileSelect">
                </div>
              </el-tooltip>
              <span style="font-size: 20px; margin-top: 10px; color: #fff;">{{ users.name }}</span>
            </div>
            <el-divider><i></i></el-divider>
            <div class="users_profix_parent">
              <el-menu
              default-active="3"
              class="el-menu-vertical-demo right_aside_menu"
              background-color="#333744"
              text-color="#fff"
              active-text-color="#ffd04b"
              router>

                <!-- 一级菜单-主页 -->
                <el-menu-item index="information">
                    <i class="el-icon-document"></i>
                    <span slot="title">我的主页</span>
                </el-menu-item>
              </el-menu>
            </div>
            <!-- 侧边栏菜单 -->
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo left_aside_menu"
              background-color="#333744"
              text-color="#fff"
              active-text-color="#ffd04b"
              :collapse="isCollapse"
              :collapse-transition="false"
              router>

                <!-- 我的地产二级菜单 -->
                <el-submenu index="1">
                    <!-- 一级菜单名称区域 -->
                    <template slot="title">
                        <i class="el-icon-more"></i><span>我的地产</span>
                    </template>
                    <!-- 二级菜单名称区域 -->
                    <el-menu-item-group>
                        <!-- 二级菜单-财务 -->
                        <el-menu-item index="1-1" disabled>
                            <i class="el-icon-user"></i><span>财务</span>
                        </el-menu-item>
                    </el-menu-item-group>
                </el-submenu>

              <!-- 一级菜单-我的土地 -->
              <el-menu-item index="LandMarket">
                <i class="el-icon-menu"></i>
                <span slot="title">土地市场</span>
              </el-menu-item>
              
            </el-menu>
          </el-col>
        </el-row>
      </el-aside>
      <el-main><!-- 页面主体 -->
        <router-view></router-view><!-- 路由占位符 -->
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data () {
    return {
      /* 判断是否折叠变量，初始不折叠，false */
      isCollapse: false,
      /* 右边栏用户数据 */
      users: {
        avatarUrl: 'default-avatar.png', // 用来存储当前头像的 URL
        name: ' ',
        num: '',
        prof: '',
        isCollapse: true,
      },
    }
  },
  methods: {
    /* 此处为点击用户头像上传图片的点击事件 */
    handleFileSelect(event) {
      // 获取用户选择的文件对象
      const file = event.target.files[0];
      // 将文件对象转换为 URL
      const url = URL.createObjectURL(file);
      // 更新当前头像 URL
      this.users.avatarUrl = url;
      // 清空 input 内容
      event.target.value = '';
      // 把原图清除换为用户上传的图片
      this.users.isCollapse = false;
    },
    changeCursor(event) {
      // 鼠标移上去时改变样式
      event.target.style.cursor = "pointer";
    },
    handleClick() {
      // 点击时执行相应逻辑
      // 触发文件选择器
      this.$refs.fileInput.click();
    },
    /* 此处为页面右上角下拉菜单的点击事件 */
    handleCommand(command) {
      this.$message('click on item ' + command);
    },
    /* 这俩是用来在点击了左右侧边栏的下拉菜单时报个数的，没啥用 */
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    toggleButton () {
      this.isCollapse = !this.isCollapse
    }
  },
}
</script>

<style scoped>
/* ---------------------------主页布局容器---------------------------- */
/* body之下第一层，element-ui组件布局 */
.el-container {
  height: 100%;
}



/* ---------------------------顶栏布局容器---------------------------- */
/* 布局容器顶栏容器样式 */
.el-header {
  /* 背景色 */
  background-color: #606266;
  /* 左右贴边对齐 */
  display: flex;
  justify-content: space-between;
  /* 左padding清空 */
  padding-left: 0;
  /* 组件框上下不贴边，居中 */
  align-items: center;
  /* 内容(主要是文字)颜色 */
  color: #fff;
  /* 文字字体大小 */
  font-size: 20px;
}
/* 头部logo和文本的父块 */
.header_div {
  /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
  white-space: nowrap;
}
/* 头部区域logo图片样式 */
.header_img {
  height: 90px;
  width: 90px;
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
  /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
  vertical-align: top;
}
/* 头部区域文本样式 */
.header_text {
  display: inline-block;
  font-size: 40px;
  margin-top: 30px;
  /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
  vertical-align: top;
}
/* 头部右边下拉菜单样式（包括欢迎你和带下拉框的文本） */
.header_dropdown {
  width: 100%;
  text-align: right;
  /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
  white-space: nowrap;
}
/* 头部右边欢迎您文本 */
.header_dropdown_welcome {
  display: inline-block;
  /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
  vertical-align: top;
  margin-right: 20px;
}
/* 头部右边下拉菜单 */
.header_dropdown_menu {
  display: inline-block;
  /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
  vertical-align: top;
  margin-top: 20px;
}
/* 下拉菜单el组件需要的style */
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
  font-size: 20px;
}


/* ---------------------------侧边栏布局容器---------------------------- */
/* 布局容器侧边栏容器样式（主要是背景色） */
.el-aside {
  background-color: #606266;
}


/* ---------------------------左侧边栏布局容器---------------------------- */
/* 左侧折叠/展开文本设置 */
.toggle_button_container {
  margin-left: 10px;
  margin-top: 10px;
}
/* 左侧菜单位置设置 */
.left_aside_menu {
  margin-top: 50px;
}


/* ---------------------------右侧边栏布局容器---------------------------- */
/* 右边侧边栏布局 */
.right_aside_menu {
  height: 100%;
  width: auto;
}
/* 用户头像的父块布局，里面包含了头像图片框和文字 */
.users_profix_parent {
  width: 200px;
  margin: 0 auto; /* 左右自动居中 */
  /* 使用了 flex 布局，并通过 `justify-content: center` 和 `align-items: center` 属性将上下两个块垂直对齐。 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* 设置一点边角距离 */
  margin-top: 30px;
}
/* 用户头像布局 */
.users_profix {
  width: auto; /* 设置块的宽度 */
  height: auto; /* 设置块的高度 */
}
/* 用户头像图片设为圆形显示 */
.circle-image {
  width: 200px;
  height: 200px;
  /* 通过 `border-radius: 50%` 把 `<div>` 元素变成圆形，并通过 `overflow: hidden` 隐藏图片元素超出圆形区域的部分 */
  border-radius: 50%;
  overflow: hidden;
}
/* 将图片的宽度设置为 `100%`，以适应圆形区域的大小。 */
.circle-image > img {
  display: block;
  width: 100%;
  height: 100%;
  /* 它会按比例缩放图片并填满整个圆形区域 */
  object-fit: cover; /* 调整图片表现方式 */
}



/* ---------------------------其他部分（我都忘了这些各自控制啥了）---------------------------- */
.tac {
  width: 100%;
}
/* 主页容器样式-主要是将它的padding属性设为0 */
.el-main {
  background-color: #fff;
  padding: 0;
}
/* 侧边框背景色 */
.el-submenu {
  background-color: #333744;
}
/* 侧边栏宽度(与侧边框保持一致) */
.el-col-12 {
  width: 100%;
}
/* 侧边栏背景色 */
.el-aside {
  background-color: #333744;
}
/* 去除白线(侧边栏与侧边框右侧连接处出现白线，染色去除) */
.el-menu {
  border-right: #333744;
}
/* 折叠展开组件设置 */
.toggle-button {
  background-color: #333744;
  color:#fff;
  font-weight:200;
  font-size: 20px;
  /* 鼠标放上去会变成小手 */
  cursor: pointer;
}
</style>
