<template>
    <div :class="['msg', `msg-${side}`]">
        <div class="msg-bubble ">
            <div class="msg-info flex items-center justify-content">
                <div v-if="side === 'disable-left'" class="msg-img" :style="{ backgroundImage: `url(${img})` }"></div>
                <div>{{ name }}</div>
                <div class="msg-info-time ml-2">{{ time ? time : formatDate(new Date()) }}</div>
            </div>
            <div class="msg-content flex-1">
                <div class="msg-text" :id="id">{{ space2entity(nl2br(htmlEntities(text))) }}</div>
                <a v-if="file" :href="getFileUrl(file)" class="msg-download" :data-file-name="file.name"
                    @click.prevent="downloadFile">下载文件</a>
                <div v-if="progressdown" class="progressdown">{{ progressdown }}</div>
            </div>
            <div class="msg-actions flex items-center">
                <button class="msg-action-forward" :data-id="id" @click.prevent="$emit('forward')">转发</button>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "AppendMessage",
    props: {
        name: String,
        img: String,
        side: String,
        text: String,
        id: {
            type: Number,
            required: false,
        },
        time: {
            type: String,
            default: null
        },
        progressdown: {
            type: String,
            default: null
        },
        file: {
            type: Object,
            default: null
        }
    },
    data() {
        return {};
    },
    methods: {
        space2entity(str) {
            return String(str).replace(/\s/g, '&nbsp;').replace(/\t/g, "&emsp;");
        },
        nl2br(str) {
            return String(str).replace(/(?:\r\n|\r|\n)/g, '<br>');
        },
        htmlEntities(str) {
            return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
        },
        formatDate(date) {
            const h = "0" + date.getHours();
            const m = "0" + date.getMinutes();

            return `${h.slice(-2)}:${m.slice(-2)}`;
        },
        getFileUrl(file) {
            return URL.createObjectURL(file);
        },
        downloadFile(file) {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', this.getFileUrl(this.file), true);
            xhr.responseType = 'blob';
            xhr.onload = () => {
                if (xhr.status === 200) {
                    // 文件下载完成
                    const blob = new Blob([xhr.response], { type: this.file.type });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = this.file.name;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            };
            xhr.onprogress = (event) => {
                // 文件下载进度更新
                if (event.lengthComputable) {
                    const percentage = Math.round((event.loaded / event.total) * 100);
                    this.progressdown = `${percentage}%`;
                }
            };
            xhr.onerror = (event) => {
                console.error('文件下载出错', event);
                this.progressdown = null;
            };

            xhr.send();

            // 开始文件下载，显示进度
            // this.progressdown = '0%';
        },
    },
}
</script>

<style scoped>
.msg {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
}

.msg-left {
    justify-content: flex-start;
}

.msg-bubble {
    max-width: 70%;
    padding: 0.5rem;
    background-color: #E0E0E0;
    border-radius: 1rem;
}

.msg-info {
    font-size: 0.75rem;
}

.msg-img {
    width: 2rem;
    height: 2rem;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.msg-info-time {
    color: #666;
    /* margin-left: auto; */
}

.msg-content {
    margin-left: 1rem;
}

.msg-text {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
}

.msg-download {
    font-size: 0.75rem;
    margin-right: 0.5rem;
}

.progress {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
}

.msg-actions {
    margin-left: 1rem;
}

.msg-action-forward {
    font-size: 0.75rem;
    background-color: #f5f5f5;
    border: none;
    border-radius: 1rem;
    padding: 0.1rem 0.5rem;
}

.justify-content {
    justify-content: space-between;
}

@media (max-width: 768px) {
    .msg {
        flex-direction: column;
    }

    .msg-bubble {
        max-width: 100%;
    }

    .msg-info {
        margin-bottom: 0.5rem;
    }

    .msg-img {
        width: 3rem;
        height: 3rem;
        margin-right: 0;
    }

    .msg-actions {
        margin-left: 0;
        margin-top: 0.5rem;
    }
}
</style>