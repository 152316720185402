<template>
    <div class="mobile-associate-background">
        <van-nav-bar class="bg-gray-50" :title="$t('MobileSafe.title')" :left-text="$t('MobileSafe.back')" left-arrow @click-left="goBack" style="width: 100%" />
        <div class="rounded-lg shadow-md hover:bg-gray-200 bg-white px-8 pt-4 pb-3 mx-6 mt-5 flex" @click="goChangePayPassword">
            <span class="flex-grow text-lg">{{hasSetPayPassword?$t('MobileSafe.modify'):$t('MobileSafe.set')}}{{$t('MobileSafe.payment')}}</span>
            <img src="../images/right.png" class="flex-none w-5 h-5 place-self-center" />
        </div>
        <div class="rounded-lg shadow-md hover:bg-gray-200 bg-white px-8 pt-4 pb-3 mx-6 mt-5 flex" @click="goChangePassword">
            <span class="flex-grow text-lg">{{$t('MobileSafe.change')}}</span>
            <img src="../images/right.png" class="flex-none w-5 h-5 place-self-center" />
        </div>
        <div class="rounded-lg shadow-md hover:bg-gray-200 bg-white px-8 pt-4 pb-3 mx-6 mt-5 flex" @click="goAssociated">
            <span class="flex-grow text-lg">{{$t('MobileSafe.bind')}}</span>
            <img src="../images/right.png" class="flex-none w-5 h-5 place-self-center" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            hasSetPayPassword: false,
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        goChangePayPassword() {
            this.$router.push("/mobile/change-password/pay");
        },
        goChangePassword() {
            this.$router.push("/mobile/change-password");
        },
        goAssociated() {
            this.$router.push("/mobile/associate");
        },
    },
};
</script>

<style scoped>
.mobile-associate-background {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}
</style>
