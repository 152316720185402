<template>
    <!-- 页面布局容器 -->
    <el-container style="overflow: hidden;">
        <!-- 页面头部区域 -->
        <el-header style="height: 100px">
            <div class="header_div">
                <img src="@/assets/logo.png" class="header_img"/>
                <span class="header_text">大图智能科技有限公司</span>
            </div>
            <div class="header_dropdown">
                <p class="header_dropdown_welcome">欢迎您</p>
                <el-dropdown class="header_dropdown_menu">
                    <span class="el-dropdown-link">{{ admin.name }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                    <el-dropdown-menu slot="dropdown">
                        <!-- 点击下拉框触发的事件到methods里找 -->
                        <el-dropdown-item command="myEstate">我的地产</el-dropdown-item>
                        <el-dropdown-item command="myTrade">我的交易</el-dropdown-item>
                        <el-dropdown-item command="myOrder">我的订单</el-dropdown-item>
                        <el-dropdown-item command="myWallet">我的钱包</el-dropdown-item>
                        <el-dropdown-item command="myAuction">我的拍卖</el-dropdown-item>
                        <el-dropdown-item command="mySub">我的订阅</el-dropdown-item>
                        <el-dropdown-item command="setting">设置</el-dropdown-item>
                        <el-dropdown-item command="unload">切换账号/退出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-header>
        
        <!-- 侧边栏和主页的布局容器 -->
        <el-container style="height: calc(100% - 100px)">
            <!-- 页面侧边框 -->
            <el-aside :width="isCollapse ? '64px' : '200px'">
                <el-row class="tac">
                    <el-col :span="12">
                        <h3>
                            <el-tooltip class="item" effect="dark" content="点击折叠/展开菜单" placement="top"  slot="append">
                                <div class="toggle-button toggle_button_container" @click="toggleButton">
                                    <i class="el-icon-folder-opened"></i>
                                    <span style="color:#fff; margin-left: 10px" font-size="64px">主菜单</span>
                                </div>
                            </el-tooltip>
                        </h3>
                    
                    
                        <!-- 侧边栏菜单 -->
                        <el-menu
                          default-active="2"
                          class="el-menu-vertical-demo left_aside_menu"
                          background-color="#333744"
                          text-color="#fff"
                          active-text-color="#ffd04b"
                          :collapse="isCollapse"
                          :collapse-transition="false"
                          router>
                        
                        
                            <!-- 一级菜单-用户管理 -->
                            <el-menu-item index="userManage">
                                <i class="el-icon-menu"></i>
                                <span slot="title">用户管理</span>
                            </el-menu-item>
                        
                        
                            <!-- 企业管理二级菜单 -->
                            <el-submenu index="Company">
                                <!-- 二级菜单下拉菜单 -->
                                <template slot="title">
                                    <!-- 小图标 -->
                                    <i class="el-icon-more"></i>
                                    <!-- 文本 -->
                                    <span>企业管理</span>
                                </template>
                                <!-- 二级菜单选项部分 -->
                                <el-menu-item-group>
                                    <!-- 二级菜单模板区域 -->
                                    <el-menu-item index="company">
                                        <!-- 小图标 -->
                                        <i class="el-icon-coin"></i>
                                        <!-- 文本 -->
                                        <span>企业认证</span>
                                    </el-menu-item>
                                    <!-- 二级菜单模板区域 -->
                                    <el-menu-item index="companyRegister">
                                        <!-- 小图标 -->
                                        <i class="el-icon-coin"></i>
                                        <!-- 文本 -->
                                        <span>注册新企业用户</span>
                                    </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>
              
              
                            <!-- 一级菜单-原始土地管理 -->
                            <el-menu-item index="/admin/apply">
                                <div class="menu-check-title">
                                    <i class="el-icon-document"></i>
                                    <span>原始土地管理x</span>
                                    <div class="menu-check-count">{{ landUploadCheckingCount }}</div>
                                </div>
                            </el-menu-item>

                            
                            <!-- 一级菜单-挂牌管理 -->
                            <el-menu-item index="sellManage">
                                <i class="el-icon-menu"></i>
                                <span slot="title">挂牌管理</span>
                            </el-menu-item>
              
              
                            <!-- 订单管理二级菜单 -->
                            <el-submenu index="Order">
                                <!-- 二级菜单下拉菜单 -->
                                <template slot="title">
                                    <!-- 小图标 -->
                                    <i class="el-icon-more"></i>
                                    <!-- 文本 -->
                                    <span>订单管理</span>
                                </template>
                                <!-- 二级菜单选项部分 -->
                                <el-menu-item-group>
                                    <!-- 二级菜单模板区域 -->
                                    <el-menu-item index="/admin/orderManage">
                                        <!-- 小图标 -->
                                        <i class="el-icon-document"></i>
                                        <!-- 文本 -->
                                        <span>订单查看</span>
                                    </el-menu-item>
                                    <!-- 二级菜单模板区域 -->
                                    <el-menu-item index="/admin/refund">
                                        <div class="menu-check-title">
                                          <i class="el-icon-time"></i>
                                          <span>退款审核x</span>
                                          <div class="menu-check-count">{{ refundCheckingCount }}</div>
                                        </div>
                                    </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>

                            
                            <el-menu-item index="/admin/chat">
                                <i class="el-icon-menu"></i>
                                <span slot="title">客服</span>
                            </el-menu-item>

                            
                            <!-- 一级菜单-操作日志 -->
                            <el-menu-item index="4">
                                <i class="el-icon-setting"></i>
                                <span slot="title">操作日志</span>
                            </el-menu-item>
                        </el-menu>
                    </el-col>
                </el-row>
            </el-aside>
      
      
            <!-- 页面主体 -->
            <el-main>
                <!-- 路由占位符 -->
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
export default {
  mounted() {
    this.$http.get('/v1/business/OriginalLandInformation')
      .then(res => {
        // TODO 处理返回值,接口没改
        // 将土地待处理数量加载到vuex上，以便使用
        /* this.$store.commit("adminCheckingCount/set_landUploadCheckingCount" ,res.data.data.length); */
        this.landUploadCheckingCount = res.data.data.length
      })
      .catch(error => {
        // 处理错误
      });
    this.$http.get('/v1/administrator/select-ordermanage-refund?status=退款中')
      .then(res => {
        // 处理返回值
        // 将退款待处理数量加载到vuex上，以便使用
        /* this.$store.commit("adminCheckingCount/set_refundCheckingCount" ,res.data.data.length); */
        this.refundCheckingCount = res.data.data.length
      })
      .catch(error => {
        // 处理错误
      });
  },


  data () {
    return {
      /* 判断是否折叠变量，初始不折叠，false */
      isCollapse: false,
      /* 土地上传信息待处理数量 */
      landUploadCheckingCount: 0,
      /* 退款待处理数量 */
      refundCheckingCount: 0,
      /* 管理员数据 */
      admin: {
        name: '管理员', // 右上角名称，应该会写等级
        num: '',
        prof: '',
        isCollapse: true,
      },
    }
  },
  methods: {
    changeCursor(event) {
      // 鼠标移上去时改变样式
      event.target.style.cursor = "pointer";
    },
    /* 此处为页面右上角下拉菜单的点击事件 */
    handleCommand(command) {
      this.$message('click on item ' + command);
    },
    /* 侧边栏折叠事件 */
    toggleButton () {
      this.isCollapse = !this.isCollapse
    },
    getLandUploadCheckingCount() {
      return this.$store.state.landUploadCheckingCount.landUploadCheckingCount
    },
  },
}
</script>

<style scoped>
/* ---------------------------主页布局容器---------------------------- */
/* body之下第一层，element-ui组件布局 */
.el-container {
  height: 100%;
}



/* ---------------------------顶栏布局容器---------------------------- */
/* 布局容器顶栏容器样式 */
.el-header {
  /* 背景色 */
  background-color: #606266;
  /* 左右贴边对齐 */
  display: flex;
  justify-content: space-between;
  /* 清空间隔属性 */
  padding: 0;
  /* 组件框上下不贴边，居中 */
  align-items: center;
  /* 内容(主要是文字)颜色 */
  color: #fff;
  /* 文字字体大小 */
  font-size: 20px;
}
/* 头部logo和文本的父块 */
.header_div {
  /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
  white-space: nowrap;
}
/* 头部区域logo图片样式 */
.header_img {
  height: 90px;
  width: 90px;
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
  /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
  vertical-align: top;
}
/* 头部区域文本样式 */
.header_text {
  display: inline-block;
  font-size: 40px;
  margin-top: 30px;
  /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
  vertical-align: top;
}
/* 头部右边下拉菜单样式（包括欢迎你和带下拉框的文本） */
.header_dropdown {
  width: 100%;
  text-align: right;
  /* 在父块设置 `white-space: nowrap`。这样可以让行内元素不换行，放在同一行。 */
  white-space: nowrap;
}
/* 头部右边欢迎您文本 */
.header_dropdown_welcome {
  display: inline-block;
  /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
  vertical-align: top;
  margin-right: 20px;
}
/* 头部右边下拉菜单 */
.header_dropdown_menu {
  display: inline-block;
  /* 通过在子块设置 `vertical-align: top` 将元素对齐，从而避免高度不一致导致的变化。 */
  vertical-align: top;
  margin-top: 20px;
}
/* 下拉菜单el组件需要的style */
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
  font-size: 20px;
}


/* ---------------------------侧边栏布局容器---------------------------- */
/* 布局容器侧边栏容器样式（主要是背景色） */
.el-aside {
  background-color: #606266;
  /* 当需要高度、宽度既有百分比属性又有数字属性时，用calc() */
  /* height: calc(100% - 100px); */
  height: 100%;
}


/* ---------------------------左侧边栏布局容器---------------------------- */
/* 左侧折叠/展开文本设置 */
.toggle_button_container {
  margin-left: 10px;
  margin-top: 10px;
}
/* 左侧菜单位置设置 */
.left_aside_menu {
  margin-top: 50px;
}
/* 待处理的文本父块 */
.menu-check-title {
  display: flex;
  align-items: center; /* 垂直居中 */
}
/* 待处理的信息数量 */
.menu-check-count {
  font-size: 20px;
  color: #ff0000;
  margin-left: 10px;
  margin-top: 2px;
}



/* ---------------------------其他部分（我都忘了这些各自控制啥了）---------------------------- */
.tac {
  width: 100%;
}
/* 主页容器样式-主要是将它的padding属性设为0 */
.el-main {
  background-color: #fff;
  padding: 0;
}
/* 侧边框背景色 */
.el-submenu {
  background-color: #333744;
}
/* 侧边栏宽度(与侧边框保持一致) */
.el-col-12 {
  width: 100%;
}
/* 侧边栏背景色 */
.el-aside {
  background-color: #333744;
}
/* 去除白线(侧边栏与侧边框右侧连接处出现白线，染色去除) */
.el-menu {
  border-right: #333744;
}
/* 折叠展开组件设置 */
.toggle-button {
  background-color: #333744;
  color:#fff;
  font-weight:200;
  font-size: 20px;
  /* 鼠标放上去会变成小手 */
  cursor: pointer;
}
</style>
