<template>
    <div class="first-main-container">
        <div @click="goBack" class="goback-parent">
            <img src="../icons/左箭头.png" alt="返回图标" class="goback-img">
            <span @mouseover="changeCursor">返回</span>
        </div>
        <div class="add-parent" v-if="isSuccess">
            <div class="add-box">
                <div class="add-input-parent">
                    <span>企业邮箱账号</span>
                    <input type="text" class="add-input" v-model="email">
                </div>
                <div class="add-input-parent">
                    <span>企业联系人手机号码</span>
                    <input type="text" class="add-input" v-model="phone">
                </div>
                <div>
                    <button class="add-button" @click="showModal" @mouseover="changeCursor">新增</button>
                    <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
                    <div class="modal" v-if="vmodal">
                        <div class="modal-box">
                            <span>确定向邮箱用户发送验证信息？</span>
                            <div style="margin-top: 20px;">
                                <button class="modal-button" @click="add" v-if="!isSending">确定</button>
                                <button class="modal-button" v-if="isSending">发送中<div class=""></div></button>
                                <button class="modal-button" @click="closeModal">取消</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-box" v-if="!isSuccess">
                <h2>新增成功，验证邮件已发至企业用户邮箱</h2>
                <h1>请提醒用户及时查看</h1>
                <h3 @click="goBack2" @mouseover="changeCursor" style="text-decoration: underline;">返回上一步</h3>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            email: '',
            phone: '',
            isSuccess: true, // 点击新增弹框确定后显示给管理员的文本
            vmodal: false, // 显示弹窗
            isSending: false, // 点击弹窗确定按钮显示发送中...
            localIsAdd: this.isAdd // 在子组件中定义一个局部变量来存储 prop 的值
        }
    },
    methods: {
        changeCursor(event) { // 鼠标移上去时改变样式
            event.target.style.cursor = "pointer";
        },
        goBack() {
            this.$router.go(-1)
        },
        showModal() {
            this.vmodal = true
        },
        closeModal() {
            this.vmodal = false
        },
        goBack2() {
            this.isSuccess = true
        },
        add() {
            this.isSending = true
            const data = {
                businessemail: this.email,
                businessphone: this.phone
            }
            this.$http.post('/v1/business/savebusinessOne', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                alert('已录入系统！')
                this.isSuccess = false
            })
            .catch(error => {
                // 处理错误
                alert('录入系统失败！请联系程序员修复')
            });
        }
    },
}
</script>

<style scoped>
.first-main-container {
    height: 100%;
    background-color: black;
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.goback-parent {
    display: flex;
    align-items: center; /* 垂直居中 */
    color: #fff;
    font-size: 20px;
    padding: 20px;
}
.goback-img {
    height: 20.67px;
    width: 40px;
}
.add-parent {
    padding: 50px;
    padding-top: 0;
    flex-grow: 1; /* 占据剩余空间 */
    overflow-y: auto; /* 添加滚动条，超出屏幕高度时可以滚动 */
}
.add-box {
    height: 100%;
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    flex-direction: column;
    background-color: #a7a7a7;
    color: #fff;
}
.add-input-parent {
    padding: 20px;
    font-size: 30px;
}
.add-input {
    height: 34px;
    margin-left: 10px;
    vertical-align: top;
}
.add-button {
    height: 40px;
    width: 500px;
    background-color: #ddd3fd;
    color: #fff;
}
/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    height: 150px;
    width: 300px;
    background-color: #a7a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
</style>