<template>
    <div class="first-main-container">
        <!-- 左上角返回，返回登录页面 -->
        <van-nav-bar :title="$t('mobileForgetPassword.title')" :left-text="$t('mobileForgetPassword.back')" left-arrow @click-left="goBack" />
        <!-- 忘记密码 -->
        <div class="my-tab" v-if="mobilechange">
            <div class="mobile-form-topic">{{$t('mobileForgetPassword.informationAuthentication')}}</div>
            <div class="mobile-box-input-parent">
                <div class="mobile-box-input-labal"><span>{{$t('mobileForgetPassword.account')}}:</span></div>
                <input type="text" v-model="account" class="mobile-box-input" :placeholder="$t('mobileForgetPassword.passwordLoginAccountInput')">
            </div>
            <div class="mobile-box-input-parent">
                <div class="mobile-box-input-labal"><span>{{$t('mobileForgetPassword.verify')}}:</span></div>
                <input v-model="code" :placeholder="$t('mobileForgetPassword.codeLoginCodeInput')" class="mobile-box-input">
                <span @click="getMobileCode" v-if="isCode" class="mobile-box-code-button">{{$t('mobileForgetPassword.getCode')}}</span>
                <span v-if="!isCode" disabled class="mobile-box-code-button">{{$t('mobileForgetPassword.resendCode')}}{{ time }}s</span>
            </div>
            <div style="width: 80%;margin-bottom: 10px;">
                <button class="mobile-form-button" @click="InforNext">{{$t('mobileForgetPassword.informationAuthentication')}}</button>
                <!-- 弹框的显示和隐藏状态，根据 vmodal 变量决定 -->
                <div class="modal" v-if="vmodal" @click="closeModal">
                    <div class="modal-box" v-if="showError">
                        <div>{{ errorText }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-tab" v-if="!mobilechange">
            <div class="mobile-form-topic">{{$t('mobileForgetPassword.resetPassword')}}</div>
            <div class="mobile-box-input-parent">
                <div class="mobile-box-input-labal"><span>{{$t('mobileForgetPassword.newPassword')}}:</span></div>
                <input :type="showPassword ? '' : 'password'" v-model="changePassword" class="mobile-form-input" :placeholder="$t('mobileForgetPassword.setNewPassword')" @input="checkPassword" style="width: 100%">
                <span class="mobile-form-icon" @click="togglePassword">
                    <img src="../images/not-visible.png" class="mobile-form-icon-img" v-if="!showPassword">
                    <img src="../images/visible.png" class="mobile-form-icon-img" v-if="showPassword">
                </span>
            </div>
            <div v-show="showRules" style="width: 80%;">{{$t('mobileForgetPassword.rule1')}}</div>
            <div v-show="showRules" style="width: 80%;">{{$t('mobileForgetPassword.rule2')}}</div>
            <div v-show="showRules" style="width: 80%;">{{$t('mobileForgetPassword.rule3')}}</div>
            <div style="width: 80%;margin-bottom: 10px;">
                <button class="mobile-form-button" @click="emailChange">{{$t('mobileForgetPassword.resetPassword')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mobilechange: true, // 由于只有两页，方便起见用了true和false
            account: '', // 三合一了
            changePassword: '',
            showPassword: false,
            code: '', // 验证码
            time: 0, // 发送验证码后重新发送需要等待60s
            isCode: true,
            timer: null, // 计时器


            vmodal: false, // 显示弹窗
            showRules: false, // 输入新密码时显示的密码规则
            showError: false, // 输入完密码后点击确认按钮时验证密码格式是否正常
            errorText: '',
        }
    },
    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        goBack() {
            this.$router.go(-1)
        },
        showModal() {
            const re = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/;
            if (re.test(this.changePassword)) {
                this.showError = false
            } else {
                // 非法操作
                this.showError = true;
                this.errorText = this.$t('mobileForgetPassword.alertPasswordError')
            }
            if (this.code === '') {
                this.showError = true;
                this.errorText = this.$t('mobileForgetPassword.alertNoCodeInput')
            }
            this.vmodal = true
        },
        closeModal() {
            this.vmodal = false
        },
        checkPassword() { // 点击密码输入框后检查密码格式是否错误
            const re = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/;
            this.showRules = !re.test(this.changePassword);
        },
        setMyInterval() { // 设置计时器
            this.time = 60
            // 计时器
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    this.isCode = true;
                    clearInterval(this.timer);
                }
            }, 1000);
            this.isCode = false
        },
        getMobileCode() { // 三合一版身份验证-获取验证码
            this.setMyInterval()
            this.showError = true;
            this.errorText = this.$t('mobileForgetPassword.alertLoading')
            this.vmodal = true
            if (this.account.length > 0) {
                this.$http.post('/v1/index/get-verification-code?loginname=' + this.account)
                .then(res => {
                    this.showError = true;
                    this.errorText = res.data.data
                    this.vmodal = true
                }).catch(err => {
                    this.showError = true;
                    this.errorText = this.$t('mobileForgetPassword.alertAxiosError')
                    this.vmodal = true
                })
            } else {
                this.showError = true;
                this.errorText = this.$t('mobileForgetPassword.alertNoAccountInput')
                this.vmodal = true
            }
        },
        InforNext() {
            if (this.account.length === 0) {
                this.showError = true;
                this.errorText = this.$t('mobileForgetPassword.alertNoAccountInput')
                this.vmodal = true
                return
            }
            if (this.code.length === 0) {
                this.showError = true;
                this.errorText = this.$t('mobileForgetPassword.alertNoCodeInput')
                this.vmodal = true
                return
            }
            const data = {
                loginname: this.account,
                code: this.code
            }
            this.$http.post('/v1/register/user-update-password-authentication', data)
                .then(res => {
                    this.showError = true;
                    this.errorText = res.data.data
                    this.vmodal = true
                    if (res.data.data === '用户验证成功') {
                        this.mobilechange = false
                    }
                }).catch(err => {
                    this.showError = true;
                    this.errorText = this.$t('mobileForgetPassword.alertAxiosError')
                    this.vmodal = true
                })
        },
        mobileChangePassword() {
            if (this.changePassword.length === 0 || this.showRules) {
                this.showError = true;
                this.errorText = this.$t('mobileForgetPassword.alertNewPasswordError')
                this.vmodal = true
                return
            }
            const data = {
                loginname: this.account,
                loginpassword: this.changePassword
            }
            this.$http.post('/v1/register/update-password', data)
                .then(response => {
                    // 处理返回值
                    this.showError = true;
                    this.errorText = this.$t('mobileForgetPassword.alertResetSuccessfully')
                    this.vmodal = true
                    this.$router.go(-1)
                }).catch(error => {
                    this.showError = true;
                    this.errorText = this.$t('mobileForgetPassword.alertAxiosError')
                    this.vmodal = true
                });
        },




        getMobileCodeEmail() { // 手机端邮箱改密获取验证码
            this.showError = true;
            this.errorText = this.$t('mobileForgetPassword.alertLoading')
            this.vmodal = true
            this.setMyInterval()
            this.$http.post('/v1/index/get-verificationcode-topassword?useremail=' + this.email)
            .then(res => {
                this.showError = true;
                this.errorText = '获取验证码成功,请登录邮箱查询,5分钟后失效'
                this.vmodal = true
            }).catch(err => {
                this.showError = true;
                this.errorText = this.$t('mobileForgetPassword.alertAxiosError')
                this.vmodal = true
            })
        },
        getMobileCodePhone() { // 手机端手机号改密获取验证码
            this.setMyInterval()
            this.$http.post('/v1/register/phone-verificationcode?userphone=' + this.phone)
                .then(response => {
                    // 处理返回值
                    this.showError = true;
                    this.errorText = this.$t('mobileForgetPassword.alertPhoneCodeSuccessfully')
                    this.vmodal = true
                }).catch(error => {
                    this.showError = true;
                    this.errorText = this.$t('mobileForgetPassword.alertAxiosError')
                    this.vmodal = true
                });
        },
        /* phoneChange() {
            // 检查格式
            if (!this.phone.test(/^[0-9]*$/)) {
                this.showError = true;
                this.errorText = '手机号格式错误'
                this.vmodal = true
                return
            }
            if (this.changePassword === '' || this.showRules) {
                this.showError = true;
                this.errorText = '密码格式错误'
                this.vmodal = true
                return
            }
        }, */
        emailChange() {
            if (!this.email.includes('@')) {
                this.showError = true;
                this.errorText = this.$t('mobileForgetPassword.alertEmailError')
                this.vmodal = true
                return
            }
            if (this.changePassword === '' || this.showRules) {
                this.showError = true;
                this.errorText = this.$t('mobileForgetPassword.alertPasswordError')
                this.vmodal = true
                return
            }
            this.$http.get('/v1/index/all-reset-password?newpassword=' + this.changePassword + '&useremail=' + this.email + '&code=' + this.code)
            .then(res => {
                // 成功时的处理
                this.showError = true
                this.errorText = res.data.data
                this.vmodal = true
            })
            .catch(error => {
                // 处理错误
                this.showError = true;
                this.errorText = this.$t('mobileForgetPassword.alertAxiosError')
                this.vmodal = true
            });
        },
    },
}
</script>

<style scoped>
.first-main-container {
    display: flex;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
    height: 100vh; /* 容器高度为屏幕高度 */
}
.mobile-box-container { /* 手机端登陆页面 */
    width: 100%;
    margin-top: 20px;
    background-color: #f9fafb;
    display: flex;
    flex: 1;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    flex-direction: column;
}
.mobile-box-input-parent { /* 输入框样式 */
    width: 80%;
    display: flex;
    flex-direction: row;
    position: relative;
}
.mobile-box-input-labal { /* 输入框前文字信息样式 */
    width: 70px;
    display: flex;
    align-items: center; /* 水平居中 */
}
.mobile-box-input { /* 输入框样式 */
    height: 50px;
    width: 70%;
    font-size: 14px;
    color: #dcdcdc;
    border: none;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 10px;
}
.mobile-box-code-button { /* 获取验证码按钮 */
    height: 40px;
    width: 100px;
    border: none;
    border-left: 1px solid #dcdcdc;
    display: flex;
    align-items: center; /* 水平居中 */
    justify-content: center;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 5px;
}
.mobile-form-container { /* 手机端登陆页面 */
    flex: 1; /* 当父div层是flex时，子div层加入该语句，可以令其占据剩余部分高度 */
    width: 100%;
    padding: 20px;
    background-color: #f9fafb;
    display: flex;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
    flex-direction: column;
}
.mobile-form-topic { /* 手机端登录注册标题 */
    font-size: 20px;
    padding: 10px;
}
.mobile-form-input { /* 输入框样式 */
    height: 50px;
    width: 80%;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
}
.mobile-form-button { /* 按钮样式 */
    height: 50px;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #f6f35c;
    border-radius: 12px;
    box-shadow: 0 2px 12px 0 rgb(255, 255, 255);
    margin-top: 10px;
    margin-bottom: 20px;
}
.mobile-form-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
.mobile-form-icon-img {
    max-height: 50px;
    max-width: 50px;
    height: 100%; /* 根据输入框的高度来设置图片大小 */
    width: auto;
}
.mobile-form-changemobel-mobile {
    width: 80%;
    text-align: right;
    font-size: 14px;
    color: #b9b9b9;
}
/* 弹窗 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* 将弹框置于其他所有元素之上 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-box {
    height: 150px;
    width: 300px;
    padding: 20px;
    background-color: #a7a7a7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column; /* `flex-direction: column` 属性使其内部元素按照列的方向排列。 */
}
.modal-button {
    height: 40px;
    width: 120px;
    background-color: #ddd3fd;
    font-size: 14px;
    color: #fff;
}
.my-tab {
    @apply relative mx-3 p-6 rounded-lg shadow-md bg-white flex flex-col items-center justify-center;
}
</style>