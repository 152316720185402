<template>
  <!-- 页面背景-body之下第一层布置 -->
  <div class="main_container">
    <MobileHomePage v-if="isMobile"></MobileHomePage>
    <ComputerLogin v-if="!isMobile"></ComputerLogin>
  </div>
</template>

<script>
import axios from "axios";

/* 请求前的拦截 */
/* axios.interceptors.request.use(
  config => {
    console.log(config)
    return config
  },
  error => {
    console.error(error)
    return Promise.reject(error)
  }
) */
/* 响应后的拦截 */
/* axios.interceptors.response.use(
  response => {
    console.log(response)
    return response
  },
  error => {
    console.error(error)
    return Promise.reject(error)
  }
) */

import MobileHomePage from "./MobileHomePage.vue";
import ComputerLogin from "./NoComputerLogin.vue";
export default {
  components: { // 手机端和PC端登陆界面组件
    MobileHomePage, // 手机端
    ComputerLogin, // PC端
  },
  created() {
    // 根据请求头信息判断是否为手机端
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    /* this.$i18n.locale = localStorage.getItem('language') */
    document.title = this.$t('pageTitle')
  },
  data() {
    return {
      isMobile: false,
    }
  },
}
</script>

<style scoped>
/* 主页区域设置 */
.main_container {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
