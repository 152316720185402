<template>
  <div ref="container" class="canvas-container">
    <canvas
      ref="bigmap"
      :width="canvasWidth"
      :height="canvasHeight"
      class="big-map"
      @mouseover="changeCursor"></canvas>

      <div class="land-information-menu" v-if="isLandInforMenu">
        <span>土地名称:{{ landName }}</span>
        <span>当前土地坐标:{{ '第' + SecondLandCoordinates + '号二号土地' + '-(' + col + ',' + row + ')'}}</span>
        <span>请输入需要进入的三号土地坐标：</span>
        <input type="text" v-model="SecondLandCoordinates">
        <span>二号土地坐标包括0~{{ gridNum - 1 }}的整型数字</span>
        <button class="button-movein" @click="moveIn">进入该二号土地</button>
      </div>
      <!-- 缩放图标 -->
      <div class="land-information-menu-show el-icon-arrow-left" v-if="isLandInforMenu" @click="isLandInforMenu = false" @mouseover="changeCursor"></div>
      <div class="land-information-menu-hidden el-icon-arrow-right" v-if="!isLandInforMenu" @click="isLandInforMenu = true" @mouseover="changeCursor"></div>

  </div>
</template>

<script>

export default {
  name: 'Secondland',
  props: {
    landid: String,
    landName: String,
    SecondLandCoordinates: Number
  },
  mounted() {
    /* ctx.fillRect(25, 25, 100, 100); // fillRect(x, y, width, height)绘制一个填充的矩形
    ctx.clearRect(45, 45, 60, 60); // strokeRect(x, y, width, height)绘制一个矩形的边框
    ctx.strokeRect(50, 50, 50, 50); // clearRect(x, y, width, height)清除指定矩形区域，让清除部分完全透明。 */
    this.gridNum = this.colLength * this.rowLength

    let timer = setTimeout(() => {  // 等待 100 毫秒后再绘制
      const canvas = this.$refs.bigmap;  // 获取 canvas 元素
      if (!canvas) { // 判断 canvas 元素是否存在
        console.error('Canvas element is not found');
      } else {
        this.drawBigMapGrid()
        clearInterval(timer)
      }
    }, 100);
  },
  watch: {
    SecondLandCoordinates(n,o) {
      this.drawBigMapGrid()
      this.drawThirdLandMapSelect()
    }
  },
  data() {
    return {
      canvasHeight: 990,
      canvasWidth: 1100,
      gridSize: 110,  // 原始土地分割成220x198的一英亩土地
      colLength: 10,
      rowLength: 9,
      data: [],
      col: 0, // 地图容器左上角坐标
      row: 0,
      gridNum: 0,

      ThirdLandCoordinates: null, // 三号土地坐标
      isLandInforMenu: true,
    }
  },
  methods: {
    changeCursor(event) {
      // 鼠标移上去时改变样式
      event.target.style.cursor = "pointer";
    },
    drawBigMapGrid() { // 绘制大地图网格
      const canvas = this.$refs.bigmap;  // 获取 canvas 元素
      const ctx = canvas.getContext("2d");  // 获取绘画环境
      // 清除原有的所有大地图画布元素
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let i = 0;i < this.colLength;i++) {  // 绘制大地图网格
        for (let j = 0;j < this.rowLength;j++) {
          ctx.strokeRect(i*this.gridSize,j*this.gridSize,this.gridSize,this.gridSize)
        }
      }
    },
    drawThirdLandMapSelect() {
      this.col = Math.floor(this.ThirdLandCoordinates % this.colLength)
      this.row = Math.floor(this.ThirdLandCoordinates / this.colLength)
      const canvas = document.querySelector('.big-map');  // 获取 canvas 元素
      const ctx = canvas.getContext("2d");  // 获取绘画环境
      ctx.fillStyle = 'orange';
      ctx.fillRect(this.col * this.gridSize, this.row * this.gridSize, this.gridSize, this.gridSize);
    },
    // 鼠标按下事件
    onMouseDown(event) {
      this.isMouseDown = true;
      this.originX = event.clientX; // 在鼠标按下时，获取鼠标的当前坐标值
      this.originY = event.clientY;
      event.preventDefault();
    },
    moveIn() {
      const id = this.ThirdLandCoordinates
      this.$router.push({
        name: 'Thirdland',
        params: {
          landid: this.landid,
          landName: this.landName,
          SecondLandCoordinates: this.SecondLandCoordinates,
          ThirdLandCoordinates: this.ThirdLandCoordinates
        }})
    },
  },
}
</script>

<style scoped>
.canvas-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.big-map {
  background-image: url('../assets/no-image.png');
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.small-map {
  position: absolute;
  right: 10px;
  top: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 1);
}

/* ----------------------------------------屏幕左侧土地信息栏---------------------------------------- */
.land-information-menu {
  height: 600px;
  width: 350px;
  position: absolute;
  top: calc(50% - 300px);
  left: 0;
  background-color: #767676;
  color: #fff;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居中 */
  justify-content: center; /* 垂直居中 */
  z-index: 1;
}
/* 信息栏缩放图标 */
.land-information-menu-show {
  height: 30px;
  width: 20px;
  position: absolute;
  top: calc(50% - 15px);
  left: 350px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #767676;
  font-size: 30px;
  color: #fff;
  z-index: 1;
}
.land-information-menu-hidden {
  height: 30px;
  width: 20px;
  position: absolute;
  top: calc(50% - 15px);
  left: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #767676;
  font-size: 30px;
  color: #fff;
  z-index: 1;
}
</style>