<template>
<div>
    <van-nav-bar class="bg-gray-50" :title="$t('MobileChangePayPassword.title')" :left-text="$t('MobileChangePayPassword.back')" left-arrow @click-left="goBack" />
<!--    初次设置支付密码-->

    <div v-if="showSet">
        <div v-if="hasSetPassword ">
            <div  class="flex justify-center items-center">
                <div class="mt-5 text-xl text-center">{{$t('MobileChangePayPassword.enterOldPassword')}}</div>
            </div>
            <van-password-input
                :value="oldPassword"
                :focused="showKeyboard"
                :length="6"
                class="mt-3 shadow-sm"
                @focus="showKeyboard = true"
            />
            <div class="flex justify-end items-center">
                <div class="text-blue-500 text-sm mr-5 mt-3" @click="onForgetPayPassword">{{$t('MobileChangePayPassword.forgetOldPassword')}}</div>
            </div>
            <van-number-keyboard
                v-model="oldPassword"
                :show="showKeyboard"
                @blur="showKeyboard = false"
            />
        </div>
        <div v-else-if="firstInput ">
            <div  class="flex justify-center items-center animate__animated animate__flipInX">
                <div class="mt-5 text-xl text-center">{{$t('MobileChangePayPassword.enterNewPassword')}}</div>
            </div>
            <van-password-input
                :value="firstInputPassword"
                :focused="showKeyboard"
                :length="6"
                class="mt-3 shadow-sm animate__animated animate__flipInX"
                @focus="showKeyboard = true"
            />
            <van-number-keyboard
                v-model="firstInputPassword"
                :show="showKeyboard"
                @blur="showKeyboard = false"
            />
        </div>
        <div v-else-if="!firstInput ">
<!--            重复输入-->
            <div  class="flex justify-center items-center animate__animated animate__flipInX">
                <div class="mt-5 text-xl text-center">{{$t('MobileChangePayPassword.resetPassword')}}</div>
            </div>
            <van-password-input
                :value="secondInputPassword"
                :focused="showKeyboard"
                :length="6"
                class="mt-3 shadow-sm animate__animated animate__flipInX"
                @focus="showKeyboard = true"
            />
            <van-number-keyboard
                v-model="secondInputPassword"
                :show="showKeyboard"
                @blur="showKeyboard = false"
            />
        </div>
    </div>
<!--    忘记支付密码-->
    <div v-if="forgetPassword" class="flex flex-col items-center  justify-center bg-white pb-5 mx-5 mt-10 rounded-lg shadow-md animate__animated animate__flipInX">
        <div class="flex justify-center items-center my-4">
            <div class="text-md text-center">{{$t('MobileChangePayPassword.verifyIdentity')}}</div>
        </div>
        <div >
            <!-- 手机验证码验证 -->
            <div class="flex items-center justify-end">
            <span v-if="hideMobile===''" class="text-gray-500 text-sm mb-3 text-center">
                {{$t('MobileChangePayPassword.tip2')}}{{hideMobile}}{{$i18n.locale === 'zh'? $t('MobileChangePayPassword.tip3') : ''}}
            </span>
                <span v-else-if="hideEmail!==''" class="text-gray-500 text-sm mb-3 text-center">
                {{$t('MobileChangePayPassword.tip1')}}{{ hideEmail }}{{$i18n.locale === 'zh'? $t('MobileChangePayPassword.tip3') : ''}}
            </span>

            </div>
            <div class="flex items-center justify-end">
                <!--            请输入图像验证码-->
                <span class="text-gray-500 text-sm">{{$t('MobileChangePayPassword.graphicCode')}}：</span>
                <input v-model="imgCode" :placeholder="$t('MobileChangePayPassword.graphicCodeInput')" style="width: 110px" class="my-input " />
                <img @click="loadCodeImg" class="h-8 w-20 " :src="imgCodeUrl" alt="验证码"/>
            </div>
            <div v-if="imgCode.length>=4" class="flex items-center mt-2 justify-end animate__animated animate__flipInX">
                <span class="text-gray-500 text-sm">{{$t('MobileChangePayPassword.verify')}}：</span>
                <input v-model="code" :placeholder="$t('MobileChangePayPassword.verifyInput')" style="width: 110px" class="my-input " />
                <div v-if="mobile.hasSent">
                    <span @click="getMobilePhoneCode" class="h-8 w-20 text-sm px-1 py-1 text-white bg-yellow-500 rounded cursor-pointer hover:bg-yellow-600">{{$t('MobileChangePayPassword.getCode')}}</span>
                </div>
                <div v-else>
                    <span disabled class="h-8 w-20 text-sm px-1 py-1 text-gray-500 bg-gray-200 rounded opacity-50">{{$t('MobileChangePayPassword.resendCode')}}{{ mobile.timeCount }}s</span>
                </div>
            </div>

        </div>
        <!--        下一步-->
        <!--        <div class="mt-4 flex justify-center items-center">-->
        <!--            <div @click="onNext" class="text-sm  px-4 py-2 text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-600">下一步</div>-->
        <!--        </div>-->
    </div>


</div>
</template>

<script>
import {Notify} from "vant";
import {mapState} from "vuex";

export default {
    name: "MobileChangePayPassword",
    created() {
        this.$axios.get("/register/getppdstatus").then((res) => {
            if (res.data.code === 200) {
                this.hasSetPassword = !res.data.data.hasSetPassword;
            }
        });
    },
    computed: {
        ...mapState({
            users: (state) => state.user.userInfo,
        }),
        hideEmail() {
            if(!this.users.useremail||this.users.useremail.length<3){
                return this.users.useremail;
            }
            const atIndex = this.users.useremail.indexOf('@');
            const username = this.users.useremail.slice(0, 2);
            const domain = this.users.useremail.slice(atIndex + 1);
            return `${username}****@${domain}`;
        },
        hideMobile() {
            if (!this.users.userphone||this.users.userphone.length !== 11) {
                return this.users.userphone;
            }
            return `${this.users.userphone.slice(0, 3)}****${this.users.userphone.slice(-4)}`;
        },
    },
    data(){
        return {
            forgetPassword:false,
            showSet: true,
            hasSetPassword: true,
            firstInput: true,
            oldPassword: "",
            firstInputPassword: "",
            secondInputPassword: "",
            newPasswordAgain: "",
            showKeyboard: false,
            imgCode:"",
            imgCodeUrl:"",

            code:"",
            mobile:{
                hasSent: true,
                timeCount:60,
            },
        }
    },
    watch:{
        code(code){
            if (code.length >= 6){
                this.showSet=true;
            }

        },
        oldPassword(oldPassword){
            if (oldPassword.length >= 6) {
                this.oldPassword = this.oldPassword.substring(0, 6);  //  截取前六位
                const toastLoading= this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: this.$t('MobileChangePayPassword.alertLoading'),
                    forbidClick: true,
                });
                this.$http.post("/v1/register/originalppd-status",this.oldPassword,
                    {
                        headers: {
                        'Content-Type': 'application/octet-stream' // 设置请求头为二进制流
                    }})
                    .then(res=>{
                    toastLoading.clear();
                    if(res.data.data){
                        this.$toast.success(this.$t('MobileChangePayPassword.alertVerifySuccessfully'));
                        this.firstInput=true;
                        this.hasSetPassword=false;
                    }else{
                        this.$toast.fail(this.$t('MobileChangePayPassword.alertVerifyFailed'));
                        this.oldPassword="";
                    }
                }).catch(err=>{
                    toastLoading.clear();
                    this.$toast.fail(this.$t('MobileChangePayPassword.alertVerifyFailed'));
                    this.oldPassword="";
                })
            }
        },
        //    密码输入完成
        firstInputPassword(firstInputPassword){
            if (firstInputPassword.length >= 6) {
                this.firstInput=false;
                console.log(this.firstInputPassword)
                this.firstInputPassword = this.firstInputPassword.substring(0, 6);  //  截取前六位
            }
        },
        secondInputPassword(secondInputPassword){
            if (secondInputPassword.length >= 6) {
                this.secondInputPassword = this.secondInputPassword.substring(0, 6);  //  截取前六位
                if (this.secondInputPassword !== this.firstInputPassword)
                {
                    this.$toast.fail(this.$t('MobileChangePayPassword.alertPasswordDifferent'));
                    this.firstInputPassword = "";
                    this.secondInputPassword = "";
                    this.firstInput = true;
                }
                else if(this.forgetPassword)
                //忘记密码
                {
                    const toastLoading= this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        message: this.$t('MobileChangePayPassword.alertSettingPassword'),
                        forbidClick: true,
                    });
                    this.$http.post("/v1/register/user-updateppd-authentication",
                        {
                            code:this.code,
                            paymentpassword: this.secondInputPassword,
                        })
                        .then(res=>{
                        toastLoading.clear();
                        if(res.data.data==="用户验证成功"){
                            this.$toast.success(this.$t('MobileChangePayPassword.alertPasswordSuccessfully'));
                            this.$router.go(-1);
                        }else{
                            this.$toast.fail(res.data.data);
                            this.firstInputPassword = "";
                            this.secondInputPassword = "";
                            this.code="";
                            this.firstInput = true;
                        }
                    }).catch(err=>{
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileChangePayPassword.alertPasswordFailed'));
                        this.firstInputPassword = "";
                        this.secondInputPassword = "";
                        this.code="";
                        this.firstInput = true;
                    })

                }
                else if(!this.hasSetPassword&&this.oldPassword==="")
                //初次设置密码(注意要多判断oldPassword是否为空)
                {
                    const toastLoading= this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        message: this.$t('MobileChangePayPassword.alertSettingPassword'),
                        forbidClick: true,
                    });
                    this.$http.post("/v1/register/insertppd", this.secondInputPassword,{
                        headers: {
                            'Content-Type': 'application/octet-stream' // 设置请求头为二进制流
                        }})
                        .then(res=>{
                        toastLoading.clear();
                        if(res.data.data==="支付密码设置成功"){
                            this.$toast.success(this.$t('MobileChangePayPassword.alertPasswordSuccessfully'));
                            this.$router.go(-1);
                        }else{
                            this.$toast.fail(this.$t('MobileChangePayPassword.alertPasswordFailed'));
                            this.firstInputPassword = "";
                            this.secondInputPassword = "";
                            this.firstInput = true;
                            this.hasSetPassword=false;
                        }
                    }).catch(err=>{
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileChangePayPassword.alertPasswordFailed'));
                        this.firstInputPassword = "";
                        this.secondInputPassword = "";
                        this.firstInput = true;
                        this.hasSetPassword=false;
                    })
                }
                else
                //根据原始密码修改密码
                {
                    const toastLoading= this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        message: this.$t('MobileChangePayPassword.alertChangingPassword'),
                        forbidClick: true,
                    });
                    this.$http.post("/v1/register/original-isok-updateppd",{
                        originalpaymentpassword: this.oldPassword,
                        paymentpassword: this.secondInputPassword
                    }).then(res=>{
                        toastLoading.clear();
                        if(res.data.data==="支付密码设置成功"){
                            this.$toast.success(this.$t('MobileChangePayPassword.alertChangePasswordSuccessfully'));
                            this.$router.go(-1);
                        }else{
                            this.$toast.fail(this.$t('MobileChangePayPassword.alertChangePasswordFailed'));
                            this.firstInputPassword = "";
                            this.secondInputPassword = "";
                            this.firstInput = true;
                            this.oldPassword=""
                            this.hasSetPassword=false;
                        }
                    }).catch(err=>{
                        toastLoading.clear();
                        this.$toast.fail(this.$t('MobileChangePayPassword.alertChangePasswordFailed'));
                        this.secondInputPassword = "";
                    })
                }
                this.loadCodeImg();

            }
        }

    },
    methods:{
        onForgetPayPassword(){
            this.loadCodeImg();
            this.forgetPassword=true;
            this.showSet=false;
            this.hasSetPassword=false;
        //    稍后需经过手机验证
            Notify({ type: 'primary', message: "稍后需经过手机验证" });
        },
        goBack() {
            this.$router.go(-1);
        },
        getMobilePhoneCode() {
            // 手机端点击获取验证码
            const toastLoading= this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: '正在发送验证码',
                forbidClick: true,
            });

            this.$http.post("/v1/register/get-code-ofppd",this.imgCode,{
                    headers: {
                        'Content-Type': 'application/octet-stream' // 设置请求头为二进制流
                    }})
                .then((response) => {
                    toastLoading.clear();
                    this.startCountdown();
                    // 处理返回值
                })
                .catch((error) => {
                    // 处理错误
                    alert("网络异常,请重试");
                });
        },
        /* 获取验证码后计时重新发送 */
        startCountdown() {
            // 设置倒计时60s
            this.mobile.timeCount = 60;
            this.mobile.hasSent = false;
            // 计时器
            this.mobile.timer = setInterval(() => {
                if (this.mobile.timeCount > 0) {
                    this.mobile.timeCount--;
                } else {
                    this.mobile.hasSent = true;
                    clearInterval(this.mobile.timer);
                    this.mobile.timer = null;
                }
            }, 1000);
        },
        onNext(){

        },
        loadCodeImg(){
            ///register/captcha
            this.$http.get("/v1/register/captcha",
                    {
                        responseType: "blob", // 设置响应类型为blob
                    })
                .then((response) => {
                    // 处理返回值
                    const imageBlob = response.data;
                    this.imgCodeUrl = URL.createObjectURL(imageBlob);
                })
        },
    },
}

</script>

<style scoped>
.my-input {
    @apply text-sm w-48 py-1 mr-2 px-1 border-b border-gray-300 focus:outline-none;
}
</style>